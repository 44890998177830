import React from "react"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { ObjectSettings } from "src/widgets/ObjectSettings/ObjectSettings"
import { Auth } from "../components/Auth/Auth"
import { Settings } from "../components/Settings/Settings"
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../components/shadcnui/ui/resizable"
import Header from "../widgets/Header/Header"
import LeftBar from "../widgets/LeftBar/LeftBar"

export const Layout = ({
  children,
}: Readonly<{
  children: React.ReactNode
}>) => {
  const { isAuth } = useAppSelector((state) => state.auth)
  const { isOpen } = useAppSelector((state) => state.globalSettings)
  const { vehicle_uid, groups_tree_id } = useAppSelector((state) => state.osId)
  return (
    <div>
      {vehicle_uid != null && groups_tree_id && (
        <div className="absolute z-[2000] w-screen h-screen left-0 top-0 flex items-center justify-center backdrop-blur-md ">
          <div className="overflow-hidden rounded border animate-inner-g-settings">
            <ObjectSettings />
          </div>
        </div>
      )}

      {isAuth == true ? (
        <>
          {isOpen && <Settings />}
          <ResizablePanelGroup
            direction="horizontal"
            className="max-w-screen max-h-screen w-screen h-screen overflow-hidden"
          >
            <ResizablePanel tagName="nav" defaultSize={20} minSize={20} maxSize={25}>
              <LeftBar />
            </ResizablePanel>

            <ResizableHandle className="w-[1px] opacity-0 relative " />

            <ResizablePanel
              tagName="section"
              defaultSize={80}
              minSize={75}
              maxSize={80}
              style={{ height: "100vh", display: "flex", flexDirection: "column" }}
            >
              <Header />

              {children}
            </ResizablePanel>
          </ResizablePanelGroup>
        </>
      ) : (
        <>
          <Auth />
        </>
      )}
    </div>
  )
}
