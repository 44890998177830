import { IBodyTableQuery } from "@/widgets/Table/model/tableThunk"
import { returnErrorFetch, serverInstance } from "./settingsApiInstance"

interface ITableTpmsApi {
  getTpmsTable: (bodyTable: IBodyTableQuery) => Promise<{ data: any; status: number }>
}

export const tableTpmsApi = {
  getTpmsTable: async (bodyTable) => {
    try {
      const { data, status } = await serverInstance.post("tpms/v1/reports/get_report_tpms", {
        vehicle_uids: bodyTable.vehicle_uids,
        driver_uids: bodyTable.driver_uids,
      })
      return { data, status }
    } catch (error: any) {
      return returnErrorFetch(error)
    }
  },
}
