import React from "react"

type propsType = {
  checked: boolean
  onChecked: () => void
  children: React.ReactNode
}

export const CheckboxItem: React.FC<propsType> = ({ checked, onChecked, children }) => {
  return (
    <div className="foldercheckbox-head">
      <label
        className={`foldercheckbox-head__checkbox ${checked ? "foldercheckbox-head__checkbox--checked" : ""}`}
      >
        <div className={checked ? "icon-checked" : ""}></div>
        <input type="checkbox" checked={checked} onChange={() => onChecked()} />
      </label>

      <div className="foldercheckbox-head__title">{children}</div>
    </div>
  )
}
