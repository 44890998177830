import { AppDispatch } from "@/store/store"
import { setSwitchTire } from "../../../shared/api/apiGlobal"
import { thunkGetCarData } from "../car/carThunk"
import { ISwitchReq } from "./switchTire"
import { ISwitchHistoryReq } from "@/interfaces/switchHistory"
import { getSwitchHistory, removeSwitchHistory } from "../../../shared/api/apiGlobal"
import { setRemoveSwitchHistory, setSwitchHistoryData } from "./switchTire"

export const switchTireThunk = (data: ISwitchReq, fun?) => {
  return async (dispatch: AppDispatch) => {
    const res = await setSwitchTire(data)
    if (res && res?.data.length != 0) {
      dispatch(thunkGetCarData(data.vehicle_uid))
      fun && fun()
    } else {
      console.error("Запрос данных графиков шины оказался неудачным")
    }
  }
}

export const getSwitchHistoryThunk = (body: ISwitchHistoryReq) => {
  return async (dispatch: AppDispatch) => {
    const res = await getSwitchHistory(body)
    if (res && res?.data.length != 0) {
      dispatch(setSwitchHistoryData(res.data))
    } else {
      dispatch(setSwitchHistoryData([]))
    }
  }
}

export const removeSwitchHistoryThunk = (id: number) => {
  return async (dispatch: AppDispatch) => {
    const res = await removeSwitchHistory(id)
    dispatch(setRemoveSwitchHistory(id))
  }
}
