import { useEffect, useState } from "react"
import { noString } from "src/shared/lib/helpers/notStringData"
import { useAppDispatch, useAppSelector } from "../../../../../../../../shared/lib/hooks/store"
import { updateGSItemData } from "src/store/reducers/globalSettings/globalSettings"
import { ITreeSwitches } from "../TreeSwiches"
import { TreeSettings } from "./TreeSettings"
import { groupNameFinder } from "./groupNameFinder"

export const GeozoneTreeUGS = ({ handler }: ITreeSwitches) => {
  const dispatch = useAppDispatch()
  const [openSubmit, setOpenSubmit] = useState(false)
  const groups = useAppSelector((state) =>
    noString(state.globalSettings.tree.users.itemSettings.settingsData),
  ).groups_tree
  const [id, setId] = useState(groups.geozone_groups_tree_id || 0)
  const tree = useAppSelector(
    (state) => state.globalSettings.tree.users.itemSettings.treeWithoutItems,
  )

  const changeElem = (id) => {
    setId(id)
    setOpenSubmit(true)
  }

  const onSubmit = () => {
    let arr = JSON.parse(JSON.stringify(groups))

    arr.geozone_groups_tree_id = id
    dispatch(
      updateGSItemData({
        value: arr,
        field: "users",
        profileField: "groups_tree",
      }),
    )
    handler(null)
  }
  useEffect(() => {}, [tree, changeElem])
  return (
    <div className="w-[90%] overflow-hidden h-[90%] bg-slate-600 text-white rounded relative shadow-xl p-2 ">
      <div className="text-3xl font-light ">Группа гео зон</div>
      <div className="py-7 flex items-center justify-start gap-3">
        <p>Выбрано:</p>
        <span>{id > 0 ? groupNameFinder(id, [tree]) || "Н/Д" : "Ничего не выбрано"}</span>
      </div>
      <div className="overflow-y-auto w-full h-full max-h-[70%] pb-10">
        {tree && <TreeSettings data={tree} func={changeElem} active={id} />}
      </div>

      <div
        onClick={onSubmit}
        className="z-10 h-16 text-xl cursor-pointer transition-all absolute  w-full bottom-0 left-0  flex items-center justify-center "
      >
        <div
          style={{
            top: openSubmit ? "-10%" : "150%",
          }}
          className="w-[90%] flex items-center justify-center relative bg-slate-700 text-white hover:bg-slate-800 active:bg-slate-950 transition-all text-center h-[80%] rounded"
        >
          Подтвердить
        </div>
      </div>
    </div>
  )
}
