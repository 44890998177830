import { IData } from "@/interfaces/global"
import {
  IMovement,
  IObjectSettingsData,
  IObjectSettingsDiag,
  IObjectSettingsMain,
  ISpeedControlViolation,
  PressureNormKoef,
  Tpms,
} from "@/interfaces/objectSettings"
import { IOSTrack } from "@/widgets/ObjectSettings/Content/ObjectSettingsContentItems/Track/interface"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import defaultObject from "src/components/Settings/Content/Help/defaultObject.json"
import {
  IFuelSensor,
  IFuelTank,
} from "src/widgets/ObjectSettings/Content/ObjectSettingsContentItems/Fuel/interfaces"
import { objectSettingsRuNames } from "src/widgets/ObjectSettings/objectSettingsRuNames"
import { IDriverSettings } from "./../../../interfaces/objectSettings"
import { axleCreator } from "./objectTemplates/axleTemplate"
import { wheelCreator } from "./objectTemplates/wheelTemplate"

interface INewDataPropsType {
  id: number
  position: "left" | "right"
}
interface ISpeedViolationProps {
  field:
    | "max_limit"
    | "reg_limit"
    | "is_enabled"
    | "reg_time_limit"
    | "reg_critical_limit"
    | "use_road_signs_instead_max_limit"
  value: boolean | number
}
interface IDriverSettingsEvent {
  field:
    | "is_enabled"
    | "end_registration_by_removing_card"
    | "end_registration_by_turning_off_ignition"
    | "restore_registration_if_card_reapplied_within"
  value: boolean | number
}
interface IDriverSettingsCPM {
  field: "addr" | "func" | "regNo" | "dataSource" | "use_as_driver_code"
  value: boolean | number
}
export interface INewNormsPropsType {
  field: "pressure_norm" | "pressure_delta" | "temperature_max"
  id: number
  value: number
}
export interface IPlugins {
  id: number
  name: string
}
type IItemData = {
  tab: string
  title: string
  data:
    | IObjectSettingsMain
    | IObjectSettingsDiag
    | IDriverSettings
    | ISpeedControlViolation
    | Tpms[]
    | IOSTrack
}
export interface INewData {
  main: IObjectSettingsMain
  diag: IObjectSettingsDiag
  driver_cards: IDriverSettings
  speed_control: ISpeedControlViolation
  tpms: Tpms
  vehicle_uid: string
}
export interface IAccounts {
  account_name: string
  create_date: number
  id: number
  is_control_user_agent: boolean
  is_enabled: boolean
  level: number
  parent_id?: number
  rights: number
  role_id: number
}
interface ISettingState extends IData<IObjectSettingsData> {
  [x: string]: any
  accounts: IAccounts[] | null | "error" | "loading"
  plugins: IPlugins[] | null | "error" | "loading"
  uid: string | null
  isOpen: boolean
  account_id: null | number
  activeTab: string
  dataToView: IItemData[] | null
  isManualScroll: boolean
  groupId: number | null
  newData: {
    main: IObjectSettingsMain | null
    diag: IObjectSettingsDiag | null
    driver_cards: IDriverSettings | null
    speed_control: ISpeedControlViolation | null
    tpms: Tpms | null
    vehicle_uid: string | null
    vehicle_groups_tree_id: number | null
    movement: IMovement | null
    fuel: IFuelTank[] | null
    track: IOSTrack | null
  }
}

const initialState: ISettingState = {
  data: null,
  uid: "",
  account_id: null,
  accounts: [],
  plugins: null,
  isOpen: false,
  activeTab: "main",
  dataToView: null,
  groupId: null,
  isManualScroll: false,
  newData: {
    vehicle_groups_tree_id: null,
    main: null,
    diag: null,
    driver_cards: null,
    speed_control: null,
    tpms: null,
    vehicle_uid: null,
    movement: null,
    fuel: null,
    track: null,
  },
}

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // Обязательный
    setObjectSettingsAccountId: (state: ISettingState, action: PayloadAction<number | null>) => {
      state.account_id = action.payload
    },
    // Обязательный
    setObjectSettingsUid: (state: ISettingState, action: PayloadAction<string | null>) => {
      state.uid = action.payload
    },
    setOpenSettings: (state: ISettingState, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
      state.data = null
      state.newData.main = null
      state.newData.diag = null
      state.newData.driver_cards = null
      state.newData.tpms = null
      state.newData.speed_control = null
      state.newData.movement = null
      state.dataToView = null
      state.newData.vehicle_groups_tree_id = null
      state.track = null
      state.uid = ""
    },
    setActiveMenuTab: (state: ISettingState, action: PayloadAction<string>) => {
      state.activeTab = action.payload
    },
    setManualScroll(state: ISettingState, action: PayloadAction<boolean>) {
      state.isManualScroll = action.payload
    },
    setObjectSettingsData: (
      state: ISettingState,
      action: PayloadAction<IData<IObjectSettingsData> | null>,
    ) => {
      if (action.payload != null) {
        const dataFromApi = action.payload.data
        state.data = dataFromApi
        if (
          dataFromApi !== null &&
          typeof dataFromApi != "string" &&
          typeof state.data != "string" &&
          state.data != null
        ) {
          const arr: any = []
          for (let i = 0; Object.keys(dataFromApi).length > i; i++) {
            arr.push({
              tab: Object.keys(dataFromApi)[i],
              title: objectSettingsRuNames[Object.keys(dataFromApi)[i]],
              data: dataFromApi[Object.keys(dataFromApi)[i]],
            })
          }
          state.newData.main = state.data.main || defaultObject.main
          state.newData.diag = state.data?.diag || defaultObject.diag
          state.newData.driver_cards = state.data?.driver_cards || defaultObject.driver_cards
          state.newData.driver_cards.CustomProtocolMonitoring =
            state.data.driver_cards.CustomProtocolMonitoring ||
            defaultObject.driver_cards.CustomProtocolMonitoring
          state.newData.tpms = state.data?.tpms || defaultObject.tpms
          state.newData.speed_control = state.data?.speed_control || defaultObject.speed_control
          state.newData.movement = state.data.movement || defaultObject.movement
          state.newData.movement.events =
            state.data.movement?.events || defaultObject.movement.events
          state.newData.vehicle_groups_tree_id = state.groupId
          state.dataToView = arr || defaultObject
          state.newData.vehicle_uid = state.data.vehicle_uid || ""
          state.newData.fuel = state.data.fuel || defaultObject.fuel
          state.newData.track = state.data.track.colors ? state.data.track : defaultObject.track
        } else {
          state.data = null
          state.newData.main = null
          state.newData.diag = null
          state.newData.driver_cards = null
          state.newData.tpms = null
          state.newData.speed_control = null
          state.dataToView = null
          state.newData.movement = null
          state.newData.vehicle_groups_tree_id = null
          state.newData.fuel = null
        }
      }
    },
    setNewData: (state: ISettingState) => {
      return
    },
    createAxle: (state: ISettingState) => {
      state.newData.tpms?.wheel_axes?.push(axleCreator())
    },
    createWheel: (state: ISettingState, action: PayloadAction<INewDataPropsType>) => {
      const id = action.payload.id
      const position = action.payload.position
      if (id > 0) {
        switch (position) {
          case "left":
            state.newData.tpms?.wheel_axes
              ?.find((i) => i.id == id)
              ?.wheels[0].push(wheelCreator("L"))
            break
          case "right":
            state.newData.tpms?.wheel_axes
              ?.find((i) => i.id == id)
              ?.wheels[1].push(wheelCreator("R"))
        }
      } else {
        switch (position) {
          case "left":
            state.newData.tpms?.wheel_axes
              ?.find((i) => i.innerAxleId == id)
              ?.wheels[0].push(wheelCreator("L"))
            break
          case "right":
            state.newData.tpms?.wheel_axes
              ?.find((i) => i.innerAxleId == id)
              ?.wheels[1].push(wheelCreator("R"))
            break
        }
      }
    },
    removeWheel: (state: ISettingState, action: PayloadAction<number>) => {
      const id = action.payload

      if (state.newData.tpms?.wheel_axes) {
        state.newData.tpms.wheel_axes = state.newData.tpms?.wheel_axes.map((axle) => ({
          ...axle,
          wheels: axle.wheels.map((wheel) =>
            id > 0
              ? wheel.filter((item) => item.id !== id)
              : wheel.filter((item) => item.innerWheelId !== id),
          ),
        }))
      } else {
        state.newData.tpms = null
      }
    },
    removeAxle: (state: ISettingState, action: PayloadAction<number>) => {
      const axleId = action.payload

      if (state.newData.tpms?.wheel_axes) {
        if (axleId > 0) {
          state.newData.tpms.wheel_axes = state.newData.tpms.wheel_axes.filter(
            (axle) => axle.id !== axleId,
          )
        } else {
          state.newData.tpms.wheel_axes = state.newData.tpms.wheel_axes.filter(
            (axle) => axle.innerAxleId !== axleId,
          )
        }
      } else {
        state.newData.tpms = null
      }
    },
    updateSensorNumber: (
      state: ISettingState,
      action: PayloadAction<{ wheelId: number; text: number }>,
    ) => {
      const { wheelId, text } = action.payload

      // Проверка, существует ли массив wheel_axes
      if (state.newData.tpms?.wheel_axes) {
        // Обновление состояния
        state.newData.tpms.wheel_axes = state.newData.tpms.wheel_axes.map((axle) => ({
          ...axle,
          wheels: axle.wheels.map((wheelArray) =>
            wheelArray.map((wheel) =>
              (wheelId > 0 && wheel.id === wheelId) ||
              (wheelId <= 0 && wheel.innerWheelId === wheelId)
                ? { ...wheel, sensor_number: text }
                : wheel,
            ),
          ),
        }))
      }
    },
    updateNorms: (state: ISettingState, action: PayloadAction<INewNormsPropsType>) => {
      const { id, field, value } = action.payload
      if (id > 0) {
        const item = state.newData.tpms?.wheel_axes?.find((i) => i.id === id)

        // Update the field if the item is found
        if (item) {
          item[field] = value
        }
      } else {
        const item = state.newData.tpms?.wheel_axes?.find((i) => i.innerAxleId === id)

        // Update the field if the item is found
        if (item) {
          item[field] = value
        }
      }
    },
    updateDiagControl: (state: ISettingState, action: PayloadAction<boolean>) => {
      if (state.newData.diag) {
        state.newData.diag.is_enabled = action.payload
      } else {
      }
    },

    updateSpeedViolationData: (
      state: ISettingState,
      action: PayloadAction<ISpeedViolationProps | any>,
    ) => {
      const { field, value } = action.payload
      const valueType = typeof value
      const item = state.newData.speed_control

      if (item) {
        if (typeof item[field] === "boolean" && valueType === "boolean") {
          item[field] = value
        } else if (typeof item[field] == "number" && valueType == "number") {
          item[field] = value
        }
      }
    },
    updateDriverSettingsEvents: (
      state: ISettingState,
      action: PayloadAction<IDriverSettingsEvent | any>,
    ) => {
      const { field, value } = action.payload
      const valueType = typeof value
      const item = state.newData.driver_cards?.driver_events

      if (item) {
        if (typeof item[field] === "boolean" && valueType === "boolean") {
          item[field] = value
        } else if (typeof item[field] == "number" && valueType == "number") {
          item[field] = value
        }
      }
    },
    updateDriverSettingsCPM: (
      state: ISettingState,
      action: PayloadAction<IDriverSettingsCPM | any>,
    ) => {
      const { field, value } = action.payload
      const valueType = typeof value
      const item = state.newData.driver_cards?.CustomProtocolMonitoring
      if (item) {
        if (typeof item[field] === "boolean" && valueType === "boolean") {
          item[field] = value
        } else if (typeof item[field] == "number" && valueType == "number") {
          item[field] = value
        }
      }
    },
    updateDriverSettingsEventsRRCRW: (state: ISettingState, action: PayloadAction<number>) => {
      if (state.newData.driver_cards && state.newData.driver_cards.driver_events) {
        state.newData.driver_cards.driver_events.restore_registration_if_card_reapplied_within =
          action.payload
      }
    },
    updateObjectSettingsMain: (
      state: ISettingState,
      action: PayloadAction<IObjectSettingsMain>,
    ) => {
      state.newData.main = action.payload
    },
    updateObjectSettingsCorrectKoef: (
      state: ISettingState,
      action: PayloadAction<PressureNormKoef[]>,
    ) => {
      if (state.newData.tpms != null) {
        state.newData.tpms.settings.wheel_axes.pressure_norm_koef = action.payload
      }
    },
    updateObjectSettingsWheelsPressValidTime: (
      state: ISettingState,
      action: PayloadAction<number>,
    ) => {
      if (state.newData.tpms?.settings.sensors.valid_time_period != null) {
        state.newData.tpms.settings.sensors.valid_time_period = action.payload
      }
    },
    setAccountsList: (
      state: ISettingState,
      action: PayloadAction<IAccounts[] | null | "error" | "loading">,
    ) => {
      state.accounts = action.payload
    },
    setPluginsList: (
      state: ISettingState,
      action: PayloadAction<IPlugins[] | null | "error" | "loading">,
    ) => {
      state.plugins = action.payload
    },
    setObjectSettingsVehicleUid: (state: ISettingState, action: PayloadAction<string | null>) => {
      state.newData.vehicle_uid = action.payload
    },
    updateMovement: (state: ISettingState, action: PayloadAction<IMovement | null>) => {
      state.newData.movement = action.payload
    },
    setOSGroupsTreeID: (state: ISettingState, action: PayloadAction<number | null>) => {
      state.groupId = action.payload
    },
    setTPMSDownAxleNorms: (state: ISettingState, action: PayloadAction<number>) => {
      const index = action.payload
      if (state.newData.tpms) {
        const pressure_delta = state.newData.tpms?.wheel_axes[index - 1].pressure_delta
        const pressure_norm = state.newData.tpms?.wheel_axes[index - 1].pressure_norm
        const temperature_max = state.newData.tpms?.wheel_axes[index - 1].temperature_max

        state.newData.tpms.wheel_axes[index].temperature_max = temperature_max
        state.newData.tpms.wheel_axes[index].pressure_delta = pressure_delta
        state.newData.tpms.wheel_axes[index].pressure_norm = pressure_norm
      }
    },
    setNewFuelTank: (state: ISettingState, action: PayloadAction<IFuelTank>) => {
      state.newData.fuel?.push(action.payload)
    },
    deleteFuelTank: (state: ISettingState, action: PayloadAction<number>) => {
      state.newData.fuel?.filter((item: IFuelTank) => item.id !== action.payload)
    },
    setNewFuelSensor: (
      state: ISettingState,
      action: PayloadAction<{ sensor: IFuelSensor; tankIndex: number }>,
    ) => {
      const tankIndex = action.payload.tankIndex
      const sensor = action.payload.sensor
      if (state.newData.fuel) {
        state.newData.fuel[tankIndex].settings.sensors.unshift(sensor)
      }
    },
    deleteFuelSensor: (
      state: ISettingState,
      action: PayloadAction<{ sensorIndex: number; tankIndex: number }>,
    ) => {
      const sensorIndex = action.payload.sensorIndex
      const tankIndex = action.payload.tankIndex

      if (state.newData.fuel) state.newData.fuel[tankIndex].settings.sensors.splice(sensorIndex, 1)
    },
    setNewCalibrCol: (
      state: ISettingState,
      action: PayloadAction<{ sensorIndex: number; tankIndex: number; col: number[] }>,
    ) => {
      const sensorIndex = action.payload.sensorIndex
      const tankIndex = action.payload.tankIndex
      const col = action.payload.col
      if (state.newData.fuel) {
        let newCol = state.newData.fuel[tankIndex].settings.sensors[sensorIndex].calibr

        // Сортировка массива по возрастанию значений во второй колонке
        newCol.sort((a, b) => a[1] - b[1])

        // Удаление дубликатов
        newCol = newCol.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t[0] === item[0] && t[1] === item[1]),
        )

        // Обновление состояния
        state.newData.fuel[tankIndex].settings.sensors[sensorIndex].calibr = newCol
      }
    },
    deleteCalibrCol: (
      state: ISettingState,
      action: PayloadAction<{ sensorIndex: number; tankIndex: number; colIndex: number }>,
    ) => {
      const sensorIndex = action.payload.sensorIndex
      const tankIndex = action.payload.tankIndex
      const colIndex = action.payload.colIndex

      if (state.newData.fuel) {
        state.newData.fuel[tankIndex].settings.sensors[sensorIndex].calibr.splice(colIndex, 1)
      }
    },
    updateCalibrCol: (
      state: ISettingState,
      action: PayloadAction<{
        sensorIndex: number
        tankIndex: number
        colIndex: number
        newColData: any
      }>,
    ) => {
      const sensorIndex = action.payload.sensorIndex
      const tankIndex = action.payload.tankIndex
      const colIndex = action.payload.colIndex
      const newColData = action.payload.newColData
      if (state.newData.fuel) {
        state.newData.fuel[tankIndex].settings.sensors[sensorIndex].calibr[colIndex] = newColData
      }
    },
    updateTankInfo: (
      state: ISettingState,
      action: PayloadAction<{ tankIndex: number; field: string; newValue: number }>,
    ) => {
      if (state.newData.fuel)
        state.newData.fuel[action.payload.tankIndex].settings[action.payload.field] =
          action.payload.newValue
    },
    updateTankName: (
      state: ISettingState,
      action: PayloadAction<{ tankIndex: number; newValue: string }>,
    ) => {
      if (state.newData.fuel)
        state.newData.fuel[action.payload.tankIndex].name = action.payload.newValue
    },
    updateFuelSensorInfo: (
      state: ISettingState,
      action: PayloadAction<{ tankIndex: number; newValue: number; sensorIndex; field: string }>,
    ) => {
      if (state.newData.fuel)
        state.newData.fuel[action.payload.tankIndex].settings.sensors[action.payload.sensorIndex][
          action.payload.field
        ] = action.payload.newValue
    },
    updateTrackEnable: (state: ISettingState, action: PayloadAction<boolean>) => {
      if (state.newData.track) state.newData.track.colors.is_enabled = action.payload
    },
    updateTrackTimeLimit: (state: ISettingState, action: PayloadAction<number>) => {
      if (state.newData.track) state.newData.track.colors.dotted.time_limit = action.payload
    },
    updateTrackItem: (
      state: ISettingState,
      action: PayloadAction<{ value: any; index: number; field: string; firstCreate?: boolean }>,
    ) => {
      const { value, index, field } = action.payload
      if (state.newData.track) {
        state.newData.track.colors.ranges[index][field] = value
      }
    },
    createTrackItem: (state: ISettingState) => {
      const firstCreate = state.newData.track?.colors.ranges.length == 0
      const newItem = { speed: 60, color: "#ff0000", time_limit: 0 }
      const lastItem = { speed: -1, color: "#ff0000", time_limit: 0 }
      let createItem = { speed: 0, color: "#ff0000", time_limit: 0 }
      if (firstCreate) {
        state.newData.track?.colors.ranges.push(newItem, lastItem)
      } else {
        const length = state.newData.track?.colors.ranges.length || 0
        const speed = state.newData.track?.colors.ranges[length - 2].speed
        if (speed) {
          createItem.speed = speed + 10
        }

        state.newData.track?.colors.ranges.splice(length - 1, 0, createItem)
      }
      state.newData.track?.colors.ranges.sort((a, b) => {
        if (a.speed === -1) return 1
        if (b.speed === -1) return -1
        return a.speed - b.speed
      })
    },
    removeTrackItem: (state: ISettingState, action: PayloadAction<number>) => {
      if (state.newData.track) state.newData.track.colors.ranges.splice(action.payload, 1)
    },
  },
})

export const {
  createTrackItem,
  removeTrackItem,
  updateTrackItem,
  updateTrackTimeLimit,
  updateTrackEnable,
  updateFuelSensorInfo,
  updateTankName,
  updateTankInfo,
  updateCalibrCol,
  deleteCalibrCol,
  setNewCalibrCol,
  setNewFuelSensor,
  deleteFuelSensor,
  deleteFuelTank,
  setNewFuelTank,
  setTPMSDownAxleNorms,
  setObjectSettingsAccountId,
  setOSGroupsTreeID,
  updateMovement,
  setOpenSettings,
  setObjectSettingsUid,
  setActiveMenuTab,
  setObjectSettingsData,
  createAxle,
  createWheel,
  removeWheel,
  removeAxle,
  setManualScroll,
  updateSensorNumber,
  updateNorms,
  updateDiagControl,
  updateSpeedViolationData,
  updateDriverSettingsCPM,
  updateDriverSettingsEvents,
  updateDriverSettingsEventsRRCRW,
  updateObjectSettingsMain,
  updateObjectSettingsCorrectKoef,
  updateObjectSettingsWheelsPressValidTime,
  setAccountsList,
  setPluginsList,
  setObjectSettingsVehicleUid,
} = settingsSlice.actions
export default settingsSlice.reducer
