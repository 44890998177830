export const SwitchHistoryIcon = ({ width, fill }) => {
  return (
    <svg
      fill="none"
      height={width}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C10.2906 21 8.64935 20.5217 7.23062 19.6336C6.6528 19.2719 6.11762 18.8456 5.63567 18.3636C5.15297 17.8808 4.72614 17.3447 4.36416 16.7658C3.4775 15.3479 3 13.7079 3 12C3 11.726 3.01228 11.4533 3.03669 11.1826C3.08628 10.6325 3.57239 10.2268 4.12244 10.2764C4.6725 10.326 5.0782 10.8121 5.02861 11.3622C5.00958 11.5732 5 11.786 5 12C5 13.3302 5.37066 14.6032 6.05992 15.7055C6.34164 16.156 6.67401 16.5735 7.04996 16.9495C7.42534 17.3249 7.84208 17.6569 8.29179 17.9384C9.39466 18.6287 10.6687 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C10.1286 5 8.38425 5.7394 7.09794 7.00204L8.49771 7.00341C9.04999 7.00341 9.49771 7.45113 9.49771 8.00341C9.49771 8.51625 9.11167 8.93892 8.61433 8.99669L8.49771 9.00341H4.49631C3.98347 9.00341 3.5608 8.61737 3.50304 8.12004L3.49631 8.00341V4.00351C3.49631 3.45123 3.94402 3.00351 4.49631 3.00351C5.00915 3.00351 5.43182 3.38955 5.48958 3.88689L5.49631 4.00351L5.49589 5.77846C7.1661 4.03158 9.49557 3 12 3ZM11.25 7C11.6295 7 11.9435 7.28233 11.9931 7.64827L12 7.75V12H14.25C14.664 12 15 12.336 15 12.75C15 13.1295 14.7177 13.4435 14.3517 13.4931L14.25 13.5H11.25C10.8705 13.5 10.5565 13.2177 10.5069 12.8517L10.5 12.75V7.75C10.5 7.336 10.836 7 11.25 7Z"
        fill={fill}
      />
    </svg>
  )
}
