import { useAppDispatch } from "src/shared/lib/hooks/store"
import { NumberComponent } from "src/widgets/ObjectSettings/Content/helpComponents/NumberComponent"

import { updateFuelSensorInfo } from "src/store/reducers/objectSettings/objectSettingsPageSlices/fuel/fuel"
import { IFuelSensor } from "../../../interfaces"
interface ISensorInfoProps {
  info: IFuelSensor
  tankIndex: number
  sensorIndex
}
export const SensorInfo = ({ info, tankIndex, sensorIndex }: ISensorInfoProps) => {
  const dispatch = useAppDispatch()
  const fields = [
    {
      name: "k_add",
      value: info.k_add,
      type: "number",
      field: "k_add",
    },
    {
      name: "k_mul",
      value: info.k_mul,
      type: "number",
      field: "k_mul",
    },
  ]
  return (
    <div className="max-w-[85%]">
      {fields.map((field, index) => (
        <div className="flex items-center justify-center gap-1">
          <p>{field.name}</p>
          <NumberComponent
            value={field.value}
            onChange={(value) =>
              dispatch(
                updateFuelSensorInfo({
                  tankIndex: tankIndex,
                  newValue: value,
                  sensorIndex: sensorIndex,
                  field: field.field,
                }),
              )
            }
          />
        </div>
      ))}
    </div>
  )
}
