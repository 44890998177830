import { useState } from "react"
import { useAppDispatch } from "src/shared/lib/hooks/store"

import { updateTankName } from "src/store/reducers/objectSettings/objectSettingsPageSlices/fuel/fuel"
import { TextareaComponent } from "../../../helpComponents/TextareaComponent"
import "../fuel.module.css"
import { IFuelTank } from "../interfaces"
import { FuelSensors } from "./Sensors/FuelSensors"
import { TankInfo } from "./TankInfo"
interface IFuelTankProps {
  tank: IFuelTank
  tankIndex: number
}
export const FuelTank = ({ tank, tankIndex }: IFuelTankProps) => {
  const dispatch = useAppDispatch()
  const [changeName, setChangeName] = useState(false)

  return (
    <>
      {tank && (
        <div className="flex w-full flex-col bg-slate-200 rounded max-h-[50vh] h-full gap-2 shadow-xl overflow-hidden overflow-y-auto">
          <div className=" h-fit w-full flex items-center justify-center text-center text-xl py-2">
            {!changeName ? (
              <div onClick={() => setChangeName(true)} className="text-2xl cursor-text">
                {tank.name}
              </div>
            ) : (
              <TextareaComponent
                onBlur={() => setChangeName(false)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setChangeName(false)
                  }
                }}
                classNames="text-2xl px-3 font-normal bg-transparent resize-none border border-orange-300 rounded focus:border focus:border-orange-500 flex items-center justify-center mx-auto min-w-1/5 w-fit"
                value={tank.name}
                onChange={(e) =>
                  dispatch(
                    updateTankName({
                      tankIndex,
                      newValue: e.target.value,
                    }),
                  )
                }
              />
            )}
          </div>
          <div
            style={{
              gridTemplateColumns: "70% 0.3% 29.7%",
            }}
            className="w-full grid h-full"
          >
            <div className="p-3">
              <FuelSensors sensors={tank.settings.sensors} tankIndex={tankIndex} />
            </div>
            <div className="w-full h-full bg-slate-300 rounded"></div>
            <div className="p-3 h-full">
              <TankInfo settings={tank.settings} tankIndex={tankIndex} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
