import { useAppSelector } from "../../../shared/lib/hooks/store"
import { useEffect } from "react"
import { Circles } from "../../../entities/Circles/Circles"
import { Rectangles } from "../../../entities/Rectangles/Rectangles"
import { Polygons } from "../../../entities/Polygons/Polygons"
import { useMap } from "react-leaflet"
import L from "leaflet"

export const Geozones = () => {
  const geozonesInfo = useAppSelector((state) => state.mapGeozone.infoGeozones)
  const uidEditor = useAppSelector((state) => state.mapEditor.creatorFigure.geozone_uid)
  const map = useMap()

  useEffect(() => {
    if (geozonesInfo.length > 0) {
      const pointsGeozones: any[] = []
      geozonesInfo
        .map((geozone) => geozone.geozone_points)
        .map((points) => {
          points.forEach((point) => {
            pointsGeozones.push([point.lat, point.lng])
          })
        })

      const points = L.polyline(pointsGeozones)
      const bounds = points.getBounds()
      map.fitBounds(bounds)
    }
  }, [geozonesInfo])

  return (
    <>
      {geozonesInfo
        .filter((geozone) => geozone.geozone_uid !== uidEditor)
        .map((geozone, key) => {
          if (geozone.geometry_type_id === 0 && geozone.latitude && geozone.longitude) {
            return <Circles geozone={geozone} key={key} index={key} />
          }
          if (geozone.geometry_type_id === 1) {
            return <Rectangles geozone={geozone} key={key} index={key} />
          }
          if (geozone.geometry_type_id === 2) {
            return <Polygons geozone={geozone} key={key} index={key} />
          }
        })}
    </>
  )
}
