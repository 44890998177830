import { organizeObjects } from "src/shared/ui/SettingsTree/helpers"
import { findPath } from "src/shared/ui/SettingsTree/helpers/findPath"

export function objectsSearchHelper(data: any[], searchTerm: string): any[] {
  const lowerSearchTerm = searchTerm.toLowerCase()

  // Function to flatten vehicle data
  function flattenVehicleData(data: any[], parent: any = null): any[] {
    const result: any[] = []
    const itemMap: { [id: string]: any } = {}
    const levelObjects: any = organizeObjects(data)
    function findAccountId(parent: any): number | null {
      if (parent?.is_root && parent?.role_id === 3 && parent?.is_folder) {
        return parent.id
      }
      if (parent?.parent_id && itemMap[parent.parent_id]) {
        return findAccountId(itemMap[parent.parent_id])
      }
      return null
    }

    function addItem(item: any, parent: any): void {
      // Skip items with role_id 1 or 2
      if (item.role_id === 1 || item.role_id === 2) {
        return
      }

      const pathName = `${parent?.path?.pathName || "root"}/${item.group_name || ""}`
      const folder: any = {
        ...item,
        name: item.group_name || "",
        children: [], // Initialize with an empty array of children
        path: findPath(levelObjects, item.id, true),
        is_folder: true, // Set is_folder to true for folders
      }

      // Add item to the map
      itemMap[item.id] = folder

      // If there are vehicles, add them as children
      if (item.vehicles) {
        const accountId = findAccountId(folder)
        folder.children = item.vehicles
          .filter((vehicle: any) => vehicle.role_id !== 1 && vehicle.role_id !== 2) // Exclude vehicles with role_id 1 or 2
          .map((vehicle: any) => ({
            ...vehicle,
            name: vehicle.vehicle_name, // Rename vehicle_name to name
            children: [], // Vehicles should not have children
            path: findPath(levelObjects, vehicle.vehicle_uid, false),
            is_folder: false, // Set is_folder to false for vehicles
            account_id: accountId, // Set account_id based on the first qualifying parent
          }))
        result.push(...folder.children)
      }

      // If there are nested folders, process them recursively
      if (item.children) {
        item.children.forEach((child: any) => addItem(child, folder))
      }

      // Add folder to the result if it has vehicles or children
      result.push(folder)
    }

    data.forEach((item) => addItem(item, parent))

    // Build hierarchy based on parent-child relationships
    for (const id in itemMap) {
      const item = itemMap[id]
      if (item.parent_id && itemMap[item.parent_id]) {
        // Ensure children is initialized as an array
        if (!itemMap[item.parent_id].children) {
          itemMap[item.parent_id].children = []
        }
        itemMap[item.parent_id].children.push(item)
      }
    }

    return result
  }

  // Function to filter folders and vehicles by the search term

  const flattened = flattenVehicleData(data)

  const returnedArr = flattened.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return returnedArr
}
