import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../shared/lib/hooks/store"
import { thunkCreateUpdateUser } from "src/store/reducers/globalSettings/globalSettingsThunk"
import { IUserInfo } from "src/store/reducers/globalSettings/interface"
import { noString } from "./../../../../../../shared/lib/helpers/notStringData"
import { Enable } from "./ContentItems/Enable"
import { IsMaster } from "./ContentItems/IsMaster"
import { Login } from "./ContentItems/Login"
import { MobilePhone } from "./ContentItems/MobilePhone"
import { Name } from "./ContentItems/Name"
import { Password } from "./ContentItems/Password"
import { TreeSwitches } from "./ContentItems/TreeSwiches"
import { DriversTreeUGS } from "./ContentItems/trees/DriversTreeUGS"
import { GeozoneTreeUGS } from "./ContentItems/trees/GeozonesTreeUGS"
import { VehicleTreeUGS } from "./ContentItems/trees/VehicleTreeUGS"
import { userProfileValidator } from "./ContentItems/trees/user-profile-validator"

interface IUserContent {
  user: IUserInfo | any
  accountID: number
}
export const Content = ({ user, accountID }: IUserContent) => {
  const [openTree, setOpenTree] = useState<null | "vehicle" | "geozone" | "driver">(null)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState<null | string>(null)
  const dispatch = useAppDispatch()
  const openTreeHandler = (treeName: null | "vehicle" | "geozone" | "driver") => {
    setOpenTree(treeName)
  }
  const data = useAppSelector((state) => state.globalSettings.tree.users.itemSettings.settingsData)

  const submitHandler = () => {
    setError(false)
    setErrorText(null)
    const { isValid, errorText } = userProfileValidator(noString(data))

    if (isValid) {
      dispatch(thunkCreateUpdateUser({ data, accountID, level: Number(user.level) }))
    } else {
      setError(true)
      setErrorText(errorText)
    }
  }

  return (
    <>
      <div className="flex w-full pb-32 overflow-hidden overflow-y-auto h-full pt-10 items-start justify-start px-10 cursor-default relative">
        <div className="absolute left-0 top-0 w-full flex items-center justify-end gap-5">
          {error && (
            <div className="bg-red-500 px-10 py-3 pr-32 shadow-2xl rounded w-fit text-lg text-white font-light flex items-center justify-center gap-5">
              <div className="">
                <p>Ошибка не заполнено поле:</p>
                <span>{errorText}</span>
              </div>
              <div className="icon-cross text-sm cursor-pointer text-white"></div>
            </div>
          )}
        </div>
        <div className="flex w-full items-start gap-5 justify-start flex-col">
          <Name />
          <Login />
          <MobilePhone />
          <Enable />
          <IsMaster />
          <fieldset className="w-full  rounded mx-auto flex items-start justify-start  py-5 border-2 border-red-500">
            <legend className="p-2 bg-white rounded ml-5 text-xl font-light text-red-500">
              Изменение пароля
            </legend>
            <Password />
          </fieldset>

          <TreeSwitches
            accountId={accountID}
            handler={(tree: null | "vehicle" | "geozone" | "driver") => openTreeHandler(tree)}
          />

          {/* <GMT /> */}
        </div>
        <div className="w-[30%] fixed top-0 right-0 text-sm h-full flex items-center justify-start">
          {openTree == "vehicle" && (
            <VehicleTreeUGS
              accountId={accountID}
              handler={(tree: null | "vehicle" | "geozone" | "driver") => openTreeHandler(tree)}
            />
          )}
          {openTree == "geozone" && (
            <GeozoneTreeUGS
              accountId={accountID}
              handler={(tree: null | "vehicle" | "geozone" | "driver") => openTreeHandler(tree)}
            />
          )}
          {openTree == "driver" && (
            <DriversTreeUGS
              accountId={accountID}
              handler={(tree: null | "vehicle" | "geozone" | "driver") => openTreeHandler(tree)}
            />
          )}
          {openTree == null && <></>}
        </div>
      </div>
      <div className="w-full h-20 absolute backdrop-blur-lg bottom-0 flex items-center justify-center">
        <div
          onClick={submitHandler}
          className="relative w-1/2 hover:bg-orange-600 active:bg-orange-700 transition-all text-white mb-2 flex items-center justify-center text-xl font-light rounded p-3 bg-orange-500 "
        >
          Сохранить
        </div>
      </div>
    </>
  )
}
