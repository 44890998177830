export const onlyNumber = (value: string) => {
  // Allow empty string
  if (value === "") {
    return ""
  }

  // Remove any non-numeric characters
  const cleanedValue = value.replace(/\D+/g, "")

  // If the cleaned value is still numeric, convert it to a number
  const newValue = Number(cleanedValue)

  // Return the number if valid, otherwise return an empty string
  return Number.isNaN(newValue) ? "" : newValue
}
