import { useState } from "react"
import {
  changeGroupCreate,
  thunkDeleteGroupGeozone,
} from "../../store/reducers/geozonesTree/thunkGeozonesTree"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { createTreeFilterGeozone } from "../../store/reducers/geozonesTree/selectorsGeozoneTree"
import { FolderCheckboxGeozone } from "./Geozone/FolderCheckboxGeozone/FolderCheckboxGeozone"
import { PopupNewGroup } from "./PopupNewGroup/PopupNewGroup"
import { PopupDeleteGroup } from "./PopupDeleteGroup /PopupDeleteGroup"
import { GeozoneItemMap } from "./Geozone/GeozoneItemMap/GeozoneItemMap"
import { GeozoneHeaderTree } from "./Geozone/GeozoneHeaderTree/GeozoneHeaderTree"

export const GeozoneTree = () => {
  const dispatch = useAppDispatch()

  const geozonesTreeAll = useAppSelector(createTreeFilterGeozone)
  const geozonesTree = geozonesTreeAll[0]?.children
  const rootGeozone: any = geozonesTreeAll[0] ? geozonesTreeAll[0] : []
  const search = useAppSelector((state) => state.geoZones.search)

  const [newGroupParentID, setNewGroupParentID] = useState<number>(0)

  const [newGroup, setNewGroup] = useState({
    group_name: "",
    account_id: 0,
    level: 0,
    isOpen: false,
  })
  const [deleteGroupInfo, setDeleteGroupInfo] = useState<null | { name: string; id: number }>(null)

  const deleteGroup = () => {
    if (!deleteGroupInfo) return
    dispatch(thunkDeleteGroupGeozone(deleteGroupInfo.id))
    setDeleteGroupInfo(null)
  }

  return (
    <>
      <GeozoneHeaderTree />
      <div className="tree-wrapper">
        <PopupNewGroup
          value={newGroup.group_name}
          isOpen={newGroup.isOpen}
          setValue={(value) => {
            setNewGroup((prev) => ({ ...prev, group_name: value }))
          }}
          setOpen={(isOpen) => {
            setNewGroup((prev) => ({ ...prev, isOpen: isOpen }))
          }}
          createGroup={() => {
            dispatch(
              changeGroupCreate(
                newGroupParentID,
                newGroup.account_id,
                newGroup.group_name,
                newGroup.level,
              ),
            )
            setNewGroup({ group_name: "", account_id: 0, isOpen: false, level: 0 })
          }}
        />
        <PopupDeleteGroup
          isOpen={!!deleteGroupInfo}
          setOpen={(value) => setDeleteGroupInfo(value)}
          nameGroup={deleteGroupInfo ? deleteGroupInfo?.name : ""}
          deleteGroup={deleteGroup}
        />
        {geozonesTree &&
          geozonesTree.map((group, key) => {
            return (
              <>
                <FolderCheckboxGeozone
                  group={group}
                  search={search}
                  setNewGroup={(value: any) =>
                    setNewGroup((prev) => ({ ...value, group_name: prev.group_name }))
                  }
                  setParentID={(id: number) => setNewGroupParentID(id)}
                  setInfoGroup={(id: number, name: string) =>
                    setDeleteGroupInfo({ name: name, id: id })
                  }
                  key={key}
                />
              </>
            )
          })}
        <GeozoneItemMap group={rootGeozone} />
      </div>
    </>
  )
}
