import { useEffect, useState } from "react"

import React from "react"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store/index"
import { setNewFuelTank } from "src/store/reducers/objectSettings/objectSettingsPageSlices/fuel/fuel"
import styles from "../styles/os-item.module.css"
import { defaultTank } from "./defaultObjects"
import { IFuelTank } from "./interfaces"
import { FuelTank } from "./Tank/FuelTank"

export const Fuel = React.memo((data) => {
  const dispatch = useAppDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const fuelData: IFuelTank[] | null = useAppSelector((state) => state.osFuel.fuel)
  const [dataD, setDataD] = useState<IFuelTank[] | null>(null)

  useEffect(() => {
    setDataD(fuelData)
  }, [fuelData])

  const createTank = (index: number) => {
    let newTank = JSON.parse(JSON.stringify(defaultTank))
    newTank.name = "Емкость №" + (index + 1)
    setActiveIndex(index)
    dispatch(setNewFuelTank(newTank))
  }
  return (
    <div className={styles.os_main}>
      {/* {activeCreate && <CreateTankPopup openHand={(open: boolean) => setActiveCreate(open)} />} */}

      <h1 className="">Емкости</h1>
      {fuelData && (
        <>
          <div className="w-full items-center justify-end flex my-3  gap-2">
            {dataD?.map((_, index) => (
              <div
                onClick={() => {
                  if (activeIndex !== index) setActiveIndex(index)
                }}
                className={` flex items-center justify-center   rounded aspect-square transition-all hover:shadow-xl   ${index == activeIndex ? "border-none active:bg-orange-700 bg-orange-500 hover:bg-orange-600  text-white w-[2.2rem]" : "bg-white border w-[2rem]"}`}
              >
                {index + 1}
              </div>
            ))}
            <div
              onClick={() => {
                createTank(dataD?.length || 0)
              }}
              className="w-[2rem] flex items-center justify-center hover:bg-slate-100 active:bg-slate-200   rounded aspect-square "
            >
              +
            </div>
          </div>
          <div className="flex items-center justify-center min-h-[50vh]">
            {dataD && dataD.length > 0 ? (
              <FuelTank tank={dataD[activeIndex]} tankIndex={activeIndex} />
            ) : (
              <div
                onClick={() => createTank(dataD?.length || 0)}
                className="w-[10rem] hover:bg-slate-100 shadow-none hover:shadow-lg active:shadow-xl  active:bg-slate-200 transition-all cursor-pointer aspect-square border-slate-400 border rounded flex items-center justify-center flex-col text-slate-400 gap-3 text-bold"
              >
                <p className="text-6xl ">+</p>
                <p>Создать емкость</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
})
