import { IIcon } from "@/interfaces/icon"

export const SearchIcon = ({ fill, width }: IIcon) => {
  return (
    <svg
      width={width}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs />
      <path
        id="XMLID_223_"
        d="M20.48 18.01L16.04 13.57C16.96 12.18 17.5 10.53 17.5 8.75C17.5 3.92 13.57 0 8.75 0C3.92 0 0 3.92 0 8.75C0 13.57 3.92 17.5 8.75 17.5C10.53 17.5 12.18 16.96 13.57 16.04L18.01 20.48C18.69 21.17 19.8 21.17 20.48 20.48C21.17 19.8 21.17 18.69 20.48 18.01ZM2.62 8.75C2.62 5.37 5.37 2.62 8.75 2.62C12.12 2.62 14.87 5.37 14.87 8.75C14.87 12.12 12.12 14.87 8.75 14.87C5.37 14.87 2.62 12.12 2.62 8.75Z"
        fill={fill}
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
    </svg>
  )
}
