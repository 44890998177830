import React, { useEffect, useRef, useState } from "react"
import styles from "./style.module.css"
import { TableRows } from "../TableRows"

interface ItableData {
  headerTable: Array<{ title: React.ReactNode | string | number; widthColumn: number }>
  rows: Array<Array<React.ReactNode | string | number>>
}

interface Iprops {
  tableData: ItableData
  rowIndexActive?: number | null
  setRowIndexActive?: (index: number) => void
  indexCol?: React.ReactNode | string | number
}

export const TableResize: React.FC<Iprops> = React.memo(
  ({ tableData, rowIndexActive, setRowIndexActive, indexCol }) => {
    const [colWidth, setColWidth] = useState<number[]>([])
    const [{ startX, startWidth, startWidthPrev }, setParam] = useState({
      startX: 0,
      startWidth: 0,
      startWidthPrev: 0,
    })
    const [currentIndex, setCurrentIndex] = useState(0)

    const tableHead = useRef<any>(null)

    useEffect(() => {
      if (!tableData) return
      let sumWidth = 0
      tableData.headerTable.forEach((headCol) => (sumWidth = sumWidth + headCol.widthColumn))
      if (sumWidth !== 100) alert(sumWidth)
      setColWidth(tableData.headerTable.map((headCol) => headCol.widthColumn))
    }, [tableData])

    useEffect(() => {
      if (currentIndex === 0) return

      document.addEventListener("mousemove", resizingStart)
      document.addEventListener("mouseup", stopResizingStart)
    }, [startX, currentIndex])

    const startResize = (e, index) => {
      if (!tableHead) return
      if (index === 0) return
      setParam({
        startX: e.pageX,
        startWidth: tableHead.current.children[index].offsetWidth,
        startWidthPrev: (tableHead.current.children[index - 1] as HTMLElement).offsetWidth,
      })
      setCurrentIndex(index)
    }

    function resizingStart(e: any) {
      if (!tableHead.current) return

      const deltaNum = e.pageX - startX
      const delta = Math.abs(deltaNum)

      if (deltaNum < 0) {
        let currentItemWidth = startWidth + delta
        let currentPrevWidth = startWidthPrev - delta

        setColWidth((prev) => {
          let newWidth = [...prev]
          newWidth[currentIndex] = (currentItemWidth / tableHead.current.offsetWidth) * 100

          newWidth[currentIndex - 1] = (currentPrevWidth / tableHead.current.offsetWidth) * 100

          return newWidth
        })
      }

      if (deltaNum > 0) {
        let currentItemWidth = startWidth - delta
        let currentPrevWidth = startWidthPrev + delta

        setColWidth((prev) => {
          let newWidth = [...prev]
          newWidth[currentIndex] = (currentItemWidth / tableHead.current.offsetWidth) * 100

          newWidth[currentIndex - 1] = (currentPrevWidth / tableHead.current.offsetWidth) * 100

          return newWidth
        })
      }
    }

    function stopResizingStart() {
      document.removeEventListener("mousemove", resizingStart)
      document.removeEventListener("mouseup", stopResizingStart)
    }

    return (
      <div className={styles.table}>
        <div className={styles.table_head}>
          {indexCol ? <div className={styles.table_item_index}>{indexCol}</div> : null}

          <div className={styles.table_head_wrapper} ref={tableHead}>
            {tableData.headerTable.map((el, index) => {
              return (
                <div
                  className={styles.table_item}
                  style={{
                    width: `${colWidth[index]}%`,
                  }}
                  key={index}
                >
                  {el.title}
                  <div
                    className={styles.table_resize}
                    onMouseDown={(e) => startResize(e, index)}
                  ></div>
                </div>
              )
            })}
          </div>
        </div>

        <TableRows
          rows={tableData.rows}
          colWidth={colWidth}
          setRowIndexActive={setRowIndexActive}
          rowIndexActive={rowIndexActive}
          indexCol={!!indexCol}
        />
      </div>
    )
  },
)
