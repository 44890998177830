import { createTree } from "../../../shared/lib/helpers/treeHelpers"
import { filterByGeozoneName } from "../../../shared/lib/helpers/driverTree"
import { RootState } from "./../../../store/store"
import { createSelector } from "@reduxjs/toolkit"

export const createTreeFilterDriver = createSelector(
  (state: RootState) => state.driver.search,
  (state: RootState) => state.driver.driversTree,
  (search, driverTree) => {
    return createTree(driverTree ? filterByGeozoneName(driverTree, search) : [])
  },
)
