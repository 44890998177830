import { useEffect, useState } from "react"
import { DriverTree } from "./DriverTree"
import { GeozoneTree } from "./GeozoneTree"
import { VehicleTree } from "./VehicleTree"
import "./style.css"
import styles from "./CheckboxTree.module.css"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { thunkGetVehicles } from "../../store/reducers/vehiclesTree/vehicleTreeThunk"
import { thunkGetDriversTree } from "../../store/reducers/drivers/driverThunk"
import { thunkGetGeoZonesTree } from "../../store/reducers/geozonesTree/thunkGeozonesTree"
import { useLoading } from "../../shared/lib/hooks/loading"
import { LoadingWrapper } from "../../components/LoadingWrapper/LoadingWrapper"
import { ObjectsIcon } from "src/shared/ui/IconComponent/ObjectIcon"
import { GeozonesIcon } from "src/shared/ui/IconComponent/GeozoneIcon"
import { DriversIcon } from "src/shared/ui/IconComponent/DriverIcon"

export const CheckboxTreeContainer = () => {
  const dispatch = useAppDispatch()

  const [activeTab, setActiveTab] = useState("transport")

  const { loading, startLoading, stopLoading } = useLoading()

  const checkedGeoZonesLength = useAppSelector((state) => state.geoZones.checkedGeoZones).length
  const checkedVehiclesLength = useAppSelector((state) => state.vehicles.checkedVehicles).length
  const checkedDriversLength = useAppSelector((state) => state.driver.checkedDrivers).length

  const geoZonesTreeLength = useAppSelector((state) => state.geoZones.geozoneTree).length
  const driversTreeLength = useAppSelector((state) => state.driver.driversTree).length
  const vehicleTreeLength = useAppSelector((state) => state.vehicles.vehicleTree)?.length

  const typeServer = useAppSelector((state) => state.router.page)

  useEffect(() => {
    dispatch(thunkGetVehicles(typeServer === "tpms" ? "tpms" : "svr"))
  }, [typeServer])

  useEffect(() => {
    startLoading()
    if (!!geoZonesTreeLength) return
    dispatch(thunkGetGeoZonesTree())
    if (!!driversTreeLength) return
    dispatch(thunkGetDriversTree())
  }, [])

  useEffect(() => {
    if (geoZonesTreeLength && driversTreeLength && vehicleTreeLength) {
      stopLoading()
    }
  }, [geoZonesTreeLength, driversTreeLength, vehicleTreeLength])

  return (
    <>
      <LoadingWrapper isLoading={loading}>
        <nav className={styles.tabs}>
          <div className={styles.tab} onClick={() => setActiveTab("transport")}>
            <span>
              <span>
                <ObjectsIcon />
              </span>
              Объекты
            </span>
            {checkedVehiclesLength}
          </div>
          <div
            className={styles.tree}
            style={{
              display: activeTab === "transport" ? "flex" : "none",
            }}
          >
            <VehicleTree />
          </div>
          <div className={styles.tab} onClick={() => setActiveTab("driver")}>
            <span>
              <span>
                <DriversIcon />
              </span>
              Водители
            </span>
            {checkedDriversLength}
          </div>
          <div
            className={styles.tree}
            style={{
              display: activeTab === "driver" ? "flex" : "none",
            }}
          >
            <DriverTree />
          </div>
          <div className={styles.tab} onClick={() => setActiveTab("geoZone")}>
            <span>
              <span>
                <GeozonesIcon />
              </span>
              Геозоны
            </span>
            {checkedGeoZonesLength}
          </div>
          <div
            className={styles.tree}
            style={{
              display: activeTab === "geoZone" ? "flex" : "none",
            }}
          >
            <GeozoneTree />
          </div>
        </nav>
      </LoadingWrapper>
    </>
  )
}
