import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface ISecutity {
  isAuth: boolean
  token: string | null
  loading: boolean
}

const initialState = {
  isAuth: false,
  token: null,
  loading: false,
} satisfies ISecutity as ISecutity

const counterSlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    setAuth(state: ISecutity, action: PayloadAction<boolean>) {
      state.isAuth = action.payload
    },
    setToken(state: ISecutity, action: PayloadAction<string>) {
      state.token = action.payload
    },
    setLoading(state: ISecutity, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

export const { setToken, setAuth, setLoading } = counterSlice.actions
export default counterSlice.reducer
