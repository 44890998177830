import { uniq } from "lodash"
import { ILevels } from "src/components/Settings/Content/Content"

interface IHorizontalTreeProps {
  data: IHorizontalTreeData[][]
}

export interface IHorizontalTreeData {
  id: string | number
  name: string
  role_id: number | null
  shortData?: [string, any][] | null
  parent_id: number | null
  account_id: number | null
  is_root: boolean | null
  is_folder: boolean
}

// Функция для поиска account_id у родителя с role_id == 3 или is_root == true
function findParentAccountId(data: any[], parentId: number | null): number | null {
  if (parentId === null) return null

  const parent = data.find((item) => item.id === parentId)

  if (!parent) return null

  if (parent.role_id === 3 || parent.is_root) {
    return parent.account_id
  }

  return findParentAccountId(data, parent.parent_id)
}

// Функция для организации объектов по уровням
export function organizeUsers(data: any[]): IHorizontalTreeData[][] | null {
  if (!Array.isArray(data)) return null
  const levels: IHorizontalTreeData[][] = []
  const idToParent: { [key: string]: number | null } = {}

  // Организуем данные по уровням
  debugger
  data?.forEach((item) => {
    const levelIndex = item.level - 1
    if (!levels[levelIndex]) {
      levels[levelIndex] = []
    }

    // Создаем новый объект с нужными полями для групп
    const newItem: IHorizontalTreeData = {
      id: item.id,
      name: item.account_name || "",
      role_id: item.role_id,
      parent_id: item.parent_id,
      account_id: item.account_id,
      shortData: null,
      is_folder: true,
      is_root: item.is_root,
    }

    levels[levelIndex].push(newItem)

    // Запоминаем, что эта группа является родителем для объектов на следующем уровне
    idToParent[item.id] = item.id
  })

  const maxLevel = levels.length

  // Добавляем объекты на следующий уровень (кроме уровня 1)
  for (let level = 0; level < maxLevel; level++) {
    if (!levels[level + 1]) {
      levels[level + 1] = []
    }

    levels[level] = levels[level]?.map((item: any) => {
      const vehicles = data.find((d) => d.id === item.id)?.users
      if (vehicles) {
        vehicles.forEach((vehicle: any) => {
          const vehicleItem: IHorizontalTreeData = {
            id: vehicle.id, // Используем vehicle_uid как id
            is_folder: false,
            is_root: null,
            account_id: vehicle.account_id, // Находим правильный account_id
            name: vehicle.user_name || "",
            role_id: null, // Для объектов role_id всегда null
            parent_id: item.id, // Устанавливаем parent_id как id группы
            shortData: null, // shortData для объектов всегда null
          }
          levels[level + 1].push(vehicleItem)
        })
      }
      return item
    })
  }

  return levels
}

// Функция для обновления уровня
export const setNewLevel = (value: [number, number], field: string, levels: ILevels) => {
  const newLevels = JSON.parse(JSON.stringify(levels)) // Предполагается, что levels — это состояние или пропс
  const index = value[0]
  const id = value[1]

  // Обработка поля "users"
  if (field === "users") {
    newLevels.tree.users.user_id = null

    if (newLevels.tree.users.data[index]) {
      newLevels.tree.users.data[index].handle_id = id

      let maxArrayIndex = -1

      // Проверка наличия последнего элемента в массиве levelIds
      if (
        newLevels.tree.users.levelIds.length > 0 &&
        newLevels.tree.users.levelIds[newLevels.tree.users.levelIds.length - 1] !== undefined
      ) {
        maxArrayIndex = newLevels.tree.users.levelIds[newLevels.tree.users.levelIds.length - 1][0]
      }

      let level = newLevels.tree.users.levelIds.find((i) => i[0] === index)

      if (level) {
        // Если текущий индекс меньше максимального, удаляем все последующие элементы
        if (index < maxArrayIndex) {
          newLevels.tree.users.levelIds = newLevels.tree.users.levelIds.filter(
            (elem) => elem[0] <= index,
          )
        }

        // Находим индекс и обновляем значение
        let indexFromLevelIds = newLevels.tree.users.levelIds.findIndex((i) => i[0] === index)
        newLevels.tree.users.levelIds[indexFromLevelIds] = [index, id]
      } else {
        // Если уровень не существует, добавляем его
        newLevels.tree.users.levelIds.push([index, id])
      }

      newLevels.tree.users.levelIds = uniq(newLevels.tree.users.levelIds)

      const lastIndex = newLevels.tree.users.data.length - 1
      newLevels.tree.users.activeAccountID = null

      // Обнуляем handle_id для всех элементов после текущего индекса
      for (let i = index + 1; i <= lastIndex; i++) {
        newLevels.tree.users.data[i].handle_id = null
      }
    }
  }

  // Обновляем состояние уровней с новыми значениями
  return newLevels
}
