import { IGeozone, IGeozoneApi } from "@/interfaces/geozone"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import L from "leaflet"
import { update } from "lodash"

interface ImapGeozone {
  infoGeozones: IGeozone[]
}

const initialState: ImapGeozone = {
  infoGeozones: [],
}

const mapSlice = createSlice({
  name: "mapGeozone",
  initialState,
  reducers: {
    setAllGeozoneInfo(state: ImapGeozone, action: PayloadAction<{ geozone: IGeozone[] }>) {
      state.infoGeozones = action.payload.geozone
    },
    addGeozone(state: ImapGeozone, action: PayloadAction<IGeozoneApi>) {
      state.infoGeozones = [
        ...state.infoGeozones,
        {
          geozone_name: action.payload.geozone_name,
          color: action.payload.color,
          geozone_uid: action.payload.geozone_uid,
          geozone_type_id: action.payload.geozone_type_id,
          latitude: action.payload.latitude ? action.payload.latitude : 0,
          longitude: action.payload.longitude ? action.payload.longitude : 0,
          radius: action.payload.radius ? action.payload.radius : 0,
          line_width: action.payload.line_width,
          geometry_type_id: action.payload.geometry_type_id,
          use_as_address: action.payload.use_as_address,
          image_url: action.payload.image_url ? action.payload.image_url : "",
          geozone_points: action.payload.geozone_points,
          comment: action.payload.comment ? action.payload.comment : "",
          transparency: action.payload.transparency,
        },
      ]
    },

    updateGeozone(state: ImapGeozone, action: PayloadAction<{ geozone: IGeozone }>) {
      let index = state.infoGeozones.findIndex(
        (geozone) => geozone.geozone_uid == action.payload.geozone.geozone_uid,
      )
      let geozones = [...state.infoGeozones]
      geozones[index] = action.payload.geozone
      state.infoGeozones = geozones
    },
    deleteGeozone(state: ImapGeozone, action: PayloadAction<string>) {
      state.infoGeozones = state.infoGeozones.filter(
        (geozone) => geozone.geozone_uid !== action.payload,
      )
    },
  },
})

export const { setAllGeozoneInfo, addGeozone, updateGeozone, deleteGeozone } = mapSlice.actions
export default mapSlice.reducer
