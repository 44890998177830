import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IGSSpeedControlState {
  is_enabled: boolean
  max_limit: number | string
  reg_critical_limit: number | string
  reg_limit: number | string
  reg_time_limit: number | string
  use_road_signs_instead_max_limit: boolean
}

const initialState: IGSSpeedControlState = {
  is_enabled: false,
  max_limit: "",
  reg_critical_limit: "",
  reg_limit: "",
  reg_time_limit: "",
  use_road_signs_instead_max_limit: false,
}

const rootOSettingsSpeedControl = createSlice({
  name: "osSettingsSpeedControl",
  initialState,
  reducers: {
    setIsSCEnabled: (state: IGSSpeedControlState) => {
      state.is_enabled = !state.is_enabled
    },
    setMaxLimit: (state: IGSSpeedControlState, action: PayloadAction<number | string>) => {
      state.max_limit = action.payload
    },
    setRegCriticalLimit: (state: IGSSpeedControlState, action: PayloadAction<number | string>) => {
      state.reg_critical_limit = action.payload
    },
    setRegLimit: (state: IGSSpeedControlState, action: PayloadAction<number | string>) => {
      state.reg_limit = action.payload
    },
    setRegTimeLimit: (state: IGSSpeedControlState, action: PayloadAction<number | string>) => {
      state.reg_time_limit = action.payload
    },
    setUseRoadSignsInsteadMaxLimit: (state: IGSSpeedControlState) => {
      state.use_road_signs_instead_max_limit = !state.use_road_signs_instead_max_limit
    },
    setSpeedControlState: (
      state: IGSSpeedControlState,
      action: PayloadAction<IGSSpeedControlState>,
    ) => {
      state.is_enabled = action.payload.is_enabled ?? false
      state.max_limit = action.payload.max_limit ?? ""
      state.reg_critical_limit = action.payload.reg_critical_limit ?? ""
      state.reg_limit = action.payload.reg_limit ?? ""
      state.reg_time_limit = action.payload.reg_time_limit ?? ""
      state.use_road_signs_instead_max_limit =
        action.payload.use_road_signs_instead_max_limit ?? false
    },
  },
})

export const {
  setIsSCEnabled,
  setMaxLimit,
  setRegCriticalLimit,
  setRegLimit,
  setRegTimeLimit,
  setUseRoadSignsInsteadMaxLimit,
  setSpeedControlState,
} = rootOSettingsSpeedControl.actions

export default rootOSettingsSpeedControl.reducer
