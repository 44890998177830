import React from "react"
import styles from "./style.module.css"

interface Iprops {
  inputType: string
  value: string
  setValue: (value: string) => void
  placeholder?: string
  size?: "extrasmall" | "small" | "medium" | "large" | "extralarge"
  variant?: "orange" | "silver" | "palesilver"
  [key: string]: any
  error?: boolean
}

export const Input: React.FC<Iprops> = React.memo(
  ({ inputType, value, setValue, placeholder, size, variant, error }) => {
    return (
      <div className={styles.input_box}>
        <input
          type={inputType}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={`${styles.input} ${error ? styles.input_error : ""} ${size ? styles[size] : ""} ${variant ? styles[variant] : ""}`}
          placeholder={placeholder ? placeholder : ""}
        />
      </div>
    )
  },
)
