import React from "react"
import { PieChart, Pie, Cell } from "recharts"
import styles from "./styles.module.css"
import { Tooltip } from "../Tooltip"

export const ChartCount = ({ color, values, message }) => {
  return (
    <Tooltip message={message}>
      <div style={{ background: `${color}69` }} className={styles.box}>
        <PieChart width={42} height={42}>
          <Pie
            data={values}
            startAngle={360}
            endAngle={0}
            innerRadius={15}
            outerRadius={20}
            fill="#8884d8"
            dataKey="value"
          >
            <Cell fill={color} stroke="#00000000" />
            <Cell fill={`${color}00`} stroke="#00000000" />
          </Pie>
        </PieChart>
        <div className={styles.count}>{values[0].value}</div>
      </div>
    </Tooltip>
  )
}
