import { TstatusGroup } from "../../../shared/types/types"
import React from "react"

type propsType = {
  onChecked: () => void
  status: TstatusGroup
}
export const RootGroupCheckbox: React.FC<propsType> = ({ status, onChecked }) => {
  return (
    <div className="foldercheckbox-head">
      <label
        className={`foldercheckbox-head__checkbox ${status !== "none" ? "foldercheckbox-head__checkbox--checked" : ""}`}
      >
        <div
          className={status === "all" ? "icon-checked" : status === "some" ? "icon-minus" : ""}
        ></div>
        <input type="checkbox" checked={status !== "none"} onChange={() => onChecked()} />
      </label>
    </div>
  )
}
