import React from "react"
import styles from "./style.module.css"

interface Iprops {
  children: React.ReactNode
  color?: "defoult" | "orange" | "red" | "paleorange"
  size?: "small" | "medium" | "large"
  [key: string]: any
}

export const Button: React.FC<Iprops> = (props) => {
  return (
    <div
      className={`${props.color ? styles[props.color] : ""}  ${props.size ? styles[props.size] : ""} ${styles.button}`}
      {...props}
    >
      {props.children}
    </div>
  )
}
