import { returnErrorFetch, serverInstance } from "./settingsApiInstance"

interface IDriverTreeAPI {
  changeTreeGroupsDriver: (
    parent_id: number,
    group_name: string,
    id?: number,
  ) => Promise<{ data: any; status: number }>
  getTreeDrivers: () => Promise<{ data: any; status: number }>
  deleteGroupDriver: (id: number) => Promise<{ data: any; status: number }>
}

export const driverTreeAPI: IDriverTreeAPI = {
  changeTreeGroupsDriver: async (parent_id, group_name, id) => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/drivers/post_driver_group", {
        parent_id: parent_id,
        group_name: group_name,
        id: id ? id : 0,
      })
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  getTreeDrivers: async () => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/drivers/get_tree_drivers")
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  deleteGroupDriver: async (id) => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/drivers/delete_driver_group", {
        id: id,
      })
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
}
