import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { changeStatus, checkObjectsPresenceGeozone } from "../../../shared/lib/helpers/geozoneTree"
import { getGroupAndChildren } from "../../../shared/lib/helpers/treeHelpers"
import { IGeozoneObject, IGeozoneTree } from "./../../../interfaces/geozoneTree"

interface IGeoZoneState {
  geozoneTree: IGeozoneTree[]
  checkedGeoZones: IGeozoneObject[]
  filteredData: any
  search: string
  activeGeozone: IGeozoneObject | null
  isOpenAll: boolean
}

const initialState: IGeoZoneState = {
  geozoneTree: [],
  checkedGeoZones: [],
  filteredData: null,
  search: "",
  activeGeozone: null,
  isOpenAll: false,
}

const geoZonesSlice = createSlice({
  name: "geoZones",
  initialState,
  reducers: {
    setActiveGeozone(state: IGeoZoneState, action: PayloadAction<IGeozoneObject>) {
      state.activeGeozone = action.payload
    },
    addGroupGeozoneTree(state: IGeoZoneState, action: PayloadAction<IGeozoneTree>) {
      state.geozoneTree = [...state.geozoneTree, { ...action.payload, status: "none" }]
    },
    setGeozoneTree(state: IGeoZoneState, action: PayloadAction<IGeozoneTree[]>) {
      state.geozoneTree = action.payload
    },
    addGeozoneChecked(
      state: IGeoZoneState,
      action: PayloadAction<{ geozone: IGeozoneObject; id: number }>,
    ) {
      if (!state.geozoneTree) return
      const allChecked: IGeozoneObject[] = [...state.checkedGeoZones, action.payload.geozone]

      const targetObject: any = state.geozoneTree.find((obj) => {
        return obj.id === action.payload.id
      })

      // Проверить, все ли транспортные средства этого объекта присутствуют в массиве checkedVehicle
      const allVehiclesCheckedGroup =
        checkObjectsPresenceGeozone(targetObject.geozones, allChecked) === "all"
      // Проверить, установлен ли статус "all" у всех дочерних элементов объекта

      const allChildrenStatusAll = state.geozoneTree
        .filter((obj) => obj.parent_id === targetObject?.id)
        .every((child) => child.status === "all")

      if (allVehiclesCheckedGroup && allChildrenStatusAll) {
        // Статус all если все отмечены и все дочерние all
        changeStatus(state.geozoneTree, action.payload.id, "all")
      } else {
        // Статус some при любом добавление
        changeStatus(state.geozoneTree, action.payload.id, "some")
      }

      state.checkedGeoZones = allChecked
    },
    deleteGeozoneChecked(
      state: IGeoZoneState,
      action: PayloadAction<{ geozone: IGeozoneObject; id: number }>,
    ) {
      if (!state.geozoneTree) return

      const allChecked = state.checkedGeoZones.filter(
        (vehicle: IGeozoneObject) => vehicle.geozone_uid !== action.payload.geozone.geozone_uid,
      )

      const targetObject: any = state.geozoneTree.find((obj) => obj.id === action.payload.id)

      // Проверяем, отсутствуют ли все транспортные средства этой группы в массиве checkedVehicle
      const missingVehicles =
        checkObjectsPresenceGeozone(targetObject.geozones, allChecked) === "none"

      // Проверить, установлен ли статус "none" у всех дочерних элементов объекта
      const allChildrenStatusAll = state.geozoneTree
        .filter((obj) => obj.parent_id === targetObject?.id)
        .every((child) => child.status === "none")

      if (missingVehicles && allChildrenStatusAll) {
        // Статус none если отсутствуют && все дочерние none
        changeStatus(state.geozoneTree, action.payload.id, "none")
      } else {
        changeStatus(state.geozoneTree, action.payload.id, "some")
      }

      state.checkedGeoZones = allChecked
    },
    groupChecked(state: IGeoZoneState, action: PayloadAction<IGeozoneTree>) {
      if (!state.geozoneTree) return

      const groupAndChildren = getGroupAndChildren(state.geozoneTree, action.payload.id)

      let allGeozones: any = groupAndChildren
        .map((el) => (el.geozones ? el.geozones : []))
        .reduce((acc, val) => acc.concat(val), [])
        .map((el) => ({ ...el }))

      allGeozones = action.payload.geozones
        ? [...allGeozones, ...action.payload.geozones]
        : allGeozones

      if (action.payload.status === "none" || action.payload.status === "some") {
        state.checkedGeoZones = [...allGeozones, ...state.checkedGeoZones]
        changeStatus(state.geozoneTree, action.payload.id, "all")
        state.geozoneTree.forEach((el) => {
          if (groupAndChildren.some((group) => group.id == el.id)) {
            el.status = "all"
          }
        })
      }
      if (action.payload.status === "all") {
        // Фильтруем исходные массивы, чтобы получить только уникальные элементы
        const uniqueElements1 = allGeozones.filter(
          (item) => !state.checkedGeoZones.some((el) => el.geozone_uid === item.geozone_uid),
        )
        const uniqueElements2 = state.checkedGeoZones.filter(
          (item) => !allGeozones.some((el) => el.geozone_uid === item.geozone_uid),
        )

        // Объединяем все уникальные элементы в один массив
        const result =
          uniqueElements1 && uniqueElements2 ? [...uniqueElements1, ...uniqueElements2] : []

        state.checkedGeoZones = result
        changeStatus(state.geozoneTree, action.payload.id, "none")
        state.geozoneTree.forEach((el) => {
          if (groupAndChildren.some((group) => group.id == el.id)) {
            el.status = "none"
          }
        })
        return
      }
    },
    setSearchGeozone(state: IGeoZoneState, action: PayloadAction<string>) {
      state.search = action.payload
    },
    addGeozoneTree(
      state: IGeoZoneState,
      action: PayloadAction<{ comment: string; uid: string; geozone_name: string; id: number }>,
    ) {
      state.geozoneTree = [
        ...state.geozoneTree.map((group) => {
          if (group.id === action.payload.id) {
            return {
              ...group,
              geozones: group.geozones
                ? [
                    ...group.geozones,
                    {
                      comment: action.payload.comment,
                      geozone_name: action.payload.geozone_name,
                      geozone_uid: action.payload.uid,
                    },
                  ]
                : [
                    {
                      comment: action.payload.comment,
                      geozone_name: action.payload.geozone_name,
                      geozone_uid: action.payload.uid,
                    },
                  ],
            }
          }
          return group
        }),
      ]
    },
    deleteGeozoneTree(state: IGeoZoneState, action: PayloadAction<string>) {
      state.geozoneTree = [
        ...state.geozoneTree.map((group) => {
          return {
            ...group,
            geozones: group.geozones
              ? group.geozones.filter((geozone) => geozone.geozone_uid !== action.payload)
              : [],
          }
        }),
      ]
    },
    updateGeozoneTree(
      state: IGeoZoneState,
      action: PayloadAction<{ geozone_uid: string; geozone_name: string; comment: string }>,
    ) {
      state.geozoneTree = state.geozoneTree.map((group) => ({
        ...group,
        geozones: group.geozones
          ? group.geozones.map((geozone) => {
              if (geozone.geozone_uid === action.payload.geozone_uid) {
                return {
                  ...geozone,
                  geozone_name: action.payload.geozone_name,
                  comment: action.payload.comment,
                }
              }
              return geozone
            })
          : [],
      }))
    },
    deleteGroupGeozoneTree(state: IGeoZoneState, action: PayloadAction<number>) {
      state.geozoneTree = state.geozoneTree.filter((group) => group.id !== action.payload)
    },
    setIsOpenAll(state: IGeoZoneState, action: PayloadAction<boolean>) {
      state.isOpenAll = action.payload
    },
    setCheckedGeozone(state: IGeoZoneState, action: PayloadAction<IGeozoneObject[]>) {
      state.checkedGeoZones = action.payload
    },
  },
})

export const {
  setCheckedGeozone,
  setIsOpenAll,
  setActiveGeozone,
  setGeozoneTree,
  addGeozoneChecked,
  deleteGeozoneChecked,
  groupChecked,
  deleteGeozoneTree,
  addGeozoneTree,
  setSearchGeozone,
  updateGeozoneTree,
  addGroupGeozoneTree,
  deleteGroupGeozoneTree,
} = geoZonesSlice.actions
export default geoZonesSlice.reducer
