import { AppDispatch } from "@/store/store"
import { addTrack, setPointInfo, setTrackAll } from "./mapTrack"
import { trackMapApi } from "./../../../shared/api/trackMapApi"
import { fetchIsError } from "../../../shared/lib/helpers/fetchIsError/fetchIsError"
import { addMenuTrack } from "../menuMap/menuMap"

export const getTrack = (
  uids: string[],
  start_time: any,
  end_time: any,
  checkedEvent: number[],
) => {
  return async (dispatch: AppDispatch) => {
    const tracks = await Promise.all(
      await uids.map(async (uid) => {
        const resTrack = await trackMapApi.getTrack(uid, start_time, end_time)
        const resEvents = await trackMapApi.getInfoPointEvent(
          uid,
          checkedEvent,
          start_time,
          end_time,
        )
        return {
          points: resTrack.data.data,
          events: resEvents?.data?.data ? resEvents.data.data : [],
          uid: uid,
          lastPos: resTrack.data.last_data.last_pos,
          lastID: resTrack.data.last_data.last_id,
          speed_control_violation: resTrack.data.settings.speed_control_violation,
          time_limit: resTrack.data?.settings?.track?.colors?.dotted?.time_limit
            ? resTrack.data.settings.track.colors.dotted.time_limit
            : 0,
          ranges: resTrack?.data?.settings?.track?.colors?.ranges
            ? resTrack.data.settings.track.colors.ranges
            : [],
          is_enabledColorTrack: resTrack?.data?.settings?.track?.colors?.is_enabled
            ? resTrack.data.settings.track.colors.is_enabled
            : null,
        }
      }),
    )
    dispatch(setTrackAll([]))
    tracks.forEach((track) => {
      dispatch(addMenuTrack(false))
      dispatch(addTrack(track))
    })
  }
}

export const getInfoPointTrack = (id: number) => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, trackMapApi.setPointInfo, [id])
    if (!res) return
    dispatch(setPointInfo(res.data))
  }
}

export const getSettingsMap = () => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, trackMapApi.getSettingsMap, [])
    if (!res) return
  }
}
export const setSettingsMap = () => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, trackMapApi.getSettingsMap, [])
    if (!res) return
  }
}
