import React from "react"
import { CheckboxItem } from "../../CheckboxItem/CheckboxItem"
import { useAppDispatch, useAppSelector } from "../../../../shared/lib/hooks/store"
import {
  addVehicleChecked,
  deleteVehicleChecked,
  setActiveVehicle,
} from "../../../../store/reducers/vehiclesTree/vehicleTreeSlice"
import { DateTime } from "ts-luxon"
import { Tooltip } from "../../../../shared/ui/Tooltip"
import L from "leaflet"
import { thunkGetObjectSettings } from "../../../../store/reducers/objectSettings/objectSettingsThunk"
import {
  setObjectSettingsAccountId,
  setObjectSettingsUid,
} from "../../../../store/reducers/objectSettings/objectSettings"
import { useMapLocal } from "src/shared/lib/hooks/mapLocal"
import { KeyIcon } from "src/shared/ui/IconComponent/KeyIcon"
import { VehicleIcon } from "src/shared/ui/IconComponent/VehicleIcon"
import { CartIcon } from "src/shared/ui/IconComponent/CartIcon"
import { SatelliteIcon } from "src/shared/ui/IconComponent/SatelliteIcon"
import { AlarmIcon } from "src/shared/ui/IconComponent/AlarmIcon"

export const VehicleItemMap = ({ group }) => {
  const dispatch = useAppDispatch()

  const onAddCheckedVehicle = (vehicle, id_group) =>
    dispatch(addVehicleChecked({ vehicle: vehicle, id: id_group }))
  const onDeleteCheckedVehicle = (vehicle, id_group) =>
    dispatch(deleteVehicleChecked({ vehicle: vehicle, id: id_group }))

  const checkedVehicle = useAppSelector((state) => state.vehicles.checkedVehicles)
  const map = useMapLocal()
  const tracks = useAppSelector((state) => state.mapTrack.tracks)
  const activeVehicle = useAppSelector((state) => state.vehicles.activeVehicle)
  const roleID = useAppSelector((state) => state.auth.authData.role_id)
  // let x =
  return (
    <>
      {group.vehicles &&
        group.vehicles.map((vehicle, key) => {
          let isChecked = checkedVehicle.some(
            (currentVehicle) => currentVehicle.vehicle_uid === vehicle.vehicle_uid,
          )
          let isActive = activeVehicle ? vehicle.vehicle_uid === activeVehicle.vehicle_uid : false

          const track = tracks.find((item) => item.uid === vehicle.vehicle_uid)
          const timeOnline =
            Math.trunc(DateTime.local().toSeconds()) - vehicle?.last_pos?.event_date

          const dateVehicleInfo = vehicle?.last_pos?.event_date
            ? DateTime.fromSeconds(vehicle?.last_pos?.event_date).toFormat("dd LLL yyyy, HH:mm:ss")
            : "Нет данных!"
          return (
            <div
              className={`checkbox-folder__item ${isActive ? "checkbox-folder__item--active" : ""}`}
              key={key}
            >
              <Tooltip message={dateVehicleInfo}>
                <div
                  onClick={() => {
                    dispatch(setActiveVehicle(vehicle))
                    if (!!!track) return
                    const points = L.polyline(
                      track.points.map((item) => {
                        return [item.lt, item.ln]
                      }),
                    )

                    const bounds = points.getBounds()
                    map.fitBounds(bounds)
                  }}
                >
                  <CheckboxItem
                    onChecked={() =>
                      isChecked
                        ? onDeleteCheckedVehicle(vehicle, group.id)
                        : onAddCheckedVehicle(vehicle, group.id)
                    }
                    checked={isChecked}
                  >
                    {vehicle.vehicle_name}
                  </CheckboxItem>
                </div>
              </Tooltip>

              <div className="checkbox-folder__info">
                <div>
                  <Tooltip
                    message={`Зажигание: ${vehicle?.last_pos?.is_ignition_on ? "включено" : "выключено"}`}
                  >
                    <KeyIcon
                      height={20}
                      color={vehicle?.last_pos?.is_ignition_on ? "#008000" : "#eb0000"}
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    message={`${vehicle?.last_pos?.speed > 1.2 ? `Движится со скоростью: ${Math.round(vehicle.last_pos.speed)} км/ч` : "Не движется"}`}
                  >
                    <VehicleIcon
                      height={20}
                      color={vehicle.last_pos?.speed > 1.2 ? "#008000" : "#eb0000"}
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    message={
                      vehicle.last_driver?.driver_code ? (
                        vehicle.last_driver?.driver_name ? (
                          <div>
                            Код карты водителя: {vehicle.last_driver?.driver_code}
                            <br />
                            Имя водятеля: {vehicle.last_driver?.driver_name}
                          </div>
                        ) : (
                          <div>
                            Код карты водителя: {vehicle.last_driver?.driver_code}
                            <br />
                            Имя водятеля: не определено
                          </div>
                        )
                      ) : (
                        "Водитель не определен!"
                      )
                    }
                  >
                    <CartIcon
                      height={20}
                      color={
                        vehicle.last_driver?.driver_code
                          ? vehicle.last_driver?.driver_name
                            ? "#008000"
                            : "#eb0000"
                          : "#474747"
                      }
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    message={`Количество спутников: ${vehicle?.last_pos?.sats ? vehicle.last_pos.sats : 0}`}
                  >
                    <SatelliteIcon
                      height={20}
                      color={
                        vehicle?.last_pos?.sats >= 7
                          ? "#008000"
                          : vehicle?.last_pos?.sats >= 4
                            ? "#F5B716"
                            : "#eb0000"
                      }
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    message={
                      vehicle?.last_pos?.event_date
                        ? `Последние данные: ${`${Math.trunc(timeOnline / 3600) > 0 ? `${Math.trunc(timeOnline / 3600)} ч. ` : ""}`}${Math.trunc((timeOnline % 3600) / 60)} мин. назад`
                        : "Нет данных"
                    }
                  >
                    <AlarmIcon
                      height={20}
                      color={
                        vehicle?.last_pos?.event_date
                          ? timeOnline < 1800
                            ? "#62B15B"
                            : timeOnline < 3600 * 24
                              ? "#F5B716"
                              : "#E94040"
                          : "#474747"
                      }
                    />
                  </Tooltip>
                </div>
                {roleID === 1 && (
                  <div
                    className="icon-more checkbox-folder__edit"
                    onClick={() => {
                      dispatch(setObjectSettingsUid(vehicle.vehicle_uid))
                      dispatch(setObjectSettingsAccountId(group.id))
                      dispatch(thunkGetObjectSettings(vehicle.vehicle_uid))
                    }}
                  ></div>
                )}
              </div>
            </div>
          )
        })}
    </>
  )
}
