import { Fuel } from "../../components/testUi/Fuel/Fuel"
import { Chart } from "../../widgets/Chart/Chart"
import "./style.css"
import { Table } from "../../widgets/Table/Table"
import { useEffect } from "react"
import { thunkGetTableData } from "../../widgets/Table/model/tableThunk"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { Tires } from "src/widgets/Tires/Tires"
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "src/components/shadcnui/ui/resizable"

export const Tpms = () => {
  const dispatch = useAppDispatch()

  const page = useAppSelector((state) => state.tpms.page)

  const isOpenFuel = useAppSelector((state) => state.fuel.isOpen)
  const checkedVehicles = useAppSelector((state) => state.vehicles.checkedVehicles)

  const checkedDrivers = useAppSelector((state) => state.driver.checkedDrivers)

  useEffect(() => {
    dispatch(
      thunkGetTableData({
        vehicle_uids: checkedVehicles.map((i) => i.vehicle_uid),
        driver_uids: checkedDrivers.map((i) => i.driver_uid),
      }),
    )
  }, [checkedVehicles, checkedDrivers])

  return (
    <>
      {isOpenFuel && <Fuel />}

      {page === "table" && (
        <>
          <ResizablePanelGroup direction="horizontal" style={{ flex: 1 }}>
            <ResizablePanel defaultSize={65} minSize={29} className="">
              <Table />
            </ResizablePanel>
            {/* Ресайзер для изменения размера таблицы */}
            <ResizableHandle className="w-[1px]" />
            {/* Боковая панель */}
            <ResizablePanel defaultSize={35} minSize={20} maxSize={71} className="">
              {/* Содержимое боковой панели */}
              <Tires />
            </ResizablePanel>
          </ResizablePanelGroup>
        </>
      )}
      {page === "chart" && <Chart />}
    </>
  )
}
