import { useAppDispatch, useAppSelector } from "../../../../shared/lib/hooks/store"
import {
  addGeozoneChecked,
  deleteGeozoneChecked,
  setActiveGeozone,
} from "../../../../store/reducers/geozonesTree/geozonesTreeSlice"
import { setCreatorFigure } from "../../../../store/reducers/mapEditor/mapEditor"
import { removeGeozone } from "../../../../store/reducers/mapGeozone/mapGeozoneThunk"
import L from "leaflet"
import { CheckboxItem } from "../../CheckboxItem/CheckboxItem"
import { ContextMenu } from "../../../../shared/ui/ContextMenu"
import { useMapLocal } from "src/shared/lib/hooks/mapLocal"

export const GeozoneItemMap = ({ group }) => {
  const dispatch = useAppDispatch()

  const checkedGeozone = useAppSelector((state) => state.geoZones.checkedGeoZones)
  const infoGeozones = useAppSelector((state) => state.mapGeozone.infoGeozones)
  const activeGeozone = useAppSelector((state) => state.geoZones.activeGeozone)
  const isEdit = useAppSelector((state) => state.mapEditor.editMap)
  const map = useMapLocal()

  const onAddCheckedVehicle = (vehicle, id_group) =>
    dispatch(addGeozoneChecked({ geozone: vehicle, id: id_group }))
  const onDeleteCheckedVehicle = (vehicle, id_group) =>
    dispatch(deleteGeozoneChecked({ geozone: vehicle, id: id_group }))

  return (
    <>
      {group?.geozones &&
        group.geozones.map((geozone, key) => {
          const isChecked = checkedGeozone.some(
            (currentGeozone) => currentGeozone.geozone_uid === geozone.geozone_uid,
          )
          const isActive = activeGeozone ? activeGeozone.geozone_uid === geozone.geozone_uid : false

          const infoGeozone = infoGeozones.find((item) => item.geozone_uid === geozone.geozone_uid)
          return (
            <div
              className={`checkbox-folder__item ${isActive ? "checkbox-folder__item--active" : ""}`}
              onClick={() => {
                if (isEdit) return
                dispatch(setActiveGeozone(geozone))
                if (!!!map) return
                if (infoGeozone) {
                  const points = L.polyline(
                    infoGeozone.geozone_points
                      .map((item) => {
                        return [item.lat, item.lng]
                      })
                      .splice(0, infoGeozone.geozone_points.length - 1),
                  )

                  const bounds = points.getBounds()
                  map.fitBounds(bounds)
                }
              }}
              key={key}
            >
              <ContextMenu
                btnMenu={[
                  {
                    label: "Редактировать геозону",
                    onClickBtn: () => {
                      if (!!!infoGeozone) return
                      let newGeozone = {
                        ...infoGeozone,
                        geozone_points: [...infoGeozone.geozone_points].splice(
                          0,
                          infoGeozone.geozone_points.length - 1,
                        ),
                      }
                      dispatch(setCreatorFigure(newGeozone))

                      const points = L.polyline(
                        infoGeozone.geozone_points
                          .map((item) => {
                            return [item.lat, item.lng]
                          })
                          .splice(0, infoGeozone.geozone_points.length - 1),
                      )

                      const bounds = points.getBounds()
                      map.fitBounds(bounds)
                    },
                  },
                  {
                    label: "Удалить геозону",
                    onClickBtn: () => {
                      dispatch(removeGeozone(geozone.geozone_uid))
                    },
                  },
                ]}
                disactive={isEdit}
              >
                <CheckboxItem
                  onChecked={() =>
                    isChecked
                      ? onDeleteCheckedVehicle(geozone, group.id)
                      : onAddCheckedVehicle(geozone, group.id)
                  }
                  checked={isChecked}
                >
                  {geozone.geozone_name}
                </CheckboxItem>
              </ContextMenu>
            </div>
          )
        })}
    </>
  )
}
