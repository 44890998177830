import { ChartCount } from "src/shared/ui/ChartCount"
import React, { useMemo } from "react"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { DateTime } from "ts-luxon"
import styles from "./ChartVehicle.module.css"

export const ChartVehicle = () => {
  const vehicleNode = useAppSelector((state) => state.vehicles.vehicleTree)

  const dataChartTime = useMemo(() => {
    let onlineVehicle = 0
    let recentlyVehicle = 0
    let longTimeVehicle = 0
    let neverVehicle = 0
    let allVehicle = 0

    if (vehicleNode) {
      vehicleNode.forEach((node) => {
        if (!node?.vehicles) return
        const currentDate = Math.trunc(DateTime.local().toSeconds())
        node.vehicles.forEach((vehicle) => {
          allVehicle = allVehicle + 1

          if (!vehicle?.last_pos?.event_date) {
            neverVehicle = neverVehicle + 1
            return
          }

          if (currentDate - vehicle.last_pos.event_date < 1800) {
            onlineVehicle = onlineVehicle + 1
            return
          }

          if (currentDate - vehicle.last_pos.event_date < 3600 * 24) {
            recentlyVehicle = recentlyVehicle + 1
            return
          }
          longTimeVehicle = recentlyVehicle + 1
        })
      })
    }

    return {
      online: {
        color: "#62B15B",
        values: [
          { name: "main", value: onlineVehicle },
          { name: "remain", value: allVehicle - onlineVehicle },
        ],
        message: "Выходили на связь в течении 30 минут!",
      },
      recently: {
        color: "#F5CC16",
        values: [
          { name: "main", value: recentlyVehicle },
          { name: "remain", value: allVehicle - recentlyVehicle },
        ],
        message: "Выходили на связь в течении 24 часов!",
      },
      longTime: {
        color: "#E94040",
        values: [
          { name: "main", value: longTimeVehicle },
          { name: "remain", value: allVehicle - longTimeVehicle },
        ],
        message: "Не выходили на связь более 24 часов!",
      },
      never: {
        color: "#474747",
        values: [
          { name: "main", value: neverVehicle },
          { name: "remain", value: allVehicle - neverVehicle },
        ],
        message: "Никогда не выходили на связь!",
      },
      allVehicle: {
        color: "#a3a3a3",
        values: [
          { name: "main", value: allVehicle },
          { name: "remain", value: 0 },
        ],
        message: "Все машины",
      },
    }
  }, [vehicleNode])

  return (
    <div className={styles.wrapper}>
      <ChartCount
        message={dataChartTime.online.message}
        values={dataChartTime.online.values}
        color={dataChartTime.online.color}
      />
      <ChartCount
        message={dataChartTime.recently.message}
        values={dataChartTime.recently.values}
        color={dataChartTime.recently.color}
      />
      <ChartCount
        message={dataChartTime.longTime.message}
        values={dataChartTime.longTime.values}
        color={dataChartTime.longTime.color}
      />
      <ChartCount
        message={dataChartTime.never.message}
        values={dataChartTime.never.values}
        color={dataChartTime.never.color}
      />
      <ChartCount
        message={dataChartTime.allVehicle.message}
        values={dataChartTime.allVehicle.values}
        color={dataChartTime.allVehicle.color}
      />
    </div>
  )
}
