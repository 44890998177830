import { IDriverInfo } from "@/interfaces/settingsDriver"
import { ObjectSettingsItem } from "src/widgets/ObjectSettings/Content/ObjectSettingsContentItems/ObjectSettingsItem"
import { useAppDispatch, useAppSelector } from "./../../../../../shared/lib/hooks/store"

import { setGSItemData, updateGSItemData } from "src/store/reducers/globalSettings/globalSettings"
import { thunkSaveDriver } from "src/store/reducers/globalSettings/itemsThunks/driverThunk"

export const DriverSettings = () => {
  const dispatch = useAppDispatch()
  const driver: IDriverInfo = useAppSelector(
    (state) => state.globalSettings.tree.drivers.itemSettings.settingsData,
  )

  interface IDriverViewData {
    name: string
    type: "text" | "number"
    field: string
    value: string | number
  }

  const viewData: IDriverViewData[] = [
    {
      name: "ФИО водителя",
      type: "text",
      value: driver.driver_name,
      field: "driver_name",
    },
    {
      name: "Код",
      type: "number",
      value: driver.driver_code,
      field: "driver_code",
    },
    // {
    //   name: "Дата рождения",
    //   type: "text",
    //   value: driver.birth_date,
    //   field: "birth_date",
    // },
    {
      name: "Телефон",
      type: "text",
      value: driver.phone,
      field: "phone",
    },
    // {
    //   name: "Дата принятия на работу",
    //   type: "text",
    //   value: driver.employment_date,
    //   field: "employment_date",
    // },
    // {
    //   name: "Дата увольнения",
    //   type: "text",
    //   value: driver.dismissal_date,
    //   field: "dismissal_date",
    // },
    {
      name: "Комментарий",
      type: "text",
      value: driver.comment,
      field: "comment",
    },
  ]

  const onUpdateValue = ({ value, field }) => {}
  return (
    <div className="w-screen h-screen absolute top-0 left-0 backdrop-blur-lg z-20 flex items-center justify-center">
      <div className="bg-white shadow-xl py-3 px-10 min-w-[30%] w-fit rounded relative">
        <div
          onClick={() =>
            dispatch(
              setGSItemData({
                data: null,
                field: "drivers",
              }),
            )
          }
          className="absolute  icon-cross right-0 text-lg top-0 text-red cursor-pointer active:text-red-800 text-red-500 p-3 hover:text-red-600 "
        ></div>
        <h2 className="text-2xl font-light text-center">Профиль водителя</h2>
        <div
          style={{
            gridTemplateColumns: "30% 70%",
          }}
          className="grid  w-full justify-center items-center gap-4 py-10"
        >
          {viewData.map((item, index) => (
            <ObjectSettingsItem
              inputWidth={"100%"}
              title={item.name}
              value={item.value}
              onChange={(e) =>
                dispatch(
                  updateGSItemData({
                    value: item.type == "number" ? e : e.target.value,
                    field: "drivers",
                    profileField: item.field,
                  }),
                )
              }
              type={item.type}
            />
          ))}
        </div>
        <div className="w-full flex items-center justify-center">
          <div
            onClick={() => dispatch(thunkSaveDriver(driver))}
            className="bg-orange-500 hover:bg-orange-600 font-light rounded shadow-xl text-white  active:bg-orange-700 transition-all px-10 py-3 "
          >
            Сохранить
          </div>
        </div>
      </div>
    </div>
  )
}
