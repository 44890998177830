export const groupIDFinder = (id, array) => {
  if (!id && !array) {
    return -1
  }
  // Recursive function to search for the group name by id
  const findGroupName = (id, node) => {
    if (node?.account_id === id) {
      return node.id
    }
    if (node?.children) {
      for (const child of node?.children) {
        const result = findGroupName(id, child)
        if (result) {
          return result
        }
      }
    }
    return null
  }

  // Iterate over the array and search for the id
  for (const item of array) {
    const groupName = findGroupName(id, item)
    if (groupName) {
      return groupName
    }
  }
  return -1
}
