import { Input } from "src/shared/ui/Input"
import { useEffect, useState } from "react"
import { Popup } from "src/shared/ui/Popup"
import { TextArea } from "src/shared/ui/Textarea"
import styles from "./EditorTirePopup.module.css"
import { Button } from "src/shared/ui/Button"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { createUpdateWheelModelThunk } from "src/store/reducers/catalogTire/catalogTireThunk"

interface Iprops {
  onClose: () => void
  openCatalogTire: () => void
}

export const EditorTirePopup: React.FC<Iprops> = ({ onClose, openCatalogTire }) => {
  const dispatch = useAppDispatch()

  const [nameTire, setNameTire] = useState("")
  const [descrTire, setDescrTire] = useState("")

  const account_id = useAppSelector((state) => state.car.data?.data?.account_id)
  const catalogTire = useAppSelector((state) => state.catalogTire.catalogTire)
  const activeIndexRowCatalogTire = useAppSelector((state) => state.catalogTire.activeTire?.index)
  const activeCatalogTire = useAppSelector((state) => state.catalogTire.activeTire?.tire)

  useEffect(() => {
    if (typeof activeIndexRowCatalogTire !== "number") return
    setNameTire(catalogTire[activeIndexRowCatalogTire].name)
    setDescrTire(catalogTire[activeIndexRowCatalogTire].comment)
  }, [])

  const createTireCatalog = () => {
    if (!nameTire || !descrTire) return
    openCatalogTire()
    onClose()
    dispatch(
      createUpdateWheelModelThunk({
        id: activeCatalogTire ? activeCatalogTire.id : 0,
        name: nameTire,
        comment: descrTire,
        account_id: account_id,
      }),
    )
  }

  return (
    <Popup
      title="Создание модели шины"
      onClose={() => {
        openCatalogTire()
        onClose()
      }}
    >
      <div className={styles.editortire_wrapper}>
        <div>Название:</div>
        <Input
          value={nameTire}
          setValue={(value) => setNameTire(value)}
          inputType="string"
          variant="orange"
          placeholder="Название"
        />
        <div>Описание:</div>
        <TextArea
          value={descrTire}
          setValue={(value) => setDescrTire(value)}
          variant="orange"
          placeholderTextarea="Описание"
        />
      </div>
      <div className={styles.editortire_btns}>
        <Button color="orange" onClick={createTireCatalog}>
          Применить
        </Button>
      </div>
    </Popup>
  )
}
