import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { setNewLevelIds } from "src/store/reducers/globalSettings/globalSettings"
import { thunkGetDriver } from "src/store/reducers/globalSettings/itemsThunks/driverThunk"
import styles from "../../../styles/search-page.module.css"
import { driversSearchHelper } from "../../Search/helpers/driverHelper"
import { SearchTreeView } from "../../Search/SearchTreeView/SearchTreeView"

export interface IOnClickName {
  id: any
  is_folder: boolean
  is_root: boolean
  role_id: number
  parent_id: number
  account_id?: number
}

interface IDriverSearchProps {
  setActiveId: (id: any) => void
}

export const DriverSearch = React.memo(({ setActiveId }: IDriverSearchProps) => {
  const dispatch = useAppDispatch()
  const [isOpenSearchPopup, setOpenSearchPopup] = useState(false)
  const [searchText, setSearchText] = useState("")
  const drivers = useAppSelector((state) => state.globalSettings.data.drivers)
  const filteredData =
    drivers?.data != null && typeof drivers?.data != "string"
      ? driversSearchHelper(drivers.data, searchText)
      : []
  const onClickPath = (levelIds: [number, number][]) => {
    dispatch(setNewLevelIds({ levelIds, page: "drivers" }))
  }
  const closeSearch = () => {
    setOpenSearchPopup(false)
  }
  const onClickName = ({ id, is_folder, is_root, role_id, parent_id }: IOnClickName) => {
    if (is_folder) return
    dispatch(thunkGetDriver(id, parent_id))
  }
  return (
    <>
      <div className={styles.input_block}>
        <div className={styles.input_item}>
          <input
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
              if (e.target.value.length > 0) {
                setOpenSearchPopup(true)
              } else {
                setOpenSearchPopup(false)
              }
            }}
            type="text"
            placeholder={`Поиск`}
          />
          <div className={styles.input_item_opt}>
            {searchText.length > 0 && (
              <span
                onClick={() => {
                  setSearchText("")
                  setOpenSearchPopup(false)
                }}
                className="icon-cross "
              ></span>
            )}
          </div>
        </div>
      </div>
      {isOpenSearchPopup && (
        <div className={styles.input_page}>
          <div className={styles.input_page_container}>
            <div className={styles.input_page_tree_view}>
              {filteredData.length > 0 ? (
                <SearchTreeView
                  setActiveId={(id: any) => setActiveId(id)}
                  onClickName={(a: IOnClickName) => onClickName(a)}
                  onClickPath={(levelIds: [number, number][]) => onClickPath(levelIds)}
                  tree={filteredData}
                  setCloseSearch={closeSearch}
                />
              ) : (
                <div className="text-center text-xl">е найдено</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
})
