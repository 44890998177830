import { IPoint } from "@/interfaces/track"
import { LatLng } from "leaflet"

export const nearestPoint = (click, allPoints: IPoint[]) => {
  let clickLine = new LatLng(click.lat, click.lng)
  let points = [...allPoints]
  let minDistance = searchMinDistance(clickLine, points)
  let minAngle: any = [
    ...minDistance.filter((el) => {
      let angle = calculateAngle(
        el.point,
        [click.lat, click.lng],
        [points[el.indexPoint + 1].lt, points[el.indexPoint + 1].ln],
      )
      return angle > 170
    }),
    ...minDistance.filter((el) => {
      let angle = calculateAngle(
        el.point,
        [click.lat, click.lng],
        [points[el.indexPoint - 1].lt, points[el.indexPoint - 1].ln],
      )
      return angle > 170
    }),
  ]
  let allAngle: any = []

  if (allPoints.length <= 21) {
    return minDistance[0].id
  }

  if (minAngle.length === 0) {
    for (let index = 0; minAngle.length === 0; index++) {
      points = [
        ...points.filter((el, index) => !minDistance.some((point) => point.indexPoint === index)),
      ]
      minDistance = searchMinDistance(clickLine, points)
      minAngle = [
        ...minDistance.filter((el) => {
          let angle = calculateAngle(
            el.point,
            [click.lat, click.lng],
            [points[el.indexPoint + 1].lt, points[el.indexPoint + 1].ln],
          )
          allAngle.push(angle)
          return angle > 170
        }),
        ...minDistance.filter((el) => {
          let angle = calculateAngle(
            el.point,
            [click.lat, click.lng],
            [points[el.indexPoint - 1].lt, points[el.indexPoint - 1].ln],
          )
          allAngle.push(angle)
          return angle > 170
        }),
      ]
    }
  }

  return minAngle[0].id
}

const calculateAngle = (A, B, C) => {
  const distance = (p1, p2) => {
    return Math.sqrt((p2[0] - p1[0]) ** 2 + (p2[1] - p1[1]) ** 2)
  }

  const angleInDegrees = (a, b, c) => {
    return Math.acos((b ** 2 + c ** 2 - a ** 2) / (2 * b * c)) * (180 / Math.PI)
  }

  const AB = distance(A, B)
  const BC = distance(B, C)
  const CA = distance(C, A)

  return angleInDegrees(CA, BC, AB)
}

const searchMinDistance = (clickLine, points) => {
  let minDistance: Array<{
    point: number[]
    id: number
    currentDist: number
    indexPoint: number
  }> = []

  for (let i = 0; i < points.length; i++) {
    const dist = clickLine.distanceTo([points[i].lt, points[i].ln])
    let indexMinDistance: null | number = null

    if (minDistance.length < 10) {
      minDistance.push({
        point: [points[i].lt, points[i].ln],
        id: points[i].id,
        currentDist: dist,
        indexPoint: i,
      })
      continue
    }

    minDistance.forEach((point, index) => {
      if (typeof indexMinDistance !== "number") {
        if (point.currentDist > dist) indexMinDistance = index
      }
    })

    if (typeof indexMinDistance === "number") {
      minDistance[indexMinDistance] = {
        point: [points[i].lt, points[i].ln],
        id: points[i].id,
        currentDist: dist,
        indexPoint: i,
      }
    }
  }
  return minDistance
}
