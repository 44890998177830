import { IGeozone } from "@/interfaces/geozone"
import {
  addGeozoneChecked,
  addGeozoneTree,
  deleteGeozoneTree,
  updateGeozoneTree,
} from "../geozonesTree/geozonesTreeSlice"
import { addMenuGeozone } from "../menuMap/menuMap"
import { findPointsOnCircle } from "../../../shared/lib/helpers/map"
import { AppDispatch } from "./../../store"
import { deleteGeozone, setAllGeozoneInfo, updateGeozone } from "./mapGeozone"
import { geozoneMapAPI } from "./../../../shared/api/geozoneMapApi"
import { fetchIsError } from "../../../shared/lib/helpers/fetchIsError/fetchIsError"

export const getGeozones = (uids: string[]) => {
  return async (dispatch: AppDispatch) => {
    const res = await fetchIsError(dispatch, geozoneMapAPI.getGeozone, [uids])
    if (!res) return
    dispatch(
      setAllGeozoneInfo({
        geozone: res.data.map((i, index) => {
          dispatch(addMenuGeozone(false))
          return i
        }),
      }),
    )
  }
}

export const setGeozone = (geozone: IGeozone) => {
  return async (dispatch: AppDispatch) => {
    let newGeozone = {
      geozone_points: geozone.geozone_points.map((point) => ({ ...point })),
      uid: geozone.geozone_uid,
      color: geozone.color,
      comment: geozone.comment,
      geometry_type_id: geozone.geometry_type_id,
      geozone_groups_tree_id: geozone.geozone_groups_tree_id,
      geozone_name: geozone.geozone_name,
      geozone_type_id: geozone.geozone_type_id,
      image_url: geozone.image_url,
      latitude: geozone.latitude,
      line_width: geozone.line_width,
      longitude: geozone.longitude,
      radius: geozone.radius,
      transparency: geozone.transparency,
      use_as_address: geozone.use_as_address,
    }
    if (geozone.geometry_type_id === 1) {
      newGeozone.geozone_points = [
        ...newGeozone.geozone_points,
        { lat: newGeozone.geozone_points[0].lat, lng: newGeozone.geozone_points[1].lng },
        { lat: newGeozone.geozone_points[1].lat, lng: newGeozone.geozone_points[0].lng },
      ]
    }

    if (geozone.geometry_type_id === 0 && geozone.geozone_points.length < 40) {
      newGeozone.geozone_points = [
        ...newGeozone.geozone_points,
        ...findPointsOnCircle(geozone.latitude, geozone.longitude, geozone.radius, 39),
      ]
    }
    newGeozone.geozone_points.push(newGeozone.geozone_points[0])
    dispatch(
      updateGeozone({
        geozone: {
          geozone_points: newGeozone.geozone_points.map((point) => ({ ...point })),
          geozone_uid: newGeozone.uid,
          color: newGeozone.color,
          comment: newGeozone.comment,
          geometry_type_id: newGeozone.geometry_type_id,
          geozone_groups_tree_id: newGeozone.geozone_groups_tree_id,
          geozone_name: newGeozone.geozone_name,
          geozone_type_id: newGeozone.geozone_type_id,
          image_url: newGeozone.image_url,
          latitude: newGeozone.latitude,
          line_width: newGeozone.line_width,
          longitude: newGeozone.longitude,
          radius: newGeozone.radius,
          transparency: newGeozone.transparency,
          use_as_address: newGeozone.use_as_address,
        },
      }),
    )

    const res = await fetchIsError(dispatch, geozoneMapAPI.setGeozoneServer, [newGeozone])
    if (!res) return
    // if (geozone.geozone_uid) {
    dispatch(
      updateGeozoneTree({
        geozone_name: geozone.geozone_name,
        geozone_uid: res.data.uid,
        comment: geozone.comment,
      }),
    )

    if (!geozone.geozone_groups_tree_id) return
    dispatch(
      addGeozoneTree({
        comment: geozone.comment,
        uid: res.data.uid,
        geozone_name: geozone.geozone_name,
        id: geozone.geozone_groups_tree_id,
      }),
    )
    dispatch(
      addGeozoneChecked({
        geozone: {
          comment: geozone.comment,
          geozone_uid: res.data.uid,
          geozone_name: geozone.geozone_name,
        },
        id: geozone.geozone_groups_tree_id,
      }),
    )
  }
}

export const removeGeozone = (uid: string) => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, geozoneMapAPI.removeGeozoneServer, [uid])
    if (!res) return
    if (res) {
      dispatch(deleteGeozoneTree(uid))
      dispatch(deleteGeozone(uid))
    }
  }
}
