import { Layout } from "./Layout"

import { useSelector } from "react-redux"
import { Main } from "./../pages/Main"
import { MapPage } from "./../pages/Map"
import { Table } from "./../pages/Table"
import { RootState } from "./../store/store"
import { useEffect, useRef, useState } from "react"
import { ErrorPopupTimer } from "src/widgets/ErrorPopupTimer/ErrorPopupTimer"
import { RefContext } from "../shared/ui/Context"

function App() {
  const { page } = useSelector((state: RootState) => state.router)
  const [RDiv, setRDiv] = useState<null | HTMLDivElement>(null)
  const divRoot = useRef(null)

  useEffect(() => {
    if (divRoot.current) {
      setRDiv(divRoot.current)
    }
  })

  return (
    <div ref={divRoot} onContextMenu={(e) => e.preventDefault()} className="root">
      <ErrorPopupTimer />
      <RefContext.Provider value={RDiv}>
        <Layout>
          <>
            {page === "main" && <Main />}
            {page === "tpms" && <Table />}
            {page === "map" && <MapPage />}
          </>
        </Layout>
      </RefContext.Provider>
    </div>
  )
}

export default App
