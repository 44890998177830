export default function FrontImg({ width }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 164 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4251 83.2394H86.4042V140.605C56.6347 143.036 34.6736 134.771 24.4251 149.842V83.2394Z"
        fill="black"
        fill-opacity="0.73"
      />
      <path
        d="M140.087 83.2394H86.4042V140.605C102.997 141.091 136.183 137.202 140.087 147.897V83.2394Z"
        fill="black"
        fill-opacity="0.73"
      />
      <path
        d="M30.2814 8.37239C34.9821 4.21004 46.3862 5.94161 46.3862 5.94161C35.4333 33.6195 31.4692 49.0025 24.9131 78.8641C24.9131 78.8641 25.8892 12.2616 30.2814 8.37239Z"
        fill="#BABABA"
        fill-opacity="0.73"
      />
      <path
        d="M130.814 7.88616C126.541 4.07547 113.734 5.94152 113.734 5.94152C125.934 29.7629 131.79 54.0704 140.087 77.8917C140.087 77.8917 134.807 11.4468 130.814 7.88616Z"
        fill="#BABABA"
        fill-opacity="0.73"
      />
      <path d="M140.45 73.1533C149.418 73.1533 158.386 70.4658 163.318 80.8899" stroke="black" />
      <path d="M24.3165 73.0608C15.1728 73.0608 6.02906 70.3412 1.00002 80.8899" stroke="black" />
      <path d="M140.002 81.1062H163.767" stroke="black" />
      <path d="M1 80.8899H24.7648" stroke="black" />
      <path
        d="M48.5297 2.70877C48.5297 -0.548721 33.7327 3.11602 32.3875 6.78075M110.856 2.70889C110.856 -0.548549 126.55 2.70877 128.344 6.37361"
        stroke="black"
      />
      <path
        d="M47.1845 5.5593C47.1845 5.5593 34.6292 4.7449 30.1456 8.00246C25.6619 11.26 24.316 82.9259 24.316 82.9259M113.098 5.5593C113.098 5.5593 127.447 4.09337 131.034 8.00246C134.621 11.9115 140.899 82.9259 140.899 82.9259"
        stroke="#BABABA"
      />
      <path
        d="M107.718 1.08017H51.2205C41.7283 1.0802 24.3169 82.9259 24.3169 82.9259H140.899C140.899 82.9259 118.928 1.08023 107.718 1.08017Z"
        fill="black"
        fill-opacity="0.73"
        stroke="black"
      />
      <path
        d="M41.7293 140.84H124.383C135.704 140.84 144.883 150.018 144.883 161.34V164.272H21.2293V161.34C21.2293 150.018 30.4075 140.84 41.7293 140.84Z"
        fill="#BABABA"
        fill-opacity="0.55"
        stroke="black"
      />
      <path
        d="M8.32036 74.0026C13.734 71.9803 24.4251 73.5164 24.4251 73.5164L23.9371 80.8087L1 80.8087C1 80.8087 4.41617 75.461 8.32036 74.0026Z"
        fill="black"
        fill-opacity="0.73"
      />
      <path
        d="M156.68 74.0654C151.266 72.0618 140.575 73.5838 140.575 73.5838L141.063 80.8087L164 80.8087C164 80.8087 160.584 75.5104 156.68 74.0654Z"
        fill="black"
        fill-opacity="0.73"
      />
      <path
        d="M28.9057 66.6341L28.7079 66.6331L28.6614 66.8253L24.9472 82.1774L24.8726 82.4857L25.1898 82.4862L139.959 82.6719L140.289 82.6725L140.201 82.3552L136.053 67.3745L136.003 67.1922L135.814 67.1912L28.9057 66.6341Z"
        fill="#778396"
        fill-opacity="0.7"
        stroke="black"
        stroke-width="0.5"
      />
    </svg>
  )
}
