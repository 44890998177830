import { CheckboxTreeContainer } from "../CheckboxTree/CheckboxTree"
import { CustomDateRangePicker } from "../../entities/CustomDateRangePicker /CustomDateRangePicker"
import { useAppDispatch } from "../../shared/lib/hooks/store"
import classes from "./LeftBar.module.css"
import { setPage } from "src/store/reducers/routing/routerThunk"

const LeftBar = () => {
  const dispatch = useAppDispatch()
  return (
    <div className={classes.leftbar}>
      <div className={classes.leftbar__logo}>
        <img
          onClick={() => dispatch(setPage("main"))}
          className="logo cursor-pointer transition-all active:active"
          src="logo.png"
          alt="ARSA"
        />
      </div>

      <CustomDateRangePicker />

      <CheckboxTreeContainer />
    </div>
  )
}

export default LeftBar
