import { AppDispatch, RootState } from "@/store/store"
import { useDispatch, useSelector } from "react-redux"
import { setGSettingsPage } from "src/store/reducers/globalSettings/globalSettings"
import { DriversIcon } from "../icons/DriversIcon"
import { ObjectsIcons } from "../icons/ObjectsIcons"
import { UsersIcon } from "../icons/UsersIcon"
import styles from "../styles/settings.module.css"
import { navbarItems } from "./navbarItems"

export const Navbar = () => {
  const page = useSelector((state: RootState) => state.globalSettings.page)
  const dispatch = useDispatch<AppDispatch>()
  return (
    <div className={styles.settings_navbar}>
      {navbarItems.map((item, index) => (
        <div
          onClick={() => dispatch(setGSettingsPage(item.name))}
          className={`${styles.settings_navbar_item} ${page == item.name ? styles.active : ""}`}
        >
          {item.name == "drivers" && <DriversIcon fill={"black"} width={24} />}
          {item.name == "objects" && <ObjectsIcons fill={"black"} width={24} />}
          {item.name == "users" && <UsersIcon fill={"black"} width={24} />}
          <div className="">{item.title}</div>
        </div>
      ))}
    </div>
  )
}
