import { IIcon } from "@/interfaces/icon"

export const Change = ({ width }: IIcon) => {
  return (
    <svg
      fill="none"
      stroke="#ff6700"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
    </svg>
  )
}
export const Delete = ({ width }: IIcon) => {
  return (
    <svg
      width={width}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="&lt;Compound Path&gt;"
        d="M2.77 24L2.71 22.98L1.84 7.27L4.1 7.27L4.91 21.84L17.08 21.84L17.87 7.27L20.15 7.27L19.25 24L2.77 24ZM15.8 0L15.8 2.69L22 2.69L22 4.85L0 4.85L0 2.69L6.19 2.69L6.19 0L15.8 0ZM8.17 2.69L13.82 2.69L13.82 1.35L8.17 1.35L8.17 2.69ZM9.63 20.08L9.34 7.27L7.38 7.27L7.67 20.08L9.63 20.08ZM14.29 20.08L14.58 7.27L12.61 7.27L12.33 20.08L14.29 20.08Z"
        fill="#E94040"
        fill-opacity="1.000000"
      />
    </svg>
  )
}
export const Create = ({ width }: IIcon) => {
  return (
    <svg width={width} viewBox="0 0 6.4541 6.40723" fill="none">
      <defs />
      <path
        id="+"
        d="M2.76 6.38Q2.81 6.4 2.87 6.4L3.57 6.4Q3.66 6.4 3.73 6.36Q3.76 6.34 3.78 6.32Q3.82 6.28 3.84 6.23Q3.86 6.18 3.86 6.12L3.86 3.77L6.16 3.77Q6.23 3.77 6.28 3.75Q6.33 3.73 6.36 3.69Q6.39 3.66 6.41 3.63Q6.45 3.56 6.45 3.47L6.45 2.85Q6.45 2.75 6.41 2.69Q6.39 2.66 6.36 2.64Q6.33 2.61 6.29 2.59Q6.23 2.56 6.16 2.56L3.86 2.56L3.86 0.28Q3.86 0.21 3.83 0.15Q3.81 0.11 3.78 0.08Q3.76 0.05 3.73 0.04Q3.66 0 3.57 0L2.87 0Q2.81 0 2.76 0.02Q2.71 0.04 2.67 0.08Q2.63 0.12 2.61 0.17Q2.58 0.22 2.58 0.28L2.58 2.56L0.28 2.56Q0.21 2.56 0.16 2.59Q0.11 2.61 0.08 2.64Q0.06 2.66 0.04 2.68Q0 2.75 0 2.85L0 3.47Q0 3.56 0.03 3.63Q0.05 3.66 0.08 3.69Q0.12 3.73 0.17 3.75Q0.22 3.77 0.28 3.77L2.58 3.77L2.58 6.12Q2.58 6.17 2.6 6.22Q2.62 6.27 2.67 6.32Q2.71 6.36 2.76 6.38Z"
        fill="#FF6700"
        fill-opacity="1.000000"
        fill-rule="evenodd"
      />
    </svg>
  )
}
export const CreateFolder = ({ width }: IIcon) => {
  return (
    <svg
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector"
        d="M20.4 3.78L12.86 3.78L12.48 2.52C12.23 1.78 11.76 1.14 11.15 0.69C10.54 0.23 9.81 -0.01 9.07 0L3.59 0C2.64 0 1.72 0.39 1.05 1.1C0.37 1.82 0 2.78 0 3.78L0 20.21C0 21.21 0.37 22.17 1.05 22.89C1.72 23.6 2.64 24 3.59 24L20.4 24C21.35 24 22.27 23.6 22.94 22.89C23.62 22.17 24 21.21 24 20.21L24 7.57C24 6.57 23.62 5.61 22.94 4.89C22.27 4.18 21.35 3.78 20.4 3.78ZM21.59 20.21C21.59 20.54 21.47 20.86 21.24 21.1C21.02 21.34 20.71 21.47 20.4 21.47L3.59 21.47C3.28 21.47 2.97 21.34 2.75 21.1C2.52 20.86 2.4 20.54 2.4 20.21L2.4 3.78C2.4 3.45 2.52 3.13 2.75 2.89C2.97 2.65 3.28 2.52 3.59 2.52L9.07 2.52C9.32 2.52 9.56 2.6 9.77 2.76C9.97 2.91 10.13 3.13 10.21 3.38L10.86 5.45C10.94 5.7 11.09 5.92 11.29 6.07C11.5 6.23 11.74 6.31 12 6.31L20.4 6.31C20.71 6.31 21.02 6.44 21.24 6.68C21.47 6.92 21.59 7.24 21.59 7.57L21.59 20.21Z"
        fill="#FF6700"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
      <path
        id="+"
        d="M11.76 17.51Q11.82 17.53 11.89 17.53L12.7 17.53Q12.81 17.53 12.88 17.49Q12.92 17.46 12.94 17.43Q12.99 17.39 13.01 17.33Q13.04 17.27 13.04 17.2L13.04 14.47L15.72 14.47Q15.8 14.47 15.86 14.44Q15.91 14.42 15.96 14.37Q15.99 14.33 16.02 14.29Q16.06 14.22 16.06 14.12L16.06 13.39Q16.06 13.28 16.01 13.2Q15.99 13.17 15.96 13.14Q15.92 13.11 15.87 13.09Q15.81 13.05 15.72 13.05L13.04 13.05L13.04 10.39Q13.04 10.31 13 10.24Q12.98 10.2 12.94 10.16Q12.92 10.13 12.88 10.11Q12.81 10.06 12.7 10.06L11.89 10.06Q11.82 10.06 11.76 10.08Q11.7 10.11 11.65 10.16Q11.6 10.2 11.58 10.26Q11.55 10.32 11.55 10.39L11.55 13.05L8.86 13.05Q8.78 13.05 8.72 13.08Q8.67 13.1 8.62 13.14Q8.6 13.17 8.58 13.19Q8.53 13.27 8.53 13.39L8.53 14.12Q8.53 14.22 8.57 14.3Q8.59 14.34 8.62 14.37Q8.67 14.42 8.73 14.44Q8.79 14.47 8.86 14.47L11.55 14.47L11.55 17.2Q11.55 17.26 11.57 17.32Q11.6 17.38 11.65 17.43Q11.7 17.48 11.76 17.51Z"
        fill="#FF6700"
        fill-opacity="1.000000"
        fill-rule="evenodd"
      />
    </svg>
  )
}
export const CreateUser = ({ width }: IIcon) => {
  return (
    <svg width={width} viewBox="0 0 21.543 17" fill="none">
      <defs />
      {/* <path
        id="Vector"
        d="M17.28 5.09C17.28 7.91 15.38 10.2 13.04 10.2C10.69 10.2 8.79 7.91 8.79 5.09C8.79 2.28 10.69 0 13.04 0C15.38 0 17.28 2.28 17.28 5.09Z"
        fill="#FFFFFF"
        fill-opacity="1.000000"
        fill-rule="evenodd"
      /> */}
      <path
        id="Vector"
        d="M13.04 10.2C15.38 10.2 17.28 7.91 17.28 5.09C17.28 2.28 15.38 0 13.04 0C10.69 0 8.79 2.28 8.79 5.09C8.79 7.91 10.69 10.2 13.04 10.2ZM13.45 9.1C13.31 9.12 13.18 9.13 13.04 9.13C12.9 9.13 12.76 9.12 12.62 9.1C12.37 9.06 12.11 8.97 11.87 8.85C11.49 8.65 11.15 8.38 10.85 8.02C10.54 7.65 10.3 7.22 10.13 6.73C10.1 6.65 10.07 6.56 10.05 6.48C9.92 6.04 9.86 5.58 9.86 5.09C9.86 4.61 9.92 4.15 10.05 3.71C10.07 3.63 10.1 3.54 10.13 3.46C10.3 2.97 10.54 2.54 10.85 2.17C11.15 1.81 11.49 1.53 11.87 1.34C12.11 1.22 12.37 1.13 12.62 1.09C12.76 1.07 12.9 1.06 13.04 1.06C13.18 1.06 13.31 1.07 13.45 1.09C13.71 1.13 13.96 1.22 14.21 1.34C14.58 1.53 14.92 1.81 15.22 2.17C15.53 2.54 15.77 2.97 15.94 3.46C15.97 3.54 16 3.63 16.02 3.71C16.15 4.15 16.22 4.61 16.22 5.09C16.22 5.58 16.15 6.04 16.02 6.48C16 6.56 15.97 6.65 15.94 6.73C15.77 7.22 15.53 7.65 15.22 8.02C14.92 8.38 14.58 8.65 14.21 8.85C13.96 8.97 13.71 9.06 13.45 9.1Z"
        fill="#FC6904"
        fill-opacity="1.000000"
        fill-rule="evenodd"
      />
      <path
        id="Vector"
        d="M21.35 14.53C20.59 13 19.15 11.73 17.28 10.96C16.77 10.79 16.18 10.79 15.75 11.05C14.91 11.56 14.06 11.81 13.04 11.81C12.02 11.81 11.17 11.56 10.32 11.05C9.9 10.88 9.3 10.79 8.79 11.05C6.93 11.81 5.49 13.09 4.72 14.62C4.13 15.72 5.06 17 6.33 17L19.74 17C21.02 17 21.95 15.72 21.35 14.53Z"
        fill="#FFFFFF"
        fill-opacity="1.000000"
        fill-rule="evenodd"
      />
      <path
        id="Vector"
        d="M4.72 14.62C4.13 15.72 5.06 17 6.33 17L19.74 17C21.02 17 21.95 15.72 21.35 14.53C20.59 13 19.15 11.73 17.28 10.96C16.77 10.79 16.18 10.79 15.75 11.05C14.91 11.56 14.06 11.81 13.04 11.81C12.02 11.81 11.17 11.56 10.32 11.05C9.9 10.88 9.3 10.79 8.79 11.05C6.93 11.81 5.49 13.09 4.72 14.62ZM9.86 12.01C9.76 11.97 9.65 11.95 9.55 11.95C9.44 11.94 9.34 11.96 9.27 12L9.23 12.01L9.2 12.03C8.38 12.36 7.66 12.8 7.05 13.34C6.45 13.86 5.99 14.45 5.67 15.09L5.66 15.1L5.66 15.12C5.62 15.19 5.6 15.26 5.6 15.32C5.6 15.42 5.63 15.52 5.7 15.61C5.72 15.64 5.73 15.66 5.75 15.68C5.81 15.74 5.88 15.8 5.96 15.84C6.01 15.87 6.06 15.89 6.11 15.9C6.18 15.92 6.25 15.93 6.33 15.93L19.74 15.93C19.83 15.93 19.91 15.92 19.99 15.9C20.03 15.88 20.07 15.86 20.11 15.84C20.18 15.8 20.25 15.76 20.3 15.7C20.32 15.67 20.35 15.64 20.37 15.61C20.43 15.52 20.46 15.43 20.47 15.34C20.49 15.23 20.46 15.12 20.4 15C20.08 14.36 19.62 13.78 19.03 13.26C18.43 12.73 17.72 12.29 16.91 11.96C16.78 11.92 16.65 11.9 16.53 11.9C16.43 11.91 16.35 11.93 16.3 11.96C15.79 12.26 15.27 12.49 14.75 12.64C14.21 12.8 13.64 12.87 13.04 12.87C12.44 12.87 11.87 12.8 11.32 12.64C10.83 12.5 10.34 12.29 9.86 12.01Z"
        fill="#FC6904"
        fill-opacity="1.000000"
        fill-rule="evenodd"
      />
      <path
        id="+"
        d="M2.76 11.58Q2.81 11.6 2.87 11.6L3.57 11.6Q3.66 11.6 3.73 11.56Q3.76 11.54 3.78 11.51Q3.82 11.47 3.84 11.43Q3.86 11.37 3.86 11.31L3.86 8.97L6.16 8.97Q6.23 8.97 6.28 8.95Q6.33 8.93 6.36 8.89Q6.39 8.86 6.41 8.82Q6.45 8.76 6.45 8.67L6.45 8.05Q6.45 7.95 6.41 7.89Q6.39 7.86 6.36 7.84Q6.33 7.81 6.29 7.79Q6.23 7.76 6.16 7.76L3.86 7.76L3.86 5.48Q3.86 5.41 3.83 5.35Q3.81 5.31 3.78 5.28Q3.76 5.25 3.73 5.23Q3.66 5.19 3.57 5.19L2.87 5.19Q2.81 5.19 2.76 5.21Q2.71 5.23 2.67 5.28Q2.63 5.32 2.61 5.36Q2.58 5.42 2.58 5.48L2.58 7.76L0.28 7.76Q0.21 7.76 0.16 7.78Q0.11 7.8 0.08 7.84Q0.06 7.86 0.04 7.88Q0 7.95 0 8.05L0 8.67Q0 8.76 0.03 8.83Q0.05 8.86 0.08 8.89Q0.12 8.93 0.17 8.95Q0.22 8.97 0.28 8.97L2.58 8.97L2.58 11.31Q2.58 11.37 2.6 11.41Q2.62 11.47 2.67 11.51Q2.71 11.56 2.76 11.58Z"
        fill="#FF6700"
        fill-opacity="1.000000"
        fill-rule="evenodd"
      />
    </svg>
  )
}
