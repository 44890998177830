export const FolderIcon = ({ fill, width }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs />
      <path
        id="Vector"
        d="M20.4 3.78L12.86 3.78L12.48 2.52C12.23 1.78 11.76 1.14 11.15 0.69C10.54 0.23 9.81 -0.01 9.07 0L3.59 0C2.64 0 1.72 0.39 1.05 1.1C0.37 1.82 0 2.78 0 3.78L0 20.21C0 21.21 0.37 22.17 1.05 22.89C1.72 23.6 2.64 24 3.59 24L20.4 24C21.35 24 22.27 23.6 22.94 22.89C23.62 22.17 24 21.21 24 20.21L24 7.57C24 6.57 23.62 5.6 22.94 4.89C22.27 4.18 21.35 3.78 20.4 3.78ZM21.59 20.21C21.59 20.54 21.47 20.86 21.24 21.1C21.02 21.34 20.71 21.47 20.4 21.47L3.59 21.47C3.28 21.47 2.97 21.34 2.75 21.1C2.52 20.86 2.4 20.54 2.4 20.21L2.4 3.78C2.4 3.45 2.52 3.13 2.75 2.89C2.97 2.65 3.28 2.52 3.59 2.52L9.07 2.52C9.32 2.52 9.56 2.6 9.77 2.76C9.97 2.91 10.13 3.13 10.21 3.38L10.86 5.45C10.94 5.7 11.09 5.92 11.29 6.07C11.5 6.23 11.74 6.31 12 6.31L20.4 6.31C20.71 6.31 21.02 6.44 21.24 6.68C21.47 6.92 21.59 7.24 21.59 7.57L21.59 20.21Z"
        fill={fill}
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
    </svg>
  )
}
