import { returnErrorFetch, serverInstance } from "./settingsApiInstance"

interface ITrackMapApi {
  getInfoPointEvent: (
    uid: string,
    checkedEvent: number[],
    start_date: number,
    end_date: number,
  ) => Promise<{ data: any; status: number }>
  getTrack: (uid: string, start_time: any, end_time: any) => Promise<{ data: any; status: number }>
  setPointInfo: (id: number) => Promise<{ data: any; status: number }>
  getSettingsMap: () => Promise<{ data: any; status: number }>
  setSettingsMap: (settings: any) => Promise<{ data: any; status: number }>
}

export const trackMapApi: ITrackMapApi = {
  getInfoPointEvent: async (
    uid: string,
    checkedEvent: number[],
    start_date: number,
    end_date: number,
  ) => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/reports/tracks/get_events", {
        vehicle_uid: uid,
        start_date: start_date,
        end_date: end_date,
        event_ids: checkedEvent,
      })
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },

  getTrack: async (uid: string, start_time: any, end_time: any) => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/reports/tracks/get_track", {
        vehicle_uid: uid, // uid машины *
        start_date: start_time, // Дата начала периода *
        end_date: end_time, // Дата окончания периода *
        thinning: true, // Прореживать трек
        star_filter: true,
      })
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  setPointInfo: async (id: number) => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/reports/tracks/get_point_data", {
        id: id,
      })
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  getSettingsMap: async () => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/users/get_user_settings")
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  setSettingsMap: async () => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/users/post_user_settings")
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
}
