import React, { useState } from "react"
import { SearchIcon } from "src/components/Settings/icons/SearchIcon"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { setNewLevelIds } from "src/store/reducers/globalSettings/globalSettings"
import {
  setObjectSettingsAccountId,
  setObjectSettingsUid,
} from "src/store/reducers/objectSettings/objectSettings"
import styles from "../../../styles/search-page.module.css"
import { objectsSearchHelper } from "../../Search/helpers/vehicleHelper"
import { SearchTreeView } from "../../Search/SearchTreeView/SearchTreeView"
import { IOnClickName } from "../Drivers/SearchDrivers"
interface IObjectSearchProps {
  setActiveId: (id: any) => void
}
export const ObjectSearch = React.memo(({ setActiveId }: IObjectSearchProps) => {
  const [isOpenSearchPopup, setOpenSearchPopup] = useState(false)
  const [searchText, setSearchText] = useState("")
  const objects = useAppSelector((state) => state.globalSettings.data.objects)
  const dispatch = useAppDispatch()
  const filteredData =
    objects != null && typeof objects != "string" ? objectsSearchHelper(objects, searchText) : []

  const onClickPath = (levelIds: [number, number][]) => {
    dispatch(setNewLevelIds({ levelIds, page: "objects" }))
  }
  const onClickName = ({
    id,
    is_folder,
    is_root,
    role_id,
    parent_id,
    account_id,
  }: IOnClickName) => {
    if (is_folder) return
    dispatch(setObjectSettingsUid(id))
    if (account_id) dispatch(setObjectSettingsAccountId(account_id))
  }

  return (
    <>
      <div className={styles.input_block}>
        <div className={styles.input_item}>
          <input
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
              if (e.target.value.length > 0) {
                setOpenSearchPopup(true)
              } else {
                setOpenSearchPopup(false)
              }
            }}
            type="text"
            placeholder={`Поиск`}
            // onKeyDown={(e) => {
            //   if (e.key == "Enter") {
            //     setOpenSearchPopup(true)
            //   }
            // }}
          />
          <div className={styles.input_item_opt}>
            {searchText.length > 0 ? (
              <span
                onClick={() => {
                  setSearchText("")
                  setOpenSearchPopup(false)
                }}
                className="icon-cross "
              ></span>
            ) : (
              <SearchIcon width={18} fill="rgb(255, 103, 0)" />
            )}
          </div>
        </div>
      </div>
      {isOpenSearchPopup && (
        <div className={styles.input_page}>
          <div className={styles.input_page_container}>
            <div
              className="absolute icon-cross text-red-400 right-0 top-0 cursor-pointer p-3"
              onClick={() => setOpenSearchPopup(false)}
            ></div>
            <div className={styles.input_page_tree_view}>
              {filteredData.length > 0 ? (
                <SearchTreeView
                  tree={filteredData}
                  onClickPath={onClickPath}
                  onClickName={onClickName}
                  setActiveId={setActiveId}
                  setCloseSearch={() => setOpenSearchPopup(false)}
                />
              ) : (
                <div className="text-center text-xl">Ничего не найдено</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
})
