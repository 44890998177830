import { useEffect } from "react"
import { noString } from "src/shared/lib/helpers/notStringData"
import { useAppDispatch, useAppSelector } from "../../../../../../../shared/lib/hooks/store"
import { updateGSItemData } from "src/store/reducers/globalSettings/globalSettings"
import { IUserInfo } from "src/store/reducers/globalSettings/interface"
import { groupIDFinder } from "./trees/groupIdFinder"
import { groupNameFinder } from "./trees/groupNameFinder"

export interface ITreeSwitches {
  handler: (e: null | "vehicle" | "geozone" | "driver") => void
  accountId: number
}

export const TreeSwitches = ({ handler, accountId }: ITreeSwitches) => {
  const dispatch = useAppDispatch()
  const data: IUserInfo = useAppSelector((state) =>
    noString(state.globalSettings.tree.users.itemSettings.settingsData),
  )
  const accId = useAppSelector((state) => state.globalSettings.tree.users.activeAccountID)
  const treeG = useAppSelector(
    (state) => state.globalSettings.tree.users.itemSettings.treeWithoutItems,
  )

  const treeV = useAppSelector(
    (state) => state.globalSettings.tree.objects.itemSettings.treeWithoutItems,
  )
  const treeD = useAppSelector(
    (state) => state.globalSettings.tree.drivers.itemSettings.treeWithoutItems,
  )

  const groupsTrees = useAppSelector(
    (state) => noString(state.globalSettings.tree.users.itemSettings.settingsData)?.groups_tree,
  )
  useEffect(() => {
    if (data.id == 0) {
      const arr = JSON.parse(JSON.stringify(groupsTrees))

      const groupIdV = groupIDFinder(accountId, [treeV])
      const groupIdG = groupIDFinder(accId, [treeG])
      const groupIdD = groupIDFinder(accId, [treeD])
      arr.vehicle_groups_tree_id = groupIdV
      arr.geozone_groups_tree_id = groupIdG
      arr.driver_groups_tree_id = groupIdD

      dispatch(
        updateGSItemData({
          value: arr,
          field: "users",
          profileField: "groups_tree",
        }),
      )
    }
  }, [])
  return (
    <div className="flex w-full flex-col text-sm items-start text-right justify-center gap-5 font-light">
      <div className="w-full grid items-center grid-cols-2 gap-5">
        <p>Группа машин</p>
        <div
          onClick={() => handler("vehicle")}
          className="bg-slate-600 w-fit px-10 py-1 text-white rounded hover:bg-slate-500 active:bg-slate-800 transition-all"
        >
          {groupsTrees.vehicle_groups_tree_id == -1
            ? "Выбрать..."
            : groupNameFinder(groupsTrees.vehicle_groups_tree_id, [treeV])}
        </div>
      </div>
      <div
        onClick={() => handler("geozone")}
        className="w-full grid items-center grid-cols-2 gap-5"
      >
        <p>Группа гео. зон</p>
        <div className="bg-slate-600 w-fit px-10 py-1 text-white rounded hover:bg-slate-500 active:bg-slate-800 transition-all">
          {groupsTrees.geozone_groups_tree_id == -1
            ? "Выбрать..."
            : groupNameFinder(groupsTrees.geozone_groups_tree_id, [treeG])}
        </div>
      </div>
      <div className="w-full grid items-center grid-cols-2 gap-5">
        <p>Группа водителей</p>
        <div
          onClick={() => handler("driver")}
          className="bg-slate-600 w-fit px-10 py-1 text-white rounded hover:bg-slate-500 active:bg-slate-800 transition-all"
        >
          {groupsTrees.driver_groups_tree_id == -1
            ? "Выбрать..."
            : groupNameFinder(groupsTrees.driver_groups_tree_id, [treeD])}
        </div>
      </div>
    </div>
  )
}
