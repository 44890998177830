import { IObjectSettingsMain } from "@/interfaces/objectSettings"
import React, { useEffect, useState } from "react"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { mergeObjects, structureObject } from "../object-settings-structure"
import { SaveObjectSettings } from "../Save/SaveObjectSettings"
import style from "../styles/object-settings.module.css"
import { ObjectSettingsAxles } from "./ObjectSettingsContentItems/Axle/ObjectSettingsAxles"
import { Diag } from "./ObjectSettingsContentItems/Diag/Diag"
import { Driver } from "./ObjectSettingsContentItems/driver/Driver"
import { Fuel } from "./ObjectSettingsContentItems/Fuel/Fuel"
import { ObjectSettingsMain } from "./ObjectSettingsContentItems/Main/ObjectSettingsMain"
import { ObjectSettingsMovement } from "./ObjectSettingsContentItems/Movement/ObjectSettingsMovement"
import { SpeedControl } from "./ObjectSettingsContentItems/Speed/SpeedControl"
import { ObjectSettingsTrack } from "./ObjectSettingsContentItems/Track/ObjectSettingsTrack"

interface IObjectSettingsContentProps {
  page: string
}

export const ObjectSettingsContent = React.memo(({ page }: IObjectSettingsContentProps) => {
  const settingsFomServer: any = useAppSelector((state) => state.objectSettings.data)
  const structure = structureObject

  const [main, setMain] = useState<IObjectSettingsMain | null>(null)

  useEffect(() => {
    if (settingsFomServer != null) {
      let settings = mergeObjects(settingsFomServer, structure)
      console.info(settings)
      setMain(settings?.main)
    }
  }, [settingsFomServer])
  return (
    <div className={style.os_view_box}>
      <div className={style.os_view_box_container}>
        {page === "main" && <ObjectSettingsMain />}
        {page === "tpms" && <ObjectSettingsAxles />}
        {page === "diag" && <Diag />}
        {page === "speed_control" && <SpeedControl />}
        {page === "driver_cards" && <Driver />}
        {page === "movement" && <ObjectSettingsMovement />}
        {page === "fuel" && <Fuel />}
        {page == "track" && <ObjectSettingsTrack />}
      </div>

      <SaveObjectSettings />
    </div>
  )
})
