import React, { useCallback } from "react"
import { onlyNumber } from "src/components/Settings/Content/onlyNumber"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { Checkbox } from "src/shared/ui/Checkbox"
import { Input } from "src/shared/ui/Input"
import {
  setAddr,
  setDataSource,
  setFunc,
  setRegNo,
  setUseAsDriverCode,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/driver-cards/driver-cards"
import { SwitchComponent } from "../../../helpComponents/SwitchComponent"
import { ISwitchBody } from "../../ObjectSettingsItem"
interface IData {
  name: string
  field: "addr" | "func" | "regNo" | "dataSource" | "use_as_driver_code"
  value: number | boolean
  type: "switch" | "checkbox" | "text" | "number"
  body?: ISwitchBody[]
}

const functions = [
  {
    option: 0,
    name: "Read Coils",
  },
  {
    option: 1,
    name: "Read Discrete Inputs",
  },
  {
    option: 2,
    name: "Read Holding Registers",
  },
  {
    option: 3,
    name: "Read Input Registers",
  },
]

export const CustomProtocolMonitoring = React.memo(() => {
  const dispatch = useAppDispatch()

  const { use_as_driver_code, func, addr, dataSource, regNo } = useAppSelector(
    (state) => state.osDriverCard.CustomProtocolMonitoring,
  )

  const handleUseAsDriverCode = useCallback(() => dispatch(setUseAsDriverCode()), [dispatch])
  const handleFunc = useCallback((a: number) => dispatch(setFunc(a)), [dispatch])
  const handleAddr = useCallback((a: any) => dispatch(setAddr(onlyNumber(a))), [dispatch])
  const handleDataSource = useCallback(
    (a: any) => dispatch(setDataSource(onlyNumber(a))),
    [dispatch],
  )
  const handleRedNo = useCallback((a: any) => dispatch(setRegNo(onlyNumber(a))), [dispatch])
  return (
    <div>
      <h2 className="text-center text-xl my-10 font-light">Считыватель карты</h2>
      <div className="grid w-1/2 mx-auto grid-cols-2 text-[14px]  gap-y-5 gap-x-32  justify-center text-right items-center">
        <p>Использовать как код карты</p>
        <div className="w-fit">
          <Checkbox checked={use_as_driver_code} setChecked={handleUseAsDriverCode} />
        </div>
        <p>Функция</p>
        <div className="w-full">
          <SwitchComponent
            value={func}
            body={functions}
            onChange={(e) => handleFunc(Number(e.target.value))}
          />
        </div>
        <p>addr</p>
        <div className="w-full relative">
          <Input
            variant="orange"
            inputType={"text"}
            value={addr.toString()}
            setValue={handleAddr}
          />
          {addr === "" && (
            <div className="text-red-500 text-sm absolute left-0">Заполните поле</div>
          )}
        </div>
        <p>dataSource</p>
        <div className="w-full relative">
          <Input
            variant="orange"
            inputType={"text"}
            value={dataSource.toString()}
            setValue={handleDataSource}
          />
          {dataSource === "" && (
            <div className="text-red-500 text-sm absolute left-0">Заполните поле</div>
          )}
        </div>
        <p>regNo</p>
        <div className="w-full relative">
          <Input
            variant="orange"
            inputType={"text"}
            value={regNo.toString()}
            setValue={handleRedNo}
          />
          {regNo === "" && (
            <div className="text-red-500 text-sm absolute left-0">Заполните поле</div>
          )}
        </div>
      </div>
    </div>
  )
})
