import React from "react"
import styles from "./style.module.css"

interface Iprops {
  value: string
  setValue: (value: string) => void
  variant?: "orange" | "silver" | "palesilver"
  placeholderTextarea?: string
}

export const TextArea: React.FC<Iprops> = ({ value, setValue, variant, placeholderTextarea }) => {
  return (
    <textarea
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className={`${styles.textarea} ${variant ? styles[variant] : ""}`}
      placeholder={placeholderTextarea ? placeholderTextarea : ""}
    ></textarea>
  )
}
