import { returnErrorFetch, serverInstance } from "./settingsApiInstance"

type VehicleTreeAPI = {
  changeTreeGroupsVehicle: (parent_id: number, group_name: string, id?: number) => Promise<any>
  deleteGroupVehicle: (id: number) => Promise<any>
  getTreeGroupsVehicles: (type: any) => Promise<any>
}

export const vehicleTreeAPI: VehicleTreeAPI = {
  changeTreeGroupsVehicle: async (parent_id: number, group_name: string, id?: number) => {
    try {
      const { data, status } = await serverInstance.post(
        "svr/v1/ctl/vehicles/post_vehicles_group",
        {
          parent_id: parent_id,
          group_name: group_name,
          id: id ? id : 0,
        },
      )
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },

  deleteGroupVehicle: async (id: number) => {
    try {
      const { data, status } = await serverInstance.post(
        "svr/v1/ctl/vehicles/delete_vehicles_group",
        {
          id: id,
        },
      )
      return { data, status }
    } catch (error: any) {
      return returnErrorFetch(error)
    }
  },

  getTreeGroupsVehicles: async (type: any) => {
    try {
      const { data, status } = await serverInstance.post(
        `${type}/v1/ctl/vehicles/get_tree_vehicles`,
      )
      return { data, status }
    } catch (error: any) {
      return returnErrorFetch(error)
    }
  },
}
