export const ObjectsIcons = ({ fill, width }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 25.5 21.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs />
      <path
        id="Vector"
        d="M17.11 15.19L17.11 6.3L21.47 6.3L24.75 9.63L24.75 15.19L17.11 15.19L0.75 15.19L0.75 0.75L17.11 0.75L17.11 15.19M5.65 20.75C4.15 20.75 2.93 19.5 2.93 17.97C2.93 16.43 4.15 15.19 5.65 15.19C7.16 15.19 8.38 16.43 8.38 17.97C8.38 19.5 7.16 20.75 5.65 20.75ZM19.84 20.75C18.33 20.75 17.11 19.5 17.11 17.97C17.11 16.43 18.33 15.19 19.84 15.19C21.34 15.19 22.56 16.43 22.56 17.97C22.56 19.5 21.34 20.75 19.84 20.75Z"
        stroke={fill}
        stroke-opacity="1.000000"
        stroke-width="1.500000"
        stroke-linejoin="round"
      />
    </svg>
  )
}
