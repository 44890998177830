import { ObjectSettingsItem } from "src/widgets/ObjectSettings/Content/ObjectSettingsContentItems/ObjectSettingsItem"
import { noString } from "src/shared/lib/helpers/notStringData"
import { useAppDispatch, useAppSelector } from "../../../../../../../shared/lib/hooks/store"
import { updateGSItemData } from "src/store/reducers/globalSettings/globalSettings"
import { IUserInfo } from "src/store/reducers/globalSettings/interface"

export const Name = () => {
  const dispatch = useAppDispatch()
  const user: IUserInfo = useAppSelector((state) =>
    noString(state.globalSettings.tree.users.itemSettings.settingsData),
  )
  const value = user?.user_name
  return (
    <div className="w-full grid items-center grid-cols-2 gap-5">
      <ObjectSettingsItem
        title={"Имя пользователя"}
        value={value}
        onChange={(e) =>
          dispatch(
            updateGSItemData({
              value: e.target.value,
              field: "users",
              profileField: "user_name",
            }),
          )
        }
        type={"text"}
      />
    </div>
  )
}
