import { Action, ThunkAction, combineReducers, configureStore } from "@reduxjs/toolkit"
import table from "../widgets/Table/model/table"
import auth from "./reducers/auth/authSlice"
import car from "./reducers/car/car"
import catalogTire from "./reducers/catalogTire/catalogTire"
import driver from "./reducers/drivers/driverSlice"
import filters from "./reducers/filters/filters"
import geoZones from "./reducers/geozonesTree/geozonesTreeSlice"
import globalErrors from "./reducers/globalErrors/globalErrorsSlice"
import globalSettings from "./reducers/globalSettings/globalSettings"
import layoutCalendar from "./reducers/layoutCalendar/layoutCalendar"
import map from "./reducers/map/map"
import mapEditor from "./reducers/mapEditor/mapEditor"
import mapGeozone from "./reducers/mapGeozone/mapGeozone"
import mapTrack from "./reducers/mapTrack/mapTrack"
import mapMenu from "./reducers/menuMap/menuMap"
import objectSettings from "./reducers/objectSettings/objectSettings"
import osDiag from "./reducers/objectSettings/objectSettingsPageSlices/diag/diag"
import osDriverCard from "./reducers/objectSettings/objectSettingsPageSlices/driver-cards/driver-cards"
import osFuel from "./reducers/objectSettings/objectSettingsPageSlices/fuel/fuel"
import osId from "./reducers/objectSettings/objectSettingsPageSlices/id/osId"
import osMain from "./reducers/objectSettings/objectSettingsPageSlices/main/main"
import osMovement from "./reducers/objectSettings/objectSettingsPageSlices/movement/movement"
import osSpeedControl from "./reducers/objectSettings/objectSettingsPageSlices/speed-control/speed-control"
import osTPMS from "./reducers/objectSettings/objectSettingsPageSlices/tpms/tpms"
import osTrack from "./reducers/objectSettings/objectSettingsPageSlices/track/track"
import router from "./reducers/routing/routerSlice"
import security from "./reducers/security/security"
import switchTire from "./reducers/switchTire/switchTire"
import fuel from "./reducers/testingUI/fuel/fuel"
import tpms from "./reducers/tpms/tpms"
import vehicles from "./reducers/vehiclesTree/vehicleTreeSlice"
import wheelChart from "./reducers/wheelChart/wheelChart"

export const store = configureStore({
  reducer: combineReducers({
    layoutCalendar,
    mapGeozone,
    mapEditor,
    mapTrack,
    mapMenu,
    security,
    table,
    car,
    map,
    wheelChart,
    filters,
    tpms,
    catalogTire,
    switchTire,
    globalErrors,
    auth,
    objectSettings,
    fuel,
    vehicles,
    driver,
    geoZones,
    router,
    globalSettings,
    osId,
    osMain,
    osDriverCard,
    osDiag,
    osSpeedControl,
    osTrack,
    osTPMS,
    osMovement,
    osFuel,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
