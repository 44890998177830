import React, { useEffect, useState } from "react"

interface ISwitchBody {
  option: number
  name: string
}

interface ISwitchComponent {
  value: number
  body: ISwitchBody[]
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export const SwitchComponent: React.FC<ISwitchComponent> = React.memo(
  ({ value, body, onChange }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [currentOption, setCurrentOption] = useState<number | undefined>(value)

    useEffect(() => {
      setCurrentOption(value)
    }, [value])

    const handleChange = (option: number) => {
      setCurrentOption(option)
      setIsOpen(false)

      // Создание синтетического события для передачи в onChange обработчик
      const syntheticEvent = {
        target: { value: option.toString() },
        currentTarget: { value: option.toString() },
        bubbles: true,
        cancelable: false,
        defaultPrevented: false,
        eventPhase: 3,
        isTrusted: true,
        preventDefault: () => {},
        isDefaultPrevented: () => false,
        stopPropagation: () => {},
        isPropagationStopped: () => false,
        persist: () => {},
        nativeEvent: new Event("change"),
      } as unknown as React.ChangeEvent<HTMLSelectElement>

      onChange(syntheticEvent)
    }

    const currentValue = body.find((i) => i.option === currentOption)
    const currentBody = body.filter((item) => item.option !== currentOption)
    return (
      <div className="w-full">
        <select className="hidden" name="" id="" onChange={onChange} value={currentOption}></select>
        <div className="w-full flex items-center justify-start relative">
          <div className="relative w-full">
            <div
              onClick={() => setIsOpen(!isOpen)}
              className={`${isOpen ? "border border-[#da7b39] border-b-none rounded-b-none" : "border border-[#da7b39]"} flex items-center justify-between w-full bg-white h-7 p-2 rounded cursor-pointer  hover:bg-slate-100`}
            >
              <div className=" text-nowrap">
                {currentValue ? currentValue.name : "Ничего не выбрано"}
              </div>
              <div className="icon-expand "></div>
            </div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="absolute top-7 left-0 w-full min-w-fit bg-white  overflow-hidden z-10 border select-none transition-all rounded-b shadow-2xl border-[#da7b39]"
            >
              {isOpen && (
                <div className="">
                  {currentBody.length == 0 ? (
                    <div className=" h-fit  hover:bg-slate-200">Ничего нет</div>
                  ) : (
                    <>
                      {currentBody.map((item, index) => (
                        <div
                          onClick={() => handleChange(item.option)}
                          className=" h-fit text-left text-nowrap px-2 hover:bg-slate-200"
                          key={index}
                          style={{
                            borderBottom:
                              index == currentBody.length - 1 ? "none" : "solid 1px #da7b39",
                          }}
                        >
                          {item.name}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  },
)
