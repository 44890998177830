import { MyMapContainer } from "../widgets/Map/MyMapContainer"

export const MapPage = () => {
  return (
    <div style={{ position: "relative", zIndex: 1 }}>
      <MyMapContainer
        center={{ lat: 51.64159, lng: 39.150995 }}
        zoomMap={7}
        track={true}
        isEditor={true}
        geozone={true}
      />
    </div>
  )
}
