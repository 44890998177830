import { returnErrorFetch, serverInstance } from "./settingsApiInstance"
// Определение интерфейса для объекта с функциями axios ts
interface IGeozoneMapAPI {
  getGeozone: (uids: string[]) => Promise<{ data: any; status: number }>
  removeGeozoneServer: (uid: string) => Promise<{ data: any; status: number }>
  setGeozoneServer: (geozone: any) => Promise<{ data: any; status: number }>
}

// Создание объекта с функциями axios ts
export const geozoneMapAPI: IGeozoneMapAPI = {
  getGeozone: async (uids: string[]) => {
    try {
      const { data, status } = await serverInstance.post(
        "svr/v1/ctl/geozones/get_geozones_detail",
        {
          geozone_uids: uids,
        },
      )
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  removeGeozoneServer: async (uid: string) => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/geozones/delete_geozone", {
        uid: uid,
      })
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  setGeozoneServer: async (geozone: any) => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/geozones/post_geozone", {
        ...geozone,
      })
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
}
