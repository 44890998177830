import { AppDispatch } from "@/store/store"
import { getAccounts, getObjectSettings, getPlugins, saveSettings } from "src/shared/api/apiGlobal"
import { setIsAuth } from "../auth/authSlice"
import { setNewGSetVehicleUID } from "../globalSettings/globalSettings"
import { thunkGetObjectsFromServer } from "../globalSettings/globalSettingsThunk"
import { setAccountsList, setObjectSettingsData, setPluginsList } from "./objectSettings"
import { setDiagState } from "./objectSettingsPageSlices/diag/diag"
import { setDriverCardState } from "./objectSettingsPageSlices/driver-cards/driver-cards"
import { setFuelState } from "./objectSettingsPageSlices/fuel/fuel"
import { setCreateObject, setFetchOSState, setOSIDs } from "./objectSettingsPageSlices/id/osId"
import { setOSMainData } from "./objectSettingsPageSlices/main/main"
import { setMovementState } from "./objectSettingsPageSlices/movement/movement"
import { setSpeedControlState } from "./objectSettingsPageSlices/speed-control/speed-control"
import { setTpmsState } from "./objectSettingsPageSlices/tpms/tpms"
import { setTrackState } from "./objectSettingsPageSlices/track/track"

export const thunkGetObjectSettings = (vehicle_uid: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setFetchOSState(false))
    const { status, data } = await getObjectSettings(vehicle_uid)

    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setObjectSettingsData({ data }))
        dispatch(setOSMainData(data.main))
        dispatch(setDriverCardState(data.driver_cards))
        dispatch(setDiagState(data.diag))
        dispatch(setTrackState(data.track))
        dispatch(setTpmsState(data.tpms))
        dispatch(setFuelState(data.fuel))
        dispatch(setSpeedControlState(data.speed_control))
        dispatch(setMovementState(data.movement))
        dispatch(setFetchOSState(true))
        break
      case 403:
        dispatch(setObjectSettingsData({ data: "error" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
    }
  }
}
export const thunkGetAccounts = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setAccountsList("loading"))
    const { status, accounts } = await getAccounts()

    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setAccountsList(accounts))
        break
      case 403:
        dispatch(setAccountsList("error"))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
    }
  }
}
export const thunkGetPlugins = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPluginsList("loading"))
    const { status, plugins } = await getPlugins()

    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setPluginsList(plugins))
        break
      case 403:
        dispatch(setPluginsList("error"))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
    }
  }
}
export const thunkSaveSettingsObject = (body: any) => {
  return async (dispatch: AppDispatch) => {
    const { status, data } = await saveSettings(body)
    dispatch(setFetchOSState(true))
    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(
          setOSIDs({
            vehicle_uid: null,
            groups_tree_id: null,
          }),
        )
        dispatch(thunkGetObjectsFromServer())
        dispatch(setNewGSetVehicleUID(data.data.vehicle_uid))
        dispatch(setFetchOSState(false))
        dispatch(setCreateObject(false))
        break
      case 403:
        dispatch(setPluginsList("error"))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
    }
  }
}
