import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IGSTrackState {
  colors: Colors
}

export interface Colors {
  is_enabled: boolean
  ranges: Range[]
  dotted: Dotted
}

export interface Dotted {
  time_limit: number | string
}

export interface Range {
  speed: number
  color: string
  time_limit: number
}

const initialState: IGSTrackState = {
  colors: {
    is_enabled: true,
    ranges: [],
    dotted: {
      time_limit: "",
    },
  },
}

const rootOSettingsTrack = createSlice({
  name: "OSettingsTrack",
  initialState,
  reducers: {
    setIsEnabled: (state: IGSTrackState) => {
      state.colors.is_enabled = !state.colors.is_enabled
    },
    setRanges: (state: IGSTrackState, action: PayloadAction<Range[]>) => {
      state.colors.ranges = action.payload
    },

    setDottedTimeLimit: (state: IGSTrackState, action: PayloadAction<number | string>) => {
      state.colors.dotted.time_limit = action.payload
    },
    setTrackState: (state: IGSTrackState, action: PayloadAction<IGSTrackState>) => {
      state.colors = action.payload.colors || {
        is_enabled: true,
        ranges: [],
        dotted: {
          time_limit: "",
        },
      }
    },
    createTrackItem: (state: IGSTrackState) => {
      const firstCreate = state.colors.ranges.length == 0
      const newItem = { speed: 60, color: "#ff0000", time_limit: 0 }
      const lastItem = { speed: -1, color: "#ff0000", time_limit: 0 }
      let createItem = { speed: 0, color: "#ff0000", time_limit: 0 }
      if (firstCreate) {
        state.colors.ranges.push(newItem, lastItem)
      } else {
        const length = state.colors.ranges.length || 0
        const speed = state.colors.ranges[length - 2].speed
        if (speed) {
          createItem.speed = speed + 10
        }

        state.colors.ranges.splice(length - 1, 0, createItem)
      }
      state.colors.ranges.sort((a, b) => {
        if (a.speed === -1) return 1
        if (b.speed === -1) return -1
        return a.speed - b.speed
      })
    },
    removeTrackItem: (state: IGSTrackState, action: PayloadAction<number>) => {
      state.colors.ranges.splice(action.payload, 1)
    },
    updateTrackItem: (
      state: IGSTrackState,
      action: PayloadAction<{ value: any; index: number; field: string; firstCreate?: boolean }>,
    ) => {
      const { value, index, field } = action.payload

      state.colors.ranges[index][field] = value
    },
  },
})

export const {
  createTrackItem,
  removeTrackItem,
  updateTrackItem,
  setIsEnabled,
  setRanges,
  setDottedTimeLimit,
  setTrackState,
} = rootOSettingsTrack.actions

export default rootOSettingsTrack.reducer
