import React, { useState } from "react"
import { ContextMenu } from "../../shared/ui/ContextMenu"
import { Circle, Marker, useMap } from "react-leaflet"
import { setCreatorFigure } from "../../store/reducers/mapEditor/mapEditor"
import L from "leaflet"
import { setMenuGeozone } from "../../store/reducers/menuMap/menuMap"
import { removeGeozone } from "../../store/reducers/mapGeozone/mapGeozoneThunk"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { IGeozone } from "@/interfaces/geozone"

type propsType = {
  geozone: IGeozone
  index: number
}

export const Circles: React.FC<propsType> = ({ geozone, index }) => {
  const dispatch = useAppDispatch()
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })
  const menuGeozones = useAppSelector((state) => state.mapMenu.menuGeozones)
  const editMap = useAppSelector((state) => state.mapEditor.editMap)
  const handleContextMenu = (event: any) => {
    setMenuPosition({ x: event.clientX, y: event.clientY })
  }
  const map = useMap()

  return (
    <>
      <ContextMenu
        btnMenu={[
          {
            label: "Редактировать геозону",
            onClickBtn: () => {
              dispatch(setCreatorFigure(geozone))
            },
          },
          {
            label: "Удалить геозону",
            onClickBtn: () => {
              dispatch(removeGeozone(geozone.geozone_uid))
            },
          },
        ]}
        menuPosition={menuPosition}
        menuVisible={menuGeozones[index]}
        contextMenu={handleContextMenu}
        setMenuVisible={(visible) => dispatch(setMenuGeozone({ index: index, value: visible }))}
        disactive={editMap}
      >
        <Circle
          center={[geozone.latitude, geozone.longitude]}
          radius={geozone.radius ? geozone.radius : 500}
          pathOptions={{
            color: geozone.color,
            weight: geozone.line_width,
            fillOpacity: geozone.transparency / 100,
          }}
          key={index}
          eventHandlers={{
            contextmenu: (e) => {
              e.originalEvent.preventDefault()
              dispatch(setMenuGeozone({ index: index, value: true }))
              setMenuPosition({
                x: e.originalEvent.clientX,
                y: e.originalEvent.clientY,
              })
              e.originalEvent.stopPropagation()
            },
            click: () => {
              dispatch(setMenuGeozone({ index: index, value: false }))
            },
          }}
        >
          <Marker
            position={[geozone.latitude, geozone.longitude]}
            icon={L.divIcon({
              className: "geozone-name",
              iconSize: [64, 64],
              html: `<span>${geozone.geozone_name}</span>`,
            })}
            eventHandlers={{
              contextmenu: (e) => {
                e.originalEvent.preventDefault()
                dispatch(setMenuGeozone({ index: index, value: true }))
                setMenuPosition({
                  x: e.originalEvent.clientX,
                  y: e.originalEvent.clientY,
                })
                e.originalEvent.stopPropagation()
              },
              click: () => {
                const points = L.polyline(
                  geozone.geozone_points
                    .map((item) => {
                      return [item.lat, item.lng]
                    })
                    .splice(0, geozone.geozone_points.length - 1),
                )
                const bounds = points.getBounds()
                map.fitBounds(bounds)
              },
            }}
          />
        </Circle>
      </ContextMenu>
    </>
  )
}
