import { AppDispatch } from "@/store/store"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { Checkbox } from "src/shared/ui/Checkbox"
import {
  setEndRegistrationByRemovingCard,
  setEndRegistrationByTurningOffIgnition,
  setIsDCEnabled,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/driver-cards/driver-cards"
import { RestoreReg } from "./CPM-inner/RestoreReg"

export const DriverEvents: React.FC = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>()
  const end_registration_by_removing_card = useAppSelector(
    (state) => state.osDriverCard.driver_events.end_registration_by_removing_card,
  )
  const end_registration_by_turning_off_ignition = useAppSelector(
    (state) => state.osDriverCard.driver_events.end_registration_by_turning_off_ignition,
  )
  const is_enabled = useAppSelector((state) => state.osDriverCard.driver_events.is_enabled)

  const handleEndCardChange = useCallback(
    () => dispatch(setEndRegistrationByRemovingCard()),
    [dispatch],
  )

  const handleEndCardIgnition = useCallback(
    () => dispatch(setEndRegistrationByTurningOffIgnition()),
    [dispatch],
  )

  const handleEnable = useCallback(() => dispatch(setIsDCEnabled()), [])

  return (
    <div className="w-full h-full">
      <h2 className="text-center text-xl mb-10 font-light">События</h2>
      <div className="grid w-1/2 mx-auto grid-cols-2 text-[14px]  gap-y-5 gap-x-32  justify-center text-right items-center">
        <p className="w-full">Регистрация водителя прикладыванием метки</p>
        <div className="w-full">
          <Checkbox checked={is_enabled} setChecked={handleEnable} />
        </div>
        <p className="w-full">Завершать регистрацию выниманием метки из держателя</p>
        <div className="w-full">
          <Checkbox checked={end_registration_by_removing_card} setChecked={handleEndCardChange} />
        </div>
        <p className="w-full">Завершать регистрацию выключением зажигания</p>
        <div className="w-full">
          <Checkbox
            checked={end_registration_by_turning_off_ignition}
            setChecked={handleEndCardIgnition}
          />
        </div>
        <p>Восстанавливать регистрацию если метка приложена через</p>
        <RestoreReg />
      </div>
    </div>
  )
})
