import { Driver, ICategoryMaket, IPressureDaum, Settings } from "@/interfaces/car"
import { useEffect, useState } from "react"
import { axlesInit } from "../../shared/lib/helpers/axlesInitter"
import { InfoWidget } from "./InfoWidget"
import { Truck } from "./carTypes/Truck"
import { CatalogIcon } from "./icons/CatalogIcon"
import { MapIcon } from "./icons/MapIcon"
import { SwitchHistoryIcon } from "./icons/SwitchHistoryIcon"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { DateTime } from "ts-luxon"
import { Window } from "src/shared/ui/Window"
import styles from "./Car.module.css"
import { NullableDate } from "src/components/NullableDate/NullableDate"
import { SwitchHistoryTirePopup } from "src/features/SwitchHistoryTirePopup/SwitchHistoryTirePopup"
import { usePopup } from "src/shared/lib/hooks/popup"
import { CatalogTire } from "src/features/CatalogTire/CatalogTire"
import { EditorTirePopup } from "src/features/EditorTirePopup/EditorTirePopup"
import { SwitchTire } from "src/features/SwitchTire/SwitchTire"
import { Button } from "src/shared/ui/Button"
import {
  setIsSelectCatalog,
  setModelTireID,
  setModelTireName,
} from "src/store/reducers/switchTire/switchTire"

export interface ICarData {
  account_id: number
  category_maket: ICategoryMaket
  category_name: string
  driver: Driver
  pressure_data: IPressureDaum[]
  settings: Settings
  vehicle_id: number
  vehicle_last_event_date: number
  vehicle_name: string
  vehicle_uid: string
}

const Car = () => {
  const [openInfoWidget, setOpenInfoWidget] = useState(false)

  const [isOpenSwitchHistory, onOpenSwitchHistory, onCloseSwitchHistory] = usePopup()
  const [isOpenCatalogTire, onOpenCatalogTire, onCloseCatalogTire] = usePopup()
  const [isOpenEditTire, onOpenEditTire, onCloseEditTire] = usePopup()
  const [isOpenSwitchTire, onOpenSwitchTire, onCloseSwitchTire] = usePopup()

  const { data, vehicle_uid } = useAppSelector((state) => state.car)
  const activeTireCatalog = useAppSelector((state) => state.catalogTire.activeTire)
  const isSelectCatalog = useAppSelector((state) => state.switchTire.isSelectCatalog)

  const dataArr = typeof data != "string" && data?.data
  const dispatch = useAppDispatch()

  const wheel_id = useAppSelector((state) => state.car.wheel_id)
  const isOpenWidget = useAppSelector((state) => state.car.isOpenWidget)

  useEffect(() => {
    setOpenInfoWidget(isOpenWidget)
  }, [data, isOpenWidget, wheel_id])

  const isNoSensors = data != null && dataArr && dataArr.pressure_data.length == 0
  const axles = !isNoSensors ? axlesInit(dataArr) : null
  return (
    <>
      <Window
        title={
          dataArr &&
          data && (
            <div
              // style={{ gridTemplateColumns: "30% 30% 30%" }}
              // className=" w-full absolute top-0 text-sm grid items-center justify-between"
              className={styles.header_car}
            >
              <span
                title={typeof dataArr != "undefined" ? dataArr.vehicle_name : ""}
                className="text-nowrap cursor-default text-ellipsis overflow-hidden"
              >
                {typeof dataArr != "undefined" && dataArr.vehicle_name}
              </span>
              <span
                title={
                  typeof dataArr != "undefined" && dataArr.driver.driver_name !== null
                    ? dataArr.driver.driver_name
                    : dataArr.driver.driver_code
                      ? dataArr.driver.driver_code.toString()
                      : "Н/д"
                }
                className="text-nowrap cursor-default text-ellipsis overflow-hidden"
              >
                {dataArr.driver.driver_name !== null ? (
                  dataArr.driver.driver_name
                ) : dataArr.driver.driver_code ? (
                  dataArr.driver.driver_code
                ) : (
                  <span className="text-red-600">Водитель - н\д</span>
                )}
              </span>
              {dataArr.vehicle_last_event_date > 0 ? (
                <span
                  title={DateTime.fromSeconds(dataArr.vehicle_last_event_date).toFormat(
                    `T dd.LL.yy`,
                  )}
                  className="text-nowrap cursor-default text-ellipsis overflow-hidden"
                >
                  {DateTime.fromSeconds(dataArr.vehicle_last_event_date).toFormat(`T dd.LL.yy`)}
                </span>
              ) : (
                <p className="text-red-500">Никогда</p>
              )}
            </div>
          )
        }
      >
        <div className="w-full  flex items-center justify-center h-full">
          {vehicle_uid.length > 0 && (
            <div className="absolute top-[10%] left-2 p-2 flex flex-col gap-2rounded-lg text-left">
              <div className="bg-[#FFEEDA] hover:bg-opacity-35 cursor-pointer rounded-lg border border-[#fc6904] p-1">
                <MapIcon width={100} fill="#fc6904" />
              </div>
              <div className="flex items-center justify-between bg-[#FFEEDA]  mt-2 rounded-lg border border-[#fc6904] p-1">
                <div
                  onClick={() => {
                    onOpenCatalogTire(true)
                  }}
                  className="flex items-center hover:bg-gray-400 p-1  cursor-pointer justify-center bg-[#FFEEDA] hover:bg-opacity-35  rounded-lg"
                >
                  <CatalogIcon fill="#fc6904" width={35} />
                </div>
                <div className="flex items-center justify-center mt-2 rounded-lg w-0.5 h-10 my-auto bg-[#fc6904]"></div>
                <div
                  onClick={() => onOpenSwitchHistory(true)}
                  className="flex items-center hover:bg-gray-400 p-1  cursor-pointer justify-center bg-[#FFEEDA] hover:bg-opacity-35  rounded-lg"
                >
                  <SwitchHistoryIcon fill="#fc6904" width={35} />
                </div>
              </div>
            </div>
          )}

          <div className="">
            {openInfoWidget &&
              vehicle_uid.length > 0 &&
              vehicle_uid != null &&
              wheel_id != null && <InfoWidget onOpenSwitchTire={onOpenSwitchTire} />}
          </div>

          <div className="  h-fit transition-all">
            {axles !== null && <Truck front={axles.front} back={axles.back} />}
          </div>
        </div>
        {!data && <NullableDate color="#049f38" text="Загрузка" />}
        {data == null && (
          <div className="absolute bottom-0 h-[93%] left-0 w-full">
            <NullableDate color="#9f8204" text="Не выбран объект" />
          </div>
        )}
        {axles == null && (
          <div className="absolute bottom-0 h-[93%] left-0 w-full">
            <NullableDate color="#d00505" text="Нет датчиков" />
          </div>
        )}
      </Window>
      {isOpenSwitchHistory && (
        <SwitchHistoryTirePopup
          onClose={() => onCloseSwitchHistory()}
          onOpenSwitchTire={onOpenSwitchTire}
        />
      )}
      {isOpenSwitchTire && (
        <SwitchTire
          onClose={() => onCloseSwitchTire()}
          onOpenSwitchHistory={onOpenSwitchHistory}
          onOpenCatalogTire={onOpenCatalogTire}
        />
      )}
      {isOpenCatalogTire && (
        <CatalogTire
          onClose={() => onCloseCatalogTire()}
          setEditTire={() => onOpenEditTire()}
          footer={
            isSelectCatalog ? (
              <div className={styles.footer_catalog_tire}>
                <div>
                  {" "}
                  <Button
                    onClick={() => {
                      if (!activeTireCatalog) return
                      dispatch(setModelTireName(activeTireCatalog.tire.name))
                      dispatch(setModelTireID(activeTireCatalog.tire.id))
                      dispatch(setIsSelectCatalog(false))
                      onCloseCatalogTire()
                    }}
                    color="orange"
                  >
                    Применить
                  </Button>
                </div>
              </div>
            ) : null
          }
        />
      )}
      {isOpenEditTire && (
        <EditorTirePopup onClose={() => onCloseEditTire()} openCatalogTire={onOpenCatalogTire} />
      )}
    </>
  )
}

export default Car
