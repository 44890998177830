import { useEffect, useState } from "react"

interface ISettingsTree {
  data: any
  func: (e: any) => void
  active: number
}

export const TreeSettings = ({ data, func, active }: ISettingsTree) => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if ([data][0].level == 1) {
      setIsOpen(true)
    }
  }, [])
  return (
    <div className="w-full h-fit ">
      {[data].map((item, index) => (
        <div key={index}>
          {item && (
            <>
              <div
                onClick={() => {
                  setIsOpen(!isOpen)
                  if (true) {
                    func(item.id)
                  }
                }}
                className={`h-full text-left flex items-center ${active == item.id && "bg-gray-200 text-slate-600"} justify-start rounded  gap-3 py-1 px-2 ${true ? "hover:bg-white hover:text-slate-600" : "hover:text-gray-300"}`}
              >
                <div className={`${isOpen && "rotate-90"} `}>{">"}</div>
                {item.group_name}
                {true && (
                  <div
                    style={{
                      background: active == item.id ? "rgb(229 231 235)" : "white",
                    }}
                    className="w-1.5 h-1.5 bg-white rounded-full"
                  ></div>
                )}
              </div>

              <div
                style={{
                  height: isOpen ? "100%" : "0",
                }}
                className=" pl-2  overflow-hidden"
              >
                {item.children?.map((i) => (
                  <>{i && <TreeSettings func={func} active={active} data={i} />}</>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  )
}
