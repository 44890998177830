import React from "react"

export const TableIcons = () => {
  return (
    <div>
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.902344 1.05738H22.9023V16.0601C22.9023 16.2942 22.8058 16.5186 22.6339 16.6841C22.462 16.8496 22.2288 16.9426 21.9857 16.9426H1.81901C1.5759 16.9426 1.34274 16.8496 1.17083 16.6841C0.998921 16.5186 0.902344 16.2942 0.902344 16.0601V1.05738Z"
          stroke="#FC6904"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.902344 6.35246H22.9023"
          stroke="#FC6904"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.902344 11.6475H22.9023"
          stroke="#FC6904"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.31934 6.35246V16.9426"
          stroke="#FC6904"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}
