import { createTree } from "../../../shared/lib/helpers/treeHelpers"
import { filterByGeozoneName } from "../../../shared/lib/helpers/geozoneTree"
import { RootState } from "./../../../store/store"
import { createSelector } from "@reduxjs/toolkit"

export const createTreeFilterGeozone = createSelector(
  (state: RootState) => state.geoZones.search,
  (state: RootState) => state.geoZones.geozoneTree,
  (search, geozoneTree) => {
    return createTree(geozoneTree ? filterByGeozoneName(geozoneTree, search) : [])
  },
)
