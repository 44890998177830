import { Button } from "src/shared/ui/Button"
import { Popup } from "../../../shared/ui/Popup"
import React from "react"

type propsType = {
  isOpen: boolean
  setOpen: (isOpen: null) => void
  nameGroup: string
  deleteGroup: () => void
}
export const PopupDeleteGroup: React.FC<propsType> = ({
  isOpen,
  setOpen,
  nameGroup,
  deleteGroup,
}) => {
  return (
    <>
      {isOpen && (
        <Popup title="Удаление папки" onClose={() => setOpen(null)}>
          <div className="popup-newgroup">
            Вы уверены что хотите удалить папку: {nameGroup}?
            <div className="popup-newgroup__btn-wrapper">
              <Button onClick={deleteGroup} color="orange">
                Удалить
              </Button>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}
