import { useEffect, useState } from "react"
import { noString } from "src/shared/lib/helpers/notStringData"
import { useAppSelector } from "../../../../../../../shared/lib/hooks/store"
import { IUserInfo } from "src/store/reducers/globalSettings/interface"
import { PasswordSwitch } from "../popups/PasswordSwitch"

export const Password = () => {
  const [passwordSwitch, setPasswordSwitch] = useState(false)
  const [errorHandler, setErrorHand] = useState(false)

  const user: IUserInfo = useAppSelector((state) =>
    noString(state.globalSettings.tree.users.itemSettings.settingsData),
  )
  const id = user?.id
  useEffect(() => {
    setPasswordSwitch(id == 0 ? true : false)
  }, [])
  return (
    <div className="w-full grid items-center  px-5">
      {!passwordSwitch && (
        <div className="grid-cols-2 gap-5 w-full grid">
          <div className="text-lg font-light text-right">Пароль</div>
          <div
            onClick={() => setPasswordSwitch(true)}
            className="py-1 px-3 text-xl font-light text-white hover:bg-red-600 active:bg-red-700 transition-all cursor-pointer bg-red-500 rounded w-fit"
          >
            {id == 0 ? "" : ""}
            Изменить
          </div>
        </div>
      )}

      <div className="w-full transition-all">
        {passwordSwitch && (
          <PasswordSwitch
            isNew={id == 0 ? true : false}
            setPasswordSwitch={(value: boolean) => setPasswordSwitch(value)}
          />
        )}
      </div>
      {/* {errorHandler && "rfef"} */}
    </div>
  )
}
