import { fetchIsError } from "src/shared/lib/helpers/fetchIsError/fetchIsError"
import { AppDispatch } from "@/store/store"
import { driverTreeAPI } from "../../../shared/api/driverTreeApi"
import { addDriverGroupTree, setDriversData } from "./driverSlice"

export const thunkGetDriversTree = () => {
  return async (dispatch: AppDispatch) => {
    const res = await fetchIsError(dispatch, driverTreeAPI.getTreeDrivers, [])
    if (!res) return
    dispatch(setDriversData(res.data.map((group) => ({ ...group, status: "none" }))))
  }
}

export const changeGroupCreateDriver = (
  parent_id: number,
  account_id: number,
  group_name: string,
  level: number,
  id?: number,
) => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, driverTreeAPI.changeTreeGroupsDriver, [
      parent_id,
      group_name,
      id,
    ])
    if (!res) return
    dispatch(
      addDriverGroupTree({
        account_id: account_id,
        drivers: [],
        group_name: group_name,
        id: res.data.id,
        parent_id: parent_id,
        is_root: false,
        role_id: 3,
        level: level,
      }),
    )
  }
}

export const deleteGroupsDriver = (id: number) => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, driverTreeAPI.deleteGroupDriver, [id])
    if (!res) return
    dispatch(deleteGroupsDriver(id))
  }
}
