import { addGroupGeozoneTree, deleteGroupGeozoneTree, setGeozoneTree } from "./geozonesTreeSlice"
import { geozoneTreeAPI } from "./../../../shared/api/geozoneTreeApi"
import { fetchIsError } from "../../../shared/lib/helpers/fetchIsError/fetchIsError"

export const thunkGetGeoZonesTree = () => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, geozoneTreeAPI.geoZonesGetTree, [])
    dispatch(setGeozoneTree(res.data.map((el) => ({ ...el, status: "none" }))))
  }
}

export const changeGroupCreate = (
  parent_id: number,
  account_id: number,
  group_name: string,
  level: number,
  id?: number,
) => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, geozoneTreeAPI.changeTreeGroupsGeozone, [
      parent_id,
      group_name,
      id,
    ])
    if (!res) return
    dispatch(
      addGroupGeozoneTree({
        account_id: account_id,
        geozones: [],
        group_name: group_name,
        id: res.data.id,
        parent_id: parent_id,
        is_root: false,
        role_id: 3,
        level: level,
      }),
    )
  }
}

export const thunkDeleteGroupGeozone = (id: number) => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, geozoneTreeAPI.deleteGroupGeozone, [id])
    if (!res) return
    dispatch(deleteGroupGeozoneTree(id))
  }
}
