import { IUserInfo } from "@/store/reducers/globalSettings/interface"

export const userProfileValidator = (
  user: IUserInfo,
): { isValid: boolean; errorText: string | null } => {
  if (
    user.groups_tree.driver_groups_tree_id &&
    user.groups_tree.driver_groups_tree_id < 1 &&
    user.groups_tree.vehicle_groups_tree_id == null
  ) {
    return { isValid: false, errorText: "driver_groups_tree_id" }
  }
  if (
    (user.groups_tree.geozone_groups_tree_id && user.groups_tree.geozone_groups_tree_id < 1) ||
    user.groups_tree.vehicle_groups_tree_id == null
  ) {
    return { isValid: false, errorText: "geozone_groups_tree_id" }
  }
  if (
    user.groups_tree.vehicle_groups_tree_id &&
    user.groups_tree.vehicle_groups_tree_id < 1 &&
    user.groups_tree.vehicle_groups_tree_id == null
  ) {
    return { isValid: false, errorText: "vehicle_groups_tree_id" }
  }
  if (user.user_login.length == 0) {
    return { isValid: false, errorText: "user_login" }
  }
  if (user.user_name.length == 0) {
    return { isValid: false, errorText: "user_name" }
  }
  if (user.id == 0 && user.user_password.length < 8) {
    return { isValid: false, errorText: "user_password" }
  }

  if (user.mobile_phone?.length == 0 || user.mobile_phone?.length == null) {
    return { isValid: false, errorText: "mobile_phone" }
  }

  return { isValid: true, errorText: null }
}
