export interface IHorizontalTreeData {
  id: string | number
  name: string
  role_id: number | null
  shortData?: [string, any][] | null
  parent_id: number | null
  account_id: number | null
  is_root: boolean | null
  is_folder: boolean
}

// Функция для поиска account_id у родителя с role_id == 3 или is_root == true
function findParentAccountId(data: any[], parentId: number | null): number | null {
  if (parentId === null) return null

  const parent = data.find((item) => item.id === parentId)

  if (!parent) return null

  if (parent.role_id === 3 || parent.is_root) {
    return parent.account_id
  }

  return findParentAccountId(data, parent.parent_id)
}
export function organizeDrivers(data: any[]): IHorizontalTreeData[][] | null {
  const levels: IHorizontalTreeData[][] = []
  let fData: any = data

  if (!Array.isArray(fData)) {
    fData = fData.data
  }

  if (!fData) return null

  // Helper function to place an item in the correct level
  const placeInLevel = (item: IHorizontalTreeData, levelIndex: number) => {
    if (!levels[levelIndex]) {
      levels[levelIndex] = []
    }
    levels[levelIndex].push(item)
  }

  // Helper function to get the correct level based on parent hierarchy
  const getLevelIndex = (item: any): number => {
    if (item.parent_id === null) return 0
    const parent = fData.find((parentItem: any) => parentItem.id === item.parent_id)
    return parent ? getLevelIndex(parent) + 1 : 0
  }

  // Organize groups first
  fData.forEach((item) => {
    const newItem: IHorizontalTreeData = {
      id: item.id,
      name: item.group_name || "",
      role_id: item.role_id,
      parent_id: item.parent_id,
      account_id: item.account_id,
      shortData: null,
      is_folder: true,
      is_root: item.is_root,
    }

    // Determine the level based on parent_id using the hierarchy
    let levelIndex = getLevelIndex(item)
    placeInLevel(newItem, levelIndex)
  })

  // Add vehicles to their parent groups' levels
  fData.forEach((item) => {
    const vehicles = item.drivers
    if (vehicles) {
      vehicles.forEach((vehicle: any) => {
        const vehicleItem: IHorizontalTreeData = {
          id: vehicle.driver_uid,
          name: vehicle.driver_name || "",
          role_id: null,
          parent_id: item.id,
          account_id: findParentAccountId(fData, item.id),
          shortData: null,
          is_folder: false,
          is_root: null,
        }

        // Place vehicles one level below their parent group
        const parentLevelIndex = getLevelIndex(item)
        placeInLevel(vehicleItem, parentLevelIndex + 1)
      })
    }
  })

  return levels
}
