import { onlyNumber } from "src/components/Settings/Content/onlyNumber"
import { useAppDispatch } from "src/shared/lib/hooks/store"

import {
  createTrackItem,
  removeTrackItem,
  updateTrackItem,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/track/track"
import styles from "../../styles/os-item.module.css"
import { IOSTrackRange } from "../interface"

interface ITrackColors {
  colors: IOSTrackRange[]
}

export const TrackColors = ({ colors }: ITrackColors) => {
  const dispatch = useAppDispatch()
  const lastIndex = colors.length - 1

  const returnPlus = (n: number | null) => {
    if (n == 0) return 1
    if (!n) return 0

    return n + 1
  }
  return (
    <div className={styles.os_track_colors}>
      <div className={styles.color_header}>
        <div className=""></div>
        <div className="">Нач. скорость (км\ч)</div>
        <div className=""></div>
        <div className="">Конечная скорость (км\ч)</div>
        <div className="">Цвет</div>
        <div className="">Задержка применения (сек)</div>
        <div className=""></div>
      </div>

      <section className="">
        {colors.map((color, index) => (
          <div className="contents" key={index}>
            {index == lastIndex && (
              <div className="w-[100%] h-[1px] bg-black rounded-sm opacity-10"></div>
            )}

            <div className={styles.os_track_colors_item}>
              <div className={styles.os_track_colors_item_values}>
                <div className="">от</div>
                <div className="w-[100%] h-7 rounded-[3px] flex items-center justify-center font-normal">
                  {index != 0 ? returnPlus(colors[index - 1].speed) || "Ошибка" : 0}
                </div>
                <div className="">до</div>
                <div className={styles.color_speed}>
                  {color.speed != -1 ? (
                    <input
                      type="text"
                      value={color.speed}
                      onChange={(e) =>
                        dispatch(
                          updateTrackItem({
                            value: onlyNumber(e.target.value),
                            index: index,
                            field: "speed",
                          }),
                        )
                      }
                    />
                  ) : (
                    <p className="infinity">∞</p>
                  )}
                </div>

                <div className="flex items-center justify-center w-full w-[2rem]">
                  <input
                    type="color"
                    className="border-none rounded-[3px] w-7 h-7"
                    onChange={(e) =>
                      dispatch(
                        updateTrackItem({
                          value: e.target.value,
                          index,
                          field: "color",
                        }),
                      )
                    }
                    value={color.color}
                    name=""
                    id=""
                  />
                </div>
                <div className="">
                  <input
                    type="text"
                    value={color.time_limit}
                    onChange={(e) =>
                      dispatch(
                        updateTrackItem({
                          value: onlyNumber(e.target.value),
                          index: index,
                          field: "time_limit",
                        }),
                      )
                    }
                  />
                </div>
                <div style={{ width: "10%" }} className="mx-auto">
                  {index !== 0 && index !== lastIndex && (
                    <button onClick={() => dispatch(removeTrackItem(index))}>-</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
      <button onClick={() => dispatch(createTrackItem())}>+</button>
    </div>
  )
}
