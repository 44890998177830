import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IGSMovementState {
  events: Events
}

export interface Events {
  standing: Standing
  stopping: Stopping
}

export interface Standing {
  is_enabled: boolean
  standing_time: number | string
}

export interface Stopping {
  is_enabled: boolean
  stopping_time: number | string
}

const initialState: IGSMovementState = {
  events: {
    standing: {
      is_enabled: true,
      standing_time: "",
    },
    stopping: {
      is_enabled: true,
      stopping_time: "",
    },
  },
}

const rootOSettingsMovement = createSlice({
  name: "OSettingsMovement",
  initialState,
  reducers: {
    setStandingEnabled: (state: IGSMovementState) => {
      state.events.standing.is_enabled = !state.events.standing.is_enabled
    },
    setStandingTime: (state: IGSMovementState, action: PayloadAction<number | string>) => {
      state.events.standing.standing_time = action.payload
    },
    setStoppingEnabled: (state: IGSMovementState) => {
      state.events.stopping.is_enabled = !state.events.stopping.is_enabled
    },
    setStoppingTime: (state: IGSMovementState, action: PayloadAction<number | string>) => {
      state.events.stopping.stopping_time = action.payload
    },
    setMovementState: (state: IGSMovementState, action: PayloadAction<IGSMovementState>) => {
      state.events = {
        ...state.events,
        ...action.payload.events,
      }
    },
  },
})

export const {
  setStandingEnabled,
  setStandingTime,
  setStoppingEnabled,
  setStoppingTime,
  setMovementState,
} = rootOSettingsMovement.actions

export default rootOSettingsMovement.reducer
