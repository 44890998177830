import { returnErrorFetch, serverInstance } from "./settingsApiInstance"

interface IGeozoneTreeAPI {
  changeTreeGroupsGeozone: (
    parent_id: number,
    group_name: string,
    id?: number,
  ) => Promise<{ data: any; status: number }>
  deleteGroupGeozone: (id: number) => Promise<{ data: any; status: number }>
  geoZonesGetTree: () => Promise<{ data: any; status: number }>
}

export const geozoneTreeAPI: IGeozoneTreeAPI = {
  changeTreeGroupsGeozone: async (parent_id, group_name, id) => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/geozones/post_geozone_group", {
        parent_id: parent_id,
        group_name: group_name,
        id: id ? id : 0,
      })
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  deleteGroupGeozone: async (id) => {
    try {
      const { data, status } = await serverInstance.post(
        "svr/v1/ctl/geozones/delete_geozone_group",
        {
          id: id,
        },
      )
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  geoZonesGetTree: async () => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/geozones/get_tree_geozones")
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
}
