import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Ok } from "src/app/styles/image/Ok"

interface ICheckboxComponent {
  value: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const CheckboxComponent: React.FC<ICheckboxComponent> = React.memo(({ value, onChange }) => {
  const [checked, setChecked] = useState<boolean>(value)

  useEffect(() => {
    setChecked(value)
  }, [value])

  const checkedClick = useCallback(() => {
    setChecked((prevChecked) => {
      const newChecked = !prevChecked
      onChange({
        target: { type: "checkbox", checked: newChecked },
      } as React.ChangeEvent<HTMLInputElement>)
      return newChecked
    })
  }, [onChange])

  const containerClass = useMemo(() => {
    return `w-6 flex items-center justify-center transition-all cursor-pointer rounded aspect-square ${
      checked ? "border-0 bg-orange-500" : "border bg-transparent border-orange-500"
    }`
  }, [checked])

  return (
    <div>
      <input type="checkbox" className="hidden" checked={checked} onChange={onChange} />
      <div onClick={checkedClick} className={containerClass}>
        {checked && <Ok fill="white" width={20} />}
      </div>
    </div>
  )
})
