import { LatLng } from "leaflet"
import React, { useEffect } from "react"
import { setEditMap, setFigure } from "../../store/reducers/mapEditor/mapEditor"
import { SwitchDecoratorLine } from "../../shared/ui/SwitchDecoratorLine"
import { ContextMenu } from "../../shared/ui/ContextMenu"
import { douglasPeucker } from "../../shared/lib/helpers/map"
import { setMenuTrack } from "../../store/reducers/menuMap/menuMap"
import { getInfoPointTrack } from "../../store/reducers/mapTrack/mapTrackThunk"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { ITrack } from "src/interfaces/track"
import { nearestPoint } from "./model/helpers/nearestPoint"

type propsType = {
  track: ITrack
  index: number
  startLoading?: () => void
  stopLoading?: () => void
  color?: string
}

export const Track: React.FC<propsType> = React.memo(
  ({ track, index, color, startLoading, stopLoading }) => {
    const dispatch = useAppDispatch()
    const pointInfo = useAppSelector((state) => state.mapTrack.pointInfo)

    useEffect(() => {
      if (pointInfo) stopLoading && stopLoading()
    }, [pointInfo])

    return (
      <ContextMenu
        btnMenu={[
          {
            label: "Создать геозону пути",
            onClickBtn: () => {
              dispatch(
                setFigure({
                  geozone_name: "Путь 1",
                  color: "#ff6801",
                  uid: "2435qwe3245",
                  geozone_type_id: 0,
                  latitude: 0,
                  longitude: 0,
                  radius: 0,
                  line_width: 10,
                  geometry_type_id: 3,
                  use_as_address: false,
                  image_url: null,
                  geozone_points: douglasPeucker(
                    track.points.map((i) => new LatLng(i.lt, i.ln)),
                    0.0009,
                  ),
                  comment: `Путь создани по треку`,
                  account_id: 654546,
                  transparency: 75,
                }),
              )
              dispatch(setEditMap(true))
            },
          },
          {
            label: "Создать геозону",
            onClickBtn: () => {
              dispatch(
                setFigure({
                  geozone_name: "Путь 1",
                  color: "#ff6801",
                  uid: "2435qwe3245",
                  geozone_type_id: 0,
                  latitude: null,
                  longitude: null,
                  radius: 0,
                  line_width: 10,
                  geometry_type_id: 2,
                  use_as_address: false,
                  image_url: null,
                  geozone_points: douglasPeucker(
                    [...track.points].map((i) => new LatLng(i.lt, i.ln)),
                    0.001,
                  ),
                  comment: `Путь создани по треку c id `,
                  account_id: 654546,
                  transparency: 75,
                }),
              )
              dispatch(setEditMap(true))
            },
          },
        ]}
        key={index}
      >
        <SwitchDecoratorLine
          points={track.points}
          pathOptions={{ color: color ? color : track.color, weight: 10, opacity: color ? 1 : 0.5 }}
          idTrack={index}
          eventHandlers={{
            contextmenu: (e) => {
              e.originalEvent.preventDefault()
              dispatch(setMenuTrack({ index: index, value: true }))
              e.originalEvent.stopPropagation()
            },
            click: (e) => {
              const idPoint = nearestPoint(e.latlng, track.points) as number
              startLoading && startLoading()
              dispatch(getInfoPointTrack(idPoint))
            },
          }}
        />
      </ContextMenu>
    )
  },
)
