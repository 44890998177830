import { useState } from "react"
import {
  changeGroupCreateDriver,
  deleteGroupsDriver,
} from "../../store/reducers/drivers/driverThunk"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { createTreeFilterDriver } from "../../store/reducers/drivers/selectorsDriverTree"
import { FolderCheckboxDriver } from "./Driver/FolderCheckboxDriver/FolderCheckboxDriver"
import { PopupNewGroup } from "./PopupNewGroup/PopupNewGroup"
import { PopupDeleteGroup } from "./PopupDeleteGroup /PopupDeleteGroup"
import { DriverItemMap } from "./Driver/DriverItemMap/DriverItemMap"
import { DriverHeaderTree } from "./Driver/DriverHeaderTree/DriverHeaderTree"

export const DriverTree = () => {
  const dispatch = useAppDispatch()

  const driverTreeAll = useAppSelector(createTreeFilterDriver)
  const driverTree = driverTreeAll[0]?.children
  const rootDriver: any = driverTreeAll[0] ? driverTreeAll[0] : []

  const search = useAppSelector((state) => state.driver.search)

  const [newGroupParentID, setNewGroupParentID] = useState<number>(0)
  const [newGroup, setNewGroup] = useState({
    group_name: "",
    account_id: 0,
    level: 0,
    isOpen: false,
  })
  const [deleteGroupInfo, setDeleteGroupInfo] = useState<null | { name: string; id: number }>(null)
  const deleteGroup = () => {
    if (!deleteGroupInfo) return
    dispatch(deleteGroupsDriver(deleteGroupInfo.id))
    setDeleteGroupInfo(null)
  }

  return (
    <>
      <DriverHeaderTree />
      <div className="tree-wrapper">
        <PopupNewGroup
          value={newGroup.group_name}
          isOpen={newGroup.isOpen}
          setValue={(value) => {
            setNewGroup((prev) => ({ ...prev, group_name: value }))
          }}
          setOpen={(isOpen) => {
            setNewGroup((prev) => ({ ...prev, isOpen: isOpen }))
          }}
          createGroup={() => {
            dispatch(
              changeGroupCreateDriver(
                newGroupParentID,
                newGroup.account_id,
                newGroup.group_name,
                newGroup.level,
              ),
            )
            setNewGroup({ group_name: "", account_id: 0, isOpen: false, level: 0 })
          }}
        />
        <PopupDeleteGroup
          isOpen={!!deleteGroupInfo}
          setOpen={(value) => setDeleteGroupInfo(value)}
          nameGroup={deleteGroupInfo ? deleteGroupInfo?.name : ""}
          deleteGroup={deleteGroup}
        />
        {driverTree &&
          driverTree.map((group, key) => {
            return (
              <>
                <FolderCheckboxDriver
                  group={group}
                  search={search}
                  setNewGroup={(value: any) =>
                    setNewGroup((prev) => ({ ...value, group_name: prev.group_name }))
                  }
                  setParentID={(id: number) => setNewGroupParentID(id)}
                  setDeleteInfoGroup={(id, name) => setDeleteGroupInfo({ id: id, name: name })}
                  key={key}
                />
              </>
            )
          })}
        <DriverItemMap group={rootDriver} />
      </div>
    </>
  )
}
