import { IGeozone, IGeozoneApi } from "@/interfaces/geozone"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import L from "leaflet"

interface Imap {
  mapRef: any
}

const initialState: Imap = {
  mapRef: null,
}

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setMap(state: Imap, action: PayloadAction<any>) {
      state.mapRef = action.payload
    },
  },
})

export const { setMap } = mapSlice.actions
export default mapSlice.reducer
