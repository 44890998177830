import { useEffect, useState } from "react"
import { Ok } from "src/app/styles/image/Ok"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import {
  deleteCalibrCol,
  setNewCalibrCol,
  updateCalibrCol,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/fuel/fuel"

interface ICalibrTable {
  calibr: number[][]
  sensorIndex: number
  tankIndex: number
}

export const CalibrTable = ({ calibr, sensorIndex, tankIndex }: ICalibrTable) => {
  const table = useAppSelector(
    (state) =>
      state.osFuel.fuel &&
      state.osFuel.fuel[tankIndex].settings.sensors[sensorIndex] &&
      state.osFuel.fuel[tankIndex].settings.sensors[sensorIndex].calibr,
  )
  const dispatch = useAppDispatch()
  const [calibrTable, setCalibrTable] = useState<number[][] | null>(null)
  const [newColRow1, setNewColRow1] = useState(0)
  const [newColRow2, setNewColRow2] = useState(0)
  const [createStatus, setCreateStatus] = useState(false)
  const [inputError, setInputError] = useState<boolean>(false)

  useEffect(() => {
    setCalibrTable(table)
  }, [table])

  const createCol = () => {
    if (calibrTable) {
      setCreateStatus(true)
      setCalibrTable([...calibrTable, [0, 0]])
    }
  }

  const handleCol = (save: boolean, colIndex: number) => {
    setCreateStatus(false)
    setInputError(false)
    if (save) {
      dispatch(
        setNewCalibrCol({
          sensorIndex,
          tankIndex,
          col: [newColRow1, newColRow2 * 1000],
        }),
      )
    } else {
      dispatch(
        deleteCalibrCol({
          sensorIndex,
          tankIndex,
          colIndex,
        }),
      )
    }
  }

  const checkDuplicate = (value: number, colIndex: number, rowIndex: number) => {
    return calibrTable?.some((col, idx) => idx !== colIndex && col[rowIndex] === value)
  }

  interface IUpdateCol {
    rowData: string
    colIndex: number
    rowIndex: number
  }

  const updateCol = ({ rowData, colIndex, rowIndex }: IUpdateCol) => {
    setInputError(false)
    const newNum: number = Number(rowData)
    if (Number.isNaN(newNum)) return

    if (calibrTable) {
      const isDuplicate = checkDuplicate(newNum * (rowIndex === 1 ? 1000 : 1), colIndex, rowIndex)

      createStatus && isDuplicate && setInputError(createStatus && isDuplicate)

      const updatedTable = calibrTable.map((col, idx) =>
        idx === colIndex ? (rowIndex === 0 ? [newNum, col[1]] : [col[0], newNum * 1000]) : col,
      )
      setCalibrTable(updatedTable)

      dispatch(
        updateCalibrCol({
          sensorIndex,
          tankIndex,
          colIndex,
          newColData: updatedTable[colIndex],
        }),
      )
    }
  }

  return (
    <div className="w-[85%] h-full">
      <div className="">
        <div className="flex items-center justify-center text-center">
          <div className="w-1/2 bg-transparent p-1 border border-black focus-within:border-orange-500">
            Литров
          </div>
          <div className="w-1/2 bg-transparent p-1 border border-black focus-within:border-orange-500">
            Значение
          </div>
        </div>
      </div>
      {calibrTable?.map((item, index) => (
        <div className="flex transition-all items-center justify-center relative" key={index}>
          <div
            onClick={() => {
              const save = createStatus && index === calibrTable.length - 1
              save && calibrTable && setCalibrTable(calibrTable.slice(0, -1))
              handleCol(save, index)
            }}
            title={createStatus && index === calibrTable.length - 1 ? "Сохранить" : "Удалить"}
            className={`absolute -right-[15%] ${createStatus === true && index === calibrTable.length - 1 ? "" : "icon-delete text-red-500 hover:text-red-600 active:text-red-700"} text-lg rounded active:bg-slate-400 transition-all cursor-pointer p-0.5 hover:bg-slate-300`}
          >
            {createStatus && index === calibrTable.length - 1 && !inputError && (
              <Ok width={16} fill="green" />
            )}
          </div>
          <input
            onChange={(e) => {
              const newN = Number(e.target.value)
              setNewColRow2(!Number.isNaN(newN) ? newN : 0)
              updateCol({
                rowData: e.target.value,
                colIndex: index,
                rowIndex: 1,
              })
            }}
            type="text"
            value={item[1] / 1000}
            className={`w-1/2 bg-transparent p-1 border ${createStatus && index === calibrTable.length - 1 && inputError ? "border-red-500" : "border-black"} focus-within:border-orange-500`}
          />
          <input
            onChange={(e) => {
              const newN = Number(e.target.value)
              setNewColRow1(!Number.isNaN(newN) ? newN : 0)
              updateCol({
                rowData: e.target.value,
                colIndex: index,
                rowIndex: 0,
              })
            }}
            type="text"
            value={item[0]}
            className="w-1/2 bg-transparent p-1 border border-black focus-within:border-orange-500"
          />
          {createStatus && index === calibrTable.length - 1 && inputError && (
            <div className="absolute -bottom-6 left-0 w-full text-red-500 text-center">
              Такое значение существует
            </div>
          )}
        </div>
      ))}
      <div className="w-full flex items-center justify-between py-2">
        <div
          onClick={createCol}
          className="w-[2rem] cursor-pointer hover:bg-slate-600 active:bg-slate-700 aspect-square transition-all flex items-center justify-center border bg-slate-500 text-white rounded"
        >
          +
        </div>
      </div>
    </div>
  )
}
