import { onlyNumber } from "src/components/Settings/Content/onlyNumber"

interface NumberComponentProps {
  value: number | string
  onChange: (e: any) => void
  disable?: boolean
}

export const NumberComponent = ({ value, onChange, disable }: NumberComponentProps) => {
  return (
    <div className="relative overflow-hidden w-fit rounded h-fit">
      {disable && <div className="absolute w-full h-full bg-black bg-opacity-70 z-20"></div>}
      <input
        type="text"
        value={value}
        placeholder="csacsd"
        className='px-2 py-2 ${rows ? "text-wrap" : "text-nowrap"} overflow-hidden w-full bg-transparent text-sm font-light text-left ${rows && rows > 1 ? " resize-y" : "resize-none"}  border rounded text-nowrap ${error && "border-red-500 border-2"}  h-fit focus-within:border-orange-600'
        onChange={(e) => onChange(onlyNumber(e.target.value))}
      />
    </div>
  )
}
