export const ArrowCircle = ({ fill, width }) => {
  return (
    <svg
      id="Layer_1"
      fill={fill}
      width={width}
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path
            d="M260.6,18.1c131.2-3.1,240.5,101.1,243.6,232.3S403.1,490.8,271.9,493.9c-83.7,2-160.7-38.9-206-109.4    l64.3-41.3c30.8,47.9,83.1,75.7,139.9,74.3c89.1-2.1,159.8-76.3,157.7-165.4c-2.1-89.1-76.3-159.8-165.4-157.7    c-65.1,1.5-122.6,41.7-146.5,102.2l-71.1-28.1C80,79.4,164.7,20.3,260.6,18.1z"
            id="XMLID_2_"
          />
        </g>
        <path
          d="M186.9,294.5l-144-44.2c-4.9-1.5-10,1.7-10.8,6.7L7.9,405.7c-1.2,7.1,6.6,12.3,12.8,8.5l168.3-104.5   C195.1,305.9,193.9,296.6,186.9,294.5z"
          id="XMLID_1_"
        />
      </g>
    </svg>
  )
}

export const FilterIcon = ({ fill, width }) => {
  return (
    <svg
      width={width}
      enable-background="new 0 0 26 26"
      id="Слой_1"
      version="1.1"
      viewBox="0 0 26 26"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          d="M1.75,7.75h6.6803589c0.3355713,1.2952271,1.5039063,2.2587891,2.9026489,2.2587891   S13.9000854,9.0452271,14.2356567,7.75H24.25C24.6640625,7.75,25,7.4140625,25,7s-0.3359375-0.75-0.75-0.75H14.2356567   c-0.3355713-1.2952271-1.5039063-2.2587891-2.9026489-2.2587891S8.7659302,4.9547729,8.4303589,6.25H1.75   C1.3359375,6.25,1,6.5859375,1,7S1.3359375,7.75,1.75,7.75z M11.3330078,5.4912109   c0.8320313,0,1.5087891,0.6767578,1.5087891,1.5087891s-0.6767578,1.5087891-1.5087891,1.5087891S9.8242188,7.8320313,9.8242188,7   S10.5009766,5.4912109,11.3330078,5.4912109z"
          fill={fill}
        />
        <path
          d="M24.25,12.25h-1.6061401c-0.3355713-1.2952271-1.5039063-2.2587891-2.9026489-2.2587891   S17.1741333,10.9547729,16.838562,12.25H1.75C1.3359375,12.25,1,12.5859375,1,13s0.3359375,0.75,0.75,0.75h15.088562   c0.3355713,1.2952271,1.5039063,2.2587891,2.9026489,2.2587891s2.5670776-0.963562,2.9026489-2.2587891H24.25   c0.4140625,0,0.75-0.3359375,0.75-0.75S24.6640625,12.25,24.25,12.25z M19.7412109,14.5087891   c-0.8320313,0-1.5087891-0.6767578-1.5087891-1.5087891s0.6767578-1.5087891,1.5087891-1.5087891S21.25,12.1679688,21.25,13   S20.5732422,14.5087891,19.7412109,14.5087891z"
          fill={fill}
        />
        <path
          d="M24.25,18.25H9.7181396c-0.3355103-1.2952271-1.5037842-2.2587891-2.9017334-2.2587891   c-1.3987427,0-2.5670776,0.963562-2.9026489,2.2587891H1.75C1.3359375,18.25,1,18.5859375,1,19s0.3359375,0.75,0.75,0.75h2.1637573   c0.3355713,1.2952271,1.5039063,2.2587891,2.9026489,2.2587891c1.3979492,0,2.5662231-0.963562,2.9017334-2.2587891H24.25   c0.4140625,0,0.75-0.3359375,0.75-0.75S24.6640625,18.25,24.25,18.25z M6.8164063,20.5087891   c-0.8320313,0-1.5087891-0.6767578-1.5087891-1.5087891s0.6767578-1.5087891,1.5087891-1.5087891   c0.8310547,0,1.5078125,0.6767578,1.5078125,1.5087891S7.6474609,20.5087891,6.8164063,20.5087891z"
          fill={fill}
        />
      </g>
    </svg>
  )
}
