import { AppDispatch } from "@/store/store"
import React, { useCallback } from "react"
import { shallowEqual, useDispatch } from "react-redux"
import { onlyNumber } from "src/components/Settings/Content/onlyNumber"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { Checkbox } from "src/shared/ui/Checkbox"
import { Input } from "src/shared/ui/Input"
import {
  setIsSCEnabled,
  setMaxLimit,
  setRegCriticalLimit,
  setRegLimit,
  setRegTimeLimit,
  setUseRoadSignsInsteadMaxLimit,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/speed-control/speed-control"
import styles from "../styles/os-item.module.css"

export const SpeedControl: React.FC<any> = (speedData) => {
  const dispatch = useDispatch<AppDispatch>()

  const {
    is_enabled,
    max_limit,
    reg_critical_limit,
    reg_limit,
    reg_time_limit,
    use_road_signs_instead_max_limit,
  } = useAppSelector((state) => state.osSpeedControl, shallowEqual)

  const handleEnable = useCallback(() => dispatch(setIsSCEnabled()), [])
  const handleMaxLimit = useCallback((text) => dispatch(setMaxLimit(onlyNumber(text))), [])
  const handleRegCriticalLimit = useCallback(
    (text) => dispatch(setRegCriticalLimit(onlyNumber(text))),
    [],
  )
  const handleRegLimit = useCallback((text) => dispatch(setRegLimit(onlyNumber(text))), [])
  const handleRegTimeLimit = useCallback((text) => dispatch(setRegTimeLimit(onlyNumber(text))), [])
  const handleUseRoadSignsInsteadMaxLimit = useCallback(
    () => dispatch(setUseRoadSignsInsteadMaxLimit()),
    [],
  )

  return (
    <div className={styles.os_main}>
      <h1 className="">Контроль скорости</h1>
      <div className="grid grid-cols-2 px-52 gap-y-5 gap-x-32 w-full justify-center items-center">
        <p>Максимально разрешенная скорость (км/ч)</p>
        <div className="w-full relative">
          <Input
            variant="orange"
            inputType={"text"}
            value={max_limit.toString()}
            setValue={handleMaxLimit}
          />
          {max_limit === "" && (
            <div className="text-red-500 text-sm absolute left-0">Заполните поле</div>
          )}
        </div>
        <p>Лимит регистрации нарушения (км/ч)</p>
        <div className="w-full relative">
          <Input
            variant="orange"
            inputType={"text"}
            value={reg_limit.toString()}
            setValue={handleRegLimit}
          />
          {reg_limit === "" && (
            <div className="text-red-500 text-sm absolute left-0">Заполните поле</div>
          )}
        </div>
        <p>Состояние регистрации </p>
        <div className="w-full relative">
          <Checkbox checked={is_enabled} setChecked={handleEnable} />
        </div>
        <p>Лимит времени на регистрацию нарушения (сек)</p>
        <div className="w-full relative">
          <Input
            variant="orange"
            inputType={"text"}
            value={reg_time_limit.toString()}
            setValue={handleRegTimeLimit}
          />
          {reg_time_limit === "" && (
            <div className="text-red-500 text-sm absolute left-0">Заполните поле</div>
          )}
        </div>
        <p>Лимит регистрации нарушения критической скорости (км/ч)</p>
        <div className="w-full relative">
          <Input
            variant="orange"
            inputType={"text"}
            value={reg_critical_limit.toString()}
            setValue={handleRegCriticalLimit}
          />
          {reg_critical_limit === "" && (
            <div className="text-red-500 text-sm absolute left-0">Заполните поле</div>
          )}
        </div>
        <p>Использовать дорожные знаки вместо max_limit</p>
        <div className="w-full">
          <Checkbox
            checked={use_road_signs_instead_max_limit}
            setChecked={handleUseRoadSignsInsteadMaxLimit}
          />
        </div>
      </div>
    </div>
  )
}
