import { PressureNormKoef } from "@/interfaces/objectSettings"
import { AppDispatch } from "@/store/store"
import React from "react"
import { useDispatch } from "react-redux"

import { useAppSelector } from "src/shared/lib/hooks/store"
import { setPressureNormKoef } from "src/store/reducers/objectSettings/objectSettingsPageSlices/tpms/tpms"
import { MonthDayPicker } from "./MonthDayPicker"

export const CorrectValue = () => {
  const correct = useAppSelector((state) => state.osTPMS.settings.wheel_axes.pressure_norm_koef)

  const dispatch = useDispatch<AppDispatch>()

  const setNewBegDate = (date: string, index: number) => {
    const newData = correct.map((item, i) =>
      i === index ? { ...item, beginning_with: date } : item,
    )
    dispatch(setPressureNormKoef(newData))
  }

  const setNewKoef = (koef: string, index: number) => {
    let currentValue = koef

    // Remove leading zeros
    if (currentValue.length > 1 && currentValue[0] === "0") {
      currentValue = currentValue.slice(1)
    }

    // Ensure the value is a valid number
    const numericValue: any = isNaN(Number(currentValue)) ? 0 : Number(currentValue)
    const newData = correct.map((item, i) => (i === index ? { ...item, koef: numericValue } : item))
    dispatch(setPressureNormKoef(newData))
  }
  const addCorrect = () => {
    const elem: PressureNormKoef[] = [
      {
        beginning_with: "01.01",
        koef: 0,
      },
    ]
    dispatch(setPressureNormKoef([...correct, elem[0]]))
  }

  const removeCorrect = (indexInner) => {
    const newData = correct.filter((item, index) => index != indexInner)
    dispatch(setPressureNormKoef(newData))
  }

  return (
    <div className="w-full">
      <h1 className="text-center text-3xl mb-5 font-light">Значения корректировки</h1>
      <div className="flex flex-wrap text-center mx-auto w-1/3 rounded  overflow-hidden">
        <div className="border p-4 w-[45%]">Дата</div>
        <div className="border p-4 w-[45%]">Давление (+/-)</div>
        <div className="w-[10%]"></div>
        {correct?.map((item, index) => (
          <React.Fragment key={index}>
            <div className="border p-4 flex items-center justify-center w-[45%] h-[5rem]">
              <MonthDayPicker
                monthDay={item.beginning_with}
                onChange={setNewBegDate}
                index={index}
              />
            </div>
            <div className="border p-4 flex items-center justify-center w-[45%] h-[5rem]">
              <textarea
                onChange={(e) => setNewKoef(e.target.value, index)}
                value={item.koef}
                className="resize-none text-center w-full bg-transparent"
              ></textarea>
            </div>
            <div
              onClick={() => removeCorrect(index)}
              className="icon-delete relative aspect-square w-[10%] h-[5rem] flex items-center justify-center p-1 cursor-pointer hover:bg-gray-100 active:bg-gray-200 rounded  bg-white  text-red-500 "
            ></div>
          </React.Fragment>
        ))}
      </div>
      <div
        onClick={() => addCorrect()}
        className="text-center p-1.5 w-fit mx-auto bg-slate-200 block active:bg-gray-400 rounded hover:bg-gray-200 cursor-pointer  mt-6"
      >
        + Добавить значение
      </div>
    </div>
  )
}
