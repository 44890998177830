import { AppDispatch, RootState } from "@/store/store"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setFolderData } from "src/store/reducers/globalSettings/globalSettings"
import { thunkCreateUpdateFolder } from "src/store/reducers/globalSettings/globalSettingsThunk"
import { TextareaComponent } from "src/widgets/ObjectSettings/Content/helpComponents/TextareaComponent"

export const CreateFolderPopup = () => {
  const data = useSelector((state: RootState) => state.globalSettings.folder)
  const [type, setType] = useState<"create" | "update">("create")
  const dispatch = useDispatch<AppDispatch>()

  const [name, setName] = useState<any>(data.data?.group_name)

  useEffect(() => {}, [data.data, data.status])

  const onChangeName = (name) => {
    setName(name)
  }

  const onSubmit = () => {
    const newData: any = {
      id: data.data?.id,
      parent_id: data.data?.parent_id,
      group_name: name,
    }
    dispatch(thunkCreateUpdateFolder(newData))
  }

  return (
    <>
      {data.data && (
        <div className="absolute w-screen h-screen z-[2001] backdrop-blur-lg left-0 top-0 flex items-center justify-center">
          <div
            style={{ boxShadow: "0px 0px 50px 3px #8f8d8d" }}
            className="bg-white w-1/3 h-auto min-h-[300px] py-20 relative rounded flex items-center justify-center"
          >
            <div
              onClick={() => {
                dispatch(setFolderData({ status: null }))
              }}
              className="absolute cursor-pointer z-20 right-0 top-0 p-3 icon-cross text-red-500 hover:text-red-600"
            ></div>
            <div className="text-2xl h-full absolute top-0 w-full p-3 mb-3 text-center font-light">
              {data.data?.id !== 0 ? "Изменение папки" : "Создание папки"}
            </div>
            <div className="w-full h-full flex flex-col items-center justify-start gap-3 px-3">
              <div className="w-3/4">
                <p className="">Введите имя папки</p>
                <TextareaComponent
                  onKeyUp={(e) => {
                    e.key == "Enter" && onSubmit()
                  }}
                  onKeyDown={(e) => {
                    e.key == "Enter" && e.preventDefault()
                  }}
                  placeholder="Новая папка"
                  value={name}
                  onChange={(e) => onChangeName(e.target.value)}
                  width={"100%"}
                />
                <input type="text" />
              </div>
            </div>
            <div className="text-xl absolute bottom-0 w-full p-3 text-center font-light flex items-center justify-center">
              <div
                onClick={() => onSubmit()}
                className="bg-slate-500 hover:bg-slate-400 rounded active:bg-slate-600 transition-all px-10 py-2 text-white cursor-pointer"
              >
                Применить
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
