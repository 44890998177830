import { ITrack } from "./../../../interfaces/track"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface ImapTrack {
  colors: ["#25992f", "#ff0000", "#0600ff", "#ff00c6", "#9f00ff", "#ff7800", "#000", "#8800d9"]
  colorUse: string[]
  tracks: Array<ITrack>
  pointInfo: any | null
  checkedEvent: number[]
  colorTrack: boolean
}

const initialState: ImapTrack = {
  colors: ["#25992f", "#ff0000", "#0600ff", "#ff00c6", "#9f00ff", "#ff7800", "#000", "#8800d9"],
  colorUse: [],
  tracks: [],
  pointInfo: null,
  checkedEvent: [3, 4, 9, 10, 11, 13],
  colorTrack: true,
}

const mapSlice = createSlice({
  name: "mapTrack",
  initialState,
  reducers: {
    addTrack(state: ImapTrack, action: PayloadAction<ITrack>) {
      // const color = state.colors.filter((color) => !state.colorUse.includes(color))[0]
      // state.colorUse = [...state.colorUse, color]
      const indexColor = state.colors[state.tracks.length]
      state.tracks = [
        ...state.tracks,
        {
          ...action.payload,
          points: action.payload.points ? action.payload.points : [],
          color: indexColor,
        },
      ]
    },
    removeTrack(state: ImapTrack, action: PayloadAction<string[]>) {
      let newTracks = state.tracks.filter((track) =>
        action.payload.find((uid) => uid === track.uid),
      )
      state.tracks = newTracks
      state.colorUse = state.colorUse.filter(
        (color) => !newTracks.find((track) => track.color === color),
      )
    },
    setTrackAll(state: ImapTrack, action: PayloadAction<any>) {
      state.tracks = action.payload
    },
    setPointInfo(state: ImapTrack, action: PayloadAction<any>) {
      state.pointInfo = action.payload
    },
    changeEventChecked(state: ImapTrack, action: PayloadAction<number[]>) {
      state.checkedEvent = action.payload
    },
    setColorTrack(state: ImapTrack, action: PayloadAction<boolean>) {
      state.colorTrack = action.payload
    },
    setSettings(state: ImapTrack, action: PayloadAction<any>) {
      state.colorTrack = action.payload.track.is_speed_colors
      let newEvents: number[] = []

      if (action.payload.events.is_draining_start) {
        newEvents.push(13)
      }
      if (action.payload.events.is_filling_start) {
        newEvents.push(11)
      }
      if (action.payload.events.is_ignition_off) {
        newEvents.push(4)
      }
      if (action.payload.events.is_ignition_on) {
        newEvents.push(3)
      }
      if (action.payload.events.is_parking) {
        newEvents.push(10)
      }
      if (action.payload.events.is_stopping) {
        newEvents.push(9)
      }
    },
  },
})

export const {
  addTrack,
  setTrackAll,
  setPointInfo,
  changeEventChecked,
  removeTrack,
  setColorTrack,
} = mapSlice.actions
export default mapSlice.reducer
