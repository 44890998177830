import { IDriver, IDriverData } from "../../../store/reducers/drivers/driverSlice"
import { getStatus } from "./treeHelpers"

export const checkObjectsPresenceGeozone = (geozoneGroup: IDriver[], geozoneChecked: IDriver[]) => {
  // Создаем множество (Set) из идентификаторов объектов в vehicleChecked для быстрого поиска
  const checkedIds = new Set(geozoneChecked.map((geozone) => geozone.driver_uid))

  // Проверяем наличие объектов из vehicleGroup в vehicleChecked
  const containAll = geozoneGroup.every((geozone) => checkedIds.has(geozone.driver_uid))

  const containSome = geozoneGroup.some((geozone) => checkedIds.has(geozone.driver_uid))

  // Возвращаем соответствующий результат
  return getStatus(containAll, containSome)
}

export const changeStatus = (objects: any[], objectId: number, newStatus: string) => {
  const object = objects.find((obj) => obj.id === objectId)
  if (object) {
    object.status = newStatus
    setStatusGroup(object, objects, newStatus)
  }
}

export const setStatusGroup = (object: IDriverData, objects: IDriverData[], newStatus) => {
  const parentId = object.parent_id
  if (parentId) {
    const hasChildren = objects.some((obj) => obj.parent_id === parentId)
    const hasVehicles = objects.some(
      (obj) => obj.parent_id === parentId && obj.drivers && obj.drivers.length > 0,
    )
    if (hasChildren || hasVehicles) {
      const parentObject = objects.find((obj) => obj.id === parentId)
      if (parentObject) {
        if (newStatus === "none") {
          const parentObjectHasChildren = objects
            .filter((obj) => obj.parent_id === parentId)
            .filter((obj) => obj.status === "some" || obj.status === "all").length
          if (!parentObjectHasChildren) {
            parentObject.status = "none"
          }
          setStatusGroup(parentObject, objects, newStatus)
        } else {
          parentObject.status = "some"
        }
      }
      if (parentObject?.parent_id) {
        setStatusGroup(parentObject, objects, newStatus)
      }
    } else {
      changeStatus(objects, parentId, newStatus)
    }
  }
}

export const filterByGeozoneName = (dataArray: IDriverData[], searchTerm: string) => {
  // If searchTerm is empty or contains only whitespace, return the original dataArray
  if (!searchTerm.trim()) {
    return dataArray
  }

  const idSearch: any = dataArray.map((item) => ({
    ...item,
    drivers: item.drivers
      ? item.drivers.filter((driver) => driver.driver_code.toString() === searchTerm)
      : [],
  }))
  if (idSearch.some((group) => group?.drivers?.length > 0)) {
    return idSearch
  }

  // Escape special characters in searchTerm to create a valid regex
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  const regex = new RegExp(escapedSearchTerm, "i")

  const parentId: number[] = []

  // Filter the dataArray based on the regex
  return dataArray
    .map((item) => {
      const newDriver = item.drivers
        ? item.drivers.filter((driver) => regex.test(driver.driver_name))
        : []
      if (newDriver.length) {
        parentId.push(item.parent_id)
      }
      return {
        ...item,
        drivers: newDriver,
      }
    })
    .filter((el) => el.drivers.length > 0 || parentId.includes(el.id))
}
