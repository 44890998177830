import { ITableData } from "@/interfaces/table"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface ITableDataState {
  tableData: ITableData[]
  sortColTable: string
  directionSort: boolean
  activeRowTable: null | { item: ITableData; index: number }
  searchTable: string
}

const initialState: ITableDataState = {
  tableData: [],
  sortColTable: "",
  directionSort: true,
  activeRowTable: null,
  searchTable: "",
}

const tableSlice = createSlice({
  name: "tableTpms",
  initialState,
  reducers: {
    setTableData(state: ITableDataState, action: PayloadAction<any>) {
      state.tableData = action.payload
    },
    setSort(state: ITableDataState, action: PayloadAction<string>) {
      state.sortColTable = action.payload
    },
    setDirection(state: ITableDataState) {
      state.directionSort = !state.directionSort
    },
    setActiveRowTable(
      state: ITableDataState,
      action: PayloadAction<{ item: ITableData; index: number }>,
    ) {
      state.activeRowTable = action.payload
    },
    setSearchTable(state: ITableDataState, action: PayloadAction<string>) {
      state.searchTable = action.payload
    },
  },
})

export const { setTableData, setSort, setDirection, setActiveRowTable, setSearchTable } =
  tableSlice.actions
export default tableSlice.reducer
