import { IFuelReq } from "@/components/testUi/Fuel/Fuel"
import { ISwitchHistoryReq } from "@/interfaces/switchHistory"
import { IWheelChart } from "@/interfaces/wheelChart"
import { IUserInfo } from "@/store/reducers/globalSettings/interface"
import { IAccounts, INewData } from "@/store/reducers/objectSettings/objectSettings"
import { ISwitchReq } from "@/store/reducers/switchTire/switchTire"
import axios from "axios"
import { IClientInfo } from "./../../components/Settings/ProfileSettings/Users/ClientSettings/ClientSettings"
import { IWheel } from "./../../interfaces/wheels"
import { serverInstance } from "./settingsApiInstance"

export const getObjectSettings = async (vehicle_uid: string) => {
  try {
    const response = await serverInstance.post("svr/v1/ctl/vehicles/get_vehicle_profile", {
      vehicle_uid: vehicle_uid,
    })
    return { status: response.status, data: response.data.data }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return { status: error.response.status, data: null }
    } else {
      return { error: "Ошибка" }
    }
  }
}
//Получение плагинов
export const getPlugins = async () => {
  try {
    const res = await serverInstance.post("svr/v1/sys/get_proto_plugins")
    const plugins = res.data.data
    const status = res.status
    return { plugins, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { accounts: null, status }
  }
}
// Сохранить настройки пользователя
export const saveSettings = async (body: INewData) => {
  try {
    const { status, data } = await serverInstance.post(
      "svr/v1/ctl/vehicles/post_vehicle_profile",
      body,
    )

    return { status, data }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { status }
  }
}

// Дерево аккаунтов
export const getAccounts = async () => {
  try {
    const res = await serverInstance.post("svr/v1/ctl/accounts/get_tree_accounts")
    const accounts = res.data.data as IAccounts[]
    const status = res.status
    return { accounts, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { accounts: null, status }
  }
}

// Плагины аккаунта

// Получение Объектов (ТС) с сервера
export const getObjectsFromServer = async () => {
  try {
    const response = await serverInstance.post("svr/v1/ctl/vehicles/get_tree_vehicles")
    const objects = response.data
    const status = response.status
    return { objects, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { status }
  }
}

// Данные по машине
export const getCarData = async (id: string) => {
  const body = {
    vehicle_uid: id,
  }
  try {
    const response = await serverInstance.post(
      "tpms/v1/reports/wheels/get_pressure_current_data",
      body,
    )
    const carData = response.data
    const status = response.status
    return { carData, status }
  } catch (e) {
    return { error: "Ошибка запроса данных" }
  }
}

export interface IWheelChartQueryParams {
  vehicle_uid: string
  start_date: number
  end_date: number
  wheel_axes_id: number
  sensor_number: number
}

// График давления в шине
export const getWheelChartData = async (body: IWheelChartQueryParams) => {
  let wheelChartData: IWheelChart
  let status

  const response = await serverInstance.post("tpms/v1/reports/wheels/get_sensor_data", body, {
    timeout: 120000, // Таймаут в миллисекундах (2 минуты)
  })
  wheelChartData = response.data
  status = response.status
  return { status, wheelChartData }
}

// Справочник шин
export const getCatalogList = async (id) => {
  const body = {
    account_id: id,
  }
  try {
    const response = await serverInstance.post("tpms/v1/ctl/vehicles/wheels/get_wheel_models", body)
    const finalRes = response.data.data
    return finalRes
  } catch (e) {
    return console.error(e)
  }
}

// Создание и обновление шины в справочнике
export const createUpdateWheelModel = async ({ id, comment, name, account_id }) => {
  const body = {
    id,
    comment,
    name,
    account_id,
  }
  try {
    const response = await serverInstance.post("tpms/v1/ctl/vehicles/wheels/post_wheel_model", body)
    return response.data
  } catch (e) {
    console.error(e)
  }
}

// Удаление модели шин в справочнике
export const deleteWheelModel = async (id: number) => {
  const body = {
    id,
  }
  try {
    const response = await serverInstance.post(
      "tpms/v1/ctl/vehicles/wheels/delete_wheel_model",
      body,
    )
    return response.data
  } catch (e) {
    console.error(e)
  }
}

// Замена шины на машине
export const setSwitchTire = async (body: ISwitchReq) => {
  try {
    const response = await serverInstance.post(
      "tpms/v1/vehicle/wheels/post_wheel_installation",
      body,
    )
    return response.data
  } catch (e) {
    console.error(e)
  }
}

// Получить историю замены
export const getSwitchHistory = async (body: ISwitchHistoryReq) => {
  try {
    const response = await serverInstance.post(
      "tpms/v1/vehicles/wheels/get_wheel_installations",
      body,
    )
    return response.data
  } catch (e) {
    console.error(e)
  }
}

// Удалить историю замены
export const removeSwitchHistory = async (id: number) => {
  try {
    const response = await serverInstance.post(
      "tpms/v1/vehicles/wheels/delete_wheel_installation",
      {
        id,
      },
    )
    return response.data
  } catch (e) {
    console.error(e)
  }
}

// тест топливо
export const getFuelData = async (body: IFuelReq) => {
  const headers = {
    "X-Auth": localStorage.getItem("X-Auth"),
  }
  try {
    const response = await serverInstance.post("svr/v1/reports/fuel/get_fuel_levels", body, {
      headers,
    })
    return { data: response.data, status: response.status }
  } catch {
    return { data: null, status: 500 }
  }
}

export interface IGroupCreate {
  id: number
  parent_id: number
  account_id: number
  group_name: string
}

// Изменение и добавление папки объектов
export const createUpdateFolder = async ({
  group_name,
  id,
  parent_id,
  account_id,
}: IGroupCreate) => {
  const body: IGroupCreate = {
    id,
    parent_id,
    account_id,
    group_name,
  }
  try {
    const { data, status } = await serverInstance.post(
      "svr/v1/ctl/vehicles/post_vehicles_group",
      body,
    )
    const dataTyped: { id: number } = data.data
    return { data: dataTyped, status }
  } catch {
    return { data: null, status: 500 }
  }
}

export const getFolderInfo = async (id: number) => {
  const body = {
    id: id,
  }
  try {
    const { data, status } = await serverInstance.post("svr/v1/ctl/vehicles/get_vehicles_group", {
      ...body,
    })

    return { data, status }
  } catch {
    return { data: null, status: 500 }
  }
}

export const deleteFolder = async (id) => {
  const body = {
    id: id,
  }
  try {
    const { data, status } = await serverInstance.post(
      "svr/v1/ctl/vehicles/delete_vehicles_group",
      {
        ...body,
      },
    )

    return { status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { status }
  }
}

export const getGSUsersTree = async () => {
  try {
    const { data, status } = await serverInstance.post("svr/v1/ctl/users/get_tree_users")

    return { data, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { data: null, status }
  }
}
export const getGSUser = async (id: number) => {
  const body = {
    id: id,
  }
  try {
    const { data, status } = await serverInstance.post("svr/v1/ctl/users/get_user", body)

    return { data, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { data: null, status }
  }
}

export const getObjectsFolders = async () => {
  try {
    const { data, status } = await serverInstance.post("svr/v1/ctl/users/get_vehicle_group_tree")
    return { vehiclesFolders: data, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { vehiclesFolders: null, status }
  }
}
export const getDriversFolders = async () => {
  try {
    const { data, status } = await serverInstance.post("svr/v1/ctl/users/get_driver_group_tree")
    return { driversFolders: data, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { driversFolders: null, status }
  }
}
export const getGeoZonesFolders = async () => {
  try {
    const { data, status } = await serverInstance.post("svr/v1/ctl/users/get_geozone_group_tree")
    return { geoZonesFolders: data, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { geoZonesFolders: null, status }
  }
}

interface ICreateUpdateUserData {
  data: IUserInfo
}

export const createUpdateUser = async ({ data }: ICreateUpdateUserData) => {
  const body = data
  try {
    const { data, status } = await serverInstance.post("svr/v1/ctl/users/post_user", body)
    return { id: data.data.id, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { id: null, status }
  }
}
export const deleteUser = async (id: number) => {
  const body = {
    id,
  }
  try {
    const { status } = await serverInstance.post("svr/v1/ctl/users/delete_user", body)
    return { status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { status }
  }
}
export const getUserClientInfo = async (id: number) => {
  const body = {
    id: id,
  }
  try {
    const { data, status } = await serverInstance.post("svr/v1/ctl/accounts/get_account", body)
    return { data: data.data, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { data: null, status }
  }
}

export const saveUserClientInfo = async (body: IClientInfo) => {
  try {
    const { data, status } = await serverInstance.post("svr/v1/ctl/accounts/post_account", body)
    return { data: data.data, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { data: null, status }
  }
}

export interface IPostUserTreeFolderProps {
  id: number // Если 0 то создать *
  parent_id: number // *
  group_name: string // Наименование *
  comment: string // Комментарий
}
export const createUpdateUserTreeFolder = async (body: IPostUserTreeFolderProps) => {
  try {
    const { data, status } = await serverInstance.post(
      "svr/v1/ctl/accounts/post_account_group",
      body,
    )
    return { data: data.data, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { data: null, status }
  }
}

export const deleteUserTreeFolder = async (id: number) => {
  const body = {
    id: id,
  }
  try {
    const { status } = await serverInstance.post("svr/v1/ctl/accounts/delete_account_group", body)
    return { status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { data: null, status }
  }
}

export const getUserTreeFolder = async (id: number) => {
  const body = {
    id: id,
  }
  try {
    const { data, status } = await serverInstance.post(
      "svr/v1/ctl/accounts/get_account_group",
      body,
    )
    return { data: data.data, status }
  } catch (error: any) {
    const status = error.response ? error.response.status : 500
    return { data: null, status }
  }
}
