import React, { useMemo, useState } from "react"
import { Polyline, SVGOverlay, useMap, useMapEvent } from "react-leaflet"
import { useAppDispatch } from "src/shared/lib/hooks/store"
import { getInfoPointTrack } from "src/store/reducers/mapTrack/mapTrackThunk"
import { ITrack } from "src/interfaces/track"
import { latLng } from "leaflet"

interface IProps {
  colors: Array<string>
  position: ITrack
  index: number
  startLoading?: () => void
}

const GradientPolyline: React.FC<IProps> = React.memo(
  ({ startLoading, colors, position, index }) => {
    const [bounds, setBounds] = useState<any>(null)
    const map = useMap()
    const dispatch = useAppDispatch()

    const angle = useMemo(() => {
      const [start, end] = [position.points[0], position.points[1]]
      const deltaY = end.lt - start.lt
      const deltaX = end.ln - start.ln

      let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI) // Угол в градусах

      // let angleDeg = Math.trunc(angle > 0 ? angle : Math.abs(angle) + Math.abs(angle) * 2)
      return -angle
    }, [position, bounds])

    useMapEvent("zoom", (e) => {
      setBounds([map.getBounds().getNorthEast(), map.getBounds().getSouthWest()])
    })

    return (
      <>
        {bounds && (
          <SVGOverlay
            bounds={bounds}
            eventHandlers={{
              zoom: () => {
                setBounds([map.getBounds().getNorthEast(), map.getBounds().getSouthWest()])
              },
            }}
          >
            <linearGradient
              id={`gradient_${colors[0]}_${index}`}
              gradientTransform={`rotate(${angle}, 0.5, 0.5)`}
            >
              {colors.length === 3 ? (
                <>
                  <stop offset="0%" stopColor={colors[0]} />
                  <stop offset="30%" stopColor={colors[1]} />
                  <stop offset="60%" stopColor={colors[1]} />
                  <stop offset="100%" stopColor={colors[2]} />
                </>
              ) : (
                <>
                  <stop offset="0%" stopColor={colors[0]} />
                  <stop offset="30%" stopColor={colors[0]} />
                  <stop offset="70%" stopColor={colors[1]} />
                  <stop offset="100%" stopColor={colors[1]} />
                </>
              )}
            </linearGradient>
            <Polyline
              positions={position.points.map((point) => [point.lt, point.ln])}
              weight={10}
              // lineCap="square"
              color={`url(#gradient_${colors[0]}_${index})`}
              eventHandlers={{
                click: (event) => {
                  startLoading && startLoading()
                  const isFirstPointDistance =
                    event.latlng.distanceTo(
                      latLng([position.points[0].lt, position.points[0].ln]),
                    ) <
                    event.latlng.distanceTo(latLng([position.points[1].lt, position.points[1].ln]))
                  if (isFirstPointDistance) {
                    dispatch(getInfoPointTrack(position.points[0].id))
                    return
                  }
                  dispatch(getInfoPointTrack(position.points[1].id))
                },
              }}
            />
          </SVGOverlay>
        )}
      </>
    )
  },
)

export default GradientPolyline
