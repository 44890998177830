import { IDriverFolder, IDriverInfo } from "@/interfaces/settingsDriver"
import { driverSettingsAPI } from "src/shared/api/globalSettings"
import { fetchIsError } from "src/shared/lib/helpers/fetchIsError/fetchIsError"
import {
  setFolderTreeItemData,
  setGSettingsUID,
  setGSItemData,
  setGSPageFolderData,
} from "../globalSettings"
import { thunkGetDriversFromServer } from "../globalSettingsThunk"

export const thunkGetDriver = (id: string, groupId: number) => {
  return async (dispatch) => {
    const data = await fetchIsError(dispatch, driverSettingsAPI.getDriver, [id])
    if (!data) return
    let driver = JSON.parse(JSON.stringify(data.data))
    driver.driver_groups_tree_id = groupId
    dispatch(
      setGSItemData({
        data: driver,
        field: "drivers",
      }),
    )
  }
}
export const thunkSaveDriver = (body: IDriverInfo) => {
  return async (dispatch) => {
    const data = await fetchIsError(dispatch, driverSettingsAPI.saveDriver, [body])
    if (!data) return
    dispatch(
      setGSItemData({
        data: null,
        field: "drivers",
      }),
    )

    if (data.data.uid) {
      dispatch(setGSettingsUID(data.data.uid))
    }
    dispatch(thunkGetDriversFromServer())
  }
}

export const thunkDeleteDriver = (id: string) => {
  return async (dispatch) => {
    const data = await fetchIsError(dispatch, driverSettingsAPI.deleteDriver, [id])
    if (!data) return
    dispatch(thunkGetDriversFromServer())
  }
}

export const thunkDeleteDriversFolder = (id: number) => {
  return async (dispatch) => {
    const data = await fetchIsError(dispatch, driverSettingsAPI.deleteDriverFolder, [id])
    if (!data) return
    dispatch(thunkGetDriversFromServer())
  }
}

export const thunkSaveDriversFolder = (body: IDriverFolder) => {
  return async (dispatch) => {
    const data = await fetchIsError(dispatch, driverSettingsAPI.saveDriverFolder, [body])
    if (!data) return
    dispatch(
      setGSPageFolderData({
        folderData: null,
        field: "drivers",
      }),
    )
    dispatch(thunkGetDriversFromServer())
  }
}

export const thunkGetDriversFolder = (id: number) => {
  return async (dispatch) => {
    const data = await fetchIsError(dispatch, driverSettingsAPI.getDriverFolder, [id])
    if (!data) return
    dispatch(
      setGSPageFolderData({
        folderData: data.data,
        field: "drivers",
      }),
    )
    dispatch(setFolderTreeItemData(data.data))
  }
}
