import { RootState } from "@/store/store"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { LoadingWrapper } from "src/components/LoadingWrapper/LoadingWrapper"
import {
  Change,
  CreateFolder,
  CreateUser,
  Delete,
} from "src/components/Settings/icons/optionsIcons/Options"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { HorizontalTree } from "src/shared/ui/SettingsTree/HorizontalTree"
import { organizeUsers } from "src/shared/ui/SettingsTree/userHelper"
import {
  setGSettingsItemSettingsID,
  setGSFolderClientData,
  setGSUserAccountId,
  setGSUserClientId,
  setNewHandleId,
  setUserId,
  setUsersTreeFolderIndex,
  setUsersTreeFolderInfo,
} from "src/store/reducers/globalSettings/globalSettings"
import { thunkGetUsers } from "src/store/reducers/globalSettings/globalSettingsThunk"
import defaultClient from "../../../ProfileSettings/Users/ClientSettings/Content/defaultAccount.json"
import defaultFolder from "../../../ProfileSettings/Users/FolderSettings/defaultFolder.json"
import { ClientIcon } from "../../Help/icons/ClientIcon"
import { DriverIcon } from "../../Help/icons/DriverIcon"
import { FolderIcon } from "../../Help/icons/FolderIcon"
import ObjectInfoColumn from "../Objects/ObjectInfoColumn"
import { UserSearch } from "./UserSearch"

export interface TestSettingsTreeProps {
  activeId: number | string | null
  setActiveId: (id: number | null) => void
}

interface IHGen {
  item: any
  parent_id: number
  index: number
}

export const Users = ({ activeId, setActiveId }: TestSettingsTreeProps) => {
  const dispatch = useAppDispatch()
  const levelIds = useAppSelector((state) => state.globalSettings.tree.users.levelIds)
  const [openDeleteID, setDeleteAlertID] = useState<null | number>(null)
  const data = useSelector((state: RootState) => state.globalSettings.data.users)

  const clickHandler = ({ id, index, is_folder }) => {
    setActiveId(null)
    if (!is_folder) {
      dispatch(setUserId({ id, level: index }))
      setActiveId(id)
    } else if (is_folder) {
      dispatch(
        setNewHandleId({
          id: Number(id),
          field: "users",
          index: index + 1,
          levelFromCreate: index,
        }),
      )
    }
  }

  useEffect(() => {
    dispatch(thunkGetUsers())
  }, [])

  interface IGenerateBody {
    groupID: number
    accountID: number
    type: "object" | "group"
    index: number
    handleId: number
    uid: string
    level: number
    handlePlusId: number
    roleID: number
    is_root: boolean
    parent_id: number
  }
  const generateBody = ({
    groupID,
    accountID,
    type,
    is_root,
    handleId,
    uid,
    parent_id,
    roleID,
  }: IGenerateBody) => {
    let body: any = []
    if (uid) {
      body = [
        {
          icon: <Change width={18} />,
          label: "Редактировать",
          onClickBtn: () => {
            dispatch(setGSUserAccountId(accountID))
            dispatch(setGSettingsItemSettingsID({ id: uid, field: "users" }))
          },
        },
        {
          icon: <Delete width={18} />,
          label: "Удалить",
          // onClick: () => dispatch(thunkDeleteUser(uid))
          onClickBtn: () => console.log("del"),
          color: "red",
        },
      ]
    }
    if (roleID == 3) {
      body = [
        {
          icon: <CreateUser width={20} />,
          label: "Добавить пользователя",
          onClickBtn: () => {
            dispatch(setGSUserAccountId(groupID))
            dispatch(setGSettingsItemSettingsID({ id: 0, field: "users" }))
          },
        },

        {
          icon: <Change width={18} />,
          label: "Редактировать",
          onClickBtn: () => dispatch(setGSUserClientId(groupID)),
        },

        {
          icon: <Delete width={18} />,
          label: "Удалить",
          onClickBtn: () => console.log("Удалить"),
          color: "red",
        },
      ]
    }
    if (roleID == 2) {
      body = [
        {
          icon: <CreateUser width={20} />,
          label: "Создать клиента",
          onClickBtn: () => {
            let obj = JSON.parse(JSON.stringify(defaultClient))
            obj.parent_id = groupID
            dispatch(setGSUserClientId(0))
            dispatch(setGSFolderClientData(obj))
          },
        },
        // {
        //   icon: <Change width={18} />,
        //   label: "Редактировать",
        //   onClickBtn: () => {
        //     // dispatch(setUsersTreeFolderIndex(index))
        //     dispatch(thunkGetUsersTreeFolder(accountID))
        //   },
        // },
      ]
    }
    return body
  }

  const generateBodyUnderItems = ({ item, parent_id, index }: IHGen) => {
    let body: any = []
    if (!item) return body
    if (item[1].role_id == 2) {
      let folder = { ...defaultFolder, parent_id: parent_id }
      body = [
        {
          icon: <CreateFolder width={18} />,
          label: "Создать папку",
          onClickBtn: () => {
            dispatch(setUsersTreeFolderIndex(index))
            dispatch(setUsersTreeFolderInfo(folder))
          },
        },
        {
          icon: <CreateUser width={20} />,
          label: "Добавить пользователя",
          onClickBtn: () => {
            dispatch(setGSUserAccountId(parent_id))
            dispatch(setGSettingsItemSettingsID({ id: 0, field: "users" }))
          },
        },
        {
          icon: <Delete width={18} />,
          label: "Удалить",
          onClickBtn: () => console.log("Удалить"),
          color: "red",
        },
      ]
    }
    if (item[0].role_id == 3) {
      let clientX = { ...defaultClient, parent_id: parent_id }
      body = [
        {
          icon: <CreateUser width={20} />,
          label: "Создать клиента",
          onClickBtn: () => {
            dispatch(setGSUserClientId(0))
            dispatch(setGSFolderClientData(clientX))
          },
        },
      ]
    }
    if (item[0].is_folder == false) {
      body = [
        {
          icon: <CreateUser width={20} />,
          label: "Создать клиента",
          onClickBtn: () => {
            let client = { defaultClient, parent_id }
            defaultClient.parent_id = parent_id
            dispatch(setGSUserClientId(0))
            dispatch(setGSFolderClientData(client))
          },
        },
      ]
    }
    return body
  }
  const iconGenerator = (is_folder: boolean, is_root: boolean, role_id) => {
    let icon: React.ReactNode

    if (!is_folder) {
      icon = <DriverIcon width={18} fill="rgb(255, 103, 0)" />
    }
    if (is_folder) {
      if (role_id !== 3) {
        icon = <FolderIcon fill={"rgb(255, 103, 0)"} width={18} />
      } else {
        icon = <ClientIcon fill={"rgb(255, 103, 0)"} width={18} />
      }
    }

    return icon
  }

  let InfoColumn = activeId ? <ObjectInfoColumn uid={activeId} /> : null

  const users: any = data != null ? organizeUsers(data) : []

  return (
    <div className="w-full h-full">
      <LoadingWrapper isLoading={data == null}>
        <UserSearch setActiveId={(id: any) => setActiveId(id)} />
        {users && (
          <HorizontalTree
            iconGenerator={(a: boolean, b: boolean, c: number) => iconGenerator(a, b, c)}
            levelIds={levelIds}
            data={users}
            onClickTreeItem={(arr: any) => clickHandler(arr)}
            contextGenerator={(arr: any) => generateBody(arr)}
            activeId={activeId}
            columnDefaultOptionsGenerator={(arr: any) => generateBodyUnderItems(arr)}
          />
        )}
      </LoadingWrapper>
    </div>
  )
}
