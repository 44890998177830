import { AppDispatch } from "@/store/store"
import { memo, useCallback } from "react"
import { useDispatch } from "react-redux"

import { useAppSelector } from "src/shared/lib/hooks/store"
import {
  createAxle,
  setTPMSDownAxleNorms,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/tpms/tpms"
import { Axle } from "./innerComponents/Axle"
import { CorrectValue } from "./innerComponents/CorrectValue"
import { ValidTime } from "./innerComponents/ValidTime"
import { CopyDown } from "./innerComponents/icons/CopyDown"
import { Plus } from "./innerComponents/icons/Plus"

export const ObjectSettingsAxles: React.FC<any> = memo((axes, onChange: (e: any) => void) => {
  const dispatch = useDispatch<AppDispatch>()
  const wheel_axes = useAppSelector((state) => state.osTPMS.wheel_axes)

  const time = useAppSelector((state) => state.osTPMS.settings.sensors.valid_time_period)

  const koef = useAppSelector((state) => state.osTPMS.settings.wheel_axes.pressure_norm_koef)
  const addAxle = useCallback(() => dispatch(createAxle()), [])
  return (
    <div className="w-full h-full relative">
      <h1 className="text-center text-3xl mb-5 font-light">{axes.name}</h1>
      <div className="">
        <div className="flex  font-light items-center justify-between gap-3 text-center px-12">
          <div className="text-center w-full  text-lg">Оси</div>
          <div className="flex text-center gap-2 text-[14px] w-[23%]">
            <div className="">Макс. давление</div>
            <div className="">Допустимое отклонение</div>
            <div className="">Макс. температура</div>
          </div>
        </div>
        <div className="">
          {wheel_axes?.map((axle, index) => (
            <div key={index}>
              {index != 0 && (
                <div className="w-full  bottom-[120%]  flex items-center justify-end">
                  <div className=" w-[23%] py-2 flex items-center justify-center">
                    <div
                      onClick={() => dispatch(setTPMSDownAxleNorms(index))}
                      className="p-2 hover:bg-slate-200 cursor-pointer rounded active:bg-slate-300 transition-all"
                    >
                      <CopyDown fill="black" width={24} />
                    </div>
                  </div>
                </div>
              )}
              <Axle data={axle} axleIndex={index} />
            </div>
          ))}
          <div
            onClick={addAxle}
            className=" cursor-pointer flex items-center  justify-center select-none  w-full   "
          >
            <div className="w-fit bg-slate-100 hover:bg-slate-200 active:bg-slate-300 p-2 gap-2 flex items-center justify-center rounded">
              <Plus fill="black" width={20} />
              <p>Добавить ось</p>
            </div>
          </div>
        </div>
      </div>
      <ValidTime />
      <CorrectValue />
    </div>
  )
})
