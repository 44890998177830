import React from "react"
import styles from "./style.module.css"

interface Iprops {
  rows: Array<Array<React.ReactNode | string | number>>
  colWidth: number[]
  setRowIndexActive?: (index: number) => void
  rowIndexActive?: number | null
  indexCol?: boolean
}

export const TableRows = React.memo<Iprops>(
  ({ rows, colWidth, rowIndexActive, setRowIndexActive, indexCol }) => {
    return (
      <>
        {rows.map((row, index) => {
          return (
            <div
              className={`${styles.table_row} ${rowIndexActive === index ? styles.table_row_active : ""}`}
              onClick={() => (setRowIndexActive ? setRowIndexActive(index) : null)}
              key={index}
            >
              {indexCol ? <div className={styles.table_item_index}>{index}</div> : null}
              {row.map((el, index) => {
                return (
                  <div
                    className={styles.table_item}
                    style={{
                      width: `${colWidth[index]}%`,
                    }}
                    key={index}
                  >
                    {el}
                  </div>
                )
              })}
            </div>
          )
        })}
      </>
    )
  },
)
