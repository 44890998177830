import React, { useEffect, useMemo, useState } from "react"
import { DateTime } from "ts-luxon"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { Popup } from "src/shared/ui/Popup"
import { Table } from "src/shared/ui/Table"
import styles from "./SwitchHistoryTirePopup.module.css"
import { Button } from "src/shared/ui/Button"
import {
  getSwitchHistoryThunk,
  removeSwitchHistoryThunk,
} from "src/store/reducers/switchTire/switchTireThunk"
import { useLoading } from "src/shared/lib/hooks/loading"
import { LoadingWrapper } from "src/components/LoadingWrapper/LoadingWrapper"
import EditorIcon from "src/shared/ui/IconComponent/EditorIcon"
import DeleteIcon from "src/shared/ui/IconComponent/DeleteIcon"
import {
  setActiveTireHistory,
  setEndDateHistory,
  setStartDateHistory,
} from "src/store/reducers/switchTire/switchTire"
import { ArrowCircle } from "src/shared/ui/IconComponent/ArrowCircle"
import { CalendarPopup } from "src/entities/CustomDateRangePicker /CalendarPopup/CalendarPopup"
import { usePopup } from "src/shared/lib/hooks/popup"

export const SwitchHistoryTirePopup: React.FC<{
  onClose: () => void
  onOpenSwitchTire: () => void
}> = ({ onClose, onOpenSwitchTire }) => {
  const dispatch = useAppDispatch()

  const activeRowTable = useAppSelector((state) => state.table.activeRowTable)
  const startDate = useAppSelector((state) => state.layoutCalendar.startTiming)
  const endDate = useAppSelector((state) => state.layoutCalendar.endTiming)
  const switchHistiryTire = useAppSelector((state) => state.switchTire.switchHistoryTire)
  const rowIndexActive = useAppSelector((state) => state.switchTire.activeHistoryTire)

  const localStartDate = useAppSelector((state) => state.switchTire.startDateHistory)
  const localEndDate = useAppSelector((state) => state.switchTire.endDateHistory)

  const { loading, startLoading, stopLoading } = useLoading()
  const [isOpen, onOpenPopup, onClosePopup, handlerPopup] = usePopup()

  useEffect(() => {
    if (localStartDate === 0 && localEndDate === 0) {
      dispatch(setStartDateHistory(startDate))
      dispatch(setEndDateHistory(endDate))
    }
  }, [])

  useEffect(() => {
    if (!activeRowTable) return
    if (localStartDate === 0 && localEndDate === 0) return
    startLoading()
    dispatch(
      getSwitchHistoryThunk({
        start_date: localStartDate,
        wheel_id: 0,
        end_date: localEndDate,
        vehicle_uid: activeRowTable?.item.vehicle_uid,
      }),
    )
  }, [localStartDate, localEndDate])

  useEffect(() => {
    if (!rowIndexActive?.tire?.id && switchHistiryTire) {
      dispatch(setActiveTireHistory({ tire: switchHistiryTire[0], index: 0 }))
    }
    stopLoading()
  }, [switchHistiryTire])

  const updateTable = () => {
    if (!activeRowTable) return
    startLoading()
    dispatch(
      getSwitchHistoryThunk({
        start_date: localStartDate,
        wheel_id: 0,
        end_date: localEndDate,
        vehicle_uid: activeRowTable?.item.vehicle_uid,
      }),
    )
  }

  const deleteHistorySwitchTire = () => {
    if (!switchHistiryTire || !rowIndexActive) return
    startLoading()
    dispatch(setActiveTireHistory({ tire: switchHistiryTire[0], index: 0 }))
    dispatch(removeSwitchHistoryThunk(rowIndexActive?.tire.id))
  }

  const tableValidMemo = useMemo(() => {
    stopLoading()
    return {
      headerTable: [
        {
          title: "Установленная модель",
          widthColumn: 20,
        },
        {
          title: "№ сенсора",
          widthColumn: 10,
        },
        {
          title: "Дата",
          widthColumn: 10,
        },
        {
          title: "Причина замены",
          widthColumn: 60,
        },
      ],
      rows: switchHistiryTire?.length
        ? switchHistiryTire?.map((row) => {
            return [
              row.wheel_model_name,
              row.sensor_number,
              <span>{DateTime.fromSeconds(row.start_date).toFormat(`T | dd.LL.yy`)}</span>,
              row.reason_replacement,
            ]
          })
        : [],
    }
  }, [switchHistiryTire])

  return (
    <Popup
      title="История замен"
      onClose={() => {
        onClose()
      }}
      className={styles.popup}
    >
      <LoadingWrapper isLoading={loading}>
        <div style={{ overflow: "hidden", position: "relative" }}>
          <div className={styles.header}>
            <div className={styles.wrapper}>
              <div className={styles.box}>
                <Button onClick={updateTable}>
                  <span className="bright">
                    <ArrowCircle width={20} fill={"#FF6700"} />
                  </span>
                  Обновить
                </Button>
                <Button onClick={() => onOpenPopup()}>
                  <div className={styles.calendar_btn}>
                    <div>{DateTime.fromSeconds(localStartDate).toFormat(`T | dd.LL.yy`)}</div>
                    <div className={`${styles.calendar_icon} icon-calendar`}></div>
                    <div>{DateTime.fromSeconds(localEndDate).toFormat(`T | dd.LL.yy`)}</div>
                  </div>
                </Button>
              </div>
              <div className={styles.box}>
                <Button onClick={deleteHistorySwitchTire}>
                  <DeleteIcon fill={"#E94040"} height={20} />
                </Button>
                <Button
                  onClick={() => {
                    onOpenSwitchTire()
                    onClose()
                  }}
                >
                  <EditorIcon fill={"#474747"} height={20} />
                </Button>
              </div>
            </div>
          </div>
          {localStartDate !== 0 && (
            <CalendarPopup
              isOpenPopup={isOpen}
              setIsOpenPopup={handlerPopup}
              setStartDate={(date) => dispatch(setStartDateHistory(Math.trunc(date.toSeconds())))}
              setEndDate={(date) => dispatch(setEndDateHistory(Math.trunc(date.toSeconds())))}
              startDate={DateTime.fromSeconds(localStartDate)}
              endDate={DateTime.fromSeconds(localEndDate)}
            />
          )}
          <div className={styles.table}>
            <Table
              tableData={tableValidMemo}
              indexCol={"№"}
              rowIndexActive={rowIndexActive?.index}
              setRowIndexActive={(index) =>
                dispatch(
                  setActiveTireHistory({
                    tire: switchHistiryTire ? switchHistiryTire[index] : [][index],
                    index: index,
                  }),
                )
              }
            />
          </div>
        </div>
      </LoadingWrapper>
    </Popup>
  )
}
