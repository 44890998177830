import { useAppDispatch } from "src/shared/lib/hooks/store"

import { updateTankInfo } from "src/store/reducers/objectSettings/objectSettingsPageSlices/fuel/fuel"
import { NumberComponent } from "../../../helpComponents/NumberComponent"
import { IFuelSettings } from "../interfaces"

interface ITankInfoProps {
  settings: IFuelSettings
  tankIndex
}
export const TankInfo = ({ settings, tankIndex }: ITankInfoProps) => {
  const dispatch = useAppDispatch()
  const info = [
    {
      value: settings.buf_apx,
      name: "buf_apx",
      field: "buf_apx",
      type: "number",
    },
    {
      value: settings.buf_raw,
      name: "buf_raw",
      field: "buf_raw",
      type: "number",
    },
    {
      value: settings.k_add,
      name: "k_add",
      field: "k_add",
      type: "number",
    },
    {
      value: settings.k_mul,
      name: "k_mul",
      field: "k_mul",
      type: "number",
    },
    {
      value: settings.norm_out,
      name: "norm_out",
      field: "norm_out",
      type: "number",
    },
    {
      value: settings.norm_out_type,
      name: "norm_out_type",
      field: "norm_out_type",
      type: "number",
    },
    {
      value: settings.threshold_in,
      name: "threshold_in",
      field: "threshold_in",
      type: "number",
    },
    {
      value: settings.threshold_out,
      name: "threshold_out",
      field: "threshold_out",
      type: "number",
    },
  ]
  return (
    <div className="w-full h-full  overflow-y-auto max-h-[40vh]">
      <h3 className="text-xl pb-6">Параметры бака</h3>
      {info.map((item, index) => (
        <div key={index} className="">
          <p>{item.name}</p>
          <NumberComponent
            value={item.value}
            onChange={(value) =>
              dispatch(
                updateTankInfo({
                  tankIndex,
                  field: item.field,
                  newValue: value,
                }),
              )
            }
          />
        </div>
      ))}
    </div>
  )
}
