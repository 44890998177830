export const UsersIcon = ({ fill, width }) => {
  return (
    <svg
      width={width}
      viewBox="0 0 25.5 23.001"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <circle id="circle" cx="20.250000" cy="16.250000" r="3.000000" fill={fill} fill-opacity="0" />
      <circle
        id="circle"
        cx="20.250000"
        cy="16.250000"
        r="3.000000"
        stroke={fill}
        stroke-opacity="1.000000"
        stroke-width="1.500000"
        stroke-linejoin="round"
      />
      <path
        id="path"
        d="M17.08 22.41L17.1 22.43C16.99 22.84 16.59 23.08 16.18 22.97C15.78 22.87 15.54 22.47 15.64 22.06L15.67 22.04L17.08 22.41ZM24.82 22.04L24.85 22.06C24.95 22.47 24.71 22.87 24.31 22.97C23.9 23.08 23.5 22.84 23.39 22.43L23.41 22.41L24.82 22.04Z"
        fill={fill}
        fill-opacity="0"
        fill-rule="nonzero"
      />
      <path
        id="path"
        d="M16.37 22.25C16.48 21.81 16.66 21.41 16.9 21.04C17.14 20.67 17.44 20.35 17.79 20.08C18.14 19.81 18.53 19.6 18.95 19.45C19.37 19.31 19.8 19.24 20.25 19.24C20.69 19.24 21.12 19.31 21.54 19.45C21.96 19.6 22.35 19.81 22.7 20.08C23.05 20.35 23.35 20.67 23.59 21.04C23.83 21.41 24.01 21.81 24.12 22.25"
        stroke={fill}
        stroke-opacity="1.000000"
        stroke-width="1.500000"
        stroke-linejoin="round"
        stroke-linecap="round"
      />
      <path
        id="path"
        d="M1.48 4.72L1.5 4.75C1.5 5.16 1.16 5.5 0.75 5.5C0.33 5.5 0 5.16 0 4.75L0.01 4.72L1.48 4.72ZM12.78 4.25L12.81 4.25C13.09 4.57 13.05 5.04 12.74 5.31C12.42 5.59 11.95 5.55 11.68 5.24L11.68 5.21L12.78 4.25Z"
        fill={fill}
        fill-opacity="0"
        fill-rule="nonzero"
      />
      <path
        id="path"
        d="M0.75 4.75L0.75 1.75C0.75 1.61 0.77 1.48 0.82 1.36C0.87 1.24 0.94 1.13 1.04 1.04C1.13 0.94 1.24 0.87 1.36 0.82C1.49 0.77 1.61 0.75 1.75 0.75L8.33 0.75C8.61 0.74 8.84 0.84 9.03 1.03L12.25 4.75"
        stroke={fill}
        stroke-opacity="1.000000"
        stroke-width="1.500000"
        stroke-linejoin="round"
        stroke-linecap="round"
      />
      <path
        id="path"
        d="M11.8 20.02L11.82 20C12.24 20 12.57 20.33 12.57 20.75C12.57 21.16 12.24 21.5 11.82 21.5L11.8 21.48L11.8 20.02ZM0.01 4.77L0 4.75C0 4.33 0.33 4 0.75 4C1.16 4 1.5 4.33 1.5 4.75L1.48 4.77L0.01 4.77ZM25.48 9.72L25.5 9.75C25.5 10.16 25.16 10.5 24.75 10.5C24.33 10.5 24 10.16 24 9.75L24.01 9.72L25.48 9.72Z"
        fill={fill}
        fill-opacity="0"
        fill-rule="nonzero"
      />
      <path
        id="path"
        d="M12.23 5.5L23.75 5.5C23.78 5.5 23.81 5.5 23.84 5.51C23.87 5.53 23.9 5.54 23.92 5.57Q23.94 5.59 23.95 5.61Q23.97 5.63 23.98 5.65C23.99 5.68 24 5.71 24 5.75L24 9.75C24 10.16 24.33 10.5 24.75 10.5C25.16 10.5 25.5 10.16 25.5 9.75L25.5 5.75C25.5 5.51 25.45 5.29 25.36 5.08C25.27 4.86 25.15 4.67 24.98 4.51C24.82 4.34 24.63 4.22 24.41 4.13C24.2 4.04 23.98 4 23.75 4L12.23 4Q11.86 4 11.67 4.18Q11.48 4.37 11.48 4.75Q11.48 5.12 11.67 5.31Q11.86 5.5 12.23 5.5ZM1.5 4.75C1.5 4.33 1.16 4 0.75 4C0.33 4 0 4.33 0 4.75L0 19.82C0 20.04 0.04 20.26 0.12 20.46C0.21 20.67 0.33 20.85 0.49 21C0.64 21.16 0.82 21.28 1.03 21.37C1.23 21.45 1.45 21.5 1.67 21.5L11.82 21.5C12.24 21.5 12.57 21.16 12.57 20.75C12.57 20.33 12.24 20 11.82 20L1.67 20C1.65 20 1.62 19.99 1.6 19.98C1.58 19.97 1.56 19.96 1.55 19.94C1.53 19.93 1.52 19.91 1.51 19.89Q1.5 19.87 1.5 19.85Q1.5 19.84 1.5 19.82L1.5 4.75Z"
        fill={fill}
        fill-opacity="1.000000"
        fill-rule="evenodd"
      />
    </svg>
  )
}
