export const defaultTank = {
  id: 0,
  name: "",
  settings: {
    k_add: 10,
    k_mul: 1000,
    buf_apx: 11,
    buf_raw: 10,
    norm_out: 280,
    threshold_in: 19000,
    norm_out_type: 1,
    threshold_out: 19000,
    sensors: [],
  },
}

export const defaultSensors = {
  id: 1,
  k_add: 200,
  k_mul: 1000,
  calibr: [
    [0, 0],
    [4095, 500000],
  ],
}
