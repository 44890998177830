/* eslint-disable array-callback-return */
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { DateTime } from "ts-luxon"

interface CounterState {
  startTiming: number
  endTiming: number
}

const initialState = {
  startTiming: Math.round(
    DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toSeconds(),
  ),
  endTiming: Math.round(DateTime.local().set({ hour: 23, minute: 59 }).toSeconds()),
} satisfies CounterState as CounterState

const counterSlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    setStartTiming(state: CounterState, action: PayloadAction<number>) {
      state.startTiming = Math.trunc(action.payload)
    },
    setEndTiming(state: CounterState, action: PayloadAction<number>) {
      state.endTiming = Math.trunc(action.payload)
    },
  },
})

export const { setStartTiming, setEndTiming } = counterSlice.actions
export default counterSlice.reducer
