import { TableFilters } from "../../features/TableFilters/TableFilters"
import { TableResize } from "src/shared/ui/TableResize"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { useMemo } from "react"
import { setActiveRowTable, setDirection, setSort } from "./model/table"
import { tableSort } from "src/shared/lib/helpers/table-sorting"
import { tableFilter } from "src/shared/lib/helpers/table-filters"
import { CircleIndicatorContainer } from "../../entities/CircleIndicatorContainer/CircleIndicatorContainer"
import { thunkGetCarData } from "src/store/reducers/car/carThunk"
import { setVehicleUID, setWheelId, setWidgetInfo } from "src/store/reducers/car/car"
import { setDefaultWheelChart } from "src/store/reducers/wheelChart/wheelChart"
import { DateTime } from "ts-luxon"
import { LoadingWrapper } from "src/components/LoadingWrapper/LoadingWrapper"
import { useLoading } from "src/shared/lib/hooks/loading"
import styles from "./Table.module.css"
import { searchInTable } from "src/shared/lib/helpers/searchFilterTable"

export const Table: React.FC = () => {
  const dispatch = useAppDispatch()

  const tableData = useAppSelector((state) => state.table.tableData)
  const activeRowTable = useAppSelector((state) => state.table.activeRowTable)
  const sortColTable = useAppSelector((state) => state.table.sortColTable)
  const directionSort = useAppSelector((state) => state.table.directionSort)
  const search = useAppSelector((state) => state.table.searchTable)
  const filters = useAppSelector((state) => state.filters.filters)
  const type = useAppSelector((state) => state.filters.type)

  const { loading, startLoading, stopLoading } = useLoading()

  const clickHandler = (sortName: string) => {
    if (sortName === sortColTable) {
      dispatch(setDirection())
    } else {
      dispatch(setSort(sortName))
    }
  }

  const tableSortingAngFilters = useMemo(() => {
    return tableSort(
      filters.length != 0
        ? searchInTable(tableFilter(tableData, filters, type), search)
        : searchInTable(tableData, search),
      sortColTable,
      directionSort,
    )
  }, [tableData, sortColTable, directionSort, filters, search])

  const tableValidMemo = useMemo(() => {
    stopLoading()
    return {
      headerTable: [
        {
          title: (
            <div className={styles.table_item} onClick={() => clickHandler("category_name")}>
              Категория
            </div>
          ),
          widthColumn: 12,
        },
        {
          title: (
            <div className={styles.table_item} onClick={() => clickHandler("vehicle_name")}>
              Гос-номер
            </div>
          ),
          widthColumn: 13,
        },
        {
          title: (
            <div className={styles.table_item} onClick={() => clickHandler("last_event_date")}>
              Посл. данные
            </div>
          ),
          widthColumn: 13,
        },
        {
          title: (
            <div className={styles.table_item} onClick={() => clickHandler("driver_name")}>
              Водители
            </div>
          ),
          widthColumn: 13,
        },
        {
          title: (
            <div className={styles.table_item} onClick={() => clickHandler("axes_count")}>
              Кол-во осей
            </div>
          ),
          widthColumn: 8,
        },
        {
          title: (
            <div className={styles.table_item} onClick={() => clickHandler("press")}>
              Давление
            </div>
          ),
          widthColumn: 16,
        },
        {
          title: (
            <div className={styles.table_item} onClick={() => clickHandler("t-delta")}>
              Перегрев
            </div>
          ),
          widthColumn: 10,
        },
        {
          title: (
            <div className={styles.table_item} onClick={() => clickHandler("t-wheel_model_names")}>
              Резина
            </div>
          ),
          widthColumn: 15,
        },
      ],
      rows: tableSortingAngFilters
        ? tableSortingAngFilters.map((row, index) => [
            row.category_name,
            row.vehicle_name,
            DateTime.fromSeconds(row.last_event_date).toFormat(`T | dd.LL.yy`),
            row.driver?.driver_name ? row.driver.driver_name : "",
            row.axes_count,
            <CircleIndicatorContainer
              data={row.wheel_pressure}
              valid_period={tableSortingAngFilters[index].settings.sensors_valid_time_period | 0}
            />,
            <div>
              {row.wheel_pressure.map((i: any) => {
                return (
                  <span
                    className="w-3 h-3 rounded-full"
                    style={{
                      background: i.t != null ? (i.t > i.t_max ? "rgb(252 165 165)" : "") : "",
                    }}
                  ></span>
                )
              })}
            </div>,
            <span>
              {row.wheel_pressure
                ? row.wheel_pressure.map((wheel) => (wheel?.wmn ? <span>{wheel.wmn} </span> : ""))
                : ""}
            </span>,
          ])
        : [],
    }
  }, [tableSortingAngFilters])

  return (
    <div className={styles.table_wrapper}>
      <LoadingWrapper isLoading={loading}>
        <TableFilters startLoad={startLoading} />
        <div className={styles.table_box}>
          <TableResize
            tableData={tableValidMemo}
            indexCol="№"
            rowIndexActive={activeRowTable ? activeRowTable.index : null}
            setRowIndexActive={(index) => {
              dispatch(thunkGetCarData(tableSortingAngFilters[index].vehicle_uid))
              dispatch(setVehicleUID(tableSortingAngFilters[index].vehicle_uid))
              dispatch(thunkGetCarData(tableSortingAngFilters[index].vehicle_uid))
              dispatch(setDefaultWheelChart())
              dispatch(setWheelId(null))
              dispatch(setWidgetInfo(null))

              dispatch(setActiveRowTable({ item: tableSortingAngFilters[index], index: index }))
            }}
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}
