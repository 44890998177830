import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import {
  setGSettingsItemSettingsID,
  setGSUserAccountId,
  setNewLevelIds,
} from "src/store/reducers/globalSettings/globalSettings"
import styles from "../../../styles/search-page.module.css"
import { usersSearchHelper } from "../../Search/helpers/userHelper"
import { SearchTreeView } from "../../Search/SearchTreeView/SearchTreeView"
import { IOnClickName } from "../Drivers/SearchDrivers"

interface IUserSearchProps {
  setActiveId: (id: any) => void
}

export const UserSearch = React.memo(({ setActiveId }: IUserSearchProps) => {
  const dispatch = useAppDispatch()
  const [isOpenSearchPopup, setOpenSearchPopup] = useState(false)
  const [searchText, setSearchText] = useState("")
  const users = useAppSelector((state) => state.globalSettings.data.users)
  const filteredData =
    users != null && typeof users != "string" ? usersSearchHelper(users, searchText) : []

  const onClickPath = (levelIds: [number, number][]) => {
    dispatch(setNewLevelIds({ levelIds, page: "users" }))
  }

  const onClickName = ({
    id,
    is_folder,
    is_root,
    role_id,
    parent_id,
    account_id,
  }: IOnClickName) => {
    if (is_folder) return

    dispatch(setGSettingsItemSettingsID({ id, field: "users" }))
    if (account_id) dispatch(setGSUserAccountId(account_id))
  }

  return (
    <>
      <div className={styles.input_block}>
        <div className={styles.input_item}>
          <input
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
              if (e.target.value.length > 0) {
                setOpenSearchPopup(true)
              } else {
                setOpenSearchPopup(false)
              }
            }}
            type="text"
            placeholder={`Поиск`}
            // onKeyDown={(e) => {
            //   if (e.key == "Enter") {
            //     setOpenSearchPopup(true)
            //   }
            // }}
          />
          <div className={styles.input_item_opt}>
            {searchText.length > 0 && (
              <span
                onClick={() => {
                  setSearchText("")
                  setOpenSearchPopup(false)
                }}
                className="icon-cross"
              ></span>
            )}
          </div>
        </div>
      </div>
      {isOpenSearchPopup && (
        <div className={styles.input_page}>
          <div className={styles.input_page_container}>
            <div className={styles.input_page_tree_view}>
              {filteredData.length > 0 ? (
                <SearchTreeView
                  tree={filteredData}
                  onClickPath={onClickPath}
                  onClickName={onClickName}
                  setActiveId={setActiveId}
                  setCloseSearch={() => setOpenSearchPopup(false)}
                />
              ) : (
                <div className="text-center text-xl">Не найдено</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
})
