import { setIsAuth } from "../../../../store/reducers/auth/authSlice"
import { addError } from "../../../../store/reducers/globalErrors/globalErrorsSlice"
import { allErrorMessages } from "./messagesError"

export const fetchIsError = async (
  dispatch: any,
  fetchFunc: any,
  params?: any,
  seconds?: number,
) => {
  const { data, status } = await fetchFunc(...params)
  if (status >= 200 && status <= 299) {
    return { ...data }
  }

  const errorCodeServer = data?.error?.code
  const messageCode = errorCodeServer ? allErrorMessages[errorCodeServer] : allErrorMessages[status]

  if (status === 401) {
    dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
    return null
  }
  if (messageCode) {
    dispatch(addError({ message: messageCode, seconds: seconds ? seconds : 3 }))
    return null
  }
  dispatch(addError({ message: "Неизвестная ошибка!", seconds: seconds ? seconds : 3 }))
  return null
}
