import React from "react"

export const HomeIcon = () => {
  return (
    <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.6372 9.7768L15.6656 1.67408C14.444 0.682208 12.6715 0.682208 11.4498 1.67408L1.4796 9.77548C1.12961 10.0594 0.930176 10.4794 0.930176 10.9285V26.1737C0.930176 26.9926 1.59715 27.6662 2.416 27.6662H9.10157C9.92043 27.6662 10.5874 26.9926 10.5874 26.1737V16.5244H16.5294V26.1737C16.5294 26.9926 17.1964 27.6662 18.0152 27.6662H24.7008C25.5196 27.6662 26.1866 26.9926 26.1866 26.1737V10.9285C26.1853 10.4794 25.9859 10.0594 25.6372 9.7768ZM24.6995 26.1817H18.0139V16.5178C18.0139 15.699 17.3469 15.0399 16.5281 15.0399H10.5861C9.76722 15.0399 9.10025 15.699 9.10025 16.5178V26.1817H2.416V10.9285L12.3876 2.82708C13.0651 2.27502 14.0517 2.27634 14.7292 2.82708L24.7008 10.9285L24.6995 26.1817Z"
        fill="#F9914B"
        stroke="#F9914B"
      />
    </svg>
  )
}
