import { Popup } from "src/shared/ui/Popup"
import React, { useState } from "react"
import styles from "./PopupSettingsEvent.module.css"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { changeEventChecked, setColorTrack } from "src/store/reducers/mapTrack/mapTrack"
import { Button } from "src/shared/ui/Button"
import { Checkbox } from "src/shared/ui/Checkbox"

type propsType = {
  setIsOpen: (value) => void
}

let eventID = [3, 4, 9, 10, 11, 13]

let eventName = [
  "Начало движения",
  "Окончание движения",
  "Включение зажигания",
  "Выключение зажигания",
  "Вход в геозону",
  "Выход из геозоны",
  "Активация водителя",
  "Деактивация водителя",
  "Остановка",
  "Стоянка",
  "Начало заправки",
  "Окончание заправки",
  "Начало слива",
  "Окончание слива",
]

export const PopupSettingsEvent: React.FC<propsType> = ({ setIsOpen }) => {
  const dispatch = useAppDispatch()
  const checkedEvent = useAppSelector((state) => state.mapTrack.checkedEvent)
  const checkedColorTrack = useAppSelector((state) => state.mapTrack.colorTrack)

  const [localCheckedEvent, setLocalCheckedEvent] = useState([...checkedEvent])
  const [localCheckedColorTrack, setLocalCheckedColorTrack] = useState(checkedColorTrack)

  const onChangeLocalCheked = (id) => {
    if (localCheckedEvent.includes(id)) {
      setLocalCheckedEvent((prev) => prev.filter((idChecked) => idChecked !== id))
      return
    }
    setLocalCheckedEvent((prev) => [...prev, id])
  }
  return (
    <Popup title="Настройка карты" onClose={() => setIsOpen(false)}>
      <div
        style={{
          minHeight: "250px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.title_settings}>События</div>

          <div className={styles.wrapper_eventCheckbox}>
            {eventID.map((el) => {
              return (
                <Checkbox
                  checked={localCheckedEvent.includes(el)}
                  setChecked={() => onChangeLocalCheked(el)}
                  labalEl={eventName[el - 1]}
                />
              )
            })}
          </div>
          <div className={styles.title_settings}>Трек</div>

          <Checkbox
            checked={localCheckedColorTrack}
            setChecked={() => {
              setLocalCheckedColorTrack((prev) => !prev)
            }}
            labalEl={"Отображать скорость на треке"}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "end", fontSize: "16px" }}>
          <Button
            onClick={() => {
              setIsOpen(false)
              dispatch(changeEventChecked(localCheckedEvent))
              dispatch(setColorTrack(localCheckedColorTrack))
            }}
            color="orange"
          >
            Применить
          </Button>
        </div>
      </div>
    </Popup>
  )
}
