import { IGeozone } from "@/interfaces/geozone"
import { Tfigure } from "@/shared/types/types"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { latLng } from "leaflet"
import { findPointsOnCircle } from "../../../shared/lib/helpers/map"

interface ImapEditor {
  editMap: boolean
  creatorFigure: IGeozone
}

const initialState: ImapEditor = {
  editMap: false,
  creatorFigure: {
    geozone_name: "Название",
    color: "#ff6801",
    geozone_uid: "",
    geozone_type_id: 1,
    latitude: 0,
    longitude: 0,
    radius: 0,
    line_width: 2,
    geometry_type_id: 2,
    use_as_address: false,
    image_url: "",
    geozone_points: [],
    comment: "",
    transparency: 50,
  },
}

const mapSlice = createSlice({
  name: "mapEditor",
  initialState,
  reducers: {
    setCreatorFigure(state: ImapEditor, action: PayloadAction<IGeozone>) {
      let figure = { ...action.payload }

      if (figure.transparency === 0) {
        figure.transparency = 1
      }
      if (figure.line_width === 0) {
        figure.line_width = 3
      }
      if (figure.color === "") {
        figure.color = "#3388ff"
      }
      state.creatorFigure = figure
      state.editMap = true
    },
    clearFigure(state: ImapEditor) {
      state.creatorFigure = {
        geozone_name: "Название",
        color: "#ff6801",
        geozone_uid: "2435qwe3245",
        geozone_type_id: 1,
        latitude: 0,
        longitude: 0,
        radius: 0,
        line_width: 1,
        geometry_type_id: 2,
        use_as_address: false,
        image_url: "",
        geozone_points: [],
        comment: "",
        transparency: 50,
        geozone_groups_tree_id: 0,
      }
    },
    setEditMap(state: ImapEditor, action: PayloadAction<boolean>) {
      state.editMap = action.payload
    },
    setGeozoneUID(state: ImapEditor, action: PayloadAction<number>) {
      state.creatorFigure.geometry_type_id = action.payload
    },
    setTypeFigure(state: ImapEditor, action: PayloadAction<Tfigure>) {
      if (state.creatorFigure.geozone_points.length <= 1) {
        state.creatorFigure.geometry_type_id = action.payload
        state.creatorFigure.geozone_points = []
        state.creatorFigure.latitude = 0
        state.creatorFigure.longitude = 0
        return
      }
      if (action.payload === 0) {
        if (state.creatorFigure.geozone_points.length === 0 || state.creatorFigure.latitude === 0) {
          state.creatorFigure.geometry_type_id = action.payload
          return
        }
        state.creatorFigure.radius = latLng([
          state.creatorFigure.latitude,
          state.creatorFigure.longitude,
        ]).distanceTo(state.creatorFigure.geozone_points[0])
      }
      if (action.payload === 1 && state.creatorFigure.geometry_type_id === 0) {
        const points = findPointsOnCircle(
          state.creatorFigure.latitude,
          state.creatorFigure.longitude,
          state.creatorFigure.radius,
          8,
        )
        state.creatorFigure.geozone_points = [points[3], points[7], points[1], points[5]]
      }
      if (action.payload === 1 && state.creatorFigure.geometry_type_id === 2) {
        let maxLat = null
        let maxLng = null
        let minLat = null
        let minLng = null
        state.creatorFigure.geozone_points.forEach((point) => {
          if (maxLat === null || point.lat > maxLat) {
            maxLat = point.lat
          }
          if (maxLng === null || point.lng > maxLng) {
            maxLng = point.lng
          }
          if (minLat === null || point.lat < minLat) {
            minLat = point.lat
          }
          if (minLng === null || point.lng < minLng) {
            minLng = point.lng
          }
        })
        state.creatorFigure.geozone_points = [
          { lat: maxLat, lng: maxLng },
          { lat: minLat, lng: minLng },
          { lat: maxLat, lng: minLng },
          { lat: minLat, lng: maxLng },
        ]
      }
      if (action.payload === 2 && state.creatorFigure.geometry_type_id === 1) {
        let newPoints = [...state.creatorFigure.geozone_points]
        newPoints = [
          newPoints[0],
          {
            lat: state.creatorFigure.geozone_points[0].lat,
            lng: state.creatorFigure.geozone_points[1].lng,
          },
          newPoints[1],
          {
            lat: state.creatorFigure.geozone_points[1].lat,
            lng: state.creatorFigure.geozone_points[0].lng,
          },
        ]
        state.creatorFigure.geozone_points = newPoints
      }
      if (action.payload === 2 && state.creatorFigure.geometry_type_id === 0) {
        state.creatorFigure.geozone_points = findPointsOnCircle(
          state.creatorFigure.latitude,
          state.creatorFigure.longitude,
          state.creatorFigure.radius,
          10,
        )
      }
      state.creatorFigure.geometry_type_id = action.payload
    },
    setColorFigure(state: ImapEditor, action: PayloadAction<string>) {
      state.creatorFigure.color = action.payload
    },
    setOpacityFigure(state: ImapEditor, action: PayloadAction<number>) {
      state.creatorFigure.transparency = action.payload
    },
    setLineWidth(state: ImapEditor, action: PayloadAction<number>) {
      state.creatorFigure.line_width = action.payload
    },
    setNameGeozone(state: ImapEditor, action: PayloadAction<string>) {
      state.creatorFigure.geozone_name = action.payload
    },
    setCommentGeozone(state: ImapEditor, action: PayloadAction<string>) {
      state.creatorFigure.comment = action.payload
    },
    setGeozonePoint(state: ImapEditor, action: PayloadAction<any>) {
      state.creatorFigure.geozone_points = action.payload
    },
    addGeozonePoint(state: ImapEditor, action: PayloadAction<any>) {
      state.creatorFigure.geozone_points = [...state.creatorFigure.geozone_points, action.payload]
    },
    addGeozonePointIndex(state: ImapEditor, action: PayloadAction<{ coord: any; index: number }>) {
      let newArrayPoints = [...state.creatorFigure.geozone_points]
      newArrayPoints.splice(action.payload.index + 1, 0, action.payload.coord)
      state.creatorFigure.geozone_points = newArrayPoints
    },
    setLatLng(state: ImapEditor, action: PayloadAction<any>) {
      state.creatorFigure.latitude = action.payload.lat
      state.creatorFigure.longitude = action.payload.lng
    },
    setRadius(state: ImapEditor, action: PayloadAction<number>) {
      state.creatorFigure.radius = Math.round(action.payload)
    },
    setFigure(state: ImapEditor, action: PayloadAction<any>) {
      state.creatorFigure = action.payload
    },
    setCenter(state: ImapEditor, action: PayloadAction<{ lat: number; lng: number }>) {
      state.creatorFigure.latitude = action.payload.lat
      state.creatorFigure.longitude = action.payload.lng
    },
    setIdTree(state: ImapEditor, action: PayloadAction<number>) {
      state.creatorFigure.geozone_groups_tree_id = action.payload
    },
  },
})

export const {
  setGeozoneUID,
  setCenter,
  setCreatorFigure,
  clearFigure,
  setEditMap,
  setTypeFigure,
  setCommentGeozone,
  setNameGeozone,
  setColorFigure,
  setOpacityFigure,
  setLineWidth,
  addGeozonePoint,
  setGeozonePoint,
  setLatLng,
  setRadius,
  setFigure,
  addGeozonePointIndex,
  setIdTree,
} = mapSlice.actions
export default mapSlice.reducer
