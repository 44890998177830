import { IVehicle, TreeNode } from "src/interfaces/vehicleTree"
import { TFilter } from "src/store/reducers/vehiclesTree/vehicleTreeSlice"
import { getStatus } from "./treeHelpers"

export const checkObjectsPresenceVehicle = (
  vehicleGroup: IVehicle[],
  vehicleChecked: IVehicle[],
) => {
  // Создаем множество (Set) из идентификаторов объектов в vehicleChecked для быстрого поиска
  const checkedIds = new Set(vehicleChecked.map((vehicle) => vehicle.vehicle_uid))

  // Проверяем наличие объектов из vehicleGroup в vehicleChecked
  const containAll = vehicleGroup.every((vehicle) => checkedIds.has(vehicle.vehicle_uid))
  const containSome = vehicleGroup.some((vehicle) => checkedIds.has(vehicle.vehicle_uid))

  // Возвращаем соответствующий результат
  return getStatus(containAll, containSome)
}

export const changeStatus = (objects: any[], objectId: number, newStatus: string) => {
  const object = objects.find((obj) => obj.id === objectId)
  if (object) {
    object.status = newStatus
    setStatusGroup(object, objects, newStatus)
  }
}

export const setStatusGroup = (object: any, objects: any, newStatus) => {
  const parentId = object.parent_id
  if (parentId) {
    const hasChildren = objects.some((obj) => obj.parent_id === parentId)
    const hasVehicles = objects.some(
      (obj) => obj.parent_id === parentId && obj.vehicles && obj.vehicles.length > 0,
    )
    if (hasChildren || hasVehicles) {
      const parentObject = objects.find((obj) => obj.id === parentId)
      if (parentObject) {
        if (newStatus === "none") {
          const parentObjectHasChildren = objects
            .filter((obj) => obj.parent_id === parentId)
            .filter((obj) => obj.status === "some" || obj.status === "all").length
          if (!parentObjectHasChildren) {
            parentObject.status = "none"
          }
          setStatusGroup(parentObject, objects, newStatus)
        } else {
          parentObject.status = "some"
        }
      }
      if (parentObject.parent_id) {
        setStatusGroup(parentObject, objects, newStatus)
      }
    } else {
      changeStatus(objects, parentId, newStatus)
    }
  }
}

export const filterByVehicleName = (dataArray: any[], searchTerm: string) => {
  if (!!!searchTerm) {
    return dataArray
  }

  const idSearch = dataArray.map((item) => ({
    ...item,
    vehicles: item.vehicles
      ? item.vehicles.filter((vehicle) => vehicle.vehicle_id == searchTerm)
      : [],
  }))

  if (idSearch.some((group) => group.vehicles.length > 0)) {
    return idSearch
  }
  // Создаем регулярное выражение для поиска, не чувствительное к регистру
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  const regex = new RegExp(escapedSearchTerm, "i")

  let parentId: number[] = []
  let maxLeval = 0

  // Фильтруем массив data
  // let filterVehicle = dataArray.map((item) => {
  return dataArray.map((item) => {
    let newVehicles = item.vehicles
      ? item.vehicles.filter((vehicle) => regex.test(vehicle.vehicle_name))
      : []
    if (newVehicles.length) {
      parentId.push(item.parent_id)
    }
    if (item.level > maxLeval) maxLeval = item.level
    return {
      ...item,
      vehicles: newVehicles,
    }
  })

  // for (let index = 0; index !== maxLeval; index++) {
  //   filterVehicle.forEach((el) => {
  //     if (parentId.includes(el.id)) parentId.push(el.id)
  //   })
  //   index++
  // }

  // return filterVehicle.filter((el) => parentId.includes(el.id))
}

export const filterVehicle = (filter: TFilter, vehicleGroup: TreeNode[]) => {
  const groups = [
    ...vehicleGroup.map((el) => ({
      ...el,
      vehicles: el.vehicles
        ? el.vehicles.map((vehicle) => ({
            ...vehicle,
            last_pos: { ...vehicle.last_pos },
            last_driver: { ...vehicle.last_driver },
          }))
        : [],
    })),
  ]
  switch (filter) {
    case "ignitionFirstOn":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            // Проверяем наличие поля last_pos и значение is_ignition_on
            const ignitionA = a.last_pos && a.last_pos.is_ignition_on
            const ignitionB = b.last_pos && b.last_pos.is_ignition_on

            // Сортировка: сначала те, у кого is_ignition_on === true
            if (ignitionA && !ignitionB) {
              return -1 // a идет раньше b
            } else if (!ignitionA && ignitionB) {
              return 1 // b идет раньше a
            } else {
              return 0 // оставляем как есть
            }
          })
        }
      })
      break
    case "ignitionFirstOff":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            // Проверяем наличие поля last_pos и значение is_ignition_on
            const ignitionA = a.last_pos && a.last_pos.is_ignition_on
            const ignitionB = b.last_pos && b.last_pos.is_ignition_on

            // Сортировка: сначала те, у кого is_ignition_on === true
            if (!ignitionA && ignitionB) {
              return -1 // a идет раньше b
            } else if (ignitionA && !ignitionB) {
              return 1 // b идет раньше a
            } else {
              return 0 // оставляем как есть
            }
          })
        }
      })
      break
    case "move":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const aIsGreater = a?.last_pos?.speed && a?.last_pos?.speed > 1.2 ? 1 : 0
            const bIsGreater = b.last_pos?.speed && b?.last_pos?.speed > 1.2 ? 1 : 0

            return bIsGreater - aIsGreater
          })
        }
      })
      break
    case "noMove":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const speedA = a?.last_pos?.speed ?? 0 // Если speedA неопределен, присваиваем ему Infinity
            const speedB = b?.last_pos?.speed ?? 0 // Если speedB неопределен, присваиваем ему Infinity

            if (speedA < 1.2 && speedB < 1.2) {
              return 0 // Оба объекта меньше 1.2, сохраняем порядок
            } else if (speedA < 1.2) {
              return -1 // speedA меньше 1.2, он должен идти первым
            } else if (speedB < 1.2) {
              return 1 // speedB меньше 1.2, он должен идти первым
            } else {
              return speedA - speedB // Сравниваем по возрастанию speed
            }
          })
        }
      })
      break
    case "cart":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const aIsNotNull =
              a?.last_driver?.driver_code !== null && a?.last_driver?.driver_name !== null
            const bIsNotNull =
              b?.last_driver?.driver_code !== null && b?.last_driver?.driver_name !== null

            if (aIsNotNull && !bIsNotNull) {
              return -1 // a должен быть перед b
            }
            if (!aIsNotNull && bIsNotNull) {
              return 1 // b должен быть перед a
            }
            return 0 // если оба объекта либо не null, либо оба null, то порядок не изменяется
          })
        }
      })
      break
    case "cartNoneDriver":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const aHasBoth =
              a?.last_driver?.driver_code !== null && a?.last_driver?.driver_name !== null
            const bHasBoth =
              b?.last_driver?.driver_code !== null && b?.last_driver?.driver_name !== null

            // Проверяем, имеет ли объект хотя бы одно поле не null
            const aHasValue =
              a?.last_driver?.driver_code !== null || a?.last_driver?.driver_name !== null
            const bHasValue =
              b?.last_driver?.driver_code !== null || b?.last_driver?.driver_name !== null

            if (!aHasValue && bHasValue) {
              return 1 // a должен быть после b
            }
            if (aHasValue && !bHasValue) {
              return -1 // a должен быть перед b
            }
            if (aHasBoth && !bHasBoth) {
              return 1 // a должен быть после b
            }
            if (!aHasBoth && bHasBoth) {
              return -1 // a должен быть перед b
            }
            return 0 // если оба объекта в одной категории, их порядок не изменяется
          })
        }
      })
      break
    case "noneCart":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const aIsNotNull =
              a?.last_driver?.driver_code === null && a?.last_driver?.driver_name === null
            const bIsNotNull =
              b?.last_driver?.driver_code === null && b?.last_driver?.driver_name === null

            if (aIsNotNull && !bIsNotNull) {
              return -1 // a должен быть перед b
            }
            if (!aIsNotNull && bIsNotNull) {
              return 1 // b должен быть перед a
            }
            return 0 // если оба объекта либо не null, либо оба null, то порядок не изменяется
          })
        }
      })
      break
    case "greenGsm":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const aIsHigh = a?.last_pos?.sats && a?.last_pos?.sats >= 7
            const bIsHigh = b?.last_pos?.sats && b?.last_pos?.sats >= 7

            if (aIsHigh && !bIsHigh) {
              return -1 // a должен быть перед b
            }
            if (!aIsHigh && bIsHigh) {
              return 1 // b должен быть перед a
            }
            return 0
          })
        }
      })
      break
    case "yellowGsm":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const aIsHigh =
              a?.last_pos?.sats &&
              a?.last_pos?.sats >= 4 &&
              a?.last_pos?.sats &&
              a?.last_pos?.sats < 7
            const bIsHigh =
              b?.last_pos?.sats &&
              b?.last_pos?.sats >= 4 &&
              b?.last_pos?.sats &&
              b?.last_pos?.sats < 7

            if (aIsHigh && !bIsHigh) {
              return -1 // a должен быть перед b
            }
            if (!aIsHigh && bIsHigh) {
              return 1 // b должен быть перед a
            }
            return 0 // ес
          })
        }
      })
      break
    case "redGsm":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const aSats = a?.last_pos?.sats ?? 0
            const bSats = b?.last_pos?.sats ?? 0

            if (aSats <= 3 && bSats <= 3) {
              return aSats - bSats
            } else if (aSats <= 3) {
              return -1
            } else if (bSats <= 3) {
              return 1
            } else {
              return bSats - aSats
            }
          })
        }
      })
      break
    case "online":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const currentTime = Math.floor(Date.now() / 1000)
            const aEventData = a?.last_pos?.event_date ?? 0
            const bEventData = b?.last_pos?.event_date ?? 0

            if (currentTime - aEventData <= 1800 && currentTime - bEventData <= 1800) {
              // Both devices were connected within the last 30 minutes, sort by event_data in ascending order
              return aEventData - bEventData
            } else if (currentTime - aEventData <= 1800) {
              // Device a was connected within the last 30 minutes, put it first
              return -1
            } else if (currentTime - bEventData <= 1800) {
              // Device b was connected within the last 30 minutes, put it first
              return 1
            } else {
              // Neither device was connected within the last 30 minutes, sort by event_data in descending order
              return bEventData - aEventData
            }
          })
        }
      })
      break
    case "recentlyOnline":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const currentTime = Math.floor(Date.now() / 1000)
            const aEventData = a?.last_pos?.event_date ?? 0
            const bEventData = b?.last_pos?.event_date ?? 0

            const aTimeDiff = currentTime - aEventData
            const bTimeDiff = currentTime - bEventData

            if (aTimeDiff > 1800 && aTimeDiff < 86400 && bTimeDiff > 1800 && bTimeDiff < 86400) {
              // Both devices were connected between 30 minutes and 24 hours ago, sort by event_data in ascending order
              return aEventData - bEventData
            } else if (aTimeDiff > 1800 && aTimeDiff < 86400) {
              // Device a was connected between 30 minutes and 24 hours ago, put it first
              return -1
            } else if (bTimeDiff > 1800 && bTimeDiff < 86400) {
              // Device b was connected between 30 minutes and 24 hours ago, put it first
              return 1
            } else {
              // Neither device was connected between 30 minutes and 24 hours ago, sort by event_data in descending order
              return bEventData - aEventData
            }
          })
        }
      })
      break
    case "longTimeOnline":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const currentTime = Math.floor(Date.now() / 1000)
            const aEventData = a?.last_pos?.event_date ?? 0
            const bEventData = b?.last_pos?.event_date ?? 0

            const aTimeDiff = currentTime - aEventData
            const bTimeDiff = currentTime - bEventData

            if (aTimeDiff > 86400 && bTimeDiff > 86400) {
              // Both devices were connected more than 24 hours ago, sort by event_data in descending order
              return bEventData - aEventData
            } else if (aTimeDiff > 86400) {
              // Device a was connected more than 24 hours ago, put it first
              return -1
            } else if (bTimeDiff > 86400) {
              // Device b was connected more than 24 hours ago, put it first
              return 1
            } else {
              // Neither device was connected more than 24 hours ago, sort by event_data in ascending order
              return aEventData - bEventData
            }
          })
        }
      })
      break
    case "neverOnline":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => {
            const aEventData = a?.last_pos?.event_date
            const bEventData = b?.last_pos?.event_date

            if (aEventData === null || aEventData === undefined) {
              if (bEventData === null || bEventData === undefined) {
                // Both devices have null or undefined event_data, keep the original order
                return 0
              } else {
                // Device a has null or undefined event_data, put it first
                return -1
              }
            } else if (bEventData === null || bEventData === undefined) {
              // Device b has null or undefined event_data, put it first
              return 1
            } else {
              // Both devices have valid event_data, sort by event_data in ascending order
              return aEventData - bEventData
            }
          })
        }
      })
      break
    case "firstAlphabetName":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => a.vehicle_name.localeCompare(b.vehicle_name))
        }
      })
      break
    case "lastAlphabetName":
      groups.forEach((el) => {
        if (el.vehicles) {
          el.vehicles.sort((a, b) => b.vehicle_name.localeCompare(a.vehicle_name))
        }
      })
      break
  }

  return groups
}
