import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IGSDiagSlice {
  is_enabled: boolean
}

const initialState: IGSDiagSlice = {
  is_enabled: false,
}

const rootOSettingsDiag = createSlice({
  name: "OSettingsDiag",
  initialState,
  reducers: {
    setIsDiagEnabled: (state: IGSDiagSlice) => {
      state.is_enabled = !state.is_enabled
    },
    setDiagState: (state: IGSDiagSlice, action: PayloadAction<IGSDiagSlice>) => {
      state.is_enabled = action.payload.is_enabled ?? false
    },
  },
})

export const { setIsDiagEnabled, setDiagState } = rootOSettingsDiag.actions
export default rootOSettingsDiag.reducer
