import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IDriverCardState {
  CustomProtocolMonitoring: CustomProtocolMonitoring
  driver_events: DriverEvents
}

export interface CustomProtocolMonitoring {
  addr: number | string
  dataSource: number | string
  func: number
  regNo: number | string
  use_as_driver_code: boolean
}

export interface DriverEvents {
  end_registration_by_removing_card: boolean
  end_registration_by_turning_off_ignition: boolean
  is_enabled: boolean
  restore_registration_if_card_reapplied_within: number | string
}

const initialState: IDriverCardState = {
  CustomProtocolMonitoring: {
    addr: "",
    dataSource: "",
    func: 0,
    regNo: "",
    use_as_driver_code: true,
  },
  driver_events: {
    end_registration_by_removing_card: false,
    end_registration_by_turning_off_ignition: false,
    is_enabled: false,
    restore_registration_if_card_reapplied_within: "",
  },
}

const rootOSettingsDriverCard = createSlice({
  name: "OSettingsDriverCard",
  initialState,
  reducers: {
    setDriverCardState: (state: IDriverCardState, action: PayloadAction<IDriverCardState>) => {
      // state.events = {
      //   ...state.events,
      //   ...action.payload.events,
      // }

      state.driver_events = {
        ...state.driver_events,
        ...action.payload.driver_events,
      }
      state.CustomProtocolMonitoring = {
        ...state.CustomProtocolMonitoring,
        ...action.payload.CustomProtocolMonitoring,
      }
    },

    setCustomProtocolMonitoring: (
      state: IDriverCardState,
      action: PayloadAction<CustomProtocolMonitoring>,
    ) => {
      state.CustomProtocolMonitoring = action.payload
    },
    setDriverEvents: (state: IDriverCardState, action: PayloadAction<DriverEvents>) => {
      state.driver_events = action.payload
    },
    setAddr: (state: IDriverCardState, action: PayloadAction<number | string>) => {
      state.CustomProtocolMonitoring.addr = action.payload
    },
    setDataSource: (state: IDriverCardState, action: PayloadAction<number | string>) => {
      state.CustomProtocolMonitoring.dataSource = action.payload
    },
    setFunc: (state: IDriverCardState, action: PayloadAction<number>) => {
      state.CustomProtocolMonitoring.func = action.payload
    },
    setRegNo: (state: IDriverCardState, action: PayloadAction<number | string>) => {
      state.CustomProtocolMonitoring.regNo = action.payload
    },
    setUseAsDriverCode: (state: IDriverCardState) => {
      state.CustomProtocolMonitoring.use_as_driver_code =
        !state.CustomProtocolMonitoring.use_as_driver_code
    },
    setEndRegistrationByRemovingCard: (state: IDriverCardState) => {
      state.driver_events.end_registration_by_removing_card =
        !state.driver_events.end_registration_by_removing_card
    },
    setEndRegistrationByTurningOffIgnition: (state: IDriverCardState) => {
      state.driver_events.end_registration_by_turning_off_ignition =
        !state.driver_events.end_registration_by_turning_off_ignition
    },
    setIsDCEnabled: (state: IDriverCardState) => {
      state.driver_events.is_enabled = !state.driver_events.is_enabled
    },
    setRestoreRegistrationIfCardReappliedWithin: (
      state: IDriverCardState,
      action: PayloadAction<number | string>,
    ) => {
      state.driver_events.restore_registration_if_card_reapplied_within = action.payload
    },
  },
})

export const {
  setDriverCardState,
  setCustomProtocolMonitoring,
  setDriverEvents,
  setAddr,
  setDataSource,
  setFunc,
  setRegNo,
  setUseAsDriverCode,
  setEndRegistrationByRemovingCard,
  setEndRegistrationByTurningOffIgnition,
  setIsDCEnabled,
  setRestoreRegistrationIfCardReappliedWithin,
} = rootOSettingsDriverCard.actions

export default rootOSettingsDriverCard.reducer
