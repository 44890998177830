import { ILogPassAuth } from "@/interfaces/auth"
import { returnErrorFetch, serverInstance } from "./settingsApiInstance"

// Авторизация
export const authAPI = async (body: ILogPassAuth) => {
  try {
    const { data, status } = await serverInstance.post("auth/login", {
      user_login: body.login,
      user_password: body.password,
    })
    return { status, data }
  } catch (error) {
    return returnErrorFetch(error)
  }
}

export const codeAuth = async (code: string) => {
  try {
    const { data, status } = await serverInstance.post("auth/confirm", { confirm: code })
    return { data, status }
  } catch (error) {
    return returnErrorFetch(error)
  }
}
