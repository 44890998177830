import { useState } from "react"
import { useAppDispatch } from "src/shared/lib/hooks/store"

import { deleteFuelSensor } from "src/store/reducers/objectSettings/objectSettingsPageSlices/fuel/fuel"
import { IFuelSensor } from "../../../interfaces"
import { CalibrTable } from "./CalibrTable"
import { SensorInfo } from "./SensorInfo"

interface ISensorProp {
  sensor: IFuelSensor
  tankIndex: number
  sensorIndex: number
}
export const SensorFuel = ({ sensor, tankIndex, sensorIndex }: ISensorProp) => {
  const dispatch = useAppDispatch()
  const [viewDelete, setViewDelete] = useState(false)
  return (
    <div
      onMouseEnter={() => setViewDelete(true)}
      onMouseLeave={() => setViewDelete(false)}
      className="w-[10rem] transition-all min-w-[12rem] rounded h-full flex flex-col gap-2 items-start justify-start pt-6 max-h-[22rem] overflow-y-auto remove_scroll relative"
    >
      <div className="h-1/2 border-2 rounded shadow-xl border-black  w-[85%] flex items-start justify-between relative">
        {viewDelete && (
          <div
            onClick={() =>
              dispatch(
                deleteFuelSensor({
                  sensorIndex,
                  tankIndex,
                }),
              )
            }
            className="absolute -right-2 transition-all -top-2 icon-delete z-30 text-red-500 hover:bg-slate-400 hover:shadow-xl active:shadow-2xl active:bg-slate-500 bg-slate-300  text-lg cursor-pointer  p-1 rounded"
          ></div>
        )}
        <div className="w-[49%] text-center text-xl font-light pt-5">{sensor.k_add}</div>
        <div className="w-[1%] h-[10rem] before:shadow-xl relative bottom-[10%] z-20 bg-black before:absolute before:bg-black before:-top-1 before:-left-1.5 before:w-4 before:h-4 before:rounded-full "></div>
        <div className="w-[49%] text-center text-xl font-light pt-5">{sensor.k_mul}</div>
      </div>
      <div className="w-full">
        <SensorInfo info={sensor} tankIndex={tankIndex} sensorIndex={sensorIndex} />
      </div>
      <div className="w-full">
        <div className="w-[85%] py-1 text-center text-lg font-light border border-black">
          Тарировка
        </div>
        <div className="">
          <CalibrTable calibr={sensor.calibr} sensorIndex={sensorIndex} tankIndex={tankIndex} />
        </div>
      </div>
    </div>
  )
}
