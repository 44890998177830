import { TstatusGroup } from "@/shared/types/types"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { changeStatus, checkObjectsPresenceGeozone } from "../../../shared/lib/helpers/driverTree"
import { getGroupAndChildren } from "../../../shared/lib/helpers/treeHelpers"

export interface IDriverData {
  account_id: number
  drivers?: IDriver[]
  group_name: string
  id: number
  is_root: boolean
  level: number
  parent_id: number
  role_id: number
  status?: TstatusGroup
  children?: IDriverData[]
}

export interface IDriver {
  birth_date: number
  comment: string
  dismissal_date: number
  driver_code: number
  driver_uid: string
  employment_date: number
  driver_name: string
  phone: string
}

interface IDriverState {
  driversTree: IDriverData[]
  checkedDrivers: IDriver[]
  search: string
  activeDriver: IDriver | null
  isOpenAll: boolean
}
const initialState: IDriverState = {
  driversTree: [],
  checkedDrivers: [],
  search: "",
  activeDriver: null,
  isOpenAll: false,
}

const driverSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    setIsOpenAll(state: IDriverState, action: PayloadAction<boolean>) {
      state.isOpenAll = action.payload
    },
    setActiveDriver: (state: IDriverState, action: PayloadAction<IDriver>) => {
      state.activeDriver = action.payload
    },
    setDriversData: (state: IDriverState, action: PayloadAction<IDriverData[]>) => {
      state.driversTree = action.payload
    },
    addDriverGroupTree(state: IDriverState, action: PayloadAction<IDriverData>) {
      state.driversTree = [...state.driversTree, { ...action.payload, status: "none" }]
    },
    deleteDriverGroupTree(state: IDriverState, action: PayloadAction<number>) {
      state.driversTree = state.driversTree.filter((group) => group.id === action.payload)
    },
    addDriverChecked(state: IDriverState, action: PayloadAction<{ driver: IDriver; id: number }>) {
      if (!state.driversTree) return
      const allChecked: IDriver[] = [...state.checkedDrivers, action.payload.driver]

      const targetObject: any = state.driversTree.find((obj) => {
        return obj.id === action.payload.id
      })
      // Проверить, все ли транспортные средства этого объекта присутствуют в массиве checkedVehicle
      const allVehiclesCheckedGroup =
        checkObjectsPresenceGeozone(targetObject.drivers, allChecked) === "all"
      // Проверить, установлен ли статус "all" у всех дочерних элементов объекта

      const allChildrenStatusAll = state.driversTree
        .filter((obj) => obj.parent_id === targetObject?.id)
        .every((child) => child.status === "all")

      if (allVehiclesCheckedGroup && allChildrenStatusAll) {
        // Статус all если все отмечены и все дочерние all
        changeStatus(state.driversTree, action.payload.id, "all")
      } else {
        // Статус some при любом добавление
        changeStatus(state.driversTree, action.payload.id, "some")
      }

      state.checkedDrivers = allChecked
    },
    deleteDriverChecked(
      state: IDriverState,
      action: PayloadAction<{ driver: IDriver; id: number }>,
    ) {
      if (!state.driversTree) return

      const allChecked = state.checkedDrivers.filter(
        (driver: IDriver) => driver.driver_uid !== action.payload.driver.driver_uid,
      )

      const targetObject: any = state.driversTree.find((obj) => obj.id === action.payload.id)

      // Проверяем, отсутствуют ли все транспортные средства этой группы в массиве checkedVehicle
      const missingVehicles =
        checkObjectsPresenceGeozone(targetObject.drivers, allChecked) === "none"

      // Проверить, установлен ли статус "none" у всех дочерних элементов объекта
      const allChildrenStatusAll = state.driversTree
        .filter((obj) => obj.parent_id === targetObject?.id)
        .every((child) => child.status === "none")

      if (missingVehicles && allChildrenStatusAll) {
        // Статус none если отсутствуют && все дочерние none
        changeStatus(state.driversTree, action.payload.id, "none")
      } else {
        changeStatus(state.driversTree, action.payload.id, "some")
      }

      state.checkedDrivers = allChecked
    },
    groupChecked(state: IDriverState, action: PayloadAction<IDriverData>) {
      if (!state.driversTree) return

      const groupAndChildren = getGroupAndChildren(state.driversTree, action.payload.id)

      let allDrivers: any = groupAndChildren
        .map((el) => (el.drivers ? el.drivers : []))
        .reduce((acc, val) => acc.concat(val), [])
        .map((el) => ({ ...el }))

      allDrivers = action.payload.drivers ? [...allDrivers, ...action.payload.drivers] : allDrivers

      if (action.payload.status === "none" || action.payload.status === "some") {
        state.checkedDrivers = [...allDrivers, ...state.checkedDrivers]
        changeStatus(state.driversTree, action.payload.id, "all")
        state.driversTree.forEach((el) => {
          if (groupAndChildren.some((group) => group.id == el.id)) {
            el.status = "all"
          }
        })
      }
      if (action.payload.status === "all") {
        // Фильтруем исходные массивы, чтобы получить только уникальные элементы
        const uniqueElements1 = allDrivers.filter(
          (item) => !state.checkedDrivers.some((el) => el.driver_uid === item.driver_uid),
        )
        const uniqueElements2 = state.checkedDrivers.filter(
          (item) => !allDrivers.some((el) => el.driver_uid === item.driver_uid),
        )

        // Объединяем все уникальные элементы в один массив
        const result =
          uniqueElements1 && uniqueElements2 ? [...uniqueElements1, ...uniqueElements2] : []

        state.checkedDrivers = result
        changeStatus(state.driversTree, action.payload.id, "none")
        state.driversTree.forEach((el) => {
          if (groupAndChildren.some((group) => group.id == el.id)) {
            el.status = "none"
          }
        })
        return
      }
    },
    setSearchDriver(state: IDriverState, action: PayloadAction<string>) {
      state.search = action.payload
    },
    setCheckedDriver(state: IDriverState, action: PayloadAction<IDriver[]>) {
      state.checkedDrivers = action.payload
    },
  },
})

export const {
  setIsOpenAll,
  setActiveDriver,
  setDriversData,
  addDriverChecked,
  deleteDriverChecked,
  groupChecked,
  setSearchDriver,
  setCheckedDriver,
  addDriverGroupTree,
  deleteDriverGroupTree,
} = driverSlice.actions

export default driverSlice.reducer
