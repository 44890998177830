import { IClientInfo } from "@/components/Settings/ProfileSettings/Users/ClientSettings/ClientSettings"
import { AppDispatch } from "@/store/store"
import {
  createUpdateFolder,
  createUpdateUser,
  createUpdateUserTreeFolder,
  deleteFolder,
  deleteUser,
  deleteUserTreeFolder,
  getDriversFolders,
  getFolderInfo,
  getGeoZonesFolders,
  getGSUser,
  getGSUsersTree,
  getObjectsFolders,
  getObjectsFromServer,
  getUserClientInfo,
  getUserTreeFolder,
  IGroupCreate,
  IPostUserTreeFolderProps,
  saveUserClientInfo,
} from "src/shared/api/apiGlobal"
import { setIsAuth } from "../auth/authSlice"
import { driverTreeAPI } from "./../../../shared/api/driverTreeApi"
import {
  IFolderData,
  setAccountName,
  setFolderData,
  setGSettingsItemSettingsID,
  setGSettingsObjects,
  setGSFolderClientData,
  setGSItemData,
  setGSUserClientId,
  setNewHandleId,
  setPredefinedHandleID,
  setTreeWithoutItem,
  setUserId,
  setUsersTreeFolderInfo,
  setUsersTreeFolderNull,
} from "./globalSettings"

export const thunkGetObjectsFromServer = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGSettingsObjects({ data: "loading", field: "objects" }))
    const { status, objects } = await getObjectsFromServer()

    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setGSettingsObjects({ data: objects, field: "objects" }))
        dispatch(setPredefinedHandleID("objects"))
        dispatch(setAccountName(objects.data[0].group_name))
        break
      case 403:
        dispatch(setGSettingsObjects({ data: "error", field: "objects" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
    }
  }
}

export const thunkGetDriversFromServer = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGSettingsObjects({ data: "loading", field: "drivers" }))
    const data = await driverTreeAPI.getTreeDrivers()
    dispatch(setGSettingsObjects({ data: data, field: "drivers" }))
    // switch (status) {
    //    case 401:
    //       dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
    //       break
    //    case 200:
    //       dispatch(setGSettingsObjects({ data: data, field: "drivers" }))
    //       break
    //    case 403:
    //       dispatch(setGSettingsObjects({ data: "error", field: "drivers" }))
    //       break
    //    case 500:
    //       dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
    // }
  }
}

export const thunkCreateUpdateFolder = (body: IGroupCreate) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setFolderData({ data: null, status: "loading" }))

    const { status, data } = await createUpdateFolder(body)

    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setFolderData({ status: "success", id: data?.id }))
        dispatch(thunkGetObjectsFromServer())
        break
      case 403:
        dispatch(setFolderData({ data: null, status: "error" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
    }
  }
}

interface IGetFolderData {
  id: number
}

export const thunkGetFolder = ({ id }: IGetFolderData) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setFolderData({ data: null, status: "loading" }))
    const { status, data } = await getFolderInfo(id)

    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setFolderData({ status: "update", data: data.data as IFolderData }))
        dispatch(thunkGetObjectsFromServer())
        break
      case 403:
        dispatch(setFolderData({ data: null, status: "error" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
    }
  }
}

export const thunkDeleteFolder = (id) => {
  return async (dispatch: AppDispatch) => {
    const { status } = await deleteFolder(id)

    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(thunkGetObjectsFromServer())
        dispatch(setFolderData({ status: "success delete" }))
        break
      case 403:
        dispatch(setFolderData({ data: null, status: "forbidden" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        break
    }
  }
}

export const thunkGetUsers = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGSettingsObjects({ data: "loading", field: "users" }))
    const { data, status } = await getGSUsersTree()

    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setGSettingsObjects({ data: data, field: "users" }))
        dispatch(setPredefinedHandleID("users"))
        break
      case 403:
        dispatch(setGSettingsObjects({ data: "error", field: "users" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        break
    }
  }
}

export const thunkGetUserInfo = (id: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGSItemData({ data: "loading", field: "users" }))
    const { data, status } = await getGSUser(id)
    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setGSItemData({ data: data.data, field: "users" }))
        // dispatch(setGSUserAccountId(accountId))
        break
      case 400:
        dispatch(setGSItemData({ data: "error", field: "users" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        break
    }
  }
}

export const thunkGetUserFolders = () => {
  return async (dispatch: AppDispatch) => {
    async function getVehiclesF() {
      const { vehiclesFolders, status } = await getObjectsFolders()
      switch (status) {
        case 401:
          dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
          break
        case 200:
          dispatch(setTreeWithoutItem({ data: vehiclesFolders.data, field: "objects" }))
          break
        case 400:
          dispatch(setTreeWithoutItem({ data: "error", field: "objects" }))
          break
        case 500:
          dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
          break
      }
    }
    async function getDriversF() {
      const { driversFolders, status } = await getDriversFolders()
      switch (status) {
        case 401:
          dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
          break
        case 200:
          dispatch(setTreeWithoutItem({ data: driversFolders.data, field: "drivers" }))
          break
        case 400:
          dispatch(setTreeWithoutItem({ data: "error", field: "drivers" }))
          break
        case 500:
          dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
          break
      }
    }
    async function getGeoZonesF() {
      const { geoZonesFolders, status } = await getGeoZonesFolders()
      switch (status) {
        case 401:
          dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
          break
        case 200:
          dispatch(setTreeWithoutItem({ data: geoZonesFolders.data, field: "users" }))
          break
        case 400:
          dispatch(setTreeWithoutItem({ data: "error", field: "users" }))
          break
        case 500:
          dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
          break
      }
    }

    await getGeoZonesF()
    await getDriversF()
    await getVehiclesF()
  }
}

interface ICUGSU {
  data: any
  accountID: number
  level: number
}
export const thunkCreateUpdateUser = ({ data, accountID, level }: ICUGSU) => {
  return async (dispatch: AppDispatch) => {
    let a = JSON.parse(JSON.stringify(data))
    a.account_id = accountID
    a.create_date = undefined
    a.rights = 63
    dispatch(setGSItemData({ data: null, field: "users" }))
    const { id, status } = await createUpdateUser({ data: a })
    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setGSettingsItemSettingsID({ id: null, field: "users" }))
        dispatch(setUserId({ id, level: level + 1 }))
        dispatch(thunkGetUsers())

        break
      case 400:
        dispatch(setUserId({ id, level: level }))
        break
      case 403:
        dispatch(setGSettingsItemSettingsID({ id: null, field: "users" }))
        dispatch(setUserId({ id, level: level }))
        break
      case 500:
        dispatch(setGSettingsItemSettingsID({ id: null, field: "users" }))
        dispatch(setUserId({ id, level: level + 1 }))
        dispatch(thunkGetUsers())
        // dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        break
    }
  }
}

export const thunkDeleteUser = (id: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGSItemData({ data: null, field: "users" }))
    const { status } = await deleteUser(id)
    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(thunkGetUsers())
        break
      case 400:
        dispatch(setGSItemData({ data: "error", field: "users" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        break
    }
  }
}
export const thunkGetClient = (id: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGSItemData({ data: null, field: "users" }))
    const { data, status } = await getUserClientInfo(id)
    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setGSFolderClientData(data))

        break
      case 400:
        dispatch(setGSItemData({ data: "error", field: "users" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        break
    }
  }
}
export const thunkSaveClient = (body: IClientInfo) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGSItemData({ data: null, field: "users" }))
    let rBody = JSON.parse(JSON.stringify(body))
    rBody.create_date = undefined
    rBody.role_id = 3
    const { status } = await saveUserClientInfo(rBody)
    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(setGSUserClientId(null))
        dispatch(setGSFolderClientData(null))
        dispatch(thunkGetUsers())

        break
      case 400:
        dispatch(setGSItemData({ data: "error", field: "users" }))
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        break
    }
  }
}

export const thunkGetUsersTreeFolder = (id: number) => {
  return async (dispatch: AppDispatch) => {
    const { data, status } = await getUserTreeFolder(id)
    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        dispatch(setUsersTreeFolderNull())
        break
      case 200:
        dispatch(setUsersTreeFolderInfo(data))
        dispatch(thunkGetUsers())

        break
      case 400:
        dispatch(setGSItemData({ data: "error", field: "users" }))
        dispatch(setUsersTreeFolderNull())
        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        dispatch(setUsersTreeFolderNull())
        break
    }
  }
}

export const thunkCreateUpdateUsersTreeFolder = (body: IPostUserTreeFolderProps, index: number) => {
  return async (dispatch: AppDispatch) => {
    const { data, status } = await createUpdateUserTreeFolder(body)
    switch (status) {
      case 401:
        dispatch(setUsersTreeFolderNull())
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        if (data.id == 0) {
          dispatch(
            setNewHandleId({
              id: data.id,
              index: index + 1,

              field: "users",
            }),
          )
        }

        dispatch(setUsersTreeFolderNull())
        dispatch(thunkGetUsers())

        break
      case 400:
        dispatch(setUsersTreeFolderNull())
        dispatch(setGSItemData({ data: "error", field: "users" }))
        break
      case 500:
        dispatch(setUsersTreeFolderNull())
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        break
    }
  }
}

export const thunkDeleteUsersTreeFolder = (id: number) => {
  return async (dispatch: AppDispatch) => {
    const { status } = await deleteUserTreeFolder(id)
    dispatch(setUsersTreeFolderNull())
    switch (status) {
      case 401:
        dispatch(setIsAuth({ auth: "error", text: "Сессия завершена" }))
        break
      case 200:
        dispatch(thunkGetUsers())

        break
      case 400:
        dispatch(setGSItemData({ data: "error", field: "users" }))
        break
      case 403:
        dispatch(setGSItemData({ data: "error", field: "users" }))

        break
      case 500:
        dispatch(setIsAuth({ auth: "error", text: "Ошибка сервера" }))
        break
    }
  }
}
