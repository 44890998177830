import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ISwitchHistoryData } from "@/interfaces/switchHistory"
import { DateTime } from "ts-luxon"

interface ISwitchTireState {
  switchHistoryTire: ISwitchHistoryData[] | null
  activeHistoryTire: { tire: ISwitchHistoryData; index: number } | null

  modelTireName: null | string
  modelTireID: null | number
  reasonReplacement: string
  dateSwitch: number
  wheelId: number | null

  isSelectCatalog: boolean
  startDateHistory: number
  endDateHistory: number

  isEdit: boolean
}

export interface ISwitchReq {
  id: number
  vehicle_uid: string
  wheel_model_id: number
  wheel_id: number
  start_date: number
  reason_replacement: string
}

const initialState: ISwitchTireState = {
  switchHistoryTire: null,
  activeHistoryTire: null,
  modelTireName: null,
  modelTireID: null,
  reasonReplacement: "",
  dateSwitch: Math.trunc(DateTime.local().toSeconds()),
  wheelId: null,
  isSelectCatalog: false,
  startDateHistory: 0,
  endDateHistory: 0,
  isEdit: false,
}
const switchTireSlice = createSlice({
  name: "switchTire",
  initialState,
  reducers: {
    setEdit(state: ISwitchTireState, action: PayloadAction<boolean>) {
      state.isEdit = action.payload
    },
    setStartDateHistory(state: ISwitchTireState, action: PayloadAction<number>) {
      state.startDateHistory = action.payload
    },
    setEndDateHistory(state: ISwitchTireState, action: PayloadAction<number>) {
      state.endDateHistory = action.payload
    },
    setIsSelectCatalog: (state: ISwitchTireState, action: PayloadAction<boolean>) => {
      state.isSelectCatalog = action.payload
    },
    setWheelIdSwitchTire: (state: ISwitchTireState, action: PayloadAction<number | null>) => {
      state.wheelId = action.payload
    },
    setModelTireName: (state: ISwitchTireState, action: PayloadAction<string | null>) => {
      state.modelTireName = action.payload
    },
    setModelTireID: (state: ISwitchTireState, action: PayloadAction<number | null>) => {
      state.modelTireID = action.payload
    },
    setReasonReplacement: (state: ISwitchTireState, action: PayloadAction<string>) => {
      state.reasonReplacement = action.payload
    },
    setDataSwitch: (state: ISwitchTireState, action: PayloadAction<number>) => {
      state.dateSwitch = Math.trunc(action.payload)
    },
    setSwitchHistoryData: (
      state: ISwitchTireState,
      action: PayloadAction<ISwitchHistoryData[] | null>,
    ) => {
      state.switchHistoryTire = action.payload
    },
    setRemoveSwitchHistory: (state: ISwitchTireState, action: PayloadAction<number>) => {
      state.switchHistoryTire = state.switchHistoryTire
        ? state.switchHistoryTire.filter((item) => item.id !== action.payload)
        : null
    },
    setActiveTireHistory: (
      state: ISwitchTireState,
      action: PayloadAction<{ tire: ISwitchHistoryData; index: number } | null>,
    ) => {
      state.activeHistoryTire = action.payload
    },
    setActiveTireIDHistory: (state: ISwitchTireState, action: PayloadAction<number>) => {
      if (!state.switchHistoryTire) return
      const index = state.switchHistoryTire?.findIndex((el) => el.id === action.payload)
      state.activeHistoryTire = { tire: state.switchHistoryTire[index], index: index }
    },
  },
})
export const {
  setSwitchHistoryData,
  setRemoveSwitchHistory,
  setActiveTireHistory,
  setModelTireName,
  setModelTireID,
  setReasonReplacement,
  setWheelIdSwitchTire,
  setDataSwitch,
  setActiveTireIDHistory,
  setIsSelectCatalog,
  setStartDateHistory,
  setEdit,
  setEndDateHistory,
} = switchTireSlice.actions
export default switchTireSlice.reducer
