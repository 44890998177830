import React from "react"

export const GeozonesIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 7.54545C17 12.6364 9 17 9 17C9 17 1 12.6364 1 7.54545C1 5.80949 1.84285 4.14463 3.34315 2.91712C4.84344 1.68961 6.87827 1 9 1C11.1217 1 13.1566 1.68961 14.6569 2.91712C16.1571 4.14463 17 5.80949 17 7.54545Z"
        stroke="#474747"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 9.72727C10.4728 9.72727 11.6667 8.75044 11.6667 7.54545C11.6667 6.34047 10.4728 5.36364 9 5.36364C7.52724 5.36364 6.33333 6.34047 6.33333 7.54545C6.33333 8.75044 7.52724 9.72727 9 9.72727Z"
        stroke="#474747"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
