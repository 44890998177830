import React, { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { Checkbox } from "src/shared/ui/Checkbox"
import { setIsDiagEnabled } from "src/store/reducers/objectSettings/objectSettingsPageSlices/diag/diag"
import styles from "../styles/os-item.module.css"

export const Diag: React.FC<any> = React.memo((diag) => {
  const is_enabled = useAppSelector((state) => state.osDiag.is_enabled)
  const dispatch = useAppDispatch()

  const handleEnable = useCallback(() => dispatch(setIsDiagEnabled()), [])

  return (
    <div className={styles.os_main}>
      <h1 className="">Контроль неисправностей</h1>
      <div className="grid w-1/2 mx-auto grid-cols-2 text-[14px]  gap-y-5 gap-x-32  justify-center text-right items-center">
        <p>Контроль</p>
        <div className="w-fit">
          <Checkbox checked={is_enabled} setChecked={handleEnable} />
        </div>
      </div>
    </div>
  )
})
