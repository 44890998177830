import React, { useEffect, useState } from "react"
import "./ErrorPopupTimer.css"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { removeFirstError } from "../../store/reducers/globalErrors/globalErrorsSlice"

export const ErrorPopupTimer = () => {
  const dispatch = useAppDispatch()

  const errors = useAppSelector((state) => state.globalErrors.errors)

  const [currentError, setCurrentError] = useState({ message: "", seconds: 0 })
  const [isOpen, setIsOpen] = useState(false)
  const [seconds, setSeconds] = useState(currentError.seconds)

  useEffect(() => {
    if (!errors.length) return
    setIsOpen(true)
    setCurrentError(errors[0])
    setSeconds(errors[0].seconds)
    if (!errors[0].seconds) return
    const time = errors[0].seconds * 1000

    const timerMessage = setInterval(() => {
      dispatch(removeFirstError())
      setCurrentError({ message: "", seconds: 0 })
      setIsOpen(false)
    }, time)
    return () => clearTimeout(timerMessage)
  }, [errors])

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1)
    }, 1000)
    return () => clearInterval(timer)
  }, [currentError])

  return (
    <>
      {isOpen && (
        <>
          <div className="error-popup-timer">
            <div className="error-popup-timer__title">
              Ошибка <span>{seconds}</span>
            </div>
            <div className="error-popup-timer__message">{currentError.message}</div>
          </div>
          {!currentError.seconds && <div className="error-popup-timer__overlay"></div>}
        </>
      )}
    </>
  )
}
