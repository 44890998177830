import { organizeDrivers } from "src/shared/ui/SettingsTree/driverHelper"
import { findPath } from "src/shared/ui/SettingsTree/helpers/findPath"

export function driversSearchHelper(data: any[], searchTerm: string): any[] {
  const lowerSearchTerm = searchTerm.toLowerCase()

  // Получаем организованные уровни водителей
  const levelDrivers: any = organizeDrivers(data)

  const pathTest = findPath(levelDrivers, "c493f3ab-c100-40e8-2b7d-e6fcd60ab598", false)

  // Flatten driver data and build the hierarchy with paths
  function flattenDriverData(levels: any[][]): any[] {
    const result: any[] = []
    const itemMap: { [id: string]: any } = {}

    function addItem(item: any, parent: any = null, level: number = 1): void {
      // Пропускать элементы с role_id 1 или 2
      if (item.role_id === 1 || item.role_id === 2) {
        return
      }

      // Используем findPath для построения пути
      const path = findPath(levelDrivers, item.id, item.is_folder)

      const folder: any = {
        ...item,
        name: item.name || "",
        children: [],
        path: path, // Присваиваем путь, сформированный с помощью findPath
        is_folder: item.is_folder, // true для папок
      }

      // Добавляем элемент в карту
      itemMap[item.id] = folder

      // Добавляем элемент в результат
      result.push(folder)
    }

    // Проходим по уровням, начиная с первого
    levels.forEach((levelData, levelIndex) => {
      levelData.forEach((item: any) => {
        // Для каждого элемента вызываем addItem, передавая родителя и уровень
        const parent = itemMap[item.parent_id]
        addItem(item, parent, levelIndex + 1)
      })
    })

    return result
  }

  // Обрабатываем данные по уровням
  const flattened = flattenDriverData(levelDrivers)

  console.log("Результат flattenDriverData:", flattened)

  // Фильтрация водителей и групп по поисковому запросу
  const returnedArr = flattened.filter((item) => item.name.toLowerCase().includes(lowerSearchTerm))

  return returnedArr
}
