import React from "react"
import { TstatusGroup } from "../../../shared/types/types"

type propsType = {
  isOpen: boolean
  onChecked: () => void
  title: string
  status: TstatusGroup
  onClickFolder: () => void
}

export const FolderCheckboxTitle: React.FC<propsType> = ({
  isOpen,
  onChecked,
  title,
  status,
  onClickFolder,
}) => {
  return (
    <div className="foldercheckbox-head">
      <label
        className={`foldercheckbox-head__checkbox ${status !== "none" ? "foldercheckbox-head__checkbox--checked" : ""}`}
      >
        <div
          className={status === "all" ? "icon-checked" : status === "some" ? "icon-minus" : ""}
        ></div>
        <input type="checkbox" checked={status !== "none"} onChange={() => onChecked()} />
      </label>

      <div className="foldercheckbox-head__title" onClick={() => onClickFolder()}>
        <span className={isOpen ? "icon-folder-open" : "icon-folder"}></span>
        {title}
      </div>
    </div>
  )
}
