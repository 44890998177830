import { IDriverFolder } from "@/interfaces/settingsDriver"
import { useEffect, useState } from "react"
import { TextareaComponent } from "src/widgets/ObjectSettings/Content/helpComponents/TextareaComponent"
import { useAppDispatch, useAppSelector } from "./../../../../../shared/lib/hooks/store"
import { setGSPageFolderData } from "src/store/reducers/globalSettings/globalSettings"
import { thunkSaveDriversFolder } from "src/store/reducers/globalSettings/itemsThunks/driverThunk"

export const DriverFolderSettings = () => {
  const dispatch = useAppDispatch()
  const folder: IDriverFolder = useAppSelector(
    (state) => state.globalSettings.tree.drivers.folderSettings.folderSettingsData,
  )
  const [folderName, setFolderName] = useState<string>("")
  useEffect(() => {
    setFolderName(folder.group_name)
  }, [])

  const onSubmit = () => {
    if (folderName.length == 0) return
    let newFolder: IDriverFolder = JSON.parse(JSON.stringify(folder))
    newFolder.group_name = folderName
    dispatch(thunkSaveDriversFolder(newFolder))
  }
  return (
    <div className="absolute left-0 top-0 backdrop-blur-lg z-20 w-screen h-screen flex items-center justify-center">
      {folder ? (
        <div className="bg-white relative rounded px-10 py-3 shadow-xl min-w-[30%]">
          <div
            onClick={() =>
              dispatch(
                setGSPageFolderData({
                  folderData: null,
                  field: "drivers",
                }),
              )
            }
            className="absolute cursor-pointer right-0 top-0 p-2 text-red-500 hover:text-red-600 active:text-red-800 icon-cross"
          ></div>
          <h3 className="font-light text-xl text-center">
            {folder.id == 0 ? "Создать" : "Изменить"} папку
          </h3>
          <div className="my-8 flex items-center gap-3 justify-center">
            <p className="font-light">Наименование</p>
            <TextareaComponent
              placeholder="Новая папка"
              width={"70%"}
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
          </div>
          <div className="w-full flex items-center justify-center">
            <div
              className="bg-orange-500 hover:bg-orange-600 text-white font-light rounded active:bg-orange-700 px-10 py-2 shadow-xl transition-all"
              onClick={onSubmit}
            >
              Сохранить
            </div>
          </div>
        </div>
      ) : (
        <div>Загрузка...</div>
      )}
    </div>
  )
}
