export function organizeByLevelDrivers(data) {
  const levels: any = []

  data.data?.forEach((item) => {
    const levelIndex = item.level - 1
    if (!levels[levelIndex]) {
      levels[levelIndex] = []
    }
    const newItem = { ...item, name: item.group_name }
    delete newItem.group_name
    levels[levelIndex].push(newItem)
  })

  const maxLevel = levels.length

  // Добавляем водителей на следующий уровень (кроме уровня 1)
  for (let level = 0; level < maxLevel; level++) {
    // Пропускаем уровень 1

    if (!levels[level + 1]) {
      levels[level + 1] = []
    }

    levels[level] = levels[level]?.map((item) => {
      if (item.drivers) {
        item.drivers.forEach((driver) => {
          const driverItem = {
            ...driver,
            parent_id: item.id,
            name: driver.driver_name,
            driver_uid: driver.driver_uid,
          }
          delete driverItem.driver_name
          levels[level + 1].push(driverItem)
        })
        return { ...item, drivers: null }
      }
      return item
    })
  }

  // Добавляем handle_id к каждому массиву на выходе
  return levels.map((level, index) => {
    return {
      level: index,
      handle_id: null,
      data: level,
    }
  })
}
export function organizeByLevelObjects(data) {
  const levels: any = []

  // Организуем данные по уровням
  data.forEach((item) => {
    const levelIndex = item.level - 1
    if (!levels[levelIndex]) {
      levels[levelIndex] = []
    }
    const newItem = { ...item, name: item.vehicle_name }
    delete newItem.vehicle_name
    levels[levelIndex].push(newItem)
  })

  const maxLevel = levels.length

  // Добавляем объекты на следующий уровень (кроме уровня 1)
  for (let level = 0; level < maxLevel; level++) {
    if (!levels[level + 1]) {
      levels[level + 1] = []
    }

    levels[level] = levels[level]?.map((item) => {
      if (item.vehicles) {
        item.vehicles.forEach((vehicle) => {
          const vehicleItem = {
            ...vehicle,
            parent_id: item.id,
            name: vehicle.vehicle_name,
            vehicle_uid: vehicle.vehicle_uid,
          }
          delete vehicleItem.vehicle_name
          levels[level + 1].push(vehicleItem)
        })
        return { ...item, vehicles: null }
      }
      return item
    })
  }

  // Добавляем handle_id к каждому массиву на выходе
  return levels.map((level, index) => {
    return {
      level: index,
      handle_id: null,
      data: level,
    }
  })
}
export function organizeByLevelAccounts(data) {
  const levels: any[] = []

  // Organize data by levels

  data?.forEach((item) => {
    const levelIndex = item.level - 1
    if (!levels[levelIndex]) {
      levels[levelIndex] = []
    }
    const newItem = { ...item, name: item.account_name }
    delete newItem.account_name
    levels[levelIndex].push(newItem)
  })

  const maxLevel = levels.length

  // Add users to the next level (except level 1)
  for (let level = 0; level < maxLevel; level++) {
    if (!levels[level + 1]) {
      levels[level + 1] = []
    }

    levels[level] = levels[level]?.map((item) => {
      if (item.users) {
        item.users.forEach((user) => {
          const userItem = {
            ...user,
            parent_id: item.id,
            name: user.user_name,
            user_uid: user.id,
          }
          delete userItem.user_name
          levels[level + 1].push(userItem)
        })
        return { ...item, users: null }
      }
      return item
    })
  }

  // Add handle_id to each output array
  return levels.map((level, index) => {
    return {
      level: index + 1,
      handle_id: null,
      data: level,
    }
  })
}
export function organizeByLevelGeoZones(data) {
  const levels: any = []

  // Организуем данные по уровням
  Array.isArray(data) &&
    data.forEach((item) => {
      const levelIndex = item.level - 1
      if (!levels[levelIndex]) {
        levels[levelIndex] = []
      }
      const newItem = { ...item, name: item.group_name }
      delete newItem.group_name
      levels[levelIndex].push(newItem)
    })

  const maxLevel = levels.length

  // Добавляем группы на следующий уровень (кроме уровня 1)
  for (let level = 0; level < maxLevel; level++) {
    if (!levels[level + 1]) {
      levels[level + 1] = []
    }

    levels[level] = levels[level]?.map((item) => {
      const children = data.filter((child) => child.parent_id === item.id)
      if (children.length > 0) {
        children.forEach((child) => {
          const childItem = {
            ...child,
            parent_id: item.id,
            name: child.group_name,
          }
          delete childItem.group_name
          levels[level + 1].push(childItem)
        })
      }
      return item
    })
  }

  // Добавляем handle_id к каждому массиву на выходе
  return levels.map((level, index) => {
    return {
      level: index + 1,
      handle_id: null,
      data: level,
    }
  })
}
export function buildByLevel(data) {
  const dataMap = new Map()
  let root = null

  // Создаем карту элементов по их id
  data.forEach((item) => dataMap.set(item.id, item))

  // Проходим по каждому элементу и добавляем его к соответствующему родительскому элементу
  data.forEach((item) => {
    if (item.parent_id === null) {
      root = item
    } else {
      const parent = dataMap.get(item.parent_id)
      if (!parent.children) {
        parent.children = []
      }
      parent.children.push(item)
    }
  })

  return root
}
