import { TPageVariant } from "src/store/reducers/globalSettings/globalSettings"

export interface INavbarGSettingsItem {
  name: TPageVariant | "test"
  title: string
}

export const navbarItems: INavbarGSettingsItem[] = [
  {
    name: "objects",
    title: "Объекты",
  },
  {
    name: "drivers",
    title: "Водители",
  },
  {
    name: "users",
    title: "Пользователи",
  },
  // {
  //   name: "test",
  //   title: "Тест комп.",
  // },
]
