import { ICatalog } from "src/store/reducers/catalogTire/catalogTire"

export const filterByCatalogName = (dataArray: ICatalog[], searchTerm: string) => {
  // If searchTerm is empty or contains only whitespace, return the original dataArray
  if (!searchTerm.trim()) {
    return dataArray
  }

  // Escape special characters in searchTerm to create a valid regex
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  const regex = new RegExp(escapedSearchTerm, "i")

  // Filter the dataArray based on the regex
  return dataArray.filter((item) => regex.test(item.name))
}
