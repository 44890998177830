import React, { useEffect, useMemo, useState } from "react"
import { PolylineDecorator } from "../PolylineDecorator"
import { Marker, Polyline, useMapEvent } from "react-leaflet"
import { latLng } from "leaflet"
import { useSelector } from "react-redux"
import { RootState } from "@/store/store"
import L from "leaflet"
import { DateTime } from "ts-luxon"
import { setPointInfo } from "../../../store/reducers/mapTrack/mapTrack"
import { useAppDispatch } from "../../lib/hooks/store"

type propsType = {
  points: any
  pathOptions: any
  eventHandlers: {
    contextmenu: (e) => void
    click: (e) => void
    locationfound?: (e) => void
  }
  idTrack: number
}

export const SwitchDecoratorLine: React.FC<propsType> = ({
  points,
  eventHandlers,
  pathOptions,
  idTrack,
}) => {
  const dispatch = useAppDispatch()
  const [isView, setIsView] = useState(true)
  const pointInfo = useSelector((state: RootState) => state.mapTrack.pointInfo)
  const [isOpen, setIsOpen] = useState(false)
  const positions = points.map((i) => [i.lt, i.ln])

  const zoomCurrent = useMemo(() => {
    let distance = 0
    positions.forEach((point, index) => {
      if (index === positions.length - 1) return
      distance = distance + latLng(point).distanceTo(positions[index + 1])
    })

    if (distance < 1000) {
      return 15
    }
    if (distance < 1500) {
      return 14
    }
    if (distance < 15000) {
      return 10
    }
    if (distance < 150000) {
      return 7
    }
    if (distance < 500000) {
      return 5
    }
    if (distance < 1000000) {
      return 3
    }
    if (distance < 1200000) {
      return 1
    }
    return 0
  }, [points])

  useMapEvent("zoom", (e) => {
    if (e.target.getZoom() < zoomCurrent) {
      setIsView(false)
      return
    }
    setIsView(true)
  })

  useMapEvent("click", () => {
    dispatch(setPointInfo(null))
    setIsOpen(false)
  })

  useEffect(() => {
    setIsOpen(true)
  }, [pointInfo])

  const customIcon = () =>
    L.divIcon({
      html: `<div>
      <b>${pointInfo.vehicle_name}</b>
      <br/>
      Время: ${DateTime.fromSeconds(pointInfo.event_date).toFormat("dd LLL yyyy, HH:mm:ss")}
      <br/>
      Скорость: ${Math.round(pointInfo.speed)} км/ч
      </div>`,
      className: "popup-track",
    })

  return (
    <>
      {pointInfo && isOpen && (
        <Marker position={[pointInfo.latitude, pointInfo.longitude]} icon={customIcon()} />
      )}
      {isView ? (
        <PolylineDecorator
          positions={positions}
          pathOptions={pathOptions}
          eventHandlers={eventHandlers}
        />
      ) : (
        <Polyline positions={positions} pathOptions={pathOptions} eventHandlers={eventHandlers} />
      )}
    </>
  )
}
