import { IGeozoneObject, IGeozoneTree } from "@/interfaces/geozoneTree"
import { getStatus } from "./treeHelpers"

export const checkObjectsPresenceGeozone = (
  geozoneGroup: IGeozoneObject[],
  geozoneChecked: IGeozoneObject[],
) => {
  // Создаем множество (Set) из идентификаторов объектов в vehicleChecked для быстрого поиска
  const checkedIds = new Set(geozoneChecked.map((geozone) => geozone.geozone_uid))

  // Проверяем наличие объектов из vehicleGroup в vehicleChecked
  const containAll = geozoneGroup.every((geozone) => checkedIds.has(geozone.geozone_uid))

  const containSome = geozoneGroup.some((geozone) => checkedIds.has(geozone.geozone_uid))

  // Возвращаем соответствующий результат
  return getStatus(containAll, containSome)
}

export const changeStatus = (objects: any[], objectId: number, newStatus: string) => {
  const object = objects.find((obj) => obj.id === objectId)
  if (object) {
    object.status = newStatus
    setStatusGroup(object, objects, newStatus)
  }
}

export const setStatusGroup = (object: IGeozoneTree, objects: IGeozoneTree[], newStatus) => {
  const parentId = object.parent_id
  if (parentId) {
    const hasChildren = objects.some((obj) => obj.parent_id === parentId)
    const hasVehicles = objects.some(
      (obj) => obj.parent_id === parentId && obj.geozones && obj.geozones.length > 0,
    )
    if (hasChildren || hasVehicles) {
      const parentObject = objects.find((obj) => obj.id === parentId)
      if (parentObject) {
        if (newStatus === "none") {
          const parentObjectHasChildren = objects
            .filter((obj) => obj.parent_id === parentId)
            .filter((obj) => obj.status === "some" || obj.status === "all").length
          if (!parentObjectHasChildren) {
            parentObject.status = "none"
          }
          setStatusGroup(parentObject, objects, newStatus)
        } else {
          parentObject.status = "some"
        }
      }
      if (parentObject?.parent_id) {
        setStatusGroup(parentObject, objects, newStatus)
      }
    } else {
      changeStatus(objects, parentId, newStatus)
    }
  }
}

export const filterByGeozoneName = (dataArray: IGeozoneTree[], searchTerm: string) => {
  if (!!!searchTerm) {
    return dataArray
  }

  // Создаем регулярное выражение для поиска, не чувствительное к регистру
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  const regex = new RegExp(escapedSearchTerm, "i")

  const parentId: number[] = []

  // Фильтруем массив data
  return dataArray
    .map((item) => {
      const newGeozone = item.geozones
        ? item.geozones.filter((geozone) => regex.test(geozone.geozone_name))
        : []
      if (newGeozone.length) {
        parentId.push(item.parent_id)
      }
      return {
        ...item,
        geozones: newGeozone,
      }
    })
    .filter((el) => el.geozones.length > 0 || parentId.includes(el.id))
}
