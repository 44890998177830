import { AppDispatch } from "@/store/store"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { onlyNumber } from "src/components/Settings/Content/onlyNumber"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { Checkbox } from "src/shared/ui/Checkbox"
import { Input } from "src/shared/ui/Input"
import {
  setStandingEnabled,
  setStandingTime,
  setStoppingEnabled,
  setStoppingTime,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/movement/movement"
import styles from "../styles/os-item.module.css"

export const ObjectSettingsMovement = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>()

  const { standing, stopping } = useAppSelector((state) => state.osMovement.events)

  const handleStandingEnable = useCallback(() => dispatch(setStandingEnabled()), [])
  const handleStoppingEnable = useCallback(() => dispatch(setStoppingEnabled()), [])
  const handleStandingTime = useCallback((text) => dispatch(setStandingTime(onlyNumber(text))), [])
  const handleStoppingTime = useCallback((text) => dispatch(setStoppingTime(onlyNumber(text))), [])

  return (
    <div className={styles.os_main}>
      <h1 className="">Движение</h1>
      <div
        style={{
          gridTemplateColumns: "15% 25% 60%",
        }}
        className="grid w-1/3 mx-auto  text-[14px]  gap-y-5 gap-x-32  justify-center text-left items-center mt-10"
      >
        <div className="flex items-center justify-end">
          <Checkbox checked={standing.is_enabled} setChecked={handleStandingEnable} />
        </div>
        <p>Стоянка</p>
        <div className="">
          <Input
            error={typeof standing.standing_time == "string"}
            variant="orange"
            inputType={"text"}
            value={standing.standing_time.toString()}
            setValue={handleStandingTime}
          />
        </div>
        <div className="flex items-center justify-end">
          <Checkbox checked={stopping.is_enabled} setChecked={handleStoppingEnable} />
        </div>
        <p>Остановка</p>
        <div className="">
          <Input
            error={typeof stopping.stopping_time == "string"}
            variant="orange"
            inputType={"text"}
            value={stopping.stopping_time.toString()}
            setValue={handleStoppingTime}
          />
        </div>
      </div>
    </div>
  )
})
