import { Button } from "src/shared/ui/Button"
import { Popup } from "../../../shared/ui/Popup"
import React from "react"

type propsType = {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  value: string
  setValue: (value: string) => void
  createGroup: () => void
}
export const PopupNewGroup: React.FC<propsType> = ({
  isOpen,
  setOpen,
  value,
  setValue,
  createGroup,
}) => {
  return (
    <>
      {isOpen && (
        <Popup title="Создание папки" onClose={() => setOpen(false)}>
          <div className="popup-newgroup">
            <input
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              autoFocus={true}
              placeholder="Наименование папки"
            />
            <div className="popup-newgroup__btn-wrapper">
              <Button onClick={createGroup} color="orange">
                Применить
              </Button>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}
