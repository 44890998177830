import { IGSTpmsState } from "@/store/reducers/objectSettings/objectSettingsPageSlices/tpms/tpms"
import { AppDispatch, RootState } from "@/store/store"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { addError } from "src/store/reducers/globalErrors/globalErrorsSlice"
import { thunkSaveSettingsObject } from "src/store/reducers/objectSettings/objectSettingsThunk"
import { IObjectSettingsError } from "../Content/ObjectSettingsContentItems/Errors/ErrorHand"
import { validateTypes } from "./validFields"

// Helper function to set `innerAxleId` to undefined in an object if it exists
const removeInnerAxleId = (obj: IGSTpmsState) => {
  let filteredTpms = structuredClone(obj)
  if (!filteredTpms) return
  filteredTpms?.wheel_axes?.forEach((item) => (item.innerAxleId = undefined))
  filteredTpms?.wheel_axes?.forEach((item) =>
    item?.wheels?.forEach((element) => {
      element?.forEach((i) => {
        console.info(i)
        i.innerAxleId = undefined
        i.innerWheelId = undefined
      })
    }),
  )

  return filteredTpms
}

export const SaveObjectSettings = () => {
  const dispatch = useDispatch<AppDispatch>()
  const body = useSelector((state: RootState) => state.objectSettings.newData)
  const {
    osDiag,
    osDriverCard,
    osFuel,
    osMain,
    osId,
    osMovement,
    osSpeedControl,
    osTPMS,
    osTrack,
  } = useAppSelector((state) => state)
  let osBody: any = {
    track: osTrack,
    main: osMain,
    diag: osDiag,
    driver_cards: osDriverCard,
    fuel: osFuel.fuel,
    movement: osMovement,
    speed_control: osSpeedControl,
    tpms: removeInnerAxleId(osTPMS),
    vehicle_uid: osId.vehicle_uid,
    vehicle_groups_tree_id: osId.groups_tree_id,
  }
  const [error, setErrorH] = useState(false)
  const [errorText, setErrorText] = useState("")
  const errorsArr: IObjectSettingsError[] = []
  const saveHandler = () => {
    const errorArray = validateTypes(osBody)

    if (errorArray.length === 0) {
      console.info("Верная структура")
      dispatch(thunkSaveSettingsObject(removeInnerAxleId(osBody)))
    } else {
      console.info(errorArray)
      dispatch(
        addError({
          message: "Не все поля введены",
          seconds: 8,
        }),
      )
    }
  }

  return (
    <div
      className=" absolute p-1  bottom-0 left-14 w-full  text-white text-lg"
      onClick={saveHandler}
    >
      <div className="absolute  left-0 -top-[150%] w-full h-fit flex items-center justify-center">
        {error && (
          <div className="bg-red-500 rounded  px-10 py-3 flex items-center justify-center gap-10">
            <div className="">
              Ошибка: не заполнено поле: <span>{errorText}</span>
            </div>
            <div
              onClick={() => setErrorH(false)}
              className="icon-cross z-40 text-sm text-white cursor-pointer"
            ></div>
          </div>
        )}
      </div>
      <div className="w-1/6 bg-orange-500 shadow-xl hover:scale-105 active:scale-110 transition-all cursor-pointer mx-auto px-10 rounded py-2 font-light flex items-center justify-center">
        Сохранить
      </div>
    </div>
  )
}
