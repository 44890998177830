export const findPath = (data: any[], id: number | string, is_folder: boolean) => {
  let element: any = null
  let arrayIndex: number | null = null
  let text: string[] = []
  let levelIds: [number, number][] = []

  // Проходим по уровням массива и находим элемент
  data.some((level, levelIndex) => {
    const foundItem = level.find((item: any) => item.id === id && item.is_folder === is_folder)
    if (foundItem) {
      element = foundItem
      arrayIndex = levelIndex

      // Если найден элемент — добавляем его в path только если это папка
      if (foundItem.is_folder) {
        levelIds.push([levelIndex + 1, foundItem.id]) // Уровень начинается с 1
      }

      return true
    }
    return false
  })

  // Если элемент найден, идем по цепочке parent_id до вершины (parent_id === null)
  if (element) {
    text.push(element.name) // Добавляем имя самого элемента
    let currentParentId = element.parent_id

    // Цикл для поиска родительских элементов
    while (currentParentId !== null) {
      let foundParent: any = null
      // Проходим по уровням и ищем родительский элемент
      data.some((level, levelIndex) => {
        foundParent = level.find((item: any) => item.id === currentParentId)
        if (foundParent) {
          text.unshift(foundParent.name) // Добавляем имя родителя в начало

          // Добавляем только те уровни, которые соответствуют папкам (is_folder === true)
          if (foundParent.is_folder) {
            levelIds.unshift([levelIndex + 1, foundParent.id]) // Добавляем уровень и id родителя
          }

          currentParentId = foundParent.parent_id // Обновляем parent_id для следующей итерации
          return true // Прекращаем поиск на уровне
        }
        return false
      })

      // Если родитель не найден, выходим из цикла
      if (!foundParent) {
        break
      }
    }
  }

  // Отображаем уровни с родителями

  const path = {
    pathName: text.join("/"),
    levelIds,
    id: is_folder ? null : id,
  }

  return path // Возвращаем также path
}
