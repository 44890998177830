import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IGSFuelState {
  fuel: ITank[]
}

interface ITank {
  id: number
  name: string
  settings: Settings
}

export interface Settings {
  buf_apx: number
  buf_raw: number
  k_add: number
  k_mul: number
  norm_out: number
  norm_out_type: number
  sensors: IFuelSensor[]
  threshold_in: number
  threshold_out: number
}

export interface IFuelSensor {
  calibr: Array<number[]>
  id: number
  k_add: number
  k_mul: number
}

const initialState: IGSFuelState = {
  fuel: [],
}

const rootOSettingsFuel = createSlice({
  name: "OSettingsFuel",
  initialState,
  reducers: {
    setFuelState: (state: IGSFuelState, action: PayloadAction<ITank[]>) => {
      state.fuel = action.payload
    },
    updateFuelSensorInfo: (
      state: IGSFuelState,
      action: PayloadAction<{ tankIndex: number; newValue: number; sensorIndex; field: string }>,
    ) => {
      state.fuel[action.payload.tankIndex].settings.sensors[action.payload.sensorIndex][
        action.payload.field
      ] = action.payload.newValue
    },
    updateTankName: (
      state: IGSFuelState,
      action: PayloadAction<{ tankIndex: number; newValue: string }>,
    ) => {
      state.fuel[action.payload.tankIndex].name = action.payload.newValue
    },
    updateTankInfo: (
      state: IGSFuelState,
      action: PayloadAction<{ tankIndex: number; field: string; newValue: number }>,
    ) => {
      state.fuel[action.payload.tankIndex].settings[action.payload.field] = action.payload.newValue
    },
    updateCalibrCol: (
      state: IGSFuelState,
      action: PayloadAction<{
        sensorIndex: number
        tankIndex: number
        colIndex: number
        newColData: any
      }>,
    ) => {
      const sensorIndex = action.payload.sensorIndex
      const tankIndex = action.payload.tankIndex
      const colIndex = action.payload.colIndex
      const newColData = action.payload.newColData

      state.fuel[tankIndex].settings.sensors[sensorIndex].calibr[colIndex] = newColData
    },
    deleteCalibrCol: (
      state: IGSFuelState,
      action: PayloadAction<{ sensorIndex: number; tankIndex: number; colIndex: number }>,
    ) => {
      const sensorIndex = action.payload.sensorIndex
      const tankIndex = action.payload.tankIndex
      const colIndex = action.payload.colIndex

      state.fuel[tankIndex].settings.sensors[sensorIndex].calibr.splice(colIndex, 1)
    },
    setNewCalibrCol: (
      state: IGSFuelState,
      action: PayloadAction<{ sensorIndex: number; tankIndex: number; col: number[] }>,
    ) => {
      const sensorIndex = action.payload.sensorIndex
      const tankIndex = action.payload.tankIndex
      const col = action.payload.col

      let newCol = state.fuel[tankIndex].settings.sensors[sensorIndex].calibr

      // Сортировка массива по возрастанию значений во второй колонке
      newCol.sort((a, b) => a[1] - b[1])

      // Удаление дубликатов
      newCol = newCol.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t[0] === item[0] && t[1] === item[1]),
      )

      // Обновление состояния
      state.fuel[tankIndex].settings.sensors[sensorIndex].calibr = newCol
    },
    deleteFuelSensor: (
      state: IGSFuelState,
      action: PayloadAction<{ sensorIndex: number; tankIndex: number }>,
    ) => {
      const sensorIndex = action.payload.sensorIndex
      const tankIndex = action.payload.tankIndex

      state.fuel[tankIndex].settings.sensors.splice(sensorIndex, 1)
    },
    setNewFuelSensor: (
      state: IGSFuelState,
      action: PayloadAction<{ sensor: IFuelSensor; tankIndex: number }>,
    ) => {
      const tankIndex = action.payload.tankIndex
      const sensor = action.payload.sensor

      state.fuel[tankIndex].settings.sensors.unshift(sensor)
    },
    deleteFuelTank: (state: IGSFuelState, action: PayloadAction<number>) => {
      state.fuel.filter((item: ITank) => item.id !== action.payload)
    },
    setNewFuelTank: (state: IGSFuelState, action: PayloadAction<ITank>) => {
      state.fuel.push(action.payload)
    },
  },
})

export const {
  setNewFuelTank,
  setFuelState,
  updateFuelSensorInfo,
  updateTankName,
  updateTankInfo,
  updateCalibrCol,
  deleteCalibrCol,
  setNewCalibrCol,
  deleteFuelSensor,
  setNewFuelSensor,
  deleteFuelTank,
} = rootOSettingsFuel.actions

export default rootOSettingsFuel.reducer
