import { IGeozoneTree } from "../../../interfaces/geozoneTree"
import { TreeNode } from "../../../interfaces/vehicleTree"
import { IDriverData } from "../../../store/reducers/drivers/driverSlice"

type Ttree = TreeNode[] | IDriverData[] | IGeozoneTree[]

export const createTree = (newNodes: Ttree): Ttree => {
  const nodes = newNodes.map((el) => ({ ...el, children: [] }))
  const rootNodes: any = []
  // Создаем объекты-узлы для каждого элемента массива
  const nodeMap: { [id: number]: any } = {}
  nodes.forEach((node) => {
    node.children = []
    nodeMap[node.id] = node
  })

  // Строим древовидную структуру, связывая узлы с их родителями
  nodes.forEach((node) => {
    const parent = nodeMap[node.parent_id]
    if (!!parent) {
      if (parent.children) {
        parent.children.push(node)
      }
    } else {
      rootNodes.push(node)
    }
  })

  return rootNodes
}

export function getGroupAndChildren(groups: Ttree, groupId: number): Ttree {
  let newGroup: any = []

  groups.forEach((group) => {
    if (group.parent_id === groupId) {
      newGroup = [...newGroup, ...getGroupAndChildren(groups, group.id), group]
    }
  })
  return newGroup
}

export const getStatus = (containAll, containSome) => {
  if (containAll) {
    return "all"
  } else if (containSome) {
    return "some"
  } else {
    return "none"
  }
}
