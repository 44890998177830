export const requiredFields = ["data.main.vehicle_name", "data.main.vehicle_uid"]

export const structureObject = {
  vehicle_uid: "",
  groups_tree_id: 0,
  main: {
    account_id: 0,
    is_enabled: true,
    plugin_id: 0,
    sim1: "",
    sim2: "",
    vehicle_id: 0,
    vehicle_name: "",
    vehicle_pwd: "",
  },
  diag: {
    is_enabled: true,
  },
  driver_cards: {
    CustomProtocolMonitoring: {
      addr: 3,
      dataSource: 0,
      func: 3,
      regNo: 0,
      use_as_driver_code: true,
    },
    driver_events: {
      end_registration_by_removing_card: false,
      end_registration_by_turning_off_ignition: false,
      is_enabled: false,
      restore_registration_if_card_reapplied_within: 0,
    },
  },
  speed_control: {
    is_enabled: true,
    max_limit: "",
    reg_critical_limit: "",
    reg_limit: "",
    reg_time_limit: "",
    use_road_signs_instead_max_limit: false,
  },
  movement: {
    events: {
      standing: {
        is_enabled: true,
        standing_time: "",
      },
      stopping: {
        is_enabled: true,
        stopping_time: "",
      },
    },
  },
  tpms: {
    settings: {
      sensors: {
        valid_time_period: 3600,
      },
      wheel_axes: {
        pressure_norm_koef: [
          {
            beginning_with: "01.03",
            koef: 100,
          },
          {
            beginning_with: "01.09",
            koef: 0,
          },
        ],
      },
    },
    wheel_axes: [],
  },
  fuel: [],
  track: {
    colors: {
      is_enabled: true,
      ranges: [],
      dotted: {
        time_limit: 300,
      },
    },
  },
}
export function mergeObjects(staticObject: any, incomingObject: any): any {
  // Проходим по ключам статического объекта
  return Object.keys(staticObject).reduce((acc, key) => {
    const incomingValue = incomingObject ? incomingObject[key] : undefined
    const staticValue = staticObject[key]

    // Если значение — это объект, и оно не null, выполняем рекурсивное слияние
    if (typeof staticValue === "object" && staticValue !== null && !Array.isArray(staticValue)) {
      acc[key] = mergeObjects(staticValue, incomingValue || {})
    } else {
      // Если значение в incomingObject есть, берем его, иначе берем значение из staticObject
      acc[key] = incomingValue !== undefined ? incomingValue : staticValue
    }

    return acc
  }, {})
}
