import { useAppDispatch } from "src/shared/lib/hooks/store"

import { setNewFuelSensor } from "src/store/reducers/objectSettings/objectSettingsPageSlices/fuel/fuel"
import { defaultSensors } from "../../defaultObjects"
import { IFuelSensor } from "../../interfaces"
import { SensorFuel } from "./FuelSensor/SensorFuel"

interface ISensors {
  sensors: IFuelSensor[]
  tankIndex: number
}

export const FuelSensors = ({ sensors, tankIndex }: ISensors) => {
  const dispatch = useAppDispatch()
  return (
    <div className="flex items-center justify-start w-full h-full gap-5">
      <div className="w-fit transition-all h-full flex gap-3 flex-nowrap items-start justify-start overflow-x-auto max-w-[90%]">
        {sensors.map((sensor, index) => (
          <SensorFuel sensor={sensor} sensorIndex={index} tankIndex={tankIndex} />
        ))}
      </div>
      <div
        onClick={() =>
          dispatch(
            setNewFuelSensor({
              sensor: defaultSensors,
              tankIndex,
            }),
          )
        }
        className="py-2 text-sm text-nowrap w-fit px-3 border-black border rounded cursor-pointer hover:bg-zinc-200 active:bg-zinc-300 transition-all"
      >
        + {sensors.length == 0 && " Добавить датчик"}
      </div>
    </div>
  )
}
