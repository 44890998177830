import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { setFilterType } from "../../store/reducers/filters/filters"
import React from "react"
import { FilterMenu } from "./FilterMenu"
import { Popup } from "src/shared/ui/Popup"

export const TablePopupFilters = ({ filterOpen, setFilterOpen }) => {
  const dispatch = useAppDispatch()

  const { type } = useAppSelector((state) => state.filters)

  const orAnd: Array<{ name: string; hand: "or" | "and" }> = [
    {
      name: "Любой из",
      hand: "or",
    },
    {
      name: "Все вместе",
      hand: "and",
    },
  ]

  return (
    <Popup onClose={() => setFilterOpen(false)} title="Фильтры">
      {filterOpen ? (
        <>
          <div className="top-0 w-full flex gap-2 flex-col items-start justify-center">
            <div
              style={{
                boxShadow: "0px 0px 20px 0px #a6a6a6",
              }}
              className="items-center relative   flex bg-white   justify-center rounded-sm"
            >
              {orAnd.map((item, index) => (
                <div
                  onClick={() => dispatch(setFilterType(item.hand))}
                  className={` ${type == item.hand && "bg-orange-500 text-white hover:bg-orange-600"} w-1/2 flex items-center justify-center h-full text-nowrap text-sm font-light py-1 px-4 cursor-pointer hover:bg-gray-100`}
                  key={index}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <FilterMenu />
        </>
      ) : (
        <></>
      )}
    </Popup>
  )
}
