import { CircleEditor } from "src/entities/CircleEditor/CircleEditor"
import { PolygonEditor } from "src/entities/PolygonEditor/PolygonEditor"
import { RectangleEditor } from "src/entities/RetangleEditor/RectangleEditor"
import { useAppSelector } from "src/shared/lib/hooks/store"

export const EdirtorMap = () => {
  const typeFigure = useAppSelector((state) => state.mapEditor.creatorFigure.geometry_type_id)
  const isEditorMap = useAppSelector((state) => state.mapEditor.editMap)

  return (
    <>
      {isEditorMap && (
        <>
          {/* {typeFigure === 3 && <LineEditor />} */}
          {typeFigure === 1 && <RectangleEditor />}
          {typeFigure === 0 && <CircleEditor />}
          {typeFigure === 2 && <PolygonEditor />}
        </>
      )}
    </>
  )
}
