import { useState } from "react"
import { filterChecked } from "../../shared/lib/helpers/checked-data-filter"
import { searchInTable } from "../../shared/lib/helpers/searchFilterTable"
import { setFilterType } from "../../store/reducers/filters/filters"
import { thunkGetTableData } from "../../widgets/Table/model/tableThunk"
import { ArrowCircle, FilterIcon } from "../../shared/ui/IconComponent/ArrowCircle"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import styles from "./TableFilters.module.css"
import { TableGrafIcon } from "../../shared/ui/IconComponent/TableGrafIcon"
import { TableIcons } from "../../shared/ui/IconComponent/TableIcons"
import { setPage } from "../../store/reducers/tpms/tpms"
import { TablePopupFilters } from "../TablePopupFilters/TablePopupFilters"
import { Input } from "src/shared/ui/Input"
import { setSearchTable } from "src/widgets/Table/model/table"

// Разбить на компоненты!!!!!!!!!!!!!

export const TableFilters = ({ startLoad }) => {
  const [filterOpen, setFilterOpen] = useState(false)
  const dispatch = useAppDispatch()
  const checkedVehicles = useAppSelector((state) => state.vehicles.checkedVehicles)
  const search = useAppSelector((state) => state.table.searchTable)
  const checkedDrivers = useAppSelector((state) => state.driver.checkedDrivers)

  const reloadHandler = () => {
    startLoad()
    dispatch(
      thunkGetTableData({
        vehicle_uids: checkedVehicles.map((el) => el.vehicle_uid),
        driver_uids: checkedDrivers.map((el) => el.driver_uid),
      }),
    )
  }

  return (
    <>
      <div className={styles.tablehead}>
        <div className={styles.tablehead_title}>
          <div className={styles.tablehead_icon}>
            <TableIcons />
          </div>
          СКДШ
        </div>
        <div className={styles.tablehead_search}>
          {!search ? (
            <span className={`icon-search ${styles.icon}`}></span>
          ) : (
            <span className="icon-cross" onClick={() => dispatch(setSearchTable(""))}></span>
          )}
          <Input
            value={search}
            setValue={(value) => dispatch(setSearchTable(value))}
            inputType="text"
            placeholder="Поиск"
            variant="palesilver"
          />
        </div>

        <div className={styles.tablehead_box}>
          <button onClick={reloadHandler} className={styles.tablehead__button}>
            <ArrowCircle fill="#FC6904" width={20} />
          </button>
          <button className={styles.tablehead__button} onClick={() => dispatch(setPage("chart"))}>
            <TableGrafIcon />
          </button>
          <button className={styles.tablehead__button} onClick={() => setFilterOpen(true)}>
            <FilterIcon width={25} fill="#FC6904" />
          </button>
        </div>

        {filterOpen && <TablePopupFilters filterOpen={filterOpen} setFilterOpen={setFilterOpen} />}
      </div>
    </>
  )
}
