import { getGroupAndChildren } from "../../../shared/lib/helpers/treeHelpers"
import { changeStatus, checkObjectsPresenceVehicle } from "../../../shared/lib/helpers/vehicleTree"
import { setCheckedDriver } from "../drivers/driverSlice"
import { IVehicle, TreeNode } from "./../../../interfaces/vehicleTree"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type TFilter =
  | "none"
  | "ignitionFirstOff"
  | "ignitionFirstOn"
  | "firstAlphabetName"
  | "lastAlphabetName"
  | "move"
  | "noMove"
  | "online"
  | "longTimeOnline"
  | "recentlyOnline"
  | "neverOnline"
  | "greenGsm"
  | "yellowGsm"
  | "redGsm"
  | "noneCart"
  | "cartNoneDriver"
  | "cart"

interface IVehicleState {
  vehicleTree: TreeNode[] | null
  checkedVehicles: IVehicle[]
  search: string
  filters: TFilter
  activeVehicle: IVehicle | null
  rootGroup: TreeNode | null
  isOpenAll: boolean
}

const initialState: IVehicleState = {
  vehicleTree: null,
  checkedVehicles: [],
  search: "",
  filters: "none",
  activeVehicle: null,
  rootGroup: null,
  isOpenAll: false,
}

const vehicleSlice = createSlice({
  name: "vehiclesTree",
  initialState,
  reducers: {
    setIsOpenAll(state: IVehicleState, action: PayloadAction<boolean>) {
      state.isOpenAll = action.payload
    },
    setActiveVehicle(state: IVehicleState, action: PayloadAction<IVehicle>) {
      state.activeVehicle = action.payload
    },
    setSearchVehicle(state: IVehicleState, action: PayloadAction<string>) {
      state.search = action.payload
    },
    setVehicleTree(state: IVehicleState, action: PayloadAction<TreeNode[]>) {
      state.vehicleTree = action.payload
    },
    addVehicleChecked(
      state: IVehicleState,
      action: PayloadAction<{ vehicle: IVehicle; id: number }>,
    ) {
      if (!state.vehicleTree) return
      const allChecked: IVehicle[] = [...state.checkedVehicles, action.payload.vehicle]

      const targetObject: any = state.vehicleTree.find((obj) => {
        return obj.id === action.payload.id
      })
      // Проверить, все ли транспортные средства этого объекта присутствуют в массиве checkedVehicle
      const allVehiclesCheckedGroup =
        checkObjectsPresenceVehicle(targetObject.vehicles, allChecked) === "all"

      // Проверить, установлен ли статус "all" у всех дочерних элементов объекта
      const allChildrenStatusAll = state.vehicleTree
        .filter((obj) => obj.parent_id === targetObject?.id)
        .every((child) => child.status === "all")

      if (allVehiclesCheckedGroup && allChildrenStatusAll) {
        // Статус all если все отмечены и все дочерние all
        changeStatus(state.vehicleTree, action.payload.id, "all")
      } else {
        // Статус some при любом добавление
        changeStatus(state.vehicleTree, action.payload.id, "some")
      }

      state.checkedVehicles = allChecked
    },
    deleteVehicleChecked(
      state: IVehicleState,
      action: PayloadAction<{ vehicle: IVehicle; id: number }>,
    ) {
      if (!state.vehicleTree) return

      const allChecked = state.checkedVehicles.filter(
        (vehicle: IVehicle) => vehicle.vehicle_uid !== action.payload.vehicle.vehicle_uid,
      )

      const targetObject: any = state.vehicleTree.find((obj) => obj.id === action.payload.id)

      // Проверяем, отсутствуют ли все транспортные средства этой группы в массиве checkedVehicle
      const missingVehicles =
        checkObjectsPresenceVehicle(targetObject.vehicles, allChecked) === "none"

      // Проверить, установлен ли статус "none" у всех дочерних элементов объекта
      const allChildrenStatusAll = state.vehicleTree
        .filter((obj) => obj.parent_id === targetObject?.id)
        .every((child) => child.status === "none")

      if (missingVehicles && allChildrenStatusAll) {
        // Статус none если отсутствуют && все дочерние none
        changeStatus(state.vehicleTree, action.payload.id, "none")
      } else {
        changeStatus(state.vehicleTree, action.payload.id, "some")
      }

      state.checkedVehicles = allChecked
    },
    groupChecked(state: IVehicleState, action: PayloadAction<TreeNode>) {
      if (!state.vehicleTree) return

      const groupAndChildren = getGroupAndChildren(state.vehicleTree, action.payload.id)

      let allVehicle: any = groupAndChildren
        .map((el) => (el.vehicles ? el.vehicles : []))
        .reduce((acc, val) => acc.concat(val), [])
        .map((el) => ({ ...el }))

      allVehicle = action.payload.vehicles
        ? [...allVehicle, ...action.payload.vehicles]
        : allVehicle

      if (action.payload.status === "none" || action.payload.status === "some") {
        state.checkedVehicles = [...allVehicle, ...state.checkedVehicles]

        changeStatus(state.vehicleTree, action.payload.id, "all")

        state.vehicleTree.forEach((el) => {
          if (groupAndChildren.some((group) => group.id == el.id)) {
            el.status = "all"
          }
        })
      }

      if (action.payload.status === "all") {
        // Фильтруем исходные массивы, чтобы получить только уникальные элементы
        const uniqueElements1 = allVehicle.filter(
          (item) => !state.checkedVehicles.some((el) => el.vehicle_uid === item.vehicle_uid),
        )
        const uniqueElements2 = state.checkedVehicles.filter(
          (item) => !allVehicle.some((el) => el.vehicle_uid === item.vehicle_uid),
        )
        // Объединяем все уникальные элементы в один массив
        const result =
          uniqueElements1 && uniqueElements2 ? [...uniqueElements1, ...uniqueElements2] : []

        state.checkedVehicles = result
        changeStatus(state.vehicleTree, action.payload.id, "none")
        state.vehicleTree.forEach((el) => {
          if (groupAndChildren.some((group) => group.id == el.id)) {
            el.status = "none"
          }
        })
        return
      }
    },
    addVehicleGroup(state: IVehicleState, action: PayloadAction<TreeNode>) {
      state.vehicleTree = state.vehicleTree
        ? [...state.vehicleTree, { ...action.payload, status: "none" }]
        : [action.payload]
    },
    setFilter(state: IVehicleState, action: PayloadAction<TFilter>) {
      state.filters = action.payload
    },
    deleteGroupVehicleTree(state: IVehicleState, action: PayloadAction<number>) {
      state.vehicleTree = state.vehicleTree
        ? state.vehicleTree.filter((el) => el.id !== action.payload)
        : []
    },
    setCheckedVehicle(state: IVehicleState, action: PayloadAction<IVehicle[]>) {
      state.checkedVehicles = action.payload
    },
  },
})

export const {
  setFilter,
  setVehicleTree,
  addVehicleChecked,
  setIsOpenAll,
  deleteVehicleChecked,
  groupChecked,
  setSearchVehicle,
  setActiveVehicle,
  addVehicleGroup,
  setCheckedVehicle,
  deleteGroupVehicleTree,
} = vehicleSlice.actions
export default vehicleSlice.reducer
