import React, { useEffect, useState } from "react"
import { FolderCheckboxTitle } from "../../FolderCheckboxTitle/FolderCheckboxTitle"
import { useAppDispatch, useAppSelector } from "../../../../shared/lib/hooks/store"
import { IGeozoneTree } from "../../../../interfaces/geozoneTree"
import { groupChecked } from "../../../../store/reducers/geozonesTree/geozonesTreeSlice"
import { ContextMenu } from "../../../../shared/ui/ContextMenu"
import { setEditMap, setIdTree } from "../../../../store/reducers/mapEditor/mapEditor"
import { GeozoneItemMap } from "../GeozoneItemMap/GeozoneItemMap"

type propsType = {
  group: IGeozoneTree
  search: string
  setNewGroup: (group: {
    group_name: string
    account_id: number | null
    isOpen: boolean
    level: number
  }) => void
  setParentID: (id: number) => void
  setInfoGroup: (id: number, name: string) => void
}

export const FolderCheckboxGeozone: React.FC<propsType> = ({
  group,
  search,
  setNewGroup,
  setParentID,
  setInfoGroup,
}) => {
  const [isOpen, setIsOpen] = useState(!!search)
  const dispatch = useAppDispatch()
  const isOpenAll = useAppSelector((state) => state.geoZones.isOpenAll)

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleContextMenu = (event: any) => {
    event.preventDefault()
    if (group.role_id === 3) {
      setMenuPosition({ x: event.clientX, y: event.clientY })
      setIsMenuOpen(true)
    }
  }

  useEffect(() => {
    if (search) {
      setIsOpen(!!search)
      return
    }
    setIsOpen(isOpenAll)
  }, [search, isOpenAll])

  const onCheckedGroup = (group) => dispatch(groupChecked(group))

  return (
    <div className="checkbox-folder">
      <ContextMenu
        btnMenu={[
          {
            label: "Создать геозону",
            onClickBtn: () => {
              dispatch(setIdTree(group.id))
              dispatch(setEditMap(true))
            },
          },
          {
            label: "Создать папку",
            onClickBtn: () => {
              setNewGroup({
                isOpen: true,
                group_name: "",
                account_id: group.account_id,
                level: group.level + 1,
              })
              setParentID(group.id)
            },
          },
          {
            label: "Удалить папку",
            onClickBtn: () => {
              setInfoGroup(group.id, group.group_name)
            },
          },
        ]}
        menuPosition={menuPosition}
        menuVisible={isMenuOpen}
        contextMenu={handleContextMenu}
        setMenuVisible={(visible) => setIsMenuOpen(visible)}
      >
        <FolderCheckboxTitle
          isOpen={isOpen}
          onChecked={() => onCheckedGroup(group)}
          onClickFolder={() => setIsOpen((prev) => !prev)}
          title={group.group_name}
          status={group.status ? group.status : "none"}
        />
      </ContextMenu>

      {isOpen && (
        <div className={`checkbox-folder__body ${isOpen ? "" : "checkbox-folder__body--close"}`}>
          {group.children &&
            group.children.map((child, key) => (
              <FolderCheckboxGeozone
                group={child}
                search={search}
                setNewGroup={setNewGroup}
                setParentID={setParentID}
                setInfoGroup={setInfoGroup}
                key={key}
              />
            ))}
          <GeozoneItemMap group={group} />
        </div>
      )}
    </div>
  )
}
