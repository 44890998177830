import { useState } from "react"
import { useAppDispatch } from "../../../../../../../shared/lib/hooks/store"
import { updateGSItemData } from "src/store/reducers/globalSettings/globalSettings"
import { Information } from "./icons/Information"
import "./style.css"

interface IPassSwitch {
  setPasswordSwitch: (e: boolean) => void
  isNew: boolean
}

export const PasswordSwitch = ({ setPasswordSwitch }: IPassSwitch) => {
  const dispatch = useAppDispatch()
  const [password, setPassword] = useState("")
  const [correctCheck, setCorrectCheck] = useState("")
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const onSubmit = () => {
    if (password === correctCheck) {
      if (password.length >= 8) {
        dispatch(
          updateGSItemData({
            value: password,
            field: "users",
            profileField: "user_password",
          }),
        )
        setPasswordSwitch(false)
      } else {
        setError(true)
        setErrorMessage("Длина пароля должна быть больше 8 символов")
      }
    } else {
      setError(true)
      setErrorMessage("Пароли не совпадают")
    }
  }

  return (
    <div className="w-full  h-full relative text-nowrap font-light flex flex-col items-center justify-center ">
      <div
        onClick={() => setPasswordSwitch(false)}
        className="icon-cross text-sm text-red-500 absolute -right-2 -top-8 p-1 cursor-pointer hover:text-red-600 active:text-red-700"
      ></div>
      {error && (
        <div className="absolute sett-err  w-full flex items-center justify-center">
          <div className="w-full shadow-2xl  bg-red-500 py-3 text-white text-lg gap-1 px-2 rounded flex items-center justify-between">
            <Information fill="white" width={32} />
            <p>{errorMessage}</p>
            <div
              className="icon-cross pl-10 text-white font-bold"
              onClick={() => setError(false)}
            ></div>
          </div>
        </div>
      )}
      <div className="text-red-500 text-right w-full">
        Длина пароля должна быть больше 8 символов
      </div>
      <div className="flex items-center justify-start my-5">
        <div className="grid grid-cols-2 text-left text-sm gap-5 justify-center items-center">
          <p className="w-full text-right">Новый пароль</p>
          <div className="">
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="bg-transparent text-sm border rounded py-2 px-2 focus-within:border-orange-500"
            />
          </div>
          <p className="w-full text-right">Повторите пароль</p>
          <div className="">
            <input
              onChange={(e) => setCorrectCheck(e.target.value)}
              autoComplete="off"
              type="password"
              className="bg-transparent text-sm border rounded py-2 px-2 focus-within:border-orange-500"
            />
          </div>
        </div>
      </div>

      <div className="w-full text-center flex items-center justify-end">
        <div
          onClick={onSubmit}
          className="bg-orange-500 rounded hover:bg-orange-600 active:bg-orange-700 text-white w-[60%] py-2"
        >
          Изменить
        </div>
      </div>
    </div>
  )
}
