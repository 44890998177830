import React from "react"

export const ObjectsIcon = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9091 10.3889V1H1V10.3889H11.9091ZM11.9091 10.3889H17V6.77778L14.8182 4.61111H11.9091V10.3889ZM6.09091 12.1944C6.09091 13.1916 5.27688 14 4.27273 14C3.26857 14 2.45455 13.1916 2.45455 12.1944C2.45455 11.1973 3.26857 10.3889 4.27273 10.3889C5.27688 10.3889 6.09091 11.1973 6.09091 12.1944ZM15.5455 12.1944C15.5455 13.1916 14.7314 14 13.7273 14C12.7231 14 11.9091 13.1916 11.9091 12.1944C11.9091 11.1973 12.7231 10.3889 13.7273 10.3889C14.7314 10.3889 15.5455 11.1973 15.5455 12.1944Z"
        stroke="#474747"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
