import { RootState } from "@/store/store"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { DriverCard } from "./charts/DriverCard"
import Press from "./charts/Press"
import { Tire } from "./charts/Tire"
import VehicleInfo from "./charts/VechicleInfo"
import { useAppDispatch } from "../../shared/lib/hooks/store"
import { setPage } from "../../store/reducers/tpms/tpms"
import { Button } from "src/shared/ui/Button"
import { TableIcons } from "src/shared/ui/IconComponent/TableIcons"

export const Chart = () => {
  interface ICharts {
    title: string
    chart: React.ReactNode
  }

  const dispatch = useAppDispatch()

  const data = useSelector((state: RootState) => state.table.tableData)
  useEffect(() => {}, [data])
  const charts: ICharts[] = [
    {
      title: "Давление",
      chart: <Press data={data} />,
    },
    {
      title: "Информация о ТС",
      chart: <VehicleInfo data={data} />,
    },
    {
      title: "Карта водителя",
      chart: <DriverCard data={data} />,
    },
    {
      title: "Резина",
      chart: <Tire data={data} />,
    },
  ]

  return (
    <>
      <div style={{ padding: " 0 0 8px 0" }}>
        <Button onClick={() => dispatch(setPage("table"))} style={{ display: "flex", gap: "10px" }}>
          <TableIcons /> Таблица
        </Button>
      </div>

      <div
        className="grid grid-cols-2 grid-rows-2 w-full gap-2 mx-auto h-[85dvh]"
        style={{ paddingRight: "9px" }}
      >
        {charts.map((chartItem, index) => (
          <>
            <>
              {data != null && typeof data != "string" && (
                <div
                  key={index}
                  className="text-center h-full"
                  style={{
                    background: "#fcfcfc",
                    borderRadius: "3px",
                    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.25);",
                  }}
                >
                  <div className="w-full h-full p-4">
                    <p className="text-lg font-medium">{chartItem.title}</p>
                    <div className="w-full h-full flex items-center justify-center">
                      {chartItem.chart}
                    </div>
                  </div>
                </div>
              )}
            </>
          </>
        ))}
      </div>
    </>
  )
}
