import { createTree } from "../../../shared/lib/helpers/treeHelpers"
import { filterByVehicleName, filterVehicle } from "../../../shared/lib/helpers/vehicleTree"
import { RootState } from "@/store/store"
import { createSelector } from "@reduxjs/toolkit"

export const createTreeFilter = createSelector(
  (state: RootState) => state.vehicles.search,
  (state: RootState) => state.vehicles.vehicleTree,
  (state: RootState) => state.vehicles.filters,
  (search, vehicleTree, filters) => {
    return createTree(
      vehicleTree ? filterVehicle(filters, filterByVehicleName(vehicleTree, search)) : [],
    )
  },
)
