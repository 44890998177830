import React, { useEffect } from "react"
import { Track } from "./Track"
import { useMap } from "react-leaflet"
import L from "leaflet"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { SpeedTrack } from "src/widgets/Map/SpeedTrack"

type propsType = {
  tracks: Array<any>
  startLoading: () => void
  stopLoading: () => void
}

export const TracksMap: React.FC<propsType> = React.memo(
  ({ tracks, startLoading, stopLoading }) => {
    const map = useMap()
    const colorTrack = useAppSelector((state) => state.mapTrack.colorTrack)

    useEffect(() => {
      if (tracks.length > 0) {
        const pointsGeozones: any[] = []
        tracks
          .map((track) => track.points)
          .forEach((points) => {
            points.forEach((point) => {
              pointsGeozones.push([point.lt, point.ln])
            })
          })
        if (pointsGeozones.length <= 0) return
        const points = L.polyline(pointsGeozones)
        const bounds = points.getBounds()
        map.fitBounds(bounds)
      }
    }, [tracks])

    return (
      <>
        {tracks &&
          tracks.map((track, index) => {
            return colorTrack && track.is_enabledColorTrack && track.ranges.length > 2 ? (
              <SpeedTrack track={track} startLoading={startLoading} stopLoading={stopLoading} />
            ) : (
              <Track
                track={track}
                index={index}
                startLoading={startLoading}
                stopLoading={stopLoading}
              />
            )
          })}
      </>
    )
  },
)
