import { memo } from "react"
import { settingsMenu } from "../menuData"
import styles from "../styles/object-settings.module.css"

interface IObjectSettingsNavbarProps {
  page: string
  setPage: (a: string) => void
}

export const ObjectSettingsNavbar = memo(({ page, setPage }: IObjectSettingsNavbarProps) => {
  return (
    <div className={styles.os_navbar}>
      <div className={styles.os_navbar_container}>
        {settingsMenu?.map((i) => (
          <div
            key={i.name}
            onClick={() => setPage(i.hand)}
            className={`${styles.settings_navbar_item} ${page == i.hand && styles.active}`}
          >
            {i.name}
          </div>
        ))}
      </div>
    </div>
  )
})
