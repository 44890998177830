interface IInfoColumn {
  uid: string | number
}

const ObjectInfoColumn = ({ uid }: IInfoColumn) => {
  const info = [
    {
      name: "",
      value: "",
    },
  ]
  return <div className="text-black w-full h-full">{uid}</div>
}
export default ObjectInfoColumn
