import React from "react"

interface TextareaComponentProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  disable?: boolean
  width?: number | string
  placeholder?: string
  rows?: number
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  error?: boolean
  classNames?: string
  onBlur?: (e: any) => void
}

export const TextareaComponent: React.FC<TextareaComponentProps> = ({
  value,
  onChange,
  disable,
  width,
  placeholder,
  rows,
  onKeyUp,
  onKeyDown,
  error,
  classNames,
  onBlur,
}) => {
  return (
    <div
      style={{
        width,
      }}
      className="relative overflow-hidden w-fit rounded h-fit"
    >
      {disable && <div className="absolute w-full h-full bg-black bg-opacity-70 z-20"></div>}
      <textarea
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={
          classNames
            ? classNames
            : `px-2 py-2 ${
                rows ? "text-wrap" : "text-nowrap"
              } overflow-hidden w-full bg-transparent text-sm font-light text-left ${
                rows && rows > 1 ? " resize-y" : "resize-none"
              } border rounded text-nowrap ${
                error && "border-red-500 border-2"
              } h-fit focus-within:border-orange-600 `
        }
        name=""
        id=""
        rows={rows || 1}
        value={value}
        onChange={onChange}
      ></textarea>
    </div>
  )
}
