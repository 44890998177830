export default function Arrow({ fill, width }) {
  return (
    <svg width={width} viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.475 6.96154L10.9125 0.888685C10.55 0.525404 10.0562 0.343763 9.5125 0.343763C8.96875 0.343763 8.48125 0.525404 8.1125 0.888685L1.52499 6.96154C0.743744 7.68204 0.743744 8.85665 1.52499 9.57716C2.30624 10.2977 3.56874 10.2977 4.35 9.57716L7.5 6.6588V19.7975C7.5 20.8207 8.39374 21.6502 9.5 21.6502C10.6062 21.6502 11.5 20.8207 11.5 19.7975V6.6588L14.65 9.57716C15.4312 10.2977 16.6937 10.2977 17.475 9.57716C18.2562 8.85665 18.2562 7.6881 17.475 6.96154Z"
        fill={fill}
      />
    </svg>
  )
}
