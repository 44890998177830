import React, { useEffect } from "react"
import { HorizontalTreeColumn } from "./innerComponents/Column/HorizontalTreeColumn"
import { IHorizontalTreeProps } from "./interface"
import style from "./style/style.module.css"

export const HorizontalTree = React.memo(
  ({
    data,
    onClickTreeItem,
    levelIds,
    activeId,
    contextGenerator,
    iconGenerator,
    infoColumn,
    columnDefaultOptionsGenerator,
  }: IHorizontalTreeProps) => {
    useEffect(() => {}, [levelIds])

    return (
      <div className={style.tree_container}>
        {infoColumn && <div className={style.info_row}>{infoColumn}</div>}

        <div className={style.tree_container_rows}>
          {data?.map((column, index) => (
            <>
              {(levelIds.find((i) => i[0] == index) || index == 1) && (
                <div className={`contents`} key={index}>
                  <HorizontalTreeColumn
                    account_id={
                      data[index - 1].find((i) => i.id == levelIds[index - 1][1])?.account_id ?? 0
                    }
                    columnDefaultOptionsGenerator={columnDefaultOptionsGenerator}
                    column={column}
                    onClickTreeItems={onClickTreeItem}
                    levelIds={levelIds}
                    colIndex={index}
                    activeId={activeId}
                    contextGenerator={(arr: any) => contextGenerator(arr)}
                    iconGenerator={(a: boolean, b: boolean, c: number) => iconGenerator(a, b, c)}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    )
  },
)
