import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { axleCreator } from "src/store/reducers/objectSettings/objectTemplates/axleTemplate"
import { wheelCreator } from "src/store/reducers/objectSettings/objectTemplates/wheelTemplate"

export interface IGSTpmsState {
  settings: Settings
  wheel_axes: any[]
}

export interface Settings {
  sensors: Sensors
  wheel_axes: WheelAxes
}

export interface Sensors {
  valid_time_period: number | ""
}

export interface WheelAxes {
  pressure_norm_koef: PressureNormKoef[]
}

export interface PressureNormKoef {
  beginning_with: string
  koef: number
}

const initialState: IGSTpmsState = {
  settings: {
    sensors: {
      valid_time_period: 3600,
    },
    wheel_axes: {
      pressure_norm_koef: [
        {
          beginning_with: "01.03",
          koef: 100,
        },
        {
          beginning_with: "01.09",
          koef: 0,
        },
      ],
    },
  },
  wheel_axes: [],
}

const rootOSettingsTpms = createSlice({
  name: "OSettingsTpms",
  initialState,
  reducers: {
    setValidTimePeriod: (state: IGSTpmsState, action: PayloadAction<number>) => {
      state.settings.sensors.valid_time_period = action.payload
    },
    setPressureNormKoef: (state: IGSTpmsState, action: PayloadAction<PressureNormKoef[]>) => {
      state.settings.wheel_axes.pressure_norm_koef = action.payload
    },
    addPressureNormKoef: (state: IGSTpmsState, action: PayloadAction<PressureNormKoef>) => {
      state.settings.wheel_axes.pressure_norm_koef.push(action.payload)
    },
    setWheelAxes: (state: IGSTpmsState, action: PayloadAction<any[]>) => {
      state.wheel_axes = action.payload
    },
    setTpmsState: (state: IGSTpmsState, action: PayloadAction<IGSTpmsState>) => {
      state.settings = action.payload.settings
      state.wheel_axes = action.payload.wheel_axes
    },
    createAxle: (state: IGSTpmsState) => {
      state.wheel_axes.push(axleCreator())
    },
    createWheel: (
      state: IGSTpmsState,
      action: PayloadAction<{ id: number; position: "left" | "right" }>,
    ) => {
      const id = action.payload.id
      const position = action.payload.position
      if (id > 0) {
        switch (position) {
          case "left":
            state.wheel_axes?.find((i) => i.id == id)?.wheels[0].push(wheelCreator("L"))
            break
          case "right":
            state.wheel_axes?.find((i) => i.id == id)?.wheels[1].push(wheelCreator("R"))
        }
      } else {
        switch (position) {
          case "left":
            state.wheel_axes?.find((i) => i.innerAxleId == id)?.wheels[0].push(wheelCreator("L"))
            break
          case "right":
            state.wheel_axes?.find((i) => i.innerAxleId == id)?.wheels[1].push(wheelCreator("R"))
            break
        }
      }
    },
    removeWheel: (state: IGSTpmsState, action: PayloadAction<number>) => {
      const id = action.payload

      if (state.wheel_axes) {
        state.wheel_axes = state.wheel_axes.map((axle) => ({
          ...axle,
          wheels: axle.wheels.map((wheel) =>
            id > 0
              ? wheel.filter((item) => item.id !== id)
              : wheel.filter((item) => item.innerWheelId !== id),
          ),
        }))
      } else {
        state = initialState
      }
    },
    removeAxle: (state: IGSTpmsState, action: PayloadAction<number>) => {
      const axleId = action.payload

      if (state.wheel_axes) {
        if (axleId > 0) {
          state.wheel_axes = state.wheel_axes.filter((axle) => axle.id !== axleId)
        } else {
          state.wheel_axes = state.wheel_axes.filter((axle) => axle.innerAxleId !== axleId)
        }
      } else {
      }
    },
    updateSensorNumber: (
      state: IGSTpmsState,
      action: PayloadAction<{ wheelId: number; text: number }>,
    ) => {
      const { wheelId, text } = action.payload

      // Проверка, существует ли массив wheel_axes
      if (state.wheel_axes) {
        // Обновление состояния
        state.wheel_axes = state.wheel_axes.map((axle) => ({
          ...axle,
          wheels: axle.wheels.map((wheelArray) =>
            wheelArray.map((wheel) =>
              (wheelId > 0 && wheel.id === wheelId) ||
              (wheelId <= 0 && wheel.innerWheelId === wheelId)
                ? { ...wheel, sensor_number: text }
                : wheel,
            ),
          ),
        }))
      }
    },
    updateNorms: (
      state: IGSTpmsState,
      action: PayloadAction<{ id: number; field: string; value: number }>,
    ) => {
      const { id, field, value } = action.payload
      if (id > 0) {
        const item = state.wheel_axes?.find((i) => i.id === id)

        // Update the field if the item is found
        if (item) {
          item[field] = value
        }
      } else {
        const item = state.wheel_axes?.find((i) => i.innerAxleId === id)

        // Update the field if the item is found
        if (item) {
          item[field] = value
        }
      }
    },
    updateObjectSettingsWheelsPressValidTime: (
      state: IGSTpmsState,
      action: PayloadAction<number | "">,
    ) => {
      if (state.settings.sensors.valid_time_period != null) {
        state.settings.sensors.valid_time_period = action.payload
      }
    },
    setTPMSDownAxleNorms: (state: IGSTpmsState, action: PayloadAction<number>) => {
      const index = action.payload

      const pressure_delta = state.wheel_axes[index - 1].pressure_delta
      const pressure_norm = state.wheel_axes[index - 1].pressure_norm
      const temperature_max = state.wheel_axes[index - 1].temperature_max

      state.wheel_axes[index].temperature_max = temperature_max
      state.wheel_axes[index].pressure_delta = pressure_delta
      state.wheel_axes[index].pressure_norm = pressure_norm
    },
  },
})

export const {
  setTPMSDownAxleNorms,
  setValidTimePeriod,
  setPressureNormKoef,
  addPressureNormKoef,
  setWheelAxes,
  setTpmsState,
  createAxle,
  createWheel,
  removeWheel,
  removeAxle,
  updateSensorNumber,
  updateNorms,
  updateObjectSettingsWheelsPressValidTime,
} = rootOSettingsTpms.actions

export default rootOSettingsTpms.reducer
