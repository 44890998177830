export const CopyDown = ({ fill, width }) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 2.75C4.5 3.16421 4.83579 3.5 5.25 3.5H18.25C18.6642 3.5 19 3.16421 19 2.75C19 2.33579 18.6642 2 18.25 2H5.25C4.83579 2 4.5 2.33579 4.5 2.75ZM4.5 21.25C4.5 21.6642 4.83579 22 5.25 22H6.11667C6.53088 22 6.86667 21.6642 6.86667 21.25C6.86667 20.8358 6.53088 20.5 6.11667 20.5H5.25C4.83579 20.5 4.5 20.8358 4.5 21.25ZM7.96667 21.25C7.96667 21.6642 8.30245 22 8.71667 22H10.45C10.8642 22 11.2 21.6642 11.2 21.25C11.2 20.8358 10.8642 20.5 10.45 20.5H8.71667C8.30245 20.5 7.96667 20.8358 7.96667 21.25ZM12.3 21.25C12.3 21.6642 12.6358 22 13.05 22H14.7833C15.1975 22 15.5333 21.6642 15.5333 21.25C15.5333 20.8358 15.1975 20.5 14.7833 20.5H13.05C12.6358 20.5 12.3 20.8358 12.3 21.25ZM16.6333 21.25C16.6333 21.6642 16.9691 22 17.3833 22H18.25C18.6642 22 19 21.6642 19 21.25C19 20.8358 18.6642 20.5 18.25 20.5H17.3833C16.9691 20.5 16.6333 20.8358 16.6333 21.25ZM12.2803 18.7803C11.9874 19.0732 11.5126 19.0732 11.2197 18.7803L6.21967 13.7803C5.92678 13.4874 5.92678 13.0126 6.21967 12.7197C6.51256 12.4268 6.98744 12.4268 7.28033 12.7197L11 16.4393V5.75C11 5.33579 11.3358 5 11.75 5C12.1642 5 12.5 5.33579 12.5 5.75V16.4393L16.2197 12.7197C16.5126 12.4268 16.9874 12.4268 17.2803 12.7197C17.5732 13.0126 17.5732 13.4874 17.2803 13.7803L12.2803 18.7803Z"
        fill={fill}
      />
    </svg>
  )
}
