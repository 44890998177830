import { useState } from "react"

export const useLoading = () => {
  const [loading, setLoading] = useState(false)

  const startLoading = () => {
    setLoading(true)
  }

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const setLoad = (value: boolean) => {
    if (loading) {
      stopLoading()
      return
    }
    startLoading()
  }

  return { loading, startLoading, stopLoading, setLoad }
}
