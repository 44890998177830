import { IOnClickName } from "../../Pages/Drivers/SearchDrivers"
import style from "../style/settings-search.module.css"
import { SearchTreeViewItem } from "./SearchTreeViewItem"

interface ISearchTreeViewProps {
  tree: any[]
  onClickPath: (levelIds: [number, number][]) => void
  onClickName: (a: IOnClickName) => void
  setActiveId: (id: any) => void
  setCloseSearch: () => void
}

export const SearchTreeView = ({
  tree,
  onClickPath,
  onClickName,
  setActiveId,
  setCloseSearch,
}: ISearchTreeViewProps) => {
  // Ensure tree is always an array
  const safeTree = Array.isArray(tree) ? tree : []

  return (
    <div className={style.tree_view}>
      <div className={style.tree_view_container}>
        {safeTree.map((item, index) => (
          <div className="contents" key={index}>
            <SearchTreeViewItem
              setCloseSearch={setCloseSearch}
              onClickName={onClickName}
              onClickPath={onClickPath}
              searchItem={item}
              setActiveId={setActiveId}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
