import { RootState } from "@/store/store"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { organizeObjects } from "src/shared/ui/SettingsTree/helpers"
import { HorizontalTree } from "src/shared/ui/SettingsTree/HorizontalTree"

import React from "react"
import { LoadingWrapper } from "src/components/LoadingWrapper/LoadingWrapper"
import { ObjectsIcons } from "src/components/Settings/icons/ObjectsIcons"
import {
  Change,
  Create,
  CreateFolder,
  Delete,
} from "src/components/Settings/icons/optionsIcons/Options"
import { setFolderData, setNewHandleId } from "src/store/reducers/globalSettings/globalSettings"
import {
  thunkDeleteFolder,
  thunkGetFolder,
  thunkGetObjectsFromServer,
} from "src/store/reducers/globalSettings/globalSettingsThunk"
import {
  setObjectSettingsUid,
  setOSGroupsTreeID,
} from "src/store/reducers/objectSettings/objectSettings"
import { setOSIDs } from "src/store/reducers/objectSettings/objectSettingsPageSlices/id/osId"
import { setOSAccountData } from "src/store/reducers/objectSettings/objectSettingsPageSlices/main/main"
import { ClientIcon } from "../../Help/icons/ClientIcon"
import { FolderIcon } from "../../Help/icons/FolderIcon"
import ObjectInfoColumn from "../Objects/ObjectInfoColumn"
import { ObjectSearch } from "./ObjectSearch"

export interface TestSettingsTreeProps {
  activeId: number | string | null
  setActiveId: (id: string | null) => void
}

export const Objects = React.memo(({ activeId, setActiveId }: TestSettingsTreeProps) => {
  const vehicleUID = useAppSelector((state) => state.globalSettings?.vehicleUID)
  const createObjectHandle = (id: number, groupId: number) => {
    dispatch(setOSAccountData(id))

    dispatch(
      setOSIDs({
        vehicle_uid: "",
        groups_tree_id: groupId,
        create: true,
        data: true,
      }),
    )
  }
  const dispatch = useAppDispatch()
  const levelIds = useAppSelector((state) => state.globalSettings.tree.objects.levelIds)
  const folderData = useSelector((state: RootState) => state.globalSettings.folder)
  const data = useSelector((state: RootState) => state.globalSettings.data.objects)

  useEffect(() => {}, [levelIds, vehicleUID])

  const clickHandler = ({ id, index, is_folder }) => {
    setActiveId(null)
    if (is_folder == false) {
      // dispatch(setNewGSetVehicleUID({ uid: id.toString(), level: index + 1 }))

      setActiveId(id)
    } else if (is_folder) {
      dispatch(
        setNewHandleId({
          id: id,
          field: "objects",
          index: index + 1,
        }),
      )
    }
  }

  useEffect(() => {
    dispatch(thunkGetObjectsFromServer())
  }, [vehicleUID])

  interface IGenerateBody {
    groupID: number
    accountID: number
    type: "object" | "group"
    index: number
    handleId: number
    uid: string
    level: number
    handlePlusId: number
    roleID: number
    is_root: boolean
    parent_id: number
  }
  const generateBody = ({
    groupID,
    accountID,
    type,
    is_root,
    handleId,
    uid,

    parent_id,
    roleID,
  }: IGenerateBody) => {
    let body: any = []
    switch (type) {
      case "object":
        body = [
          {
            icon: <Change width={18} />,
            label: "Редактировать объект",
            onClickBtn: () => {
              dispatch(setOSGroupsTreeID(parent_id))
              dispatch(
                setOSIDs({
                  vehicle_uid: uid,
                  groups_tree_id: Number(parent_id),
                }),
              )
              dispatch(setObjectSettingsUid(uid))
            },
          },
        ]
        return body
      case "group":
        if (roleID == 3 && is_root) {
          body = [
            {
              icon: <Create width={18} />,
              label: "Создать объект",
              onClickBtn: () => createObjectHandle(accountID, groupID),
            },
            {
              icon: <CreateFolder width={18} />,
              label: "Создать папку",
              onClickBtn: () => {
                dispatch(
                  setFolderData({
                    data: { group_name: "", id: 0, parent_id: groupID },
                    status: "create",
                    accountId: accountID,
                  }),
                )
              },
            },
          ]
        }
        if (roleID == 3 && !is_root) {
          body = [
            {
              icon: <Create width={18} />,
              label: "Создать объект",
              onClickBtn: () => createObjectHandle(accountID, groupID),
            },
            {
              icon: <CreateFolder width={18} />,
              label: "Создать папку",
              onClickBtn: () => {
                dispatch(
                  setFolderData({
                    data: { group_name: "", id: 0, parent_id: groupID },
                    status: "create",
                  }),
                )
              },
            },
            {
              icon: <Change width={18} />,
              label: "Редактировать папку",
              onClickBtn: () => {
                dispatch(thunkGetFolder({ id: groupID }))
                dispatch(
                  setFolderData({
                    data: { group_name: "", id: groupID, parent_id },
                    status: "create",
                  }),
                )
              },
            },
            {
              icon: <Delete width={18} />,
              label: "Удалить папку",
              color: "#d55050",
              onClickBtn: () => dispatch(thunkDeleteFolder(groupID)),
            },
          ]
        }

        return body
    }
  }
  const iconGenerator = (is_folder: boolean, is_root: boolean, role_id) => {
    let icon: React.ReactNode

    if (!is_folder) {
      icon = <ObjectsIcons width={18} fill="rgb(255, 103, 0)" />
    }
    if (is_folder) {
      if (is_root) {
        icon = <ClientIcon fill={"rgb(255, 103, 0)"} width={18} />
        if (role_id !== 3) {
          icon = <FolderIcon fill={"rgb(255, 103, 0)"} width={18} />
        }
      } else {
        icon = <FolderIcon fill={"rgb(255, 103, 0)"} width={18} />
      }
    }

    return icon
  }
  // const setInfoColumn = (uid: any): React.ReactElement => {
  //   return <ObjectInfoColumn data={uid} />
  // }
  let InfoColumn = activeId ? <ObjectInfoColumn uid={activeId} /> : null

  const objects: any = data != null ? organizeObjects(data) : null
  const generateColumnOptions = ({ item, parent_id, index, account_id }) => {
    let body: any = []

    if ((item[0].role_id >= 3 && item[0].is_root == false) || typeof item[0].id == "string") {
      body = [
        {
          icon: <Create width={18} />,
          label: "Создать объект",
          onClickBtn: () => createObjectHandle(account_id, parent_id),
        },
        {
          icon: <CreateFolder width={18} />,
          label: "Создать папку",
          onClickBtn: () => {
            dispatch(
              setFolderData({
                data: { group_name: "", id: 0, parent_id: parent_id },
                status: "create",
                accountId: account_id,
              }),
            )
          },
        },
      ]
    }

    return body
  }
  return (
    <div className="w-full h-full">
      <LoadingWrapper isLoading={data == null}>
        <ObjectSearch setActiveId={(id: any) => setActiveId(id)} />
        {objects && (
          <HorizontalTree
            columnDefaultOptionsGenerator={generateColumnOptions}
            iconGenerator={(a: boolean, b: boolean, c: number) => iconGenerator(a, b, c)}
            levelIds={levelIds}
            data={objects}
            onClickTreeItem={(arr: any) => clickHandler(arr)}
            contextGenerator={(arr: any) => generateBody(arr)}
            activeId={activeId}
            infoColumn={InfoColumn}
          />
        )}
      </LoadingWrapper>
    </div>
  )
})
