export const MapIcon = ({ fill, width }) => {
  return (
    <svg width={width} viewBox="0 0 81 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6768 53.414C15.5479 53.4148 15.42 53.3901 15.3007 53.3413C15.1813 53.2925 15.0727 53.2207 14.9812 53.1299L8.05398 46.2713C7.05387 45.2778 6.26014 44.0962 5.71851 42.7947C5.17688 41.4932 4.89804 40.0974 4.89804 38.6877C4.89804 37.2779 5.17688 35.8821 5.71851 34.5806C6.26014 33.2791 7.05387 32.0976 8.05398 31.104C10.0842 29.0984 12.823 27.9736 15.6768 27.9736C18.5306 27.9736 21.2695 29.0984 23.2996 31.104C24.2998 32.0976 25.0935 33.2791 25.6351 34.5806C26.1767 35.8821 26.4556 37.2779 26.4556 38.6877C26.4556 40.0974 26.1767 41.4932 25.6351 42.7947C25.0935 44.0962 24.2998 45.2778 23.2996 46.2713L16.3725 53.1299C16.2809 53.2207 16.1723 53.2925 16.053 53.3413C15.9336 53.3901 15.8058 53.4148 15.6768 53.414ZM15.6768 29.8989C13.3383 29.8931 11.0933 30.8165 9.4355 32.466C8.61905 33.2773 7.97111 34.2421 7.52897 35.3048C7.08682 36.3676 6.8592 37.5072 6.8592 38.6583C6.8592 39.8093 7.08682 40.949 7.52897 42.0117C7.97111 43.0744 8.61905 44.0392 9.4355 44.8506L15.6768 51.0527L21.9181 44.8506C22.7346 44.0392 23.3825 43.0744 23.8247 42.0117C24.2668 40.949 24.4944 39.8093 24.4944 38.6583C24.4944 37.5072 24.2668 36.3676 23.8247 35.3048C23.3825 34.2421 22.7346 33.2773 21.9181 32.466C20.2604 30.8165 18.0153 29.8931 15.6768 29.8989Z"
        fill={fill}
      />
      <path
        d="M15.6769 43.6161C14.7079 43.6161 13.7608 43.3288 12.9551 42.7905C12.1495 42.2522 11.5216 41.487 11.1508 40.5919C10.78 39.6967 10.683 38.7117 10.872 37.7614C11.061 36.8111 11.5276 35.9381 12.2127 35.253C12.8979 34.5679 13.7708 34.1013 14.7211 33.9123C15.6714 33.7232 16.6564 33.8202 17.5516 34.191C18.4468 34.5618 19.2119 35.1897 19.7502 35.9954C20.2885 36.801 20.5758 37.7482 20.5758 38.7171C20.5758 40.0164 20.0597 41.2625 19.141 42.1812C18.2222 43.1 16.9761 43.6161 15.6769 43.6161ZM15.6769 35.7777C15.0955 35.7777 14.5272 35.9501 14.0438 36.2731C13.5604 36.5961 13.1837 37.0551 12.9612 37.5923C12.7387 38.1294 12.6805 38.7204 12.7939 39.2906C12.9074 39.8607 13.1873 40.3845 13.5984 40.7956C14.0095 41.2067 14.5332 41.4866 15.1034 41.6C15.6736 41.7134 16.2646 41.6552 16.8017 41.4328C17.3388 41.2103 17.7979 40.8335 18.1209 40.3501C18.4439 39.8668 18.6162 39.2985 18.6162 38.7171C18.6162 37.9375 18.3066 37.1899 17.7553 36.6386C17.2041 36.0874 16.4564 35.7777 15.6769 35.7777Z"
        fill={fill}
      />
      <path
        d="M64.6768 28.4142C64.5478 28.4149 64.42 28.3902 64.3006 28.3414C64.1813 28.2927 64.0727 28.2208 63.9811 28.13L57.054 21.2714C56.0538 20.2779 55.2601 19.0964 54.7185 17.7948C54.1769 16.4933 53.898 15.0975 53.898 13.6878C53.898 12.2781 54.1769 10.8823 54.7185 9.58074C55.2601 8.27922 56.0538 7.09768 57.054 6.10416C59.0841 4.09849 61.823 2.97375 64.6768 2.97375C67.5306 2.97375 70.2694 4.09849 72.2996 6.10416C73.2997 7.09768 74.0935 8.27922 74.6351 9.58074C75.1767 10.8823 75.4556 12.2781 75.4556 13.6878C75.4556 15.0975 75.1767 16.4933 74.6351 17.7948C74.0935 19.0964 73.2997 20.2779 72.2996 21.2714L65.3724 28.13C65.2809 28.2208 65.1723 28.2927 65.0529 28.3414C64.9336 28.3902 64.8057 28.4149 64.6768 28.4142ZM64.6768 4.899C62.3383 4.89326 60.0932 5.81665 58.4355 7.46607C57.619 8.27743 56.9711 9.24223 56.5289 10.305C56.0868 11.3677 55.8592 12.5074 55.8592 13.6584C55.8592 14.8094 56.0868 15.9491 56.5289 17.0118C56.9711 18.0746 57.619 19.0394 58.4355 19.8507L64.6768 26.0528L70.9181 19.8507C71.7345 19.0394 72.3825 18.0746 72.8246 17.0118C73.2668 15.9491 73.4944 14.8094 73.4944 13.6584C73.4944 12.5074 73.2668 11.3677 72.8246 10.305C72.3825 9.24223 71.7345 8.27743 70.9181 7.46607C69.2603 5.81665 67.0153 4.89326 64.6768 4.899Z"
        fill={fill}
      />
      <path
        d="M64.6768 18.6162C63.7079 18.6162 62.7607 18.3289 61.9551 17.7906C61.1495 17.2523 60.5215 16.4872 60.1507 15.592C59.78 14.6968 59.6829 13.7118 59.872 12.7615C60.061 11.8112 60.5276 10.9383 61.2127 10.2531C61.8979 9.56798 62.7708 9.1014 63.7211 8.91237C64.6714 8.72334 65.6564 8.82036 66.5516 9.19115C67.4468 9.56195 68.2119 10.1899 68.7502 10.9955C69.2885 11.8011 69.5758 12.7483 69.5758 13.7172C69.5758 15.0165 69.0597 16.2626 68.1409 17.1813C67.2222 18.1001 65.9761 18.6162 64.6768 18.6162ZM64.6768 10.7778C64.0955 10.7778 63.5272 10.9502 63.0438 11.2732C62.5604 11.5962 62.1837 12.0553 61.9612 12.5924C61.7387 13.1295 61.6805 13.7205 61.7939 14.2907C61.9073 14.8609 62.1873 15.3846 62.5984 15.7957C63.0094 16.2068 63.5332 16.4867 64.1034 16.6001C64.6736 16.7136 65.2646 16.6554 65.8017 16.4329C66.3388 16.2104 66.7979 15.8337 67.1208 15.3503C67.4438 14.8669 67.6162 14.2986 67.6162 13.7172C67.6162 12.9377 67.3065 12.19 66.7553 11.6388C66.204 11.0875 65.4564 10.7778 64.6768 10.7778Z"
        fill={fill}
      />
      <path
        d="M16 54C65.8139 50.2486 58 46.3746 39.5 40.2373C21 34.1001 63.5 28.5 63.5 28.5"
        stroke={fill}
        stroke-dasharray="2 2"
      />
    </svg>
  )
}
