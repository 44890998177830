import React from "react"
import styles from "./style.module.css"

export const Cart = ({ clickCart, image, title }) => {
  return (
    <div className={styles.cart} onClick={() => clickCart()}>
      <div className={styles.cart_img}>
        <img src={image} alt="СКДШ" />
        <div className={styles.cart_title}>{title}</div>
      </div>
    </div>
  )
}
