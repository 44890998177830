import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "src/store/store"
import { Drivers } from "./Pages/Drivers/Drivers"
import { Objects } from "./Pages/Objects/Objects"
import { TestSettingsTree } from "./Pages/Test/Test"
import { Users } from "./Pages/Users/Users"

export type ILevels = {
  [key: string]: [number, number][]
}
export type IActiveItemType = {
  [key: string]: string | number
}

export const Content = () => {
  const page = useSelector((state: RootState) => state.globalSettings.page)
  const data = useSelector((state: RootState) => state.globalSettings.data)

  const [activeID, setActiveId] = useState<null | string>(null)

  const [objectActiveId, setObjectActiveId] = useState<null | string>(null)
  const [usersActiveId, setUsersActiveId] = useState<null | number>(null)
  const [driversActiveId, setDriversActiveId] = useState<null | string>(null)

  useEffect(() => {}, [data, page])

  return (
    <div className="h-full relative w-[80%] min-w-[80%] max-w-[80%] flex flex-col gap-[8px]">
      {page === "drivers" && (
        <Drivers
          activeId={driversActiveId}
          setActiveId={(a: string | null) => setDriversActiveId(a)}
        />
      )}
      {page === "objects" && (
        <Objects
          activeId={objectActiveId}
          setActiveId={(id: string | null) => setObjectActiveId(id)}
        />
      )}
      {/* {page === "objects" && <Objects />} */}
      {page === "users" && (
        <Users activeId={usersActiveId} setActiveId={(id: null | number) => setUsersActiveId(id)} />
      )}
      {page === "test" && (
        <TestSettingsTree
          activeId={activeID}
          setActiveId={(id: string | null) => setActiveId(id)}
        />
      )}
    </div>
  )
}
