import { InfoIcon } from "lucide-react"
import { GlobalSettingsOpenHandler } from "src/components/Settings/SettingsOpenHandler"
import { TPages } from "../../store/reducers/routing/routerSlice"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import styles from "./Header.module.css"
import { HomeIcon } from "src/shared/ui/IconComponent/HomeIcon"
import { TPMSIcon } from "src/shared/ui/IconComponent/TPMSIcon"
import { GeozonesIcon } from "src/shared/ui/IconComponent/GeozoneIcon"
import { setPage } from "src/store/reducers/routing/routerThunk"

interface INavItem {
  title: React.ReactNode
  link: TPages
}

const Header = () => {
  const roleID = useAppSelector((state) => state.auth.authData.role_id)

  const dispatch = useAppDispatch()
  const navItem: INavItem[] = [
    {
      title: (
        <>
          <HomeIcon />
        </>
      ),
      link: "main",
    },
    {
      title: (
        <>
          <TPMSIcon />
          СКДШ
        </>
      ),
      link: "tpms",
    },
    {
      title: (
        <>
          <GeozonesIcon />
          Трек
        </>
      ),
      link: "map",
    },
  ]

  return (
    <header className={styles.header}>
      <nav className={styles.header_nav}>
        <div className={styles.tab_routes}>
          {navItem.map((item, index) => {
            return (
              <div
                onClick={() => dispatch(setPage(item.link))}
                className={styles.tab_route}
                style={{
                  zIndex: index + 1,
                  transform: index === 0 ? "translateX(0)" : `translateX(-${index * 10}px)`,
                }}
              >
                {item.title}
              </div>
            )
          })}
        </div>
        <div className={styles.header_message}>
          <InfoIcon width={30} strokeWidth={2} height={30} />
          Сервис в активной разработке
        </div>
        <div style={{ paddingRight: "10px" }}>{roleID < 3 && <GlobalSettingsOpenHandler />}</div>
      </nav>
    </header>
  )
}

export default Header
