import { IPoint, IRange, ITrack } from "src/interfaces/track"
import React, { useMemo } from "react"
import GradientPolyline from "./GradientLine"
import { Track } from "src/entities/Track/Track"
import { Marker, Polyline } from "react-leaflet"
import L from "leaflet"

interface IProps {
  track: ITrack
  startLoading: () => void
  stopLoading: () => void
}

export const SpeedTrack: React.FC<IProps> = React.memo(({ track, startLoading, stopLoading }) => {
  const getTrack = useMemo(() => {
    let trackComponent: Array<React.ReactNode> = []
    let assemblyPoint: IPoint[] = []
    let isFirstGradient = false

    const addRenderSimple = (color, index) => {
      trackComponent.push(
        <Track
          track={{ ...track, points: assemblyPoint }}
          color={color}
          index={index}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />,
      )
      assemblyPoint = []
    }

    const addRenderGradient = (color: Array<string>, position: IPoint[], index) => {
      trackComponent.push(
        <GradientPolyline
          colors={color}
          position={{ ...track, points: position }}
          index={index}
          startLoading={startLoading}
        />,
      )
    }

    const addRenderDashLine = (position) => {
      trackComponent.push(
        <Polyline
          positions={position}
          pathOptions={{ color: "#9d9d9d", weight: 10 }}
          dashArray="5, 20"
        />,
      )
    }

    let prevRange: IRange = track.ranges[0]

    for (let indexPoint = 0; indexPoint < track.points.length; indexPoint++) {
      const point = track.points[indexPoint]

      if (indexPoint === 0) continue

      let prevSpeed = Math.round(track.points[indexPoint - 1].sp)
      let currentSpeed = Math.round(point.sp)
      let isLastPoint = track.points.length - 1 - indexPoint === 0

      for (let index = 0; index < track.ranges.length; index++) {
        const range = track.ranges[index]
        if (point.ed - track.points[indexPoint - 1].ed > track.time_limit) {
          addRenderSimple(prevRange.color, indexPoint)
          addRenderDashLine([
            [point.lt, point.ln],
            [track.points[indexPoint - 1].lt, track.points[indexPoint - 1].ln],
          ])
          break
        }

        let isLastReg = track.ranges.length - 1 - index === 0
        let isFirstReg = index === 0
        const color = range.color
        const nextColor =
          track.ranges.length - 1 - index === 0
            ? track.ranges[track.ranges.length - 1].color
            : track.ranges[index + 1].color

        const limitSpeed = range.speed as number

        if (isLastReg) break
        if (isFirstReg) {
          if (currentSpeed <= limitSpeed && prevSpeed <= limitSpeed) {
            assemblyPoint.push(track.points[indexPoint - 1])
            assemblyPoint.push(point)
            isLastPoint && addRenderSimple(color, indexPoint)
            break
          }
          if (currentSpeed > limitSpeed && prevSpeed <= limitSpeed) {
            assemblyPoint.length > 0 && addRenderSimple(color, indexPoint)
            addRenderGradient(
              [color, nextColor],
              [track.points[indexPoint - 1], point],
              index + indexPoint,
            )
            prevRange = range
          }
          if (currentSpeed <= limitSpeed && prevSpeed > limitSpeed) {
            assemblyPoint.length > 0 && addRenderSimple(prevRange.color, indexPoint)
            addRenderGradient(
              [prevRange.color, color],
              [track.points[indexPoint - 1], point],
              index + indexPoint,
            )
            isFirstGradient = true
            prevRange = track.ranges[index + 1]
            break
          }
          prevRange = track.ranges[index + 1]
          continue
        }

        if (currentSpeed <= limitSpeed && prevSpeed <= limitSpeed) {
          !isFirstGradient && assemblyPoint.push(track.points[indexPoint - 1])
          isFirstGradient = false
          assemblyPoint.push(point)
          isLastPoint && addRenderSimple(prevRange.color, indexPoint)
          prevRange = range
          break
        }
        if (
          currentSpeed > limitSpeed &&
          prevSpeed > limitSpeed &&
          track.ranges.length - 1 - index === 1
        ) {
          assemblyPoint.push(track.points[indexPoint - 1])
          assemblyPoint.push(point)
          isLastPoint && addRenderSimple(nextColor, indexPoint)
          prevRange = range
          break
        }
        if (currentSpeed > limitSpeed && prevSpeed <= limitSpeed) {
          assemblyPoint.length > 0 && addRenderSimple(prevRange.color, indexPoint)
          addRenderGradient(
            [color, nextColor],
            [track.points[indexPoint - 1], point],
            indexPoint + index,
          )
          prevRange = range
          break
        }
        if (currentSpeed <= limitSpeed && prevSpeed > limitSpeed) {
          assemblyPoint.length > 0 && addRenderSimple(nextColor, indexPoint)
          addRenderGradient(
            [nextColor, color],
            [track.points[indexPoint - 1], point],
            indexPoint + index,
          )
          prevRange = range
          break
        }
      }
    }
    return trackComponent
  }, [track])

  return <div>{getTrack.map((el) => el)}</div>
})
