import { CheckboxComponent } from "src/widgets/ObjectSettings/Content/helpComponents/CheckboxComponent"
import { TextareaComponent } from "src/widgets/ObjectSettings/Content/helpComponents/TextareaComponent"
import { noString } from "./../../../../../../shared/lib/helpers/notStringData"
import { useAppDispatch } from "../../../../../../shared/lib/hooks/store"
import { setGSFolderClientData } from "src/store/reducers/globalSettings/globalSettings"
import { thunkSaveClient } from "src/store/reducers/globalSettings/globalSettingsThunk"
import { IClientInfo } from "../ClientSettings"

interface IClientFields {
  value?: string
  check?: boolean
  name: string
  type: "checkbox" | "text"
  field: string
}

interface IClientContentProps {
  data: any
}

export const Content = ({ data }: IClientContentProps) => {
  const dispatch = useAppDispatch()
  const client: IClientInfo = noString(data)
  interface IUpdateHandler {
    value?: string
    check?: boolean
    field: string
  }
  const updateHandler = ({ value, check, field }: IUpdateHandler) => {
    let clientUpdate = JSON.parse(JSON.stringify(client))
    clientUpdate[field] = value || check
    dispatch(setGSFolderClientData(clientUpdate))
  }

  const fields: IClientFields[] = [
    {
      name: "Имя аккаунта",
      value: client.account_name,
      type: "text",
      field: "account_name",
    },
    {
      name: "Подтверждение по SMS",
      check: client.is_always_require_confirm,
      type: "checkbox",
      field: "is_always_require_confirm",
    },
    {
      name: "Запоминать 'USER-AGENT'",
      check: client.is_control_user_agent,
      type: "checkbox",
      field: "is_control_user_agent",
    },
    {
      name: "Одиночная сессия",
      check: client.is_one_session,
      type: "checkbox",
      field: "is_one_session",
    },
    {
      name: "Активен",
      check: client.is_enabled,
      type: "checkbox",
      field: "is_enabled",
    },
  ]
  return (
    <div className="relative w-full h-full flex items-center justify-center">
      <h1 className="absolute text-2xl font-light  top-0 left-0 w-full flex items-center justify-center h-[10%]">
        Профиль клиента
      </h1>
      <div className="h-[80%] flex flex-col gap-5 items-center justify-start text-left w-3/4">
        {fields.map((item, index) => (
          <div className="w-full flex items-center justify-between">
            <h3>{item.name}</h3>
            <div className="">
              {item.type == "text" && (
                <TextareaComponent
                  value={item.value ? item.value : ""}
                  onChange={(e) =>
                    updateHandler({
                      value: e.target.value,
                      field: item.field,
                    })
                  }
                />
              )}
              {item.type == "checkbox" && (
                <CheckboxComponent
                  value={item.check ? item.check : false}
                  onChange={(e) =>
                    updateHandler({
                      check: e.target.checked,
                      field: item.field,
                    })
                  }
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="absolute text-2xl  bottom-0 left-0 w-full flex items-center justify-center h-[10%]">
        <div
          onClick={() => {
            dispatch(thunkSaveClient(client))
          }}
          className="bg-orange-500 hover:bg-orange-600 active:bg-orange-700 px-10 cursor-pointer rounded text-white py-2"
        >
          Применить
        </div>
      </div>
    </div>
  )
}
