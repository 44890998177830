import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface ICatalog {
  id: number
  comment: string
  name: string
}

interface ICatalogState {
  catalogTire: ICatalog[]
  activeTire: { tire: ICatalog; index: number } | null
  searchCatalog: string
}

const initialState: ICatalogState = {
  catalogTire: [],
  activeTire: null,
  searchCatalog: "",
}

const catalogSlice = createSlice({
  name: "catalogTire",
  initialState,
  reducers: {
    setCatalog: (state: ICatalogState, action: PayloadAction<ICatalog[] | []>) => {
      state.catalogTire = action.payload
    },
    setNewTire: (state: ICatalogState, action: PayloadAction<ICatalog>) => {
      state.catalogTire.unshift(action.payload)
    },
    setActiveTire: (
      state: ICatalogState,
      action: PayloadAction<{ tire: ICatalog; index: number } | null>,
    ) => {
      state.activeTire = action.payload
    },
    setActiveTireID: (state: ICatalogState, action: PayloadAction<number>) => {
      const index = state.catalogTire.findIndex((el) => el.id === action.payload)
      state.activeTire = { tire: state.catalogTire[index], index: index }
    },
    setSearchCatalog: (state: ICatalogState, action: PayloadAction<string>) => {
      state.searchCatalog = action.payload
    },
  },
})

export const { setCatalog, setNewTire, setActiveTire, setActiveTireID, setSearchCatalog } =
  catalogSlice.actions
export default catalogSlice.reducer
