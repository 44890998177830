import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IGSMainState {
  account_id: number | string
  is_enabled: boolean
  plugin_id: number
  sim1: string
  sim2: string
  vehicle_id: number | string
  vehicle_name: string
  vehicle_pwd: string
}

interface IGSMainInfo {
  account_id: number
  is_enabled: boolean
  plugin_id: number
  sim1: string
  sim2: string
  vehicle_id: number
  vehicle_name: string
  vehicle_pwd: string
}

const initialState: IGSMainState = {
  account_id: 0,
  is_enabled: true,
  plugin_id: 0,
  sim1: "",
  sim2: "",
  vehicle_id: "",
  vehicle_name: "",
  vehicle_pwd: "",
}

const rootOSettingsMain = createSlice({
  name: "OSettingsMain",
  initialState,
  reducers: {
    setOSMainData: (state: IGSMainState, action: PayloadAction<IGSMainInfo>) => {
      state.account_id = action.payload.account_id
      state.is_enabled = action.payload.is_enabled
      state.plugin_id = action.payload.plugin_id
      state.sim1 = action.payload.sim1
      state.sim2 = action.payload.sim2
      state.vehicle_id = action.payload.vehicle_id
      state.vehicle_name = action.payload.vehicle_name
      state.vehicle_pwd = action.payload.vehicle_pwd
    },
    setOSAccountData: (state: IGSMainState, action: PayloadAction<string | number>) => {
      state.account_id = action.payload
    },
    setOSEnabled: (state: IGSMainState) => {
      state.is_enabled = !state.is_enabled
    },
    setOSPluginId: (state: IGSMainState, action: PayloadAction<number>) => {
      state.plugin_id = action.payload
    },
    setOSSim1: (state: IGSMainState, action: PayloadAction<string>) => {
      state.sim1 = action.payload
    },
    setOSSim2: (state: IGSMainState, action: PayloadAction<string>) => {
      state.sim2 = action.payload
    },
    setOSVehicleId: (state: IGSMainState, action: PayloadAction<string | number>) => {
      state.vehicle_id = action.payload
    },
    setOSVehicleName: (state: IGSMainState, action: PayloadAction<string>) => {
      state.vehicle_name = action.payload
    },
    setOSVehiclePwd: (state: IGSMainState, action: PayloadAction<string>) => {
      state.vehicle_pwd = action.payload
    },
  },
})

export const {
  setOSMainData,
  setOSAccountData,
  setOSEnabled,
  setOSPluginId,
  setOSSim1,
  setOSSim2,
  setOSVehicleId,
  setOSVehicleName,
  setOSVehiclePwd,
} = rootOSettingsMain.actions

export default rootOSettingsMain.reducer
