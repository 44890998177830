import axios from "axios"

let originUrl = ""
export let currentService = "svr"

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  originUrl = "https://testonline.arsa.pro/api/"
} else {
  originUrl =
    window.location.origin === "https://arsa-dev-tpms.vercel.app"
      ? "https://testonline.arsa.pro/api/"
      : `${window.location.origin}/api/`
}

export let serverInstance = axios.create({
  baseURL: originUrl,
})

export const setCurrentService = (service: string) => {
  currentService = service
}

export const createInstanceServer = (token: string) => {
  serverInstance = axios.create({
    baseURL: originUrl,
    headers: { "X-Auth": token, "Content-Type": "application/json" },
  })
}
export const returnErrorFetch = (error: any) => {
  if (error.response) {
    return { data: error.response.data, status: error.response.status }
  } else {
    return { data: null, status: 500 } // Пример статуса 500 в случае отсутствия ответа от сервера
  }
}
