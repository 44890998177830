import React, { useState } from "react"
import { Search } from "../../Search/Search"
import { RootGroupCheckbox } from "../../RootGroupCheckbox/RootGroupCheckbox"
import { useAppDispatch, useAppSelector } from "../../../../shared/lib/hooks/store"
import {
  groupChecked,
  setFilter,
  setIsOpenAll,
  setSearchVehicle,
} from "../../../../store/reducers/vehiclesTree/vehicleTreeSlice"
import { changeGroupCreateVehicle } from "../../../../store/reducers/vehiclesTree/vehicleTreeThunk"
import { PopupNewGroup } from "../../PopupNewGroup/PopupNewGroup"
import { ChartVehicle } from "../ChartVehicle/ChartVehicle"
import { KeyIcon } from "src/shared/ui/IconComponent/KeyIcon"
import { VehicleIcon } from "src/shared/ui/IconComponent/VehicleIcon"
import { CartIcon } from "src/shared/ui/IconComponent/CartIcon"
import { SatelliteIcon } from "src/shared/ui/IconComponent/SatelliteIcon"
import { AlarmIcon } from "src/shared/ui/IconComponent/AlarmIcon"
import Arrow from "src/shared/ui/IconComponent/Arrow"

export const VehicleHeaderTree = () => {
  const dispatch = useAppDispatch()
  const searchVehicle = useAppSelector((state) => state.vehicles.search)
  const onSearchVehicle = (value: string) => dispatch(setSearchVehicle(value))
  const rootVehicles: any = useAppSelector((state) => state.vehicles.vehicleTree)
  const filters = useAppSelector((state) => state.vehicles.filters)
  const isOpenAll = useAppSelector((state) => state.vehicles.isOpenAll)
  const roleID = useAppSelector((state) => state.auth.authData.role_id)

  const [newGroup, setNewGroup] = useState({
    group_name: "",
    account_id: 0,
    level: 0,
    isOpen: false,
  })

  return (
    <>
      <PopupNewGroup
        value={newGroup.group_name}
        isOpen={newGroup.isOpen}
        setValue={(value) => {
          setNewGroup((prev) => ({ ...prev, group_name: value }))
        }}
        setOpen={(isOpen) => {
          setNewGroup((prev) => ({ ...prev, isOpen: isOpen }))
        }}
        createGroup={() => {
          dispatch(
            changeGroupCreateVehicle(
              rootVehicles[0].id,
              rootVehicles[0].account_id,
              newGroup.group_name,
              newGroup.level,
            ),
          )
          setNewGroup({ group_name: "", account_id: 0, isOpen: false, level: 0 })
        }}
      />
      <div className="tree__search">
        {!searchVehicle ? (
          <span className="icon-search"></span>
        ) : (
          <span className="icon-cross" onClick={() => onSearchVehicle("")}></span>
        )}
        <Search
          value={searchVehicle}
          setValue={(value) => onSearchVehicle(value)}
          placeholderInput="Поиск ТС"
        />
      </div>
      <ChartVehicle />
      <div className="tree__header">
        <div style={{ display: "flex", alignItems: "center", gap: "5px", padding: "5px 10px" }}>
          {rootVehicles ? (
            <RootGroupCheckbox
              status={rootVehicles[0]?.status ? rootVehicles[0].status : "none"}
              onChecked={() => dispatch(groupChecked(rootVehicles[0]))}
            />
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "var(--main-color)",
            }}
            onClick={() => {
              dispatch(setIsOpenAll(!isOpenAll))
            }}
          >
            <span className={isOpenAll ? "icon-folder-open" : "icon-folder"}></span>
          </div>
          {roleID === 3 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "var(--main-color)",
              }}
              onClick={() => {
                setNewGroup((prev) => ({ ...prev, isOpen: true }))
              }}
            >
              <span className={"icon-folder-add"}></span>
            </div>
          )}
        </div>
        <div className="tree_filter">
          <div
            className="tree_filter_name"
            onClick={() => {
              dispatch(
                setFilter(
                  filters === "firstAlphabetName"
                    ? "lastAlphabetName"
                    : filters === "lastAlphabetName"
                      ? "none"
                      : "firstAlphabetName",
                ),
              )
            }}
          >
            Наименования
            <div
              style={{
                display:
                  filters !== "firstAlphabetName" && filters !== "lastAlphabetName"
                    ? "none"
                    : "block",
                transform: `rotate(${filters === "firstAlphabetName" ? "180" : "0"}deg)`,
              }}
            >
              <Arrow width={10} fill={"#000"} />
            </div>
          </div>

          <div className={`tree__icon ${roleID === 1 ? "tree_icon_more" : ""}`}>
            <div
              onClick={() =>
                dispatch(
                  setFilter(
                    filters === "ignitionFirstOn"
                      ? "ignitionFirstOff"
                      : filters === "ignitionFirstOff"
                        ? "none"
                        : "ignitionFirstOn",
                  ),
                )
              }
            >
              <KeyIcon
                height={20}
                color={
                  filters === "ignitionFirstOn"
                    ? "green"
                    : filters === "ignitionFirstOff"
                      ? "red"
                      : "#000"
                }
              />
            </div>
            <div
              onClick={() =>
                dispatch(
                  setFilter(filters === "move" ? "noMove" : filters === "noMove" ? "none" : "move"),
                )
              }
            >
              <VehicleIcon
                height={20}
                color={filters === "move" ? "green" : filters === "noMove" ? "red" : "#000"}
              />
            </div>
            <div
              onClick={() =>
                dispatch(
                  setFilter(
                    filters === "cart"
                      ? "cartNoneDriver"
                      : filters === "cartNoneDriver"
                        ? "noneCart"
                        : filters === "noneCart"
                          ? "none"
                          : "cart",
                  ),
                )
              }
            >
              <CartIcon
                height={20}
                color={
                  filters === "cart"
                    ? "green"
                    : filters === "cartNoneDriver"
                      ? "red"
                      : filters === "noneCart"
                        ? "#474747"
                        : "#000"
                }
              />
            </div>
            <div
              onClick={() =>
                dispatch(
                  setFilter(
                    filters === "greenGsm"
                      ? "yellowGsm"
                      : filters === "yellowGsm"
                        ? "redGsm"
                        : filters === "redGsm"
                          ? "none"
                          : "greenGsm",
                  ),
                )
              }
            >
              <SatelliteIcon
                height={20}
                color={
                  filters === "greenGsm"
                    ? "green"
                    : filters === "yellowGsm"
                      ? "yellow"
                      : filters === "redGsm"
                        ? "red"
                        : "#000"
                }
              />
            </div>
            <div
              onClick={() =>
                dispatch(
                  setFilter(
                    filters === "online"
                      ? "recentlyOnline"
                      : filters === "recentlyOnline"
                        ? "longTimeOnline"
                        : filters === "longTimeOnline"
                          ? "neverOnline"
                          : filters === "neverOnline"
                            ? "none"
                            : "online",
                  ),
                )
              }
            >
              <AlarmIcon
                height={20}
                color={
                  filters === "online"
                    ? "green"
                    : filters === "recentlyOnline"
                      ? "yellow"
                      : filters === "longTimeOnline"
                        ? "red"
                        : filters === "neverOnline"
                          ? "#474747"
                          : "#000"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
