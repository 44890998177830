interface ISettingsMenu {
  name: string
  hand: string
}

export const settingsMenu: ISettingsMenu[] = [
  {
    name: "Основное",
    hand: "main",
  },
  {
    name: "Регистрация водителя",
    hand: "driver_cards",
  },
  {
    name: "Контроль неисправностей",
    hand: "diag",
  },
  {
    name: "Контроль скорости",
    hand: "speed_control",
  },
  {
    name: "Трек",
    hand: "track",
  },
  {
    name: "СКДШ",
    hand: "tpms",
  },
  {
    name: "Движение",
    hand: "movement",
  },
  {
    name: "Топливо",
    hand: "fuel",
  },
]
