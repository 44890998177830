import React, { useCallback } from "react"
import { onlyNumber } from "src/components/Settings/Content/onlyNumber"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { Input } from "src/shared/ui/Input"
import { setRestoreRegistrationIfCardReappliedWithin } from "src/store/reducers/objectSettings/objectSettingsPageSlices/driver-cards/driver-cards"

export const RestoreReg = React.memo(() => {
  const dispatch = useAppDispatch()
  const restore_registration_if_card_reapplied_within = useAppSelector(
    (state) => state.osDriverCard.driver_events.restore_registration_if_card_reapplied_within,
  )

  const setRestoreTime = useCallback(
    (totalSeconds: number | "") =>
      dispatch(setRestoreRegistrationIfCardReappliedWithin(totalSeconds)),
    [dispatch],
  )

  type TTimeNames = "min" | "sec"

  interface IHandleTimeProps {
    value: "" | number
    type: TTimeNames
  }

  const handleTime = ({ value, type }: IHandleTimeProps) => {
    let minutes = 0
    let seconds: number | "" = ""

    if (typeof restore_registration_if_card_reapplied_within === "number") {
      minutes = Math.floor(restore_registration_if_card_reapplied_within / 60)
      seconds = restore_registration_if_card_reapplied_within % 60
    }

    if (type === "min") {
      minutes = value === "" ? 0 : Number(value)
    } else if (type === "sec") {
      seconds = value === "" ? "" : Number(value)
    }

    const totalSeconds = seconds === "" ? "" : minutes * 60 + seconds
    setRestoreTime(totalSeconds)
  }

  return (
    <div className="w-full flex items-center justify-start gap-4">
      <div className="flex items-center justify-between gap-2 w-1/2">
        <div className="border border-[#da7b39] rounded-[3px] w-[3rem]">
          <Input
            inputType={""}
            value={
              typeof restore_registration_if_card_reapplied_within === "number"
                ? Math.floor(restore_registration_if_card_reapplied_within / 60).toString()
                : ""
            }
            setValue={(text) =>
              handleTime({
                value: onlyNumber(text),
                type: "min",
              })
            }
          />
        </div>
        <p>мин.</p>
      </div>
      <div className="flex items-center justify-start gap-2 w-1/2">
        <div className="border w-[3rem] border-[#da7b39] rounded-[3px]">
          <Input
            inputType={""}
            value={
              typeof restore_registration_if_card_reapplied_within === "number"
                ? (restore_registration_if_card_reapplied_within % 60).toString()
                : ""
            }
            setValue={(text: any) =>
              handleTime({
                value: onlyNumber(text),
                type: "sec",
              })
            }
          />
        </div>
        <p>сек.</p>
      </div>
    </div>
  )
})
