import { findPath } from "src/shared/ui/SettingsTree/helpers/findPath"
import { organizeUsers } from "src/shared/ui/SettingsTree/userHelper"

export function usersSearchHelper(data: any[], searchTerm: string): any[] {
  const lowerSearchTerm = searchTerm.toLowerCase()

  // Function to flatten user data
  function flattenUserData(data: any[], parent: any = null): any[] {
    const result: any[] = []
    const itemMap: { [id: string]: any } = {}

    const levelUsers: any = organizeUsers(data)

    function findAccountId(parent: any): number | null {
      if (parent?.is_root && parent?.role_id === 3 && parent?.is_folder) {
        return parent.id
      }
      if (parent?.parent_id && itemMap[parent.parent_id]) {
        return findAccountId(itemMap[parent.parent_id])
      }
      return null
    }

    function addItem(item: any, parent: any): void {
      // Пропускать элементы с role_id 1 или 2
      if (item.role_id === 1 || item.role_id === 2) {
        return
      }

      const pathName = `${parent?.path?.pathName || "root"}/${item.account_name || ""}`
      const folder: any = {
        ...item,
        name: item.account_name || "",
        children: [], // Initialize with an empty array of children
        path: findPath(levelUsers, item.id, true),
        is_folder: true, // Set is_folder to true for groups
      }

      // Add item to the map
      itemMap[item.id] = folder

      // If there are users, add them as children
      if (item.users) {
        const accountId = findAccountId(folder)
        folder.children = item.users
          .filter((user: any) => user.is_enabled) // Фильтр только активных пользователей
          .map((user: any) => ({
            ...user,
            name: user.user_name, // Присваиваем имя пользователя
            children: [], // У пользователей нет детей
            path: findPath(levelUsers, user.id, false),
            is_folder: false, // Пользователи — не папки
            account_id: accountId, // account_id based on the first qualifying parent
          }))
        result.push(...folder.children)
      }

      // Если есть вложенные группы, рекурсивно обрабатываем их
      if (item.children) {
        item.children.forEach((child: any) => addItem(child, folder))
      }

      // Добавляем группу в результат, если она имеет пользователей или детей
      result.push(folder)
    }

    data.forEach((item) => addItem(item, parent))

    // Build hierarchy based on parent-child relationships
    for (const id in itemMap) {
      const item = itemMap[id]
      if (item.parent_id && itemMap[item.parent_id]) {
        if (!itemMap[item.parent_id].children) {
          itemMap[item.parent_id].children = []
        }
        itemMap[item.parent_id].children.push(item)
      }
    }

    return result
  }

  // Function to filter groups and users by the search term
  const flattened = flattenUserData(data)

  const returnedArr = flattened.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return returnedArr
}
