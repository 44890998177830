import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IOSIdState {
  vehicle_uid: null | string
  groups_tree_id: null | number
  create?: boolean
  data?: boolean
}

const initialState: IOSIdState = {
  vehicle_uid: null,
  groups_tree_id: null,
  create: false,
  data: false,
}

const rootOSIdSlice = createSlice({
  name: "OSId",
  initialState,
  reducers: {
    setOSVehicleUid: (state: IOSIdState, action: PayloadAction<string | null>) => {
      state.vehicle_uid = action.payload
    },
    setOSGroupsTreeId: (state: IOSIdState, action: PayloadAction<number | null>) => {
      state.groups_tree_id = action.payload
    },
    setOSIDs: (state: IOSIdState, action: PayloadAction<IOSIdState>) => {
      state.groups_tree_id = action.payload.groups_tree_id
      state.vehicle_uid = action.payload.vehicle_uid
      if (action.payload.create != undefined) state.create = action.payload.create
      if (action.payload.data != undefined) state.data = action.payload.data
    },
    setFetchOSState: (state: IOSIdState, action: PayloadAction<boolean>) => {
      state.data = action.payload
    },
    setCreateObject: (state: IOSIdState, action: PayloadAction<boolean>) => {
      state.create = action.payload
    },
  },
})

export const { setOSVehicleUid, setCreateObject, setOSGroupsTreeId, setOSIDs, setFetchOSState } =
  rootOSIdSlice.actions
export default rootOSIdSlice.reducer
