import { AppDispatch, RootState } from "@/store/store"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setFolderData } from "src/store/reducers/globalSettings/globalSettings"

export const ForbiddenAlert = () => {
  const state = useSelector((state: RootState) => state.globalSettings.folder.status)
  const dispatch = useDispatch<AppDispatch>()
  const [time, setTime] = useState(5000) // total time in milliseconds
  const [progress, setProgress] = useState(100) // progress in percentage

  useEffect(() => {
    let timer: NodeJS.Timeout
    let progressInterval: NodeJS.Timeout

    if (state == "forbidden") {
      setTime(5000)
      setProgress(100)
      timer = setTimeout(() => {
        dispatch(setFolderData({ status: null }))
      }, 5000)

      progressInterval = setInterval(() => {
        setTime((prev) => {
          const newTime = prev - 100
          setProgress((newTime / 5000) * 100)
          return newTime
        })
      }, 100)
    }

    return () => {
      clearTimeout(timer)
      clearInterval(progressInterval)
    }
  }, [state, dispatch])

  return (
    <div
      style={{
        right: state == "forbidden" ? "0" : "-120%",
      }}
      className="absolute transition-all z-[100] rounded top-[10%] bg-red-500 p-4 text-white"
    >
      <div className="text-xl">
        <span className=""></span>
        Удаление запрещено
      </div>

      <div className="font-light">Имеются вложенные элементы</div>

      {state == "forbidden" && (
        <div className="w-full rotate-180 bg-red-300 mt-2">
          <div style={{ width: `${progress}%` }} className="h-1 bg-red-700 transition-all"></div>
        </div>
      )}
    </div>
  )
}
