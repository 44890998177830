import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../../../../shared/lib/hooks/store"
import {
  setGSettingsItemSettingsID,
  setGSItemData,
} from "src/store/reducers/globalSettings/globalSettings"
import {
  thunkGetUserFolders,
  thunkGetUserInfo,
} from "src/store/reducers/globalSettings/globalSettingsThunk"
import { IUserInfo } from "./../../../../../store/reducers/globalSettings/interface"
import { AppDispatch } from "./../../../../../store/store"
import { Content } from "./Content/Content"
import defaultData from "./defaultUser.json"

export const UserSettings = (id: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const dataU = useAppSelector((state) => state.globalSettings.tree.users.itemSettings.settingsData)
  const user: IUserInfo | null = dataU != null && typeof dataU != "string" ? dataU : null
  const data: any = defaultData
  const accID = useAppSelector((state) => state.globalSettings.tree.users.activeAccountID)
  const folders = useAppSelector(
    (state) => state.globalSettings.tree.objects.itemSettings.treeWithoutItems,
  )
  useEffect(() => {
    if (id.id == 0) {
      !dataU &&
        dispatch(
          setGSItemData({
            data,
            field: "users",
          }),
        )
    } else {
      !dataU && dispatch(thunkGetUserInfo(id.id))
    }

    folders == null && dispatch(thunkGetUserFolders())
  }, [])

  return (
    <div className="w-screen z-[3000] h-screen absolute flex items-center justify-center backdrop-blur-lg top-0 bottom-0">
      <div
        style={{
          boxShadow: "0px 0px 50px 3px black",
        }}
        className="w-fit overflow-hidden transition-all h-[80%] relative flex flex-col items-center cursor-pointer justify-start rounded bg-white "
      >
        <div
          onClick={() => {
            dispatch(setGSettingsItemSettingsID({ id: null, field: "users" }))
            dispatch(
              setGSItemData({
                data: null,
                field: "users",
              }),
            )
          }}
          className="absolute right-0 top-0 p-4 text-xl text-red-500 icon-cross"
        ></div>
        <h1 className="text-center py-10 h-[10%] backdrop-blur-lg text-3xl font-light">
          Профиль пользователя
        </h1>
        <div className="w-full h-[90%] flex items-center justify-center">
          {user && folders ? (
            <Content user={dataU} accountID={accID ? accID : 0} />
          ) : (
            <>Loading...</>
          )}
        </div>
      </div>
    </div>
  )
}
