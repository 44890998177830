import React, { useEffect, useState } from "react"
import styles from "./styles.module.css"

interface ItableData {
  headerTable: Array<{ title: React.ReactNode | string | number; widthColumn: number }>
  rows: Array<Array<React.ReactNode | string | number>>
}

interface Iprops {
  tableData: ItableData
  rowIndexActive?: number | null
  setRowIndexActive?: (index: number) => void
  indexCol?: React.ReactNode | string | number
  className?: string
}

export const Table: React.FC<Iprops> = ({
  tableData,
  rowIndexActive,
  setRowIndexActive,
  indexCol,
  className,
}) => {
  const [tableDateLocal, setTableDateLocal] = useState(tableData)

  useEffect(() => {
    setTableDateLocal(tableData)
  }, [tableData])

  return (
    <div className={`${styles.table} ${className ? className : ""}`}>
      <div className={styles.table_head}>
        {indexCol ? (
          <div className={`${styles.table_item} ${styles.table_item_index}`}>{indexCol}</div>
        ) : null}

        {tableDateLocal.headerTable.map((el) => {
          return (
            <div
              className={styles.table_item}
              style={{
                width: `${el.widthColumn}%`,
              }}
            >
              {el.title}
            </div>
          )
        })}
      </div>

      {tableDateLocal.rows.map((row, index) => {
        return (
          <div
            className={`${styles.table_row} ${rowIndexActive === index ? styles.table_row_active : ""}`}
            onClick={() => (setRowIndexActive ? setRowIndexActive(index) : null)}
          >
            {indexCol ? (
              <div className={`${styles.table_item} ${styles.table_item_index}`}>{index + 1}</div>
            ) : null}
            {row.map((el, index) => {
              return (
                <div
                  className={styles.table_item}
                  style={{
                    width: `${tableDateLocal.headerTable[index].widthColumn}%`,
                  }}
                >
                  {el}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
