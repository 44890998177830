import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { LoadingWrapper } from "src/components/LoadingWrapper/LoadingWrapper"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { setDiagState } from "src/store/reducers/objectSettings/objectSettingsPageSlices/diag/diag"
import { setDriverCardState } from "src/store/reducers/objectSettings/objectSettingsPageSlices/driver-cards/driver-cards"
import { setFuelState } from "src/store/reducers/objectSettings/objectSettingsPageSlices/fuel/fuel"
import { setOSIDs } from "src/store/reducers/objectSettings/objectSettingsPageSlices/id/osId"
import { setOSMainData } from "src/store/reducers/objectSettings/objectSettingsPageSlices/main/main"
import { setMovementState } from "src/store/reducers/objectSettings/objectSettingsPageSlices/movement/movement"
import { setSpeedControlState } from "src/store/reducers/objectSettings/objectSettingsPageSlices/speed-control/speed-control"
import { setTrackState } from "src/store/reducers/objectSettings/objectSettingsPageSlices/track/track"
import { thunkGetObjectSettings } from "src/store/reducers/objectSettings/objectSettingsThunk"
import { AppDispatch } from "src/store/store"
import { ObjectSettingsContent } from "./Content/ObjectSettingsContent"
import { ObjectSettingsNavbar } from "./Navbar/ObjectSettingsNavbar"
import { structureObject } from "./object-settings-structure"
import styles from "./styles/object-settings.module.css"

export const ObjectSettings = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>()
  let creator: any = structuredClone(structureObject)
  creator.main.account_id = undefined
  const { data, create, vehicle_uid } = useAppSelector((state) => state.osId)
  const [page, setPage] = useState<string>("main")
  useEffect(() => {
    if (!vehicle_uid) {
      dispatch(setOSMainData(creator.main))

      dispatch(setMovementState(creator.movement))
      dispatch(setDiagState(creator.diag))
      dispatch(setTrackState(creator.track))
      dispatch(setSpeedControlState(creator.speed_control))
      dispatch(setFuelState(creator.fuel))
      dispatch(setDriverCardState(creator.driver_cards))
    } else {
      dispatch(thunkGetObjectSettings(vehicle_uid))
    }
  }, [])

  return (
    <div className={styles.os}>
      <div className={styles.os_container}>
        <div className={styles.os_header}>
          <h1>Профиль объекта</h1>
          <button
            onClick={() => {
              dispatch(
                setOSIDs({
                  vehicle_uid: null,
                  groups_tree_id: null,
                }),
              )
            }}
          >
            Выйти
          </button>
        </div>

        <LoadingWrapper isLoading={data != undefined ? !data : true}>
          <div className={styles.os_content_container}>
            <ObjectSettingsNavbar page={page} setPage={(a: string) => setPage(a)} />
            <ObjectSettingsContent page={page} />
          </div>
        </LoadingWrapper>
      </div>
    </div>
  )
})
