import styles from "../styles/os-item.module.css"
import { CustomProtocolMonitoring } from "./innerComponents/CustomProtocolMonitoring"
import { DriverEvents } from "./innerComponents/DriverEvents"

export const Driver = (driver: any) => {
  return (
    <div className={styles.os_main}>
      <h1>Регистрация водителя</h1>
      <DriverEvents />
      <CustomProtocolMonitoring />
    </div>
  )
}
