import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { Popup } from "src/shared/ui/Popup"
import { Table } from "src/shared/ui/Table"
import { useEffect, useMemo, useState } from "react"
import styles from "./CatalogTire.module.css"
import { Button } from "src/shared/ui/Button"
import EditorIcon from "src/shared/ui/IconComponent/EditorIcon"
import DeleteIcon from "src/shared/ui/IconComponent/DeleteIcon"
import { useLoading } from "src/shared/lib/hooks/loading"
import { LoadingWrapper } from "src/components/LoadingWrapper/LoadingWrapper"
import {
  deleteWheelModelThunk,
  getCatalogThunk,
} from "src/store/reducers/catalogTire/catalogTireThunk"
import { setActiveTire } from "src/store/reducers/catalogTire/catalogTire"
import { filterByCatalogName } from "./model/helpers"
import { Input } from "src/shared/ui/Input"
import { ArrowCircle } from "src/shared/ui/IconComponent/ArrowCircle"
import AddIcon from "src/shared/ui/IconComponent/AddIcon"

interface Iprops {
  onClose: () => void
  setEditTire: () => void
  footer: React.ReactNode
}

export const CatalogTire: React.FC<Iprops> = ({ onClose, setEditTire, footer }) => {
  const dispatch = useAppDispatch()

  const catalogTire = useAppSelector((state) => state.catalogTire.catalogTire)

  const { loading, startLoading, stopLoading } = useLoading()
  const account_id = useAppSelector((state) => state.car.data?.data?.account_id)
  const activeIndexRowCatalogTire = useAppSelector((state) => state.catalogTire.activeTire?.index)

  const [search, setSearch] = useState("")

  useEffect(() => {
    if (!account_id) return
    startLoading()
    dispatch(getCatalogThunk(account_id))
  }, [])

  useEffect(() => {
    if (catalogTire && !activeIndexRowCatalogTire) {
      dispatch(setActiveTire({ tire: catalogTire[0], index: 0 }))
    }
  }, [catalogTire])

  const updateCatalogTire = () => {
    if (!account_id) return
    startLoading()
    dispatch(getCatalogThunk(account_id))
  }

  const deleteItemCatalog = () => {
    if (typeof activeIndexRowCatalogTire !== "number" || !account_id) return
    startLoading()
    dispatch(deleteWheelModelThunk(catalogTire[activeIndexRowCatalogTire].id, account_id))
  }

  const filterCatalogTire = useMemo(() => {
    return filterByCatalogName(catalogTire, search)
  }, [search, catalogTire])

  const tableValidMemo = useMemo(() => {
    stopLoading()
    return {
      headerTable: [
        {
          title: "Производитель",
          widthColumn: 30,
        },
        {
          title: "Коментарий",
          widthColumn: 70,
        },
      ],
      rows: filterCatalogTire?.length
        ? filterCatalogTire?.map((row) => {
            return [row.name, row.comment]
          })
        : [],
    }
  }, [filterCatalogTire])

  return (
    <>
      <Popup title="Справочник" onClose={() => onClose()} className={styles.catalogtire_popup}>
        <LoadingWrapper isLoading={loading}>
          <div className={styles.catalogtire_header}>
            <div className={styles.catalogtire_box}>
              <div>
                <Button onClick={() => updateCatalogTire()}>
                  <span className="bright">
                    <ArrowCircle width={20} fill={"#FF6700"} />
                  </span>
                  Обновить
                </Button>
                <Button
                  onClick={() => {
                    onClose()
                    setEditTire()
                    dispatch(setActiveTire(null))
                  }}
                >
                  <span className="bright">
                    <AddIcon height={20} fill={"#FF6700"} />
                  </span>
                  Добавить
                </Button>
                <div className={styles.search}>
                  <Input
                    value={search}
                    setValue={(value) => setSearch(value)}
                    inputType="text"
                    placeholder="Поиск..."
                    size="medium"
                  />
                  {!search ? (
                    <span className="icon-search"></span>
                  ) : (
                    <span
                      className={`${styles.cross} icon-cross`}
                      onClick={() => setSearch("")}
                    ></span>
                  )}
                </div>
              </div>
              <div>
                <Button
                  onClick={() => {
                    onClose()
                    setEditTire()
                  }}
                >
                  <EditorIcon fill={"#474747"} height={20} />
                </Button>
                <Button onClick={() => deleteItemCatalog()}>
                  <DeleteIcon fill={"#E94040"} height={20} />
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.catalogtire_wrapper}>
            <Table
              tableData={tableValidMemo}
              indexCol="№"
              rowIndexActive={activeIndexRowCatalogTire}
              setRowIndexActive={(index) =>
                dispatch(setActiveTire({ tire: catalogTire[index], index: index }))
              }
            />
          </div>
          {footer && footer}
        </LoadingWrapper>
      </Popup>
    </>
  )
}
