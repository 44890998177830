import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import React from "react"
import L from "leaflet"
import { Marker } from "react-leaflet"
import { getInfoPointTrack } from "src/store/reducers/mapTrack/mapTrackThunk"

interface IProps {
  startLoading?: () => void
}

export const LastPosition: React.FC<IProps> = ({ startLoading }) => {
  const dispatch = useAppDispatch()
  const tracks = useAppSelector((state) => state.mapTrack.tracks)

  return (
    <>
      {tracks.map((track) => {
        const lastPos = new L.DivIcon({
          iconSize: [40, 30],
          html: `<div id='last-pos'><svg xmlns="http://www.w3.org/2000/svg" style="transform-origin: center; transform: rotateZ(${track?.lastPos?.dir ? track?.lastPos?.dir - 90 : 0}deg)" version="1.0" width="40px" height="30px" viewBox="0 0 1280.000000 899.000000" preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,899.000000) scale(0.100000,-0.100000)" fill="${track.color}" stroke="none">
<path d="M866 8974 c-141 -34 -282 -144 -350 -273 -71 -135 -66 102 -66 -2969 0 -2749 0 -2773 20 -2812 11 -21 36 -51 57 -67 l36 -28 3144 -3 c3139 -2 3144 -2 3183 18 22 11 52 39 67 62 l28 42 3 2768 c2 2429 0 2777 -13 2841 -39 187 -166 333 -353 406 l-67 26 -2815 2 c-2404 1 -2824 -1 -2874 -13z"/>
<path d="M7790 7846 c-138 -40 -221 -119 -262 -248 -17 -58 -18 -160 -18 -2694 l0 -2634 -1410 0 -1409 0 51 -47 c210 -197 376 -496 435 -785 l18 -88 1487 0 1487 0 6 38 c78 457 379 868 792 1080 667 341 1471 147 1907 -462 133 -186 208 -368 262 -634 l4 -22 753 0 753 0 50 25 c28 14 60 40 74 62 l25 36 3 316 c2 203 -1 329 -7 352 -15 49 -45 86 -92 109 -35 18 -59 20 -283 20 l-245 0 -4 1348 c-3 1498 2 1374 -72 1599 -47 142 -100 243 -228 433 -572 853 -1102 1630 -1159 1698 -203 242 -497 413 -835 484 -107 22 -113 23 -1073 25 -770 2 -974 -1 -1010 -11z m2207 -1005 c36 -20 108 -119 490 -660 246 -350 458 -656 470 -680 27 -52 29 -97 8 -148 -21 -50 -42 -72 -90 -94 -38 -18 -96 -19 -1213 -19 -1033 0 -1177 2 -1209 15 -50 21 -72 42 -94 90 -17 38 -19 81 -19 708 0 728 -2 704 58 760 61 58 43 57 832 54 l725 -2 42 -24z"/>
<path d="M105 2253 c-47 -24 -73 -53 -90 -97 -13 -35 -15 -90 -13 -362 l3 -321 25 -37 c15 -22 45 -47 75 -61 l50 -25 1034 2 1034 3 18 87 c56 271 191 525 387 729 l94 99 -1293 0 c-1160 -1 -1297 -2 -1324 -17z"/>
<path d="M3520 2255 c-240 -40 -481 -170 -641 -343 -418 -455 -407 -1145 26 -1577 125 -126 276 -219 443 -275 414 -139 871 -27 1172 286 143 148 236 311 288 502 24 88 26 114 26 287 1 178 -1 196 -27 289 -121 437 -469 753 -911 831 -105 18 -271 18 -376 0z m329 -570 c182 -43 342 -196 402 -385 28 -85 30 -230 5 -315 -58 -196 -215 -348 -411 -400 -69 -18 -182 -20 -257 -4 -184 38 -357 193 -420 377 -30 89 -32 258 -4 342 63 189 215 335 397 384 71 19 208 19 288 1z"/>
<path d="M9462 2255 c-225 -41 -431 -145 -591 -299 -244 -236 -362 -532 -348 -870 22 -528 390 -954 922 -1068 81 -17 332 -17 420 1 606 120 1008 694 910 1300 -39 237 -145 443 -319 617 -173 172 -374 277 -610 319 -106 18 -283 18 -384 0z m392 -589 c223 -85 366 -293 366 -531 0 -152 -58 -292 -166 -399 -290 -291 -783 -184 -930 200 -32 84 -44 234 -25 322 44 199 209 372 411 427 88 24 257 15 344 -19z"/>
</g>`,
          className: "last-pos-icon",
        })

        return (
          track.lastPos?.lat && (
            <Marker
              position={
                track.points?.length > 0
                  ? [
                      track.points[track.points.length - 1].lt,
                      track.points[track.points.length - 1].ln,
                    ]
                  : [track.lastPos.lat, track.lastPos.lon]
              }
              icon={lastPos}
              eventHandlers={{
                click: (e) => {
                  startLoading && startLoading()
                  if (track.points?.length > 0) {
                    dispatch(getInfoPointTrack(track.points[track.points.length - 1].id))
                    return
                  }
                  track.lastID && dispatch(getInfoPointTrack(track.lastID))
                },
              }}
            />
          )
        )
      })}
      {}
    </>
  )
}
