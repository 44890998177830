import { RootState } from "@/store/store"

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  Change,
  CreateFolder,
  CreateUser,
  Delete,
} from "src/components/Settings/icons/optionsIcons/Options"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { organizeDrivers } from "src/shared/ui/SettingsTree/driverHelper"
import { HorizontalTree } from "src/shared/ui/SettingsTree/HorizontalTree"
import {
  setGSItemData,
  setGSPageFolderData,
  setNewHandleId,
} from "src/store/reducers/globalSettings/globalSettings"
import { thunkGetDriversFromServer } from "src/store/reducers/globalSettings/globalSettingsThunk"
import {
  thunkDeleteDriver,
  thunkGetDriver,
  thunkGetDriversFolder,
} from "src/store/reducers/globalSettings/itemsThunks/driverThunk"
import { ClientIcon } from "../../Help/icons/ClientIcon"
import { DriverIcon } from "../../Help/icons/DriverIcon"
import { FolderIcon } from "../../Help/icons/FolderIcon"

export interface TestSettingsTreeProps {
  activeId: number | string | null
  setActiveId: (id: string | null) => void
}
let defaultDriver = {
  uid: "",
  driver_groups_tree_id: 0,
  driver_name: "*",
  driver_code: 1,
  birth_date: 1,
  dismissal_date: 0,
  employment_date: 1,
  phone: "",
  comment: "",
}
let defaultFolder = {
  group_name: "",
  id: 0,
  parent_id: 0,
}

export const TestSettingsTree = ({ activeId, setActiveId }: TestSettingsTreeProps) => {
  const dispatch = useAppDispatch()
  const levelIds = useAppSelector((state) => state.globalSettings.tree.drivers.levelIds)
  const [openDeleteID, setDeleteAlertID] = useState<null | number>(null)
  const data = useSelector((state: RootState) => state.globalSettings.data.drivers)

  // const clickHandler = ({ id, index, is_folder }) => {
  //   setActiveId(null)
  //   if (!is_folder) {
  //     dispatch(setUserId({ id, level: index }))
  //     setActiveId(id)
  //   } else if (is_folder) {
  //     dispatch(
  //       setNewHandleId({
  //         id: Number(id),
  //         field: "users",
  //         index: index + 1,
  //         levelFromCreate: index,
  //       }),
  //     )
  //   }
  // }

  useEffect(() => {
    dispatch(thunkGetDriversFromServer())
  }, [])
  const clickHandler = ({ id, index, is_folder }) => {
    setActiveId(null)
    if (!is_folder) {
      setActiveId(id)
    } else if (is_folder) {
      dispatch(
        setNewHandleId({
          id: Number(id),
          field: "drivers",
          index: index + 1,
          levelFromCreate: index,
        }),
      )
    }
  }
  interface IGenerateBody {
    groupID: number
    accountID: number
    type: "object" | "group"
    index: number
    handleId: number
    uid: string
    level: number
    handlePlusId: number
    roleID: number
    is_root: boolean
    parent_id: number
  }
  const generateBody = ({
    groupID,
    accountID,
    type,
    is_root,
    handleId,
    uid,
    parent_id,
    roleID,
  }: IGenerateBody) => {
    let body: any = []

    if (roleID == 3) {
      if (is_root) {
        body = [
          {
            icon: <CreateUser width={18} />,
            label: "Создать водителя",
            onClickBtn: () => {
              defaultDriver.driver_groups_tree_id = groupID
              dispatch(
                setGSItemData({
                  data: defaultDriver,
                  field: "drivers",
                }),
              )
            },
          },
          {
            icon: <CreateFolder width={18} />,
            label: "Создать папку",
            onClickBtn: () => {
              defaultFolder.parent_id = groupID
              dispatch(
                setGSPageFolderData({
                  folderData: defaultFolder,
                  field: "drivers",
                }),
              )
            },
          },
        ]
      } else {
        body = [
          {
            icon: <CreateUser width={18} />,
            label: "Создать водителя",
            onClickBtn: () => {
              defaultDriver.driver_groups_tree_id = groupID
              dispatch(
                setGSItemData({
                  data: defaultDriver,
                  field: "drivers",
                }),
              )
            },
          },
          {
            icon: <Change width={18} />,
            label: "Редактировать",
            onClickBtn: () => {
              defaultDriver.driver_groups_tree_id = groupID
              dispatch(thunkGetDriversFolder(groupID))
            },
          },
          {
            icon: <CreateFolder width={18} />,
            label: "Создать папку",
            onClickBtn: () => {
              defaultFolder.parent_id = groupID
              dispatch(
                setGSPageFolderData({
                  folderData: defaultFolder,
                  field: "drivers",
                }),
              )
            },
          },
          {
            icon: <Delete width={18} />,
            label: "Удалить",
            color: "red",
            onClickBtn: () => {
              defaultFolder.parent_id = groupID
              dispatch(
                setGSPageFolderData({
                  folderData: defaultFolder,
                  field: "drivers",
                }),
              )
            },
          },
        ]
      }
    }
    if (type == "object") {
      body = [
        {
          icon: <Change width={18} />,
          label: "Редактировать",
          onClickBtn: () => dispatch(thunkGetDriver(uid, parent_id)),
        },
        {
          icon: <Delete width={18} />,
          label: "Удалить",
          onClickBtn: () => dispatch(thunkDeleteDriver(uid)),
          color: "red",
        },
      ]
    }
    return body
  }

  const iconGenerator = (is_folder: boolean, is_root: boolean, role_id) => {
    let icon: React.ReactNode

    if (!is_folder) {
      icon = <DriverIcon width={18} fill="rgb(255, 103, 0)" />
    }
    if (is_folder) {
      icon = <FolderIcon fill={"rgb(255, 103, 0)"} width={18} />
      if (is_root && role_id == 3) {
        icon = <ClientIcon fill={"rgb(255, 103, 0)"} width={18} />
      }
    }

    return icon
  }

  const drivers: any = data !== null ? organizeDrivers(data) : []

  return (
    <>
      <HorizontalTree
        iconGenerator={(a: boolean, b: boolean, c: number) => iconGenerator(a, b, c)}
        levelIds={levelIds}
        data={drivers}
        onClickTreeItem={(arr: any) => clickHandler(arr)}
        contextGenerator={(arr: any) => generateBody(arr)}
        activeId={activeId}
      />
    </>
  )
}
