import { setTableData } from "./table"
import { fetchIsError } from "../../../shared/lib/helpers/fetchIsError/fetchIsError"
import { tableTpmsApi } from "../../../shared/api/tableTpmsApi"

export interface IBodyTableQuery {
  vehicle_uids: string[]
  driver_uids: string[]
}

export const thunkGetTableData = (body: IBodyTableQuery) => {
  return async (dispatch) => {
    const res = await fetchIsError(dispatch, tableTpmsApi.getTpmsTable, [body])
    if (!res) return
    dispatch(setTableData(res.data))
  }
}
