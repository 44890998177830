import React, { useState } from "react"
import styles from "./styles.module.css"

type propsType = {
  children: React.ReactNode
  message: React.ReactNode
}

export const Tooltip: React.FC<propsType> = ({ children, message }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [pos, setPos] = useState({ x: 0, y: 0 })
  const [timeoutID, setTimeoutID] = useState<any>(null)

  const onMouseEnterTooltip = () => {
    setIsOpen(true)
  }

  return (
    <div
      className="tooltip"
      onMouseLeave={() => {
        clearTimeout(timeoutID)
        setIsOpen(false)
        setPos({ x: 0, y: 0 })
      }}
      onMouseEnter={(event) => {
        const timeoutID = setTimeout(onMouseEnterTooltip, 700)
        setPos({ x: event.clientX + 5, y: event.clientY })

        setTimeoutID(timeoutID)
      }}
      onMouseMove={(event) => {
        setPos({ x: event.clientX + 5, y: event.clientY })
      }}
    >
      {children}
      {message && (
        <div
          className={`${styles.tooltip_message} ${isOpen && styles.tooltip_message_open}`}
          style={{
            top: pos.y,
            left: pos.x,
          }}
        >
          {message}
        </div>
      )}
    </div>
  )
}
