import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface ImapMenu {
  isOpenMenuMap: boolean
  menuGeozones: Array<boolean>
  menuTracks: Array<boolean>
}

const initialState: ImapMenu = {
  menuGeozones: [],
  isOpenMenuMap: false,
  menuTracks: [],
}

const mapMenuSlice = createSlice({
  name: "mapMenu",
  initialState,
  reducers: {
    addMenuGeozone(state: ImapMenu, action: PayloadAction<boolean>) {
      state.menuGeozones = [...state.menuGeozones, action.payload]
    },
    setAllMenuGeozone(state: ImapMenu, action: PayloadAction<boolean[]>) {
      state.menuGeozones = action.payload
    },
    setMenuGeozone(state: ImapMenu, action: PayloadAction<{ index: number; value: boolean }>) {
      state.menuGeozones = state.menuGeozones.map((el, index) => {
        if (index === action.payload.index) {
          return action.payload.value
        }
        return false
      })
      state.menuTracks = state.menuTracks.map((el, index) => false)
      state.isOpenMenuMap = false
    },
    addMenuTrack(state: ImapMenu, action: PayloadAction<boolean>) {
      state.menuTracks = [...state.menuTracks, action.payload]
    },
    setAllMenuTrack(state: ImapMenu, action: PayloadAction<boolean[]>) {
      state.menuTracks = action.payload
    },
    setMenuTrack(state: ImapMenu, action: PayloadAction<{ index: number; value: boolean }>) {
      state.menuTracks = [...state.menuTracks].map((el, index) => {
        if (index === action.payload.index) {
          return action.payload.value
        }
        return false
      })
      state.menuGeozones = state.menuGeozones.map((el, index) => false)
      state.isOpenMenuMap = false
    },
    setIsOpenMenuMap(state: ImapMenu, action: PayloadAction<boolean>) {
      state.isOpenMenuMap = action.payload
      state.menuGeozones = state.menuGeozones.map((el, index) => false)
      state.menuTracks = state.menuTracks.map((el, index) => false)
    },
  },
})

export const {
  setAllMenuGeozone,
  addMenuGeozone,
  setMenuGeozone,
  setIsOpenMenuMap,
  addMenuTrack,
  setAllMenuTrack,
  setMenuTrack,
} = mapMenuSlice.actions
export default mapMenuSlice.reducer
