import React from "react"
import styles from "./style.module.css"

type propsType = {
  children: React.ReactNode
  title: string
  onClose: () => void
  className?: string
  classNameBody?: string
}

export const Popup: React.FC<propsType> = (props) => {
  return (
    <>
      <div className={`${styles.popup} ${props.className ? props.className : ""}`}>
        <div className={styles.popup_title}>
          {props.title} <span className="icon-cross" onClick={props.onClose}></span>
        </div>
        <div className={`${styles.popup_body} ${props.classNameBody ? props.classNameBody : ""}`}>
          {props.children}
        </div>
      </div>
      <div className={styles.popup_bg} onClick={props.onClose}></div>
    </>
  )
}
