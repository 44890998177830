import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type TError = { message: string; seconds: number }

interface IErros {
  errors: TError[]
}

const initialState: IErros = {
  errors: [],
}

const filtersSlice = createSlice({
  name: "globalErrors",
  initialState,
  reducers: {
    addError(state: IErros, action: PayloadAction<TError>) {
      if (state.errors.some((err) => err.message === action.payload.message)) return
      state.errors = [...state.errors, action.payload]
    },
    removeFirstError(state: IErros) {
      state.errors = state.errors.splice(1)
    },
  },
})

export const { addError, removeFirstError } = filtersSlice.actions
export default filtersSlice.reducer
