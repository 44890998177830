import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"

import React from "react"
import { onlyNumber } from "src/components/Settings/Content/onlyNumber"
import { Checkbox } from "src/shared/ui/Checkbox"
import { Input } from "src/shared/ui/Input"
import {
  setDottedTimeLimit,
  setIsEnabled,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/track/track"
import styles from "../styles/os-item.module.css"
import { TrackColors } from "./inner-components/TrackColors"

export const ObjectSettingsTrack = React.memo(() => {
  const track = useAppSelector((state) => state.osTrack)
  const dispatch = useAppDispatch()
  const setChecked = () => {
    dispatch(setIsEnabled())
  }
  return (
    <div className={styles.os_main}>
      <h1>Трек</h1>
      {track ? (
        <div className={styles.os_track}>
          <div className={styles.os_track_container}>
            <p>Включить цвета трека</p>
            <div className="w-fit">
              <Checkbox checked={track.colors.is_enabled} setChecked={setChecked} />
            </div>
          </div>
          <div className={styles.os_track_container}>
            <p>Пунктир (сек)</p>
            <div className="w-fit relative">
              <Input
                variant="orange"
                inputType="text"
                error={track.colors.dotted.time_limit === ""}
                value={track.colors.dotted.time_limit.toString()}
                setValue={(e) => dispatch(setDottedTimeLimit(onlyNumber(e)))}
              />
            </div>
          </div>
          <TrackColors colors={track.colors.ranges} />
        </div>
      ) : (
        <div className="w-full text-2xl text-center">Не удалось создать</div>
      )}
    </div>
  )
})
