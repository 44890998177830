import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { createTreeFilter } from "../../store/reducers/vehiclesTree/selectorsTree"
import {
  changeGroupCreateVehicle,
  thunkDeleteGroupVehicle,
} from "../../store/reducers/vehiclesTree/vehicleTreeThunk"
import { PopupDeleteGroup } from "./PopupDeleteGroup /PopupDeleteGroup"
import { PopupNewGroup } from "./PopupNewGroup/PopupNewGroup"
import { FolderCheckboxVehicle } from "./Vehicle/FolderCheckboxVehicle/FolderCheckboxVehicle"

import { VehicleItemMap } from "./Vehicle/VehicleItemMap/VehicleItemMap"
import { VehicleHeaderTree } from "./Vehicle/VehicleHeaderTree/VehicleHeaderTree"

export const VehicleTree = () => {
  const dispatch = useAppDispatch()
  const search = useAppSelector((state) => state.vehicles.search)

  const vehicleTreeAll = useAppSelector(createTreeFilter)
  const vehicleTree = vehicleTreeAll[0]?.children
  const rootVehicle: any = vehicleTreeAll[0] ? vehicleTreeAll[0] : []

  const [newGroupParentID, setNewGroupParentID] = useState<number>(0)
  const [newGroup, setNewGroup] = useState({
    group_name: "",
    account_id: 0,
    level: 0,
    isOpen: false,
  })
  const [deleteGroupInfo, setDeleteGroupInfo] = useState<null | { name: string; id: number }>(null)

  const deleteGroup = () => {
    if (!deleteGroupInfo) return
    dispatch(thunkDeleteGroupVehicle(deleteGroupInfo.id))
    setDeleteGroupInfo(null)
  }

  return (
    <>
      <VehicleHeaderTree />
      <div className="tree-wrapper">
        <PopupNewGroup
          value={newGroup.group_name}
          isOpen={newGroup.isOpen}
          setValue={(value) => {
            setNewGroup((prev) => ({ ...prev, group_name: value }))
          }}
          setOpen={(isOpen) => {
            setNewGroup((prev) => ({ ...prev, isOpen: isOpen }))
          }}
          createGroup={() => {
            dispatch(
              changeGroupCreateVehicle(
                newGroupParentID,
                newGroup.account_id,
                newGroup.group_name,
                newGroup.level,
              ),
            )
            setNewGroup({ group_name: "", account_id: 0, isOpen: false, level: 0 })
          }}
        />
        <PopupDeleteGroup
          isOpen={!!deleteGroupInfo}
          setOpen={(value) => setDeleteGroupInfo(value)}
          nameGroup={deleteGroupInfo ? deleteGroupInfo?.name : ""}
          deleteGroup={deleteGroup}
        />
        {vehicleTree &&
          vehicleTree.map((group, key) => {
            return (
              <div>
                <FolderCheckboxVehicle
                  group={group}
                  search={search}
                  setNewGroup={(value: any) =>
                    setNewGroup((prev) => ({ ...value, group_name: prev.group_name }))
                  }
                  setParentID={(id: number) => setNewGroupParentID(id)}
                  setInfoGroup={(id: number, name: string) =>
                    setDeleteGroupInfo({ id: id, name: name })
                  }
                  key={key}
                />
              </div>
            )
          })}
        <VehicleItemMap group={rootVehicle} />
      </div>
    </>
  )
}
