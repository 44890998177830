import { memo, useEffect, useState } from "react"
import { ContextMenu } from "src/shared/ui/ContextMenu"
import { Tooltip } from "src/shared/ui/Tooltip"
import { IHorizontalTreeData } from "../../helpers"
import style from "../../style/style.module.css"
import { DeleteQuest } from "../DeleteQuest/DeleteQuest"

interface ISTCProps {
  column: IHorizontalTreeData[]
  levelIds: [number, number][]
  onClickTreeItems: (e: any) => void
  colIndex: number
  activeId: number | string | null
  contextGenerator: any
  iconGenerator: (a: boolean, b: boolean, c: number) => React.ReactNode
  columnDefaultOptionsGenerator?: (arr: any) => any[]
  account_id: number
}

export const HorizontalTreeColumn = memo(
  ({
    column,
    onClickTreeItems,
    colIndex,
    levelIds,
    contextGenerator,
    activeId,
    iconGenerator,
    columnDefaultOptionsGenerator,
    account_id,
  }: ISTCProps) => {
    const [activeItem, setActiveItem] = useState<IHorizontalTreeData | null>(null)
    const [openDeleteQuest, setOpenDeleteQuest] = useState<boolean>(false)
    const [isDeletingFunc, setIsDeletingFunc] = useState<any | null>(null)

    useEffect(() => {
      let newActiveItem: IHorizontalTreeData | null = null

      if (levelIds[colIndex]) {
        newActiveItem = column.find((i) => i.id === levelIds[colIndex][1]) || null
      }

      if (activeId) {
        newActiveItem =
          column.find((i) => i.id === activeId && i.is_folder !== true) || newActiveItem
      }

      setActiveItem(newActiveItem)
    }, [levelIds, activeId, colIndex, column])

    const handleItemClick = (i: IHorizontalTreeData, index: number) => {
      // Если это не папка, то обновляем только текущую колонку
      if (!i.is_folder) {
        setActiveItem(i)
      }

      // Вызываем обработчик клика
      onClickTreeItems({
        role_id: i.role_id,
        is_folder: i.is_folder,
        id: i.id,
        index: colIndex,
      })
    }

    return (
      <>
        <div className={` ${style.tree_container_row} `}>
          <div className={style.options_field}>
            <div className={style.options_field_container}>
              <div className="flex items-center text-sm justify-start gap-3 max-w-1/2 w-1/2 px-2">
                {columnDefaultOptionsGenerator &&
                  columnDefaultOptionsGenerator({
                    item: column,
                    parent_id: levelIds[colIndex - 1][1],
                    index: colIndex,
                    account_id,
                  }).map((opt, optIndex) => (
                    <Tooltip key={optIndex} message={opt.label}>
                      <div onClick={opt.onClickBtn} className={style.options_field_item}>
                        {opt.icon}
                      </div>
                    </Tooltip>
                  ))}
              </div>
              <div className="w-[1px] bg-slate-500 h-full"></div>
              <div className="flex items-center text-sm justify-end gap-3 max-w-1/2 w-1/2 px-2">
                {activeItem &&
                  contextGenerator({
                    groupID: activeItem.id || null,
                    accountID: activeItem.account_id,
                    type: activeItem.role_id ? "group" : "object",
                    index: colIndex || null,
                    handleId: levelIds[colIndex] ? levelIds[colIndex][1] : null,
                    uid: activeItem.role_id ? null : activeItem.id || null,
                    level: colIndex || null,
                    parent_id: activeItem.parent_id,
                    roleID: activeItem.role_id,
                    is_root: activeItem.is_root,
                  }).map((i) => (
                    <Tooltip message={i.label}>
                      <div
                        key={i.label}
                        onClick={() => {
                          if (i.option == "delete") {
                            setIsDeletingFunc({ func: i.onClickBtn })
                          } else {
                            i.onClickBtn()
                          }
                        }}
                        className={style.options_field_item}
                      >
                        {i.icon}
                      </div>
                    </Tooltip>
                  ))}
              </div>
            </div>
          </div>
          <div className={style.items_map}>
            {column
              .filter(
                (i) =>
                  i.name && i.parent_id === (levelIds[colIndex > 0 ? colIndex - 1 : 0]?.[1] || 0),
              ) // Фильтруем элементы с пустыми именами
              .map((i, index) => {
                return (
                  <div className="contents" onContextMenu={() => handleItemClick(i, index)}>
                    <ContextMenu
                      btnMenu={contextGenerator({
                        groupID: i.id || null,
                        accountID: i.account_id,
                        type: i.role_id ? "group" : "object",
                        index: colIndex || null,
                        handleId: levelIds[colIndex] ? levelIds[colIndex][1] : null,
                        uid: i.role_id ? null : i.id || null,
                        level: colIndex || null,
                        parent_id: i.parent_id,
                        roleID: i.role_id,
                        is_root: i.is_root,
                      })}
                    >
                      <div
                        key={i.id}
                        onClick={() => handleItemClick(i, index)}
                        className={`${style.tree_container_column} ${
                          activeItem && activeItem.id === i.id && style.active
                        } `}
                        style={{ width: `100%` }}
                      >
                        <div className={style.tree_container_image_name}>
                          <div>
                            {iconGenerator(i.is_folder, i.is_root || false, i.role_id || 0)}
                          </div>
                          {i.name}
                        </div>

                        <p>{i.is_folder && ">"}</p>
                      </div>
                    </ContextMenu>
                  </div>
                )
              })}
          </div>
        </div>
        {isDeletingFunc && (
          <DeleteQuest func={isDeletingFunc.func} close={() => setIsDeletingFunc(null)} />
        )}
      </>
    )
  },
)
