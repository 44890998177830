import React from "react"
import styles from "./style.module.css"

interface Iprops {
  checked: boolean
  setChecked: () => void
  labalEl?: React.ReactNode
  variant?: "none" | "minus" | "checked"
}

export const Checkbox: React.FC<Iprops> = React.memo(
  ({ checked, setChecked, labalEl, variant }) => {
    let currentChecked = variant ? `icon-${variant}` : checked ? "icon-checked" : ""
    return (
      <label className={`${styles.checkbox} ${checked ? styles.checkbox_checked : ""}`}>
        <div className={currentChecked}></div>
        <input type="checkbox" checked={checked} onChange={() => setChecked()} />
        {labalEl ? labalEl : null}
      </label>
    )
  },
)
