import { useState } from "react"
import { useDispatch } from "react-redux"
import { ObjectsIcons } from "src/components/Settings/icons/ObjectsIcons"
import { ClientIcon } from "../../Help/icons/ClientIcon"
import { FolderIcon } from "../../Help/icons/FolderIcon"
import { IOnClickName } from "../../Pages/Drivers/SearchDrivers"
import style from "../style/settings-search.module.css"
import { SearchTreeView } from "./SearchTreeView"

interface ISearchTreeViewItemProps {
  searchItem: any
  onClickPath: (levelIds: [number, number][]) => void
  onClickName: (a: IOnClickName) => void
  setActiveId: (id: any) => void
  setCloseSearch: () => void
}

export const SearchTreeViewItem = ({
  searchItem,
  onClickPath,
  onClickName,
  setActiveId,
  setCloseSearch,
}: ISearchTreeViewItemProps) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const iconGenerator = (is_folder: boolean, is_root: boolean, role_id) => {
    let icon: React.ReactNode

    if (!is_folder) {
      icon = <ObjectsIcons width={18} fill="rgb(255, 103, 0)" />
    }
    if (is_folder) {
      if (is_root) {
        if (role_id == 3) {
          icon = <ClientIcon fill={"rgb(255, 103, 0)"} width={18} />
        }
        icon = <FolderIcon fill={"rgb(255, 103, 0)"} width={18} />
      } else {
        icon = <FolderIcon fill={"rgb(255, 103, 0)"} width={18} />
      }
    }

    return icon
  }

  const clickHandler = (id, account_id) => {
    // dispatch(setObjectSettingsUid(id))
    // dispatch(setObjectSettingsAccountId(account_id))
  }
  return (
    <>
      <div
        className={style.tree_view_item}
        onClick={() => {
          searchItem.is_folder
            ? setOpen(!open)
            : clickHandler(searchItem.vehicle_uid, searchItem.account_id)
        }}
      >
        <div
          onClick={() => {
            onClickName({
              id: searchItem.id || searchItem.vehicle_uid,
              is_folder: searchItem.is_folder,
              is_root: searchItem.is_root,
              role_id: searchItem.role_id,
              parent_id: searchItem.parent_id,
              account_id: searchItem.account_id,
            })
          }}
          className={style.icon_name}
        >
          {iconGenerator(
            searchItem.is_folder,
            searchItem.is_root || null,
            searchItem.role_id || null,
          )}
          {searchItem.name}
        </div>
        <div
          onClick={() => {
            if (searchItem.path.id) {
              setActiveId(searchItem.path.id)
            }
            onClickPath(searchItem.path.levelIds)
            setCloseSearch()
          }}
          className={style.path}
        >
          {searchItem.path && searchItem.path.pathName}
        </div>
      </div>
      {open && searchItem.children.length > 0 && (
        <SearchTreeView
          setCloseSearch={setCloseSearch}
          setActiveId={setActiveId}
          onClickName={onClickName}
          onClickPath={onClickPath}
          tree={searchItem.children}
        />
      )}
    </>
  )
}
