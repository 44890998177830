import { setCheckedDriver } from "../drivers/driverSlice"
import { setCheckedGeozone } from "../geozonesTree/geozonesTreeSlice"
import { setCheckedVehicle } from "../vehiclesTree/vehicleTreeSlice"
import { setRoutePage } from "./routerSlice"

export const setPage = (page) => (dispatch) => {
  dispatch(setRoutePage(page))
  dispatch(setCheckedDriver([]))
  dispatch(setCheckedVehicle([]))
  dispatch(setCheckedGeozone([]))
}
