import { LatLngExpression } from "leaflet"

import React, { useEffect, useState } from "react"
import { MapContainer, Polyline, Rectangle, TileLayer, useMap } from "react-leaflet"
import { LoadingWrapper } from "../../components/LoadingWrapper/LoadingWrapper"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { ITrack } from "../../interfaces/track"
import { setAllGeozoneInfo } from "../../store/reducers/mapGeozone/mapGeozone"
import { getGeozones } from "../../store/reducers/mapGeozone/mapGeozoneThunk"
import { removeTrack, setTrackAll } from "../../store/reducers/mapTrack/mapTrack"
import { getSettingsMap, getTrack } from "../../store/reducers/mapTrack/mapTrackThunk"
import {
  setIsOpenMenuMap,
  setMenuGeozone,
  setMenuTrack,
} from "../../store/reducers/menuMap/menuMap"
import { Geozones } from "./Geozones/Geozones"
import { useLoading } from "../../shared/lib/hooks/loading"
import { SettingsEvents } from "../../features/SettingsEvents/SettingsEvents"
import { MenuEditor } from "../../features/MenuEditor/MenuEditor"
import { TracksMap } from "../../entities/Track/TracksMap"
import { EdirtorMap } from "src/features/EdirtorMap/EdirtorMap"
import { useMapLocal } from "src/shared/lib/hooks/mapLocal"
import { LastPosition } from "src/entities/Track/LastPosition/LastPosition"
import { EventsTrack } from "src/entities/Track/EventsTrack/EventsTrack"

type propsType = {
  center: LatLngExpression
  zoomMap: number
  children?: React.ReactNode
  isEditor: boolean
  geozone: boolean
  track: boolean
}

export const MyMapContainer: React.FC<propsType> = React.memo((props) => {
  const dispatch = useAppDispatch()

  const { loading, startLoading, stopLoading } = useLoading()
  const geozones = useAppSelector((state) => state.mapGeozone.infoGeozones)
  const tracks: ITrack[] = useAppSelector((state) => state.mapTrack.tracks)

  const menuGeozones = useAppSelector((state) => state.mapMenu.menuGeozones)
  const chekedGeozone = useAppSelector((state) => state.geoZones.checkedGeoZones)

  const vehicleCheked = useAppSelector((state) => state.vehicles.checkedVehicles)

  const checkedEvent = useAppSelector((state) => state.mapTrack.checkedEvent)

  const startDate = useAppSelector((state) => state.layoutCalendar.startTiming)
  const endDate = useAppSelector((state) => state.layoutCalendar.endTiming)

  const [prevVehicleUID, setPrevVehicleUID] = useState(
    vehicleCheked.map((vehicle) => vehicle.vehicle_uid),
  )

  useEffect(() => {
    dispatch(getSettingsMap())
  }, [])

  useEffect(() => {
    if (!props.track) return

    if (vehicleCheked.length > 1) {
      alert("Можно показать не более 1 треков")
      return
    }

    const vehicleCheckedUID = vehicleCheked.map((vehicle) => vehicle.vehicle_uid)
    let vehicleChangeUID: string[] = []

    startLoading()
    setPrevVehicleUID(vehicleCheckedUID)
    if (prevVehicleUID.length === 0 || prevVehicleUID.length === vehicleCheckedUID.length) {
      dispatch(getTrack(vehicleCheckedUID, startDate, endDate, checkedEvent))
      return
    }

    if (prevVehicleUID.length < vehicleCheked.length) {
      vehicleChangeUID = vehicleCheckedUID.filter((vehicleCheckedUID) =>
        prevVehicleUID.find((vehicleUID) => vehicleCheckedUID !== vehicleUID),
      )
      dispatch(getTrack(vehicleChangeUID, startDate, endDate, checkedEvent))
      return
    }

    if (prevVehicleUID.length > vehicleCheked.length) {
      vehicleChangeUID = vehicleCheckedUID.filter((vehicleUID) =>
        prevVehicleUID.includes(vehicleUID),
      )
      dispatch(removeTrack(vehicleChangeUID))
      return
    }

    if (vehicleCheked.length === 0) {
      dispatch(setTrackAll([]))
      return
    }
    dispatch(getTrack(vehicleCheckedUID, startDate, endDate, checkedEvent))
  }, [vehicleCheked, endDate, startDate, checkedEvent])

  useEffect(() => {
    if (!props.geozone) return
    startLoading()
    if (chekedGeozone.length === 0) {
      dispatch(setAllGeozoneInfo({ geozone: [] }))
    }
    if (chekedGeozone.length > 0) {
      dispatch(getGeozones(chekedGeozone.map((el) => el.geozone_uid)))
    }
  }, [chekedGeozone, startDate, endDate])

  useEffect(() => {
    stopLoading()
  }, [tracks, geozones])

  return (
    <div
      style={{ width: "100%", height: "94vh", position: "relative", zIndex: 0 }}
      onClick={(e) => {
        if (tracks) {
          dispatch(setIsOpenMenuMap(false))
          menuGeozones.forEach((el, index) => {
            dispatch(setMenuGeozone({ index: index, value: false }))
          })
          tracks.forEach((i, index) => {
            dispatch(setMenuTrack({ index: index, value: false }))
          })
        }
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <LoadingWrapper isLoading={loading}>
        <MapContainer
          center={props.center}
          zoom={props.zoomMap}
          minZoom={3}
          maxZoom={18}
          scrollWheelZoom={true}
          style={{ width: "100%", height: "100%" }}
          className="container-map"
        >
          {props?.children && props.children}
          <SetMap />
          {props.track && (
            <TracksMap tracks={tracks} startLoading={startLoading} stopLoading={stopLoading} />
          )}
          <LastPosition />
          <EventsTrack />
          <TileLayer
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // url="https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=24.09.09-0-b240906182700&x={x}&y={y}&z={}&scale=1"
          />
          {props.track && <SettingsEvents />}
          {props.geozone && (
            <>
              <Geozones />
            </>
          )}
          <EdirtorMap />
        </MapContainer>
        <MenuEditor />
      </LoadingWrapper>
    </div>
  )
})
const SetMap = React.memo(() => {
  const map = useMap()
  useMapLocal(map)
  return null
})
