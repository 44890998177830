import React from "react"

export const DriversIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 17V15.2222C17 14.2792 16.5786 13.3749 15.8284 12.7081C15.0783 12.0413 14.0609 11.6667 13 11.6667H5C3.93913 11.6667 2.92172 12.0413 2.17157 12.7081C1.42143 13.3749 1 14.2792 1 15.2222V17M13 4.55556C13 6.51923 11.2091 8.11111 9 8.11111C6.79086 8.11111 5 6.51923 5 4.55556C5 2.59188 6.79086 1 9 1C11.2091 1 13 2.59188 13 4.55556Z"
        stroke="#474747"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
