import React from "react"
import styles from "./style.module.css"

interface Iprops {
  children: React.ReactNode
  title: React.ReactNode
}

export const Window: React.FC<Iprops> = ({ children, title }) => {
  return (
    <div className={styles.window}>
      <div className={styles.window_title}>{title}</div>
      <div className={styles.window_content}>{children}</div>
    </div>
  )
}
