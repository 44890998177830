import React, { useState } from "react"
import { ContextMenu } from "../../shared/ui/ContextMenu"
import { Marker, Polygon, useMap } from "react-leaflet"
import { setCreatorFigure } from "../../store/reducers/mapEditor/mapEditor"
import { setMenuGeozone } from "../../store/reducers/menuMap/menuMap"
import L from "leaflet"
import { removeGeozone } from "../../store/reducers/mapGeozone/mapGeozoneThunk"
import { useAppDispatch, useAppSelector } from "../../shared/lib/hooks/store"
import { IGeozone } from "../../interfaces/geozone"

type geozoneType = {
  geozone: IGeozone
  index: number
}

export const Polygons: React.FC<geozoneType> = ({ geozone, index }) => {
  const dispatch = useAppDispatch()

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })
  const menuGeozones = useAppSelector((state) => state.mapMenu.menuGeozones)
  const editMap = useAppSelector((state) => state.mapEditor.editMap)
  const handleContextMenu = (event: any) => {
    setMenuPosition({ x: event.clientX, y: event.clientY })
  }
  const map = useMap()

  return (
    <>
      <ContextMenu
        btnMenu={[
          {
            label: "Редактировать геозону",
            onClickBtn: () => {
              let newGeozone = {
                ...geozone,
                geozone_points: [...geozone.geozone_points].splice(
                  0,
                  geozone.geozone_points.length - 1,
                ),
              }
              dispatch(setCreatorFigure(newGeozone))
            },
          },
          {
            label: "Удалить геозону",
            onClickBtn: () => {
              dispatch(removeGeozone(geozone.geozone_uid))
            },
          },
        ]}
        menuPosition={menuPosition}
        menuVisible={menuGeozones[index]}
        contextMenu={handleContextMenu}
        setMenuVisible={(visible) => dispatch(setMenuGeozone({ index: index, value: visible }))}
        disactive={editMap}
      >
        <Polygon
          pathOptions={{
            color: geozone.color,
            weight: geozone.line_width,
            fillOpacity: geozone.transparency / 100,
          }}
          positions={geozone.geozone_points
            .map((item) => {
              return [item.lat, item.lng]
            })
            .splice(0, geozone.geozone_points.length - 1)}
          eventHandlers={{
            click: () => {
              dispatch(setMenuGeozone({ index: index, value: false }))
            },
            contextmenu: (e) => {
              e.originalEvent.preventDefault()
              dispatch(setMenuGeozone({ index: index, value: true }))
              setMenuPosition({
                x: e.originalEvent.clientX,
                y: e.originalEvent.clientY,
              })
              e.originalEvent.stopPropagation()
            },
          }}
        >
          <Marker
            position={[geozone.latitude, geozone.longitude]}
            icon={L.divIcon({
              className: "geozone-name",
              iconSize: [64, 64],
              html: `<span>${geozone.geozone_name}</span>`,
            })}
            eventHandlers={{
              contextmenu: (e) => {
                e.originalEvent.preventDefault()
                dispatch(setMenuGeozone({ index: index, value: true }))
                setMenuPosition({
                  x: e.originalEvent.clientX,
                  y: e.originalEvent.clientY,
                })
                e.originalEvent.stopPropagation()
              },
              click: () => {
                const points = L.polyline(
                  geozone.geozone_points
                    .map((item) => {
                      return [item.lat, item.lng]
                    })
                    .splice(0, geozone.geozone_points.length - 1),
                )

                const bounds = points.getBounds()
                map.fitBounds(bounds)
              },
            }}
          />
        </Polygon>
      </ContextMenu>
    </>
  )
}
