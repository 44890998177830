import { Delete } from "src/components/Settings/icons/optionsIcons/Options"
import styles from "./delete-quest.module.css"

interface IDeleteQuest {
  func: () => void
  close: () => void
}

export const DeleteQuest = ({ func, close }: IDeleteQuest) => {
  const onAbort = () => {
    close()
  }
  const onSubmit = () => {
    func()
    close()
  }

  return (
    <div className={styles.delete_quest_popup}>
      <div className={styles.delete_quest_content}>
        <div className={styles.header}>
          <div className={styles.name}>
            <Delete width={18} />
            <span>Подтверждение удаления</span>
          </div>
          <div onClick={onAbort} className={styles.exit}>
            Выйти
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.query}>
            <p>Вы подтверждаете удаление элемента?</p>
          </div>
          <div className={styles.buttons}>
            <button onClick={onAbort}>Отменить</button>
            <button
              onClick={onSubmit}
              style={{
                background: "#ef3c3c",
                color: "#fff",
                boxShadow: "box-shadow: 0px 0px 20.2px 0px #ef3c3c;",
              }}
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
