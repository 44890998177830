import { AppDispatch, RootState } from "@/store/store"
import React, { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { onlyNumber } from "src/components/Settings/Content/onlyNumber"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { Checkbox } from "src/shared/ui/Checkbox"
import { Input } from "src/shared/ui/Input"
import {
  setOSEnabled,
  setOSPluginId,
  setOSSim1,
  setOSSim2,
  setOSVehicleId,
  setOSVehicleName,
  setOSVehiclePwd,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/main/main"
import { thunkGetPlugins } from "src/store/reducers/objectSettings/objectSettingsThunk"
import { SwitchComponent } from "../../helpComponents/SwitchComponent"
import styles from "../styles/os-item.module.css"

export const ObjectSettingsMain = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>()

  // Memoized selectors to ensure only relevant state changes trigger a re-render
  const vehicleId = useAppSelector((state) => state.osMain.vehicle_id)
  const vehicleName = useAppSelector((state) => state.osMain.vehicle_name)
  const vehiclePwd = useAppSelector((state) => state.osMain.vehicle_pwd)
  const isEnabled = useAppSelector((state) => state.osMain.is_enabled)
  const pluginId = useAppSelector((state) => state.osMain.plugin_id)
  const sim1 = useAppSelector((state) => state.osMain.sim1)
  const sim2 = useAppSelector((state) => state.osMain.sim2)
  const plugins = useSelector((state: RootState) => state.objectSettings.plugins)

  useEffect(() => {
    if (!plugins) {
      dispatch(thunkGetPlugins())
    }
  }, [plugins, dispatch])

  const pluginsDataBody = useMemo(() => {
    if (typeof plugins === "string") return []
    return (
      plugins?.map((plugin) => ({
        name: plugin.name,
        option: plugin.id,
      })) || []
    )
  }, [plugins])

  // Memoize handlers to avoid creating new functions on every render
  const handleIdChange = useCallback(
    (e: string) => dispatch(setOSVehicleId(onlyNumber(e))),
    [dispatch],
  )
  const handleNameChange = useCallback((e: string) => dispatch(setOSVehicleName(e)), [dispatch])
  const handlePwdChange = useCallback((e: string) => dispatch(setOSVehiclePwd(e)), [dispatch])
  const handleEnabledChange = useCallback(() => dispatch(setOSEnabled()), [dispatch])
  const handlePluginIdChange = useCallback(
    (e: string) => dispatch(setOSPluginId(Number(e))),
    [dispatch],
  )
  const handleSim1Change = useCallback((e: string) => dispatch(setOSSim1(e)), [dispatch])
  const handleSim2Change = useCallback((e: string) => dispatch(setOSSim2(e)), [dispatch])

  return (
    <div className={styles.os_main}>
      <h1>Основное</h1>
      <section>
        <p>{"ID *"}</p>
        <div className="w-1/2 relative">
          <Input
            error={vehicleId == "" || vehicleId == 0}
            variant="orange"
            inputType="text"
            value={vehicleId.toString()}
            setValue={handleIdChange}
          />
          {/* {(vehicleId == "" || vehicleId == 0) && (
            <div className="text-red-500 text-sm absolute top-1 left-1/3">Заполните поле</div>
          )} */}
        </div>

        <p>{"Пароль"}</p>
        <div className="w-1/2">
          <Input variant="orange" inputType="text" value={vehiclePwd} setValue={handlePwdChange} />
        </div>
        <p>{"Наименование *"}</p>
        <div className="w-1/2 relative">
          <Input
            variant="orange"
            inputType="text"
            error={vehicleName == ""}
            value={vehicleName}
            setValue={handleNameChange}
          />
          {/* {vehicleName == "" && (
            <div className="text-red-500 text-sm absolute top-1 left-1/3">Заполните поле</div>
          )} */}
        </div>
        <p>{"Плагина"}</p>
        <div className="w-1/2">
          <SwitchComponent
            value={pluginId}
            body={pluginsDataBody}
            onChange={(e) => handlePluginIdChange(e.target.value)}
          />
          {/* <Select options={[]} currentOption={pluginId} setOption={function (value: string): void {
            throw new Error('Function not implemented.')
          } } /> */}
        </div>
        <p>{"Номер sim2"}</p>
        <div className="w-1/2">
          <Input variant="orange" inputType="text" value={sim1} setValue={handleSim1Change} />
        </div>
        <p>{"Номер sim1"}</p>

        <div className="w-1/2">
          <Input
            variant="orange"
            className={"border-red-500 border-2"}
            inputType="text"
            value={sim2}
            setValue={handleSim2Change}
          />
        </div>
        <p>{"Прием данных"}</p>
        <div className="w-fit">
          <Checkbox checked={isEnabled} setChecked={handleEnabledChange} />
        </div>
      </section>
    </div>
  )
})
