import React from "react"
import { Search } from "../../Search/Search"
import { RootGroupCheckbox } from "../../RootGroupCheckbox/RootGroupCheckbox"
import { useAppDispatch, useAppSelector } from "../../../../shared/lib/hooks/store"
import {
  groupChecked,
  setIsOpenAll,
  setSearchDriver,
} from "../../../../store/reducers/drivers/driverSlice"

export const DriverHeaderTree = () => {
  const dispatch = useAppDispatch()

  const rootDrivers = useAppSelector((state) => state.driver.driversTree)

  const searchDriver = useAppSelector((state) => state.driver.search)
  const onSearchDriver = (value: string) => dispatch(setSearchDriver(value))
  const isOpenAll = useAppSelector((state) => state.driver.isOpenAll)
  const roleID = useAppSelector((state) => state.auth.authData.role_id)

  return (
    <>
      <div className="tree__search">
        {!searchDriver ? (
          <span className="icon-search"></span>
        ) : (
          <span className="icon-cross" onClick={() => onSearchDriver("")}></span>
        )}
        <Search
          value={searchDriver}
          setValue={(value) => onSearchDriver(value)}
          placeholderInput="Поиск водителя"
        />
      </div>

      <div className="tree__header">
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {rootDrivers ? (
            <RootGroupCheckbox
              status={rootDrivers[0]?.status ? rootDrivers[0].status : "none"}
              onChecked={() => dispatch(groupChecked(rootDrivers[0]))}
            />
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "var(--main-color)",
            }}
            onClick={() => {
              dispatch(setIsOpenAll(!isOpenAll))
            }}
          >
            <span className={isOpenAll ? "icon-folder-open" : "icon-folder"}></span>
          </div>
          {/* {roleID === 3 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "var(--main-color)",
              }}
              onClick={() => {
                alert("add")
              }}
            >
              <span className={"icon-folder-add"}></span>
            </div>
          )} */}
        </div>
      </div>
    </>
  )
}
