import { IWheelAx } from "@/interfaces/objectSettings"
import { AppDispatch } from "@/store/store"
import { useDispatch } from "react-redux"

import React, { useCallback } from "react"
import {
  createWheel,
  removeAxle,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/tpms/tpms"
import { AxleNorms } from "./AxleNorms"
import { AxleSide } from "./AxleSide"
import { Plus } from "./icons/Plus"

interface IAxle {
  data: IWheelAx
  changeAxle?: () => void
  axleIndex: number
}

export const Axle = React.memo(({ data, changeAxle, axleIndex }: IAxle) => {
  const dispatch = useDispatch<AppDispatch>()
  const handleRemoveAxle = useCallback((id: number) => dispatch(removeAxle(id)), [])
  const handleWheelAxle = useCallback(
    (arr: { position: "left" | "right"; id: number }) => dispatch(createWheel(arr)),
    [],
  )
  // Определение высоты каждой из сторон
  const leftSideHeight = Math.ceil(data.wheels.length / 2)

  return (
    <>
      <div className="flex items-center justify-between gap-3">
        <div
          style={{
            gridTemplateColumns: "46% 8% 46%",
          }}
          className=" grid grid-cols-3 w-full  justify-start items-center relative"
        >
          <div className=" grid-cols-2 items-center justify-end  ">
            <div
              style={{
                gridTemplateColumns: "5% 70%",
              }}
              className="flex items-center justify-end "
            >
              {data.wheels[0].length !== 13 && (
                <div
                  onClick={() =>
                    handleWheelAxle({
                      position: "left",
                      id: data.innerAxleId ? data.innerAxleId : data.id,
                    })
                  }
                  className="border-2 transition-all hover:scale-105 active:scale-110 cursor-pointer hover:bg-slate-100 border-gray-300 mx-5 h-14 w-10 flex items-center justify-center rounded"
                >
                  <Plus fill="#989595" width={20} />
                </div>
              )}
              <div className="ml-3">
                {data.wheels.slice(0, leftSideHeight).map((item, index) => (
                  <AxleSide key={index} side={item} position="L" />
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-full gap-2 items-center justify-center">
            <div className="w-0.5 h-20 bg-gray-400"></div>
            <div className="w-0.5 h-20 bg-gray-400"></div>
          </div>

          <div className=" items-center ">
            <div className="flex items-center justify-start  ">
              <div className="mr-3">
                {data.wheels.slice(leftSideHeight).map((item, index) => (
                  <AxleSide key={index} side={item} position="R" />
                ))}
              </div>

              {data.wheels[1].length !== 13 && (
                <div
                  onClick={() =>
                    handleWheelAxle({
                      position: "right",
                      id: data.innerAxleId ? data.innerAxleId : data.id,
                    })
                  }
                  className="border-2 transition-all hover:scale-105 active:scale-110 cursor-pointer hover:bg-slate-100 border-gray-300 mx-5 h-14 w-10 flex items-center justify-center rounded"
                >
                  <Plus fill="#989595" width={20} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 relative items-center justify-between w-1/4 gap-5">
          <AxleNorms
            pressure_delta={data.pressure_delta}
            pressure_norm={data.pressure_norm}
            temperature_max={data.temperature_max}
            id={data.id > 0 ? data.id : data.innerAxleId || 0}
            wheels={data.wheels}
            index={axleIndex}
          />
        </div>
        <div className="w-fit h-full ">
          <div
            onClick={() => handleRemoveAxle(data.innerAxleId ? data.innerAxleId : data.id)}
            className="icon-delete relative aspect-square w-6 p-1 cursor-pointer hover:bg-gray-100 active:bg-gray-200 rounded  bg-white  text-red-500 "
          ></div>
        </div>
      </div>
    </>
  )
})
