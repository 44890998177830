import { IDriverFolder } from "@/interfaces/settingsDriver"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { uniq, uniqBy } from "lodash"
import {
  buildByLevel,
  organizeByLevelAccounts,
  organizeByLevelDrivers,
  organizeByLevelObjects,
} from "src/components/Settings/Content/Help/organizeByLevel"
import { IPostUserTreeFolderProps } from "src/shared/api/apiGlobal"
import { IUserInfo } from "./interface"

export type TPageVariant = "objects" | "drivers" | "users" | "test"

interface INoEmptyObjects {
  level: number
  handle: number
}
export interface ITreeElem<T, S> {
  search: string
  handleId: number | null
  data: any | null | "loading" | "error"
  user_id?: null | number
  client_id?: null | number
  driver_id?: null | number
  activeAccountID?: null | number
  noEmptyLevels: INoEmptyLevels[]
  levelIds: [number, number][]
  itemSettings: {
    settingsID: number | string | null
    settingsData: S | null | "loading" | "error"
    treeWithoutItems: any
  }
  folderSettings: {
    folderSettingsData: any | "loading" | "error" | null
  }
}
interface INoEmptyLevels {
  level: number
  handle_id: number
}
export interface IFolderData {
  group_name: string
  id: number
  parent_id: number
  comment?: string
}
interface IFolder {
  data?: null | IFolderData
  status:
    | "success"
    | "error"
    | "loading"
    | null
    | "create"
    | "update"
    | "forbidden"
    | "success delete"
  id?: number | null
  accountId?: number | null
}

export interface ILevels {}

interface IGlobalSettingsState {
  isOpen: boolean
  page: TPageVariant
  accountName: string
  account_id: number
  uid: string
  vehicleUID: string
  levelFromCreate: number | null
  noEmptyLevelsObjects: INoEmptyLevels[]
  folder: IFolder

  userTreeFolder: {
    index: number | null
    data: null | IPostUserTreeFolderProps
  } | null
  tree: {
    drivers: ITreeElem<any, any>
    objects: ITreeElem<any, any>
    users: ITreeElem<any, IUserInfo>
  }
  data: {
    drivers: any | null | "loading" | "error"
    objects: any | null | "loading" | "error"
    users: any | null | "loading" | "error"
  }
}

const initialState: IGlobalSettingsState = {
  isOpen: false,
  page: "objects",
  uid: "",
  account_id: 0,
  accountName: "",
  vehicleUID: "",
  levelFromCreate: null,
  noEmptyLevelsObjects: [],
  userTreeFolder: {
    index: null,
    data: null,
  },
  folder: {
    data: null,
    status: null,
    id: null,
    accountId: null,
  },
  data: {
    drivers: null,
    objects: null,
    users: null,
  },
  tree: {
    drivers: {
      search: "",
      noEmptyLevels: [],
      levelIds: [],
      itemSettings: {
        treeWithoutItems: null,
        settingsID: null,
        settingsData: null,
      },
      folderSettings: {
        folderSettingsData: null,
      },
      handleId: null,
      data: null,
    },
    objects: {
      search: "",
      noEmptyLevels: [],
      levelIds: [],
      itemSettings: {
        treeWithoutItems: null,
        settingsID: null,
        settingsData: null,
      },
      folderSettings: {
        folderSettingsData: null,
      },
      handleId: null,
      data: null,
    },
    users: {
      search: "",
      noEmptyLevels: [],
      levelIds: [],
      itemSettings: {
        treeWithoutItems: null,
        settingsID: null,
        settingsData: null,
      },
      folderSettings: {
        folderSettingsData: null,
      },
      activeAccountID: null,
      client_id: null,
      user_id: null,
      handleId: null,
      data: null,
    },
  },
}

const globalSettings = createSlice({
  name: "globalSettings",
  initialState,
  reducers: {
    setGSSearchText: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ text: string; field: TPageVariant }>,
    ) => {
      state.tree[action.payload.field].search = action.payload.text
    },
    setAccountName(state: IGlobalSettingsState, action: PayloadAction<string>) {
      state.accountName = action.payload
    },
    setOpenGlobalSettings: (state: IGlobalSettingsState, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setGSettingsPage: (state: IGlobalSettingsState, action: PayloadAction<TPageVariant>) => {
      state.page = action.payload
    },
    setGSettingsUID: (state: IGlobalSettingsState, action: PayloadAction<string>) => {
      state.uid = action.payload
    },
    setGSAccountId: (state: IGlobalSettingsState, action: PayloadAction<number>) => {
      state.account_id = action.payload
    },
    setGSettingsObjects: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ data: any | null | "loading" | "error"; field: TPageVariant }>,
    ) => {
      const data = action.payload.data.data

      if (data != null && typeof data != "string") {
        switch (action.payload.field) {
          case "drivers":
            state.data.drivers = data
            const treeD = organizeByLevelDrivers(data)
            state.tree.drivers.levelIds.push([1, treeD[0].data[0].id])
            state.tree.drivers.levelIds = uniq(state.tree.drivers.levelIds)
            // const treeWithIndxD = [...treeD, (treeD[1].handle_id = treeD[0].data[0].id)]

            state.tree.drivers.data = treeD
            break
          case "objects":
            state.data.objects = data
            const treeV = organizeByLevelObjects(data)

            state.tree.objects.levelIds.push([1, treeV[0].data[0].id])
            state.tree.objects.levelIds = uniq(state.tree.objects.levelIds)
            // const treeWithInd = [...treeV, (treeV[1].handle_id = treeV[0].data[0].id)]

            state.tree.objects.data = treeV
            break
          case "users":
            state.data.users = data
            const treeU = organizeByLevelAccounts(data)

            state.tree.users.levelIds.push([1, treeU[0].data[0].id])
            state.tree.users.levelIds = uniq(state.tree.users.levelIds)
            state.tree.users.data = treeU
            break
        }
      }
    },
    setNewHandleId: (
      state: IGlobalSettingsState,
      action: PayloadAction<{
        id: number
        index: number
        field: TPageVariant
        levelFromCreate?: number
      }>,
    ) => {
      const { id, index, field } = action.payload

      const updateLevelIds = (
        data: any[],
        levelIds: [number, number][],
        index: number,
      ): [number, number][] => {
        // Фильтрация элементов, у которых array[0] больше чем текущий index
        levelIds = levelIds.filter((elem) => elem[0] <= index)

        // Проверка наличия дубликатов по array[0]
        const existingLevel = levelIds.find((i) => i[0] === index)
        if (existingLevel) {
          const indexFromLevelIds = levelIds.findIndex((i) => i[0] === index)
          levelIds[indexFromLevelIds] = [index, id] // Обновляем элемент, если он уже существует
        } else {
          levelIds.push([index, id]) // Добавляем новый элемент, если его не было
        }

        return uniqBy(levelIds, (elem) => elem[0]) // Удаление элементов с одинаковыми array[0]
      }

      switch (field) {
        case "drivers":
          state.tree.drivers.levelIds = updateLevelIds(
            state.tree.drivers.data,
            state.tree.drivers.levelIds,
            action.payload.index,
          )

          break
        case "users":
          state.tree.users.levelIds = updateLevelIds(
            state.tree.users.data,
            state.tree.users.levelIds,
            action.payload.index,
          )
          break
        case "objects":
          state.tree.objects.levelIds = updateLevelIds(
            state.tree.objects.data,
            state.tree.objects.levelIds,
            action.payload.index,
          )
          break
      }
    },

    setNewGSetVehicleUID: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ uid: string; level?: number }>,
    ) => {
      if (action.payload && action.payload.level != undefined) {
        const level = action.payload.level

        let tree = state.tree.objects.data
        for (let i = 0; i < tree.length; i++) {
          if (level && tree[i].level > level - 1) {
            tree[i].handle_id = null
          }
        }
        state.vehicleUID = action.payload.uid
      }
    },
    setPredefinedHandleID: (state: IGlobalSettingsState, action: PayloadAction<TPageVariant>) => {
      const ids = state.noEmptyLevelsObjects

      switch (action.payload) {
        case "objects":
          break
        case "drivers":
          break
        case "users":
      }
    },
    setFolderData: (state: IGlobalSettingsState, action: PayloadAction<any>) => {
      state.folder = action.payload
    },
    setFolderAccountId: (state: IGlobalSettingsState, action: PayloadAction<number | null>) => {
      state.folder.accountId = action.payload
    },
    setUserId: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ id: number | null; level: number }>,
    ) => {
      const level = action.payload.level

      let tree = state.tree.users.data
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].level > level + 1) {
          tree[i].handle_id = null
        }
      }
      state.tree.users.user_id = action.payload.id
    },
    setGSettingsItemSettingsID: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ id: number | string | null; field: TPageVariant }>,
    ) => {
      const field = action.payload.field
      if (state.tree[field]?.itemSettings !== undefined) {
        state.tree[field].itemSettings.settingsID = action.payload.id
      }
    },
    setGSPageFolderData: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ folderData: any; field: TPageVariant }>,
    ) => {
      if (state.tree[action.payload.field]) {
        state.tree[action.payload.field].folderSettings.folderSettingsData =
          action.payload.folderData
      }
    },
    setGSItemData: (
      state: IGlobalSettingsState,
      action: PayloadAction<{
        data: any | null | "loading" | "error"
        field: TPageVariant
      }>,
    ) => {
      const field = action.payload.field
      if (state.tree[field]?.itemSettings !== undefined) {
        state.tree[field].itemSettings.settingsData = action.payload.data
      }
    },
    updateGSItemData: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ value: any; field: TPageVariant; profileField: string }>,
    ) => {
      const { field, profileField, value } = action.payload
      if (state.tree[field]?.itemSettings !== undefined) {
        state.tree[field].itemSettings.settingsData[profileField] = value
      }
    },
    setTreeWithoutItem: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ data: any; field: TPageVariant }>,
    ) => {
      switch (action.payload.field) {
        case "objects":
          const treeCL = organizeByLevelObjects(action.payload.data)
          state.tree.objects.itemSettings.treeWithoutItems = buildByLevel(action.payload.data)
          break
        case "drivers":
          state.tree.drivers.itemSettings.treeWithoutItems = buildByLevel(action.payload.data)
          break
        case "users":
          state.tree.users.itemSettings.treeWithoutItems = buildByLevel(action.payload.data)
          break
      }
      // state.tree[action.payload.field].itemSettings.treeWithoutItems = action.payload.data
    },
    setGSUserAccountId: (state: IGlobalSettingsState, action: PayloadAction<number | null>) => {
      state.tree.users.activeAccountID = action.payload
    },
    setGSUserClientId: (state: IGlobalSettingsState, action: PayloadAction<number | null>) => {
      state.tree.users.client_id = action.payload
    },
    setGSFolderClientData: (state: IGlobalSettingsState, action: PayloadAction<any>) => {
      if (state.tree.users.folderSettings) {
        state.tree.users.folderSettings.folderSettingsData = action.payload
      }
    },
    setFolderTreeItemData: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ data: IDriverFolder; field: TPageVariant }>,
    ) => {
      if (state.tree[action.payload.field]) {
        state.tree[action.payload.field].folderSettings.folderSettingsData = action.payload.data
      }
    },
    setClientParentId: (state: IGlobalSettingsState, action: PayloadAction<number | null>) => {
      if (state.tree.users.folderSettings) {
        state.tree.users.folderSettings.folderSettingsData.parent_id = action.payload
      }
    },
    setUsersTreeFolderInfo: (
      state: IGlobalSettingsState,
      action: PayloadAction<IPostUserTreeFolderProps | null>,
    ) => {
      if (state.userTreeFolder) {
        state.userTreeFolder.data = action.payload
      }
    },
    setUsersTreeFolderIndex: (
      state: IGlobalSettingsState,
      action: PayloadAction<number | null>,
    ) => {
      if (state.userTreeFolder) {
        state.userTreeFolder.index = action.payload
      }
    },
    setUsersTreeFolderNull: (state: IGlobalSettingsState) => {
      state.userTreeFolder = {
        data: null,
        index: null,
      }
    },
    setNoEmptyLevels: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ data: INoEmptyLevels; field: TPageVariant }>,
    ) => {
      state.tree[action.payload.field].noEmptyLevels.push(action.payload.data)
    },
    setNewLevelIds: (
      state: IGlobalSettingsState,
      action: PayloadAction<{ levelIds: [number, number][]; page: TPageVariant }>,
    ) => {
      const { levelIds, page } = action.payload

      state.tree[page].levelIds = levelIds
    },
  },
})

export const {
  setNewLevelIds,
  setGSSearchText,
  setAccountName,
  setGSPageFolderData,
  setTreeWithoutItem,
  setUserId,
  setNewGSetVehicleUID,
  setOpenGlobalSettings,
  setGSettingsPage,
  setGSettingsObjects,
  setGSettingsUID,
  setNewHandleId,
  setPredefinedHandleID,
  setFolderData,
  setFolderAccountId,
  setGSettingsItemSettingsID,
  setGSItemData,
  updateGSItemData,
  setGSUserAccountId,
  setGSUserClientId,
  setGSFolderClientData,
  setClientParentId,
  setUsersTreeFolderInfo,
  setUsersTreeFolderIndex,
  setUsersTreeFolderNull,
  setNoEmptyLevels,
  setFolderTreeItemData,
  setGSAccountId,
} = globalSettings.actions
export default globalSettings.reducer
