import React from "react"

export const TableGrafIcon = () => {
  return (
    <div>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1V21M1 21H21M1 21L8.42857 13.3529L11.8571 16.2941L20.4286 7.47059M20.4286 7.47059H16.4286M20.4286 7.47059V11.5882"
          stroke="#FC6904"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
  )
}
