import { onlyNumber } from "src/components/Settings/Content/onlyNumber"
import { useAppSelector } from "src/shared/lib/hooks/store"
import { Input } from "src/shared/ui/Input"
import { updateObjectSettingsWheelsPressValidTime } from "src/store/reducers/objectSettings/objectSettingsPageSlices/tpms/tpms"

import { AppDispatch } from "@/store/store"
import { useCallback } from "react"
import { useDispatch } from "react-redux"

export const ValidTime = () => {
  const time = useAppSelector((state) => state.osTPMS.settings.sensors.valid_time_period)
  const dispatch = useDispatch<AppDispatch>()

  const handlerHpdateHandlerUpdateValidTime = useCallback(
    (a: string) => dispatch(updateObjectSettingsWheelsPressValidTime(onlyNumber(a))),
    [],
  )

  return (
    <div className=" w-full flex items-center justify-end gap-6 my-3">
      <p>Актуально в течение (сек)</p>
      <div className="w-fit relative">
        <Input
          variant="orange"
          inputType={"text"}
          value={time.toString()}
          setValue={(text) => handlerHpdateHandlerUpdateValidTime(text)}
        />
        {time === "" && <div className="text-red-500 text-sm absolute left-0">Заполните поле</div>}
      </div>
    </div>
  )
}
