import { useEffect, useState } from "react"
import { TextareaComponent } from "src/widgets/ObjectSettings/Content/helpComponents/TextareaComponent"
import { useAppDispatch, useAppSelector } from "../../../../../shared/lib/hooks/store"
import { setUsersTreeFolderInfo } from "src/store/reducers/globalSettings/globalSettings"
import { thunkCreateUpdateUsersTreeFolder } from "src/store/reducers/globalSettings/globalSettingsThunk"

export const FolderSettings = () => {
  const dispatch = useAppDispatch()
  const data = useAppSelector((state) => state.globalSettings.userTreeFolder?.data)
  const index = useAppSelector((state) => state.globalSettings.userTreeFolder?.index)

  const [name, setName] = useState<string>("")
  const [comment, setComment] = useState<string>("")
  useEffect(() => {
    if (data) {
      setName(data?.group_name)
      setComment(data?.comment)
    }
  }, [])

  const submitHandle = () => {
    const body = JSON.parse(JSON.stringify(data))
    body.group_name = name
    body.comment = comment

    index && dispatch(thunkCreateUpdateUsersTreeFolder(body, index))
  }
  return (
    <div className="w-screen absolute flex items-center justify-center h-screen left-0 top-0  backdrop-blur-lg z-20">
      <div className="w-[30%] h-[70%] relative bg-white shadow-xl flex items-center justify-center">
        <div
          onClick={() => dispatch(setUsersTreeFolderInfo(null))}
          className="icon-cross text-red-500 hover:text-red-600 hover:text-[25px] active:text-red-700 cursor-pointer transition-all p-4 text-2xl absolute z-10 right-0 top-0"
        ></div>
        <div className="absolute top-0 h-[10%] flex items-center justify-center w-full py-8 text-center text-2xl font-light">
          {data?.id == 0 ? "Создание " : "Изменение "}папки
        </div>
        <div className="flex flex-col h-[80%] gap-5 items-center justify-center w-[70%]">
          <div className="w-full flex items-center justify-between gap-2">
            <p className="text-lg font-light">Наименование</p>
            <TextareaComponent value={name ? name : ""} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="w-full flex items-center flex-col justify-between gap-2">
            <p className="text-lg w-full font-light">Комментарий</p>
            <TextareaComponent
              rows={4}
              value={comment ? comment : ""}
              onChange={(e) => setComment(e.target.value)}
              width={"100%"}
            />
          </div>
        </div>
        <div className="absolute bottom-0 h-[10%] flex items-center justify-center w-full py-8 text-center text-2xl font-light">
          <div
            onClick={() => submitHandle()}
            className="bg-orange-500 hover-bg-orange-600 active:bg-orange-700 transition-all px-10 py-3 text-white rounded"
          >
            Применить
          </div>
        </div>
      </div>
    </div>
  )
}
