import { useCallback, useEffect, useState } from "react"
import { IObjectWheel } from "src/interfaces/objectSettings"
import { useAppDispatch } from "src/shared/lib/hooks/store"

import {
  removeWheel,
  updateSensorNumber,
} from "src/store/reducers/objectSettings/objectSettingsPageSlices/tpms/tpms"

interface IObjectSettingsWheel {
  data: IObjectWheel
}

export const Wheel: React.FC<IObjectSettingsWheel> = ({ data }) => {
  const dispatch = useAppDispatch()
  const [dataArr, setData] = useState<IObjectWheel>()
  const [isOpenRemover, setOpenRemover] = useState(false)

  useEffect(() => {
    setData(data)
  }, [data])

  const handlerUpdateSensorNumber = useCallback(
    (arr: { wheelId: number; text: number }) => dispatch(updateSensorNumber(arr)),
    [],
  )

  const handlerRemoveWheel = useCallback((id: number) => dispatch(removeWheel(id)), [])

  const setSensorNumber = (text: string) => {
    const num = Number(text)
    if (num >= 0) {
      handlerUpdateSensorNumber({
        wheelId: data ? (data.innerWheelId ? data.innerWheelId : data.id) : 0,
        text: num,
      })
    }
  }

  return (
    <div
      onMouseEnter={() => setOpenRemover(true)}
      onMouseLeave={() => setOpenRemover(false)}
      className="min-w-10 w-fit h-14 my-4  rounded border border-orange-500 flex items-center relative justify-center text-sm"
    >
      {isOpenRemover && (
        <div
          onClick={() => {
            console.info(dataArr ? (dataArr.innerWheelId ? dataArr?.innerWheelId : dataArr?.id) : 0)
            handlerRemoveWheel(
              dataArr ? (dataArr.innerWheelId ? dataArr?.innerWheelId : dataArr?.id) : 0,
            )
          }}
          className="icon-delete p-1 cursor-pointer hover:bg-gray-100 active:bg-gray-200 rounded absolute bg-white -right-3 -top-3 text-red-500"
        ></div>
      )}
      <div className="w-full h-full flex items-center justify-center">
        <textarea
          onChange={(e) => setSensorNumber(e.target.value)}
          rows={1}
          className="bg-transparent inline-block min-w-10 w-10 max-w-full text-nowrap text-center resize-none text-[16px] "
          value={dataArr?.sensor_number}
        ></textarea>
      </div>
    </div>
  )
}
