export const requiredFields = ["main.vehicle_name", "main.vehicle_id"]

export function validateTypes(data: any): string[] {
  const errors: string[] = []

  function checkType(key: string, value: any, expectedType: string) {
    if (
      (expectedType === "array" && !Array.isArray(value)) ||
      (typeof value !== expectedType && expectedType !== "array")
    ) {
      errors.push(key)
    }
  }

  function checkObjectType(key: string, value: any, expectedInterface: any) {
    if (typeof value !== "object" || value === null) {
      errors.push(key)
      return
    }

    Object.keys(expectedInterface).forEach((subKey) => {
      const expectedSubType = expectedInterface[subKey]
      const fullKey = `${key}.${subKey}`

      if (Array.isArray(expectedSubType)) {
        if (!Array.isArray(value[subKey])) {
          errors.push(fullKey)
        } else {
          value[subKey].forEach((item: any, index: number) => {
            checkObjectType(`${fullKey}[${index}]`, item, expectedSubType[0])
          })
        }
      } else if (typeof expectedSubType === "object") {
        checkObjectType(fullKey, value[subKey], expectedSubType)
      } else {
        checkType(fullKey, value[subKey], expectedSubType)
      }
    })
  }

  function checkRequiredFields(data: any) {
    requiredFields.forEach((fieldPath) => {
      const fieldValue = fieldPath
        .split(".")
        .reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined), data)
      if (
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue === "" ||
        fieldValue === 0
      ) {
        errors.push(`data.${fieldPath}`)
      }
    })
  }

  const expectedStructure: any = {
    diag: { is_enabled: "boolean" },
    driver_cards: {
      CustomProtocolMonitoring: {
        addr: "number",
        dataSource: "number",
        func: "number",
        regNo: "number",
        use_as_driver_code: "boolean",
      },
      driver_events: {
        end_registration_by_removing_card: "boolean",
        end_registration_by_turning_off_ignition: "boolean",
        is_enabled: "boolean",
        restore_registration_if_card_reapplied_within: "number",
      },
    },
    fuel: [
      {
        id: "number",
        name: "string",
        settings: {
          buf_apx: "number",
          buf_raw: "number",
          k_add: "number",
          k_mul: "number",
          norm_out: "number",
          norm_out_type: "number",
          sensors: [
            {
              calibr: [["number", "number"]],
              id: "number",
              k_add: "number",
              k_mul: "number",
            },
          ],
          threshold_in: "number",
          threshold_out: "number",
        },
      },
    ],
    main: {
      account_id: "number",
      is_enabled: "boolean",
      plugin_id: "number",
      sim1: "string",
      sim2: "string",
      vehicle_id: "number",
      vehicle_name: "string",
      vehicle_pwd: "string",
    },
    movement: {
      events: {
        standing: {
          is_enabled: "boolean",
          standing_time: "number",
        },
        stopping: {
          is_enabled: "boolean",
          stopping_time: "number",
        },
      },
    },
    speed_control: {
      is_enabled: "boolean",
      max_limit: "number",
      reg_critical_limit: "number",
      reg_limit: "number",
      reg_time_limit: "number",
      use_road_signs_instead_max_limit: "boolean",
    },
    tpms: {
      settings: {
        sensors: {
          valid_time_period: "number",
        },
        wheel_axes: {
          pressure_norm_koef: [
            {
              beginning_with: "string",
              koef: "number",
            },
          ],
        },
      },
      wheel_axes: "array",
    },
    track: {
      colors: {
        dotted: {
          time_limit: "number",
        },
        is_enabled: "boolean",
        ranges: [
          {
            color: "string",
            speed: "number",
            time_limit: "number",
          },
        ],
      },
    },
    vehicle_uid: "string",
    vehicle_groups_tree_id: "number",
  }

  checkObjectType("data", data, expectedStructure)
  checkRequiredFields(data)

  return errors
}
