export const DriversIcon = ({ width, fill }) => {
  return (
    <svg
      width={width}
      viewBox="0 0 24.9062 23.584"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector"
        d="M12.45 14.15C15.88 14.15 18.66 10.98 18.66 7.07C18.66 3.16 15.88 0 12.45 0C9.01 0 6.23 3.16 6.23 7.07C6.23 10.98 9.01 14.15 12.45 14.15ZM13.08 12.09C12.87 12.13 12.66 12.15 12.45 12.15C12.24 12.15 12.03 12.13 11.82 12.09C11.5 12.03 11.2 11.93 10.9 11.79C10.88 11.78 10.86 11.77 10.84 11.76C10.36 11.52 9.93 11.18 9.55 10.75C9.51 10.7 9.47 10.65 9.43 10.6C9.08 10.17 8.8 9.68 8.59 9.13C8.54 8.98 8.49 8.83 8.45 8.68C8.3 8.17 8.23 7.63 8.23 7.07C8.23 6.51 8.3 5.97 8.45 5.46C8.49 5.31 8.54 5.16 8.59 5.01C8.8 4.46 9.08 3.97 9.43 3.54C9.47 3.49 9.51 3.44 9.55 3.39C9.93 2.96 10.36 2.62 10.84 2.38C10.86 2.37 10.88 2.36 10.9 2.35C11.2 2.21 11.5 2.11 11.82 2.05C12.03 2.01 12.24 2 12.45 2C12.66 2 12.87 2.01 13.08 2.05C13.39 2.11 13.7 2.21 14 2.35C14.02 2.36 14.04 2.37 14.06 2.38C14.54 2.62 14.96 2.96 15.34 3.39C15.38 3.44 15.43 3.49 15.47 3.54C15.82 3.97 16.1 4.46 16.3 5.01C16.36 5.16 16.4 5.31 16.45 5.46C16.59 5.97 16.66 6.51 16.66 7.07C16.66 7.63 16.59 8.17 16.45 8.68C16.4 8.83 16.36 8.98 16.3 9.13C16.1 9.68 15.82 10.17 15.47 10.6C15.43 10.65 15.38 10.7 15.34 10.75C14.96 11.18 14.54 11.52 14.06 11.76C14.04 11.77 14.02 11.78 14 11.79C13.7 11.93 13.39 12.03 13.08 12.09Z"
        fill={fill}
        fill-opacity="1.000000"
        fill-rule="evenodd"
      />
      <path
        id="Vector"
        d="M0.26 20.28C-0.61 21.81 0.76 23.58 2.63 23.58L22.27 23.58C24.14 23.58 25.5 21.81 24.63 20.16C23.51 18.04 21.4 16.27 18.66 15.21C17.92 14.97 17.05 14.97 16.43 15.32C15.18 16.03 13.94 16.39 12.45 16.39C10.96 16.39 9.71 16.03 8.47 15.32C7.85 15.09 6.98 14.97 6.23 15.32C3.5 16.39 1.38 18.15 0.26 20.28ZM7.65 17.16C7.54 17.12 7.43 17.11 7.32 17.1C7.22 17.1 7.14 17.11 7.09 17.13L7.02 17.16L6.95 17.19C5.79 17.64 4.78 18.22 3.92 18.94C3.1 19.62 2.47 20.38 2.03 21.21L2.02 21.24L2 21.26C2 21.27 2 21.27 2 21.27C2.01 21.28 2.02 21.3 2.03 21.32C2.08 21.39 2.15 21.45 2.25 21.49C2.27 21.5 2.29 21.51 2.31 21.52C2.41 21.56 2.51 21.58 2.63 21.58L22.27 21.58C22.39 21.58 22.5 21.56 22.61 21.51C22.62 21.51 22.63 21.5 22.64 21.49C22.73 21.45 22.8 21.39 22.85 21.32C22.88 21.28 22.9 21.25 22.9 21.22C22.9 21.18 22.89 21.14 22.86 21.09C22.43 20.26 21.8 19.51 20.98 18.82C20.13 18.12 19.14 17.54 18.01 17.1C17.86 17.06 17.72 17.04 17.58 17.04C17.52 17.05 17.47 17.05 17.43 17.06C17.42 17.06 17.41 17.07 17.41 17.07C16.63 17.51 15.85 17.84 15.05 18.05C14.23 18.27 13.36 18.39 12.45 18.39C11.54 18.39 10.67 18.27 9.84 18.05C9.1 17.85 8.37 17.55 7.65 17.16Z"
        fill={fill}
        fill-opacity="1.000000"
        fill-rule="evenodd"
      />
    </svg>
  )
}
