import { IDriverFolder, IDriverInfo } from "@/interfaces/settingsDriver"
import { returnErrorFetch, serverInstance } from "./settingsApiInstance"

interface IDriverAPI {
  getDriver: (uid: string) => Promise<{ data: unknown; status: number }>
  saveDriver: (body: IDriverInfo) => Promise<{ data: unknown; status: number }>
  deleteDriver: (uid: string) => Promise<{ data: unknown; status: number }>
  saveDriverFolder: (body: IDriverFolder) => Promise<{ data: unknown; status: number }>
  deleteDriverFolder: (uid: string) => Promise<{ data: unknown; status: number }>
  getDriverFolder: (uid: number) => Promise<{ data: unknown; status: number }>
}

export const driverSettingsAPI: IDriverAPI = {
  getDriver: async (uid) => {
    let body = {
      uid: uid,
    }
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/drivers/get_driver", body)
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  saveDriver: async (body) => {
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/drivers/post_driver", body)
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  deleteDriver: async (uid: string) => {
    let body = {
      uid: uid,
    }
    try {
      const { data, status } = await serverInstance.post("svr/v1/ctl/drivers/delete_driver", body)
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  saveDriverFolder: async (body: IDriverFolder) => {
    try {
      const { data, status } = await serverInstance.post(
        "svr/v1/ctl/drivers/post_driver_group",
        body,
      )
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
  deleteDriverFolder: async (uid: string) => {
    let body = {
      id: uid,
    }
    try {
      const { data, status } = await serverInstance.post(
        "svr/v1/ctl/drivers/delete_driver_group",
        body,
      )
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },

  getDriverFolder: async (uid) => {
    let body = {
      id: uid,
    }
    try {
      const { data, status } = await serverInstance.post(
        "svr/v1/ctl/drivers/get_driver_group",
        body,
      )
      return { data, status }
    } catch (error) {
      return returnErrorFetch(error)
    }
  },
}
