import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../components/shadcnui/ui/resizable"
import { Charts } from "./../ChartsPressureTemp/Charts"
import Car from "../Car/Car"

export const Tires = () => {
  return (
    <div className="h-full" style={{ padding: "0 10px 10px 10px" }}>
      <ResizablePanelGroup className="h-full" direction="vertical">
        <ResizablePanel minSize={30} className="overflow-auto pb-[8px] relative">
          <Car />
        </ResizablePanel>
        <ResizableHandle className="h-[1px]" />

        <ResizablePanel minSize={30} defaultSize={50} className="transition-all">
          <Charts />
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  )
}
