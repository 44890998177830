import React from "react"
import "./Search.css"
import { Input } from "src/shared/ui/Input"

type propsType = {
  value: string
  setValue: (value: string) => void
  placeholderInput: string
}

export const Search: React.FC<propsType> = ({ setValue, value, placeholderInput }) => {
  return (
    <Input
      inputType="text"
      className="main-input"
      value={value}
      setValue={(value) => setValue(value)}
      placeholder={placeholderInput}
      variant="silver"
    />
  )
}
