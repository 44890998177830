import React, { useContext, useEffect, useState } from "react"
import { RefContext } from "../Context"
import styles from "./style.module.css"

type propsType = {
  children: React.ReactNode
  btnMenu: Array<{
    label: string
    onClickBtn: () => void
  }>
  contextMenu?: (e: any) => void
  setMenuVisible?: (visible: boolean) => void
  menuVisible?: boolean
  menuPosition?: {
    x: number
    y: number
  }
  disactive?: boolean
}

export const ContextMenu: React.FC<propsType> = ({
  children,
  btnMenu,
  contextMenu,
  setMenuVisible,
  menuPosition,
  menuVisible,
  disactive,
}) => {
  const [menuVisibleComponent, setMenuVisibleComponent] = useState(false)
  const [menuPositionComponent, setMenuPositionComponent] = useState({ x: 0, y: 0 })

  const RDiv = useContext(RefContext)

  const handleContextMenu = (event: any) => {
    event.preventDefault()
    contextMenu && contextMenu(event)
    !setMenuVisible && setMenuVisibleComponent(true)

    !contextMenu && setMenuPositionComponent({ x: event.clientX, y: event.clientY })
  }

  useEffect(() => {
    const handlerClick = () => {
      if (!visable) return
      !setMenuVisible && setMenuVisibleComponent(false)
      setMenuVisible && setMenuVisible(false)
    }
    RDiv.addEventListener("click", handlerClick)
    RDiv.addEventListener("contextmenu", handlerClick)

    return () => {
      RDiv.removeEventListener("click", handlerClick)
      RDiv.removeEventListener("contextmenu", handlerClick)
    }
  })

  const visable = menuVisible ? menuVisible : menuVisibleComponent

  return (
    <div
      onContextMenu={(e) => {
        handleContextMenu(e)
      }}
      onClick={() => {
        setMenuVisible ? setMenuVisible(false) : setMenuVisibleComponent(false)
      }}
      className={styles.contextmenu}
    >
      {btnMenu.length != 0 && (
        <div
          className={styles.contextmenu_body}
          style={{
            top: menuPosition ? menuPosition.y : menuPositionComponent.y,
            left: menuPosition ? menuPosition.x : menuPositionComponent.x,
            display: (disactive ? !disactive : visable) ? "block" : "none",
          }}
        >
          {btnMenu.map((item, index) => {
            return (
              <button
                key={index}
                className={styles.contextmenu_item}
                onClick={() => {
                  item.onClickBtn()
                  setMenuVisible ? setMenuVisible(false) : setMenuVisibleComponent(false)
                }}
              >
                {item.label}
              </button>
            )
          })}
        </div>
      )}

      {children}
    </div>
  )
}
