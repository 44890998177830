import React, { useState } from "react"

interface IMonthDayPicker {
  monthDay: string
  onChange: (date: string, index: number) => void
  index: number
}

export const MonthDayPicker = ({ monthDay, onChange, index }: IMonthDayPicker) => {
  const [selectedDay, setSelectedDay] = useState<number>(Number(monthDay.slice(0, -3)))
  const [selectedMonth, setSelectedMonth] = useState<number>(Number(monthDay.slice(-2)))
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const day = Number(monthDay.slice(0, -3))
  const month = Number(monthDay.slice(-2))
  const daysInMonth = Array.from({ length: 31 }, (_, i) => {
    const day = i + 1
    return day < 10 ? `0${day}` : `${day}`
  })
  const months = [
    { name: "Январь", value: "01" },
    { name: "Февраль", value: "02" },
    { name: "Март", value: "03" },
    { name: "Апрель", value: "04" },
    { name: "Май", value: "05" },
    { name: "Июнь", value: "06" },
    { name: "Июль", value: "07" },
    { name: "Август", value: "08" },
    { name: "Сентябрь", value: "09" },
    { name: "Октябрь", value: "10" },
    { name: "Ноябрь", value: "11" },
    { name: "Декабрь", value: "12" },
  ]

  const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newDay = Number(event.target.value)
    setSelectedDay(newDay)
    handleChange(newDay, selectedMonth)
  }

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = Number(event.target.value)
    setSelectedMonth(newMonth)
    handleChange(selectedDay, newMonth)
  }

  const handleChange = (day: number, month: number) => {
    const formattedDay = day < 10 ? `0${day}` : `${day}`
    const formattedMonth = month < 10 ? `0${month}` : `${month}`
    setIsOpen(false)
    onChange(`${formattedDay}.${formattedMonth}`, index)
  }

  return (
    <div>
      <div
        className="p-2 hover:bg-gray-200 cursor-pointer transition-all rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        {monthDay}
      </div>
      {isOpen && (
        <div className="flex">
          <select value={day > 10 ? month.toString() : "0" + day} onChange={handleDayChange}>
            {daysInMonth.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
          <select value={month > 10 ? month.toString() : "0" + month} onChange={handleMonthChange}>
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  )
}
