import { vehicleTreeAPI } from "./../../../shared/api/vehicleTreeApi"
import { fetchIsError } from "../../../shared/lib/helpers/fetchIsError/fetchIsError"
import { addVehicleGroup, deleteGroupVehicleTree, setVehicleTree } from "./vehicleTreeSlice"

export const thunkGetVehicles = (type: any) => {
  return async (dispatch) => {
    const data = await fetchIsError(dispatch, vehicleTreeAPI.getTreeGroupsVehicles, [type])
    if (!data) return
    dispatch(setVehicleTree(data.data.map((el) => ({ ...el, status: "none" }))))
  }
}

export const changeGroupCreateVehicle = (
  parent_id: number,
  account_id: number,
  group_name: string,
  level: number,
  id?: number,
) => {
  return async (dispatch) => {
    const data = await fetchIsError(dispatch, vehicleTreeAPI.changeTreeGroupsVehicle, [
      parent_id,
      group_name,
      id,
    ])
    if (!data) return
    dispatch(
      addVehicleGroup({
        account_id: account_id,
        vehicles: [],
        group_name: group_name,
        id: data.id,
        parent_id: parent_id,
        is_root: false,
        role_id: 3,
        level: level,
      }),
    )
  }
}

export const thunkDeleteGroupVehicle = (id: number) => {
  return async (dispatch) => {
    const data = await fetchIsError(dispatch, vehicleTreeAPI.deleteGroupVehicle, [id])
    if (!data) return
    dispatch(deleteGroupVehicleTree(id))
  }
}
