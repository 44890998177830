export default function DeleteIcon({ fill, height }) {
  return (
    <svg height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.93701 20.7037L2.88304 19.8474L2.14098 6.6598H4.08047L4.77193 18.8935H15.2283L15.9029 6.6598H17.8592L17.0834 20.7037H2.93701ZM14.1219 0.555542V2.81906H19.4446V4.62925H0.555664V2.81906H5.87828V0.555542H14.1219ZM7.57491 2.81906H12.4253V1.68888H7.57491V2.81906ZM8.82967 17.417L8.58344 6.6598H6.89693L7.14316 17.417H8.82967ZM12.8301 17.417L13.0763 6.6598H11.3898L11.1436 17.417H12.8301Z"
        fill="#E94040"
      />
    </svg>
  )
}
