export default function EditorIcon({ fill, height }) {
  return (
    <svg height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9167 4.33334H4.33341C3.75878 4.33334 3.20768 4.56162 2.80135 4.96795C2.39502 5.37427 2.16675 5.92537 2.16675 6.50001V21.6667C2.16675 22.2413 2.39502 22.7924 2.80135 23.1987C3.20768 23.6051 3.75878 23.8333 4.33341 23.8333H19.5001C20.0747 23.8333 20.6258 23.6051 21.0321 23.1987C21.4385 22.7924 21.6667 22.2413 21.6667 21.6667V14.0833"
        stroke="#474747"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0417 2.70834C20.4727 2.27737 21.0573 2.03525 21.6667 2.03525C22.2762 2.03525 22.8608 2.27737 23.2917 2.70834C23.7227 3.13932 23.9648 3.72385 23.9648 4.33334C23.9648 4.94284 23.7227 5.52737 23.2917 5.95834L13.0001 16.25L8.66675 17.3333L9.75008 13L20.0417 2.70834Z"
        stroke="#474747"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
