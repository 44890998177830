import { Calendar } from "src/entities/CustomDateRangePicker /Calendar/Calendar"
import { Popup } from "src/shared/ui/Popup"
import { TextArea } from "src/shared/ui/Textarea"
import { useEffect, useState } from "react"
import { DateTime } from "ts-luxon"
import styles from "./SwitchTire.module.css"
import { Checkbox } from "src/shared/ui/Checkbox"
import { Button } from "src/shared/ui/Button"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { switchTireThunk } from "src/store/reducers/switchTire/switchTireThunk"
import { addError } from "src/store/reducers/globalErrors/globalErrorsSlice"
import {
  setActiveTireHistory,
  setDataSwitch,
  setEdit,
  setIsSelectCatalog,
  setModelTireID,
  setModelTireName,
  setReasonReplacement,
  setWheelIdSwitchTire,
} from "src/store/reducers/switchTire/switchTire"
import { setWheelId } from "src/store/reducers/car/car"
import { setActiveTireID } from "src/store/reducers/catalogTire/catalogTire"
import { LoadingWrapper } from "src/components/LoadingWrapper/LoadingWrapper"
import { useLoading } from "src/shared/lib/hooks/loading"

export const SwitchTire = ({ onClose, onOpenSwitchHistory, onOpenCatalogTire }) => {
  const dispatch = useAppDispatch()

  const [switchAll, setSwitchAll] = useState(false)
  const [idHistory, setIdHistory] = useState<null | number>(null)

  const wheelId = useAppSelector((state) => state.car.wheel_id)
  const vehicleUid = useAppSelector((state) => state.car.vehicle_uid)

  const modelTireID = useAppSelector((state) => state.switchTire.modelTireID)
  const modelTireName = useAppSelector((state) => state.switchTire.modelTireName)
  const activeRowHistory = useAppSelector((state) => state.switchTire.activeHistoryTire)
  const reasonReplacement = useAppSelector((state) => state.switchTire.reasonReplacement)
  const switchTime = useAppSelector((state) => state.switchTire.dateSwitch)
  const isEdit = useAppSelector((state) => state.switchTire.isEdit)

  const { loading, startLoading } = useLoading()

  useEffect(() => {
    return () => {
      dispatch(setDataSwitch(DateTime.local().toSeconds()))
      dispatch(setReasonReplacement(""))
      dispatch(setModelTireName(null))
      dispatch(setModelTireID(null))
      dispatch(setWheelIdSwitchTire(null))
      dispatch(setEdit(false))
    }
  }, [])

  useEffect(() => {
    if (!activeRowHistory) return
    dispatch(setDataSwitch(activeRowHistory.tire.start_date))
    dispatch(setReasonReplacement(activeRowHistory.tire.reason_replacement))
    dispatch(setModelTireName(activeRowHistory.tire.wheel_model_name))
    dispatch(setModelTireID(activeRowHistory.tire.wheel_model_id))
    dispatch(setWheelId(activeRowHistory.tire.wheel_id))
    setIdHistory(activeRowHistory?.tire?.id ? activeRowHistory.tire.id : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRowHistory])

  const sendSwitchTire = () => {
    if (!wheelId || !modelTireID || !switchTime || !reasonReplacement) {
      dispatch(addError({ message: "Не все поля заполнены!", seconds: 5 }))
      return
    }
    startLoading()
    dispatch(
      switchTireThunk(
        {
          wheel_id: switchAll ? 0 : wheelId, // ID КОЛЕСА ЕСЛИ 0 ТО МЕНЯЕТ ВСЕ КОЛЕСА
          wheel_model_id: modelTireID, // ID МОДЕЛИ
          reason_replacement: reasonReplacement,
          start_date: switchTime,
          vehicle_uid: vehicleUid,
          id: idHistory ? idHistory : 0, // ID ЗАМЕНЫ ЕСЛИ 0 ТО СОЗДАЕТ НОВУЮ ИСТОРИЮ
        },
        () => {
          onClose()
          onOpenSwitchHistory()
        },
      ),
    )
  }

  return (
    <Popup
      onClose={() => {
        if (activeRowHistory) onOpenSwitchHistory()
        onClose()
      }}
      title="Замена шины"
    >
      <LoadingWrapper isLoading={loading}>
        <div className={styles.switchtire_wrapper}>
          <div className={styles.switchtire_box}>
            <div className={styles.switchtire_tire}>
              {modelTireName ? modelTireName : "Ничего не выбрано"}
              <Button
                color="orange"
                onClick={() => {
                  dispatch(setIsSelectCatalog(true))
                  dispatch(setActiveTireID(modelTireID ? modelTireID : 0))
                  onOpenCatalogTire()
                }}
              >
                Выбрать
              </Button>
            </div>
            Причина замены
            <div className={styles.switchtire_textarea}>
              <TextArea
                value={reasonReplacement}
                setValue={(value) => dispatch(setReasonReplacement(value))}
                variant="orange"
              />
            </div>
            {isEdit && (
              <div className={styles.switchtire_checkbox}>
                <Checkbox checked={switchAll} setChecked={() => setSwitchAll((prev) => !prev)} />
                Поменять на всех колесах
              </div>
            )}
          </div>
          <div>
            <Calendar
              currentDate={DateTime.fromSeconds(switchTime)}
              setCurrentDate={(date) => dispatch(setDataSwitch(date.toSeconds()))}
            />
          </div>
        </div>
        <div className={styles.switchtire_btns}>
          <Button color="orange" onClick={sendSwitchTire}>
            Пременить
          </Button>
        </div>
      </LoadingWrapper>
    </Popup>
  )
}
