import React from "react"
import { offIgnition, onIgnition, parking, refueling, startDrain, stop } from "./../EventIcons"
import MarkerClusterGroup from "react-leaflet-cluster"
import { Marker } from "react-leaflet"
import { useAppDispatch, useAppSelector } from "src/shared/lib/hooks/store"
import { Icon, DivIcon, Point, MarkerCluster, divIcon, point } from "leaflet"
import { getInfoPointTrack } from "src/store/reducers/mapTrack/mapTrackThunk"

interface IProps {
  startLoading?: () => void
}

export const EventsTrack: React.FC<IProps> = ({ startLoading }) => {
  const dispatch = useAppDispatch()
  const tracks = useAppSelector((state) => state.mapTrack.tracks)

  const customIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/256/484/484167.png",
    iconSize: new Point(40, 47),
  })

  const createIcon = (iconSvg) => {
    return new DivIcon({
      iconSize: [40, 30],
      html: `<div class="event-icon__wrapper">${iconSvg}</div>`,
      className: "event-icon",
    })
  }

  return (
    <>
      {tracks.map((track, index) => {
        const createClusterCustomIcon = function (cluster: MarkerCluster) {
          return divIcon({
            html: `<div class="custom-marker-cluster-div" style="background: ${track.color}"><div style="background: ${track.color}">${cluster.getChildCount()}</div></div>`,
            className: "custom-marker-cluster",
            iconSize: point(50, 50, true),
          })
        }
        return (
          track.events.length > 0 && (
            <MarkerClusterGroup
              zoomToBoundsOnClick={true}
              spiderfyOnMaxZoom={true}
              disableClusteringAtZoom={18}
              showCoverageOnHover={false}
              iconCreateFunction={createClusterCustomIcon}
              removeOutsideVisibleBounds={true}
            >
              {track.events.map((el) => {
                if (el.event_type_id === 3) {
                  return (
                    <Marker
                      position={[el.latitude, el.longitude]}
                      icon={createIcon(onIgnition)}
                      eventHandlers={{
                        click: (e) => {
                          dispatch(getInfoPointTrack(el.mdd_id))
                          startLoading && startLoading()
                        },
                      }}
                    />
                  )
                }
                if (el.event_type_id === 4) {
                  return (
                    <Marker
                      position={[el.latitude, el.longitude]}
                      icon={createIcon(offIgnition)}
                      eventHandlers={{
                        click: (e) => {
                          dispatch(getInfoPointTrack(el.mdd_id))
                          startLoading && startLoading()
                        },
                      }}
                    />
                  )
                }
                if (el.event_type_id === 9) {
                  return (
                    <Marker
                      position={[el.latitude, el.longitude]}
                      icon={createIcon(parking)}
                      eventHandlers={{
                        click: (e) => {
                          dispatch(getInfoPointTrack(el.mdd_id))
                          startLoading && startLoading()
                        },
                      }}
                    />
                  )
                }
                if (el.event_type_id === 10) {
                  return (
                    <Marker
                      position={[el.latitude, el.longitude]}
                      icon={createIcon(stop)}
                      eventHandlers={{
                        click: (e) => {
                          dispatch(getInfoPointTrack(el.mdd_id))
                          startLoading && startLoading()
                        },
                      }}
                    />
                  )
                }
                if (el.event_type_id === 11) {
                  return (
                    <Marker
                      position={[el.latitude, el.longitude]}
                      icon={createIcon(refueling)}
                      eventHandlers={{
                        click: (e) => {
                          dispatch(getInfoPointTrack(el.mdd_id))
                          startLoading && startLoading()
                        },
                      }}
                    />
                  )
                }
                if (el.event_type_id === 13) {
                  return (
                    <Marker
                      position={[el.latitude, el.longitude]}
                      icon={createIcon(startDrain)}
                      eventHandlers={{
                        click: (e) => {
                          dispatch(getInfoPointTrack(el.mdd_id))
                          startLoading && startLoading()
                        },
                      }}
                    />
                  )
                }
                return (
                  <Marker
                    position={[el.latitude, el.longitude]}
                    icon={customIcon}
                    eventHandlers={{
                      click: (e) => {
                        dispatch(getInfoPointTrack(el.mdd_id))
                        startLoading && startLoading()
                      },
                    }}
                  />
                )
              })}
            </MarkerClusterGroup>
          )
        )
      })}
    </>
  )
}
