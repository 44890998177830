import { useEffect } from "react"
import { setGSUserClientId } from "src/store/reducers/globalSettings/globalSettings"
import { thunkGetClient } from "src/store/reducers/globalSettings/globalSettingsThunk"
import { useAppDispatch, useAppSelector } from "../../../../../shared/lib/hooks/store"
import { Content } from "./Content/Content"

export interface IClientInfo {
  account_name: string
  bitrix: any
  comment: string
  create_date: number
  id: number
  is_always_require_confirm: boolean
  is_control_user_agent: boolean
  is_enabled: boolean
  is_one_session: boolean
  parent_id: number
  requisites: any
  role_id: number
}
interface IClientSettProps {
  id: number
}
export const ClientSettings = ({ id }: IClientSettProps) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    id != 0 && dispatch(thunkGetClient(id))
  }, [])
  const data = useAppSelector(
    (state) => state.globalSettings.tree.users.folderSettings?.folderSettingsData,
  )
  return (
    <div className="absolute flex z-10 items-center justify-center top-0 left-0 w-screen h-screen backdrop-blur-lg">
      <div className="w-1/3 h-2/3 bg-white relative rounded shadow-xl flex items-center justify-center">
        <div
          onClick={() => dispatch(setGSUserClientId(null))}
          className="absolute z-10 right-0 top-0 p-4 icon-cross text-xl hover:text-red-600 active:text-red-700 text-red-500 cursor-pointer transition-all"
        ></div>
        {data ? <Content data={data} /> : <p>Загрузка...</p>}
      </div>
    </div>
  )
}
