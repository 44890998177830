import React, { useEffect, useState } from "react"

type propsType = {
  isLoading: boolean
  children: React.ReactNode
}

export const LoadingWrapper: React.FC<propsType> = ({ isLoading, children }) => {
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backdropFilter: "blur(12px)",
            zIndex: 50000,
            width: "100%",
            height: "100%",
          }}
        >
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {children}
    </>
  )
}
