export const Plus = ({ fill, width }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.56716 13.208C6.40716 13.208 6.27116 13.16 6.15916 13.064C6.06316 12.952 6.01516 12.816 6.01516 12.656V7.664H1.09516C0.935156 7.664 0.799156 7.616 0.687156 7.52C0.591156 7.408 0.543156 7.272 0.543156 7.112V6.344C0.543156 6.184 0.591156 6.056 0.687156 5.96C0.799156 5.848 0.935156 5.792 1.09516 5.792H6.01516V0.943999C6.01516 0.783999 6.06316 0.656 6.15916 0.56C6.27116 0.447999 6.40716 0.391999 6.56716 0.391999H7.40716C7.56716 0.391999 7.69516 0.447999 7.79116 0.56C7.90316 0.656 7.95916 0.783999 7.95916 0.943999V5.792H12.9032C13.0632 5.792 13.1912 5.848 13.2872 5.96C13.3992 6.056 13.4552 6.184 13.4552 6.344V7.112C13.4552 7.272 13.3992 7.408 13.2872 7.52C13.1912 7.616 13.0632 7.664 12.9032 7.664H7.95916V12.656C7.95916 12.816 7.90316 12.952 7.79116 13.064C7.69516 13.16 7.56716 13.208 7.40716 13.208H6.56716Z"
        fill={fill}
      />
    </svg>
  )
}
