import { AppDispatch } from "@/store/store"
import { useDispatch } from "react-redux"
import { TPages } from "../store/reducers/routing/routerSlice"
import { Cart } from "src/shared/ui/Cart"
import { setPage } from "src/store/reducers/routing/routerThunk"

interface IPages {
  page: string
  image: string
  link: TPages
}

export const Main = () => {
  const dispatch = useDispatch<AppDispatch>()
  const items: IPages[] = [
    {
      page: "СКДШ",
      image: "images/TPMS.png",
      link: "tpms",
    },
    {
      page: "Карта",
      image: "images/map.png",
      link: "map",
    },
  ]
  return (
    <div className="w-full h-full">
      <div
        style={{
          gridTemplateColumns: "repeat(5, 250px)",
        }}
        className="w-full  gap-3 flex flex-wrap items-center"
      >
        {items.map((page, index) => (
          <Cart
            clickCart={() => dispatch(setPage(page.link))}
            image={page.image}
            title={page.page}
          />
        ))}
      </div>
    </div>
  )
}
