import L from "leaflet"
export const parking = `<svg width="45.000000" height="45.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<clipPath id="clip731_22320">
			<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)" fill="white" fill-opacity="0"/>
		</clipPath>
	</defs>
	<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)"  fill-opacity="0.500000"/>
	<g clip-path="url(#clip731_22320)">
		<path d="M64 32C64 49.67 32 64 32 64C32 64 0 49.67 0 32C0 14.32 14.32 0 32 0C49.67 0 64 14.32 64 32Z" fill="#F1711B" fill-opacity="1.000000" fill-rule="nonzero"/>
		<path d="M22.8 43.82Q23.03 44 23.4 44L25.52 44Q25.88 44 26.12 43.82Q26.17 43.79 26.21 43.74Q26.31 43.64 26.37 43.52Q26.48 43.33 26.48 43.08L26.48 33.08L33.56 33.08Q36.51 33.08 38.69 32.12Q40.26 31.43 41.31 30.27Q41.73 29.81 42.07 29.28Q43.27 27.39 43.27 24.55Q43.27 21.72 42.07 19.82Q41.68 19.19 41.19 18.67Q40.17 17.6 38.69 16.95Q36.51 16 33.56 16L23.4 16Q23.05 16 22.82 16.16Q22.77 16.21 22.72 16.26Q22.68 16.29 22.66 16.33Q22.47 16.57 22.47 16.95L22.47 43.08Q22.47 43.36 22.59 43.57Q22.64 43.66 22.72 43.74Q22.76 43.78 22.8 43.82ZM33.35 29.72L26.44 29.72L26.44 19.36L33.35 19.36Q36.4 19.36 37.83 20.71Q38.08 20.94 38.28 21.2Q39.28 22.49 39.28 24.55Q39.28 26.64 38.26 27.91Q38.05 28.17 37.79 28.4Q36.32 29.72 33.35 29.72Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
	</g>
</svg>`

export const refueling = `<svg width="45.000000" height="45.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<clipPath id="clip731_22339">
			<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)" fill="white" fill-opacity="0"/>
		</clipPath>
	</defs>
	<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)"  fill-opacity="0.500000"/>
	<g clip-path="url(#clip731_22339)">
		<path d="M64 32C64 49.67 32 64 32 64C32 64 0 49.67 0 32C0 14.32 14.32 0 32 0C49.67 0 64 14.32 64 32Z" fill="#62B15B" fill-opacity="1.000000" fill-rule="evenodd"/>
		<path d="M46.6 47C46.6 47 47.59 42.35 43.82 42.35C43.66 42.35 43.17 42.35 42.43 42.35C37.62 42.35 22.22 42.35 17.41 42.35C16.67 42.35 16.18 42.35 16.02 42.35C11.78 42.35 13.24 47 13.24 47L46.6 47ZM42.43 42.35C42.43 42.35 42.43 38.86 42.43 34.23C42.43 26.07 42.43 13.83 42.43 11.61C42.43 8.01 17.41 8.43 17.41 11.02C17.41 14.5 17.41 42.35 17.41 42.35M37.7 13.34C39.08 13.34 39.08 22.63 37.7 22.63C34.91 22.63 24.22 22.63 22.02 22.63C20.63 22.63 20.63 13.34 22.02 13.34C23.41 13.34 34.91 13.34 37.7 13.34ZM42.43 34.23C49.38 33.07 43.82 38.87 47.99 38.87C52.16 38.87 50.77 15.66 50.77 14.5C50.77 13.34 45.21 11.02 45.21 11.02M50.77 14.5L46.6 17.99C46.6 22.63 46.6 22.63 50.77 22.63" stroke="#FFFFFF" stroke-opacity="1.000000" stroke-width="2.500000" stroke-linecap="round"/>
	</g>
</svg>`

export const startDrain = `<svg width="45.000000" height="45.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<clipPath id="clip708_22906">
			<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)"  fill-opacity="0"/>
		</clipPath>
	</defs>
	<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)"  fill-opacity="0.500000"/>
	<g clip-path="url(#clip708_22906)">
		<path d="M64 32C64 49.67 32 64 32 64C32 64 0 49.67 0 32C0 14.32 14.32 0 32 0C49.67 0 64 14.32 64 32Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
		<path d="M22.74 33.14C22.74 33.14 15.65 32.7 13.9 29.83C12.42 27.4 15.1 23.84 15.1 23.84C15.1 23.84 37.24 5.19 37.69 5.28C38.13 5.37 52.79 23.04 52.7 23.55C52.61 24.05 36.52 37.47 35.76 37.47C35 37.47 27 38.05 26.57 37.79C26.13 37.54 22.74 33.14 22.74 33.14L15.1 23.84M29.01 41.21L33.84 40.68" stroke="#E94040" stroke-opacity="1.000000" stroke-width="2.500000"/>
		<path d="M29.11 49.4C25.47 56.33 39.83 55.97 35.29 49.13L31.99 44.48L29.11 49.4Z" stroke="#E94040" stroke-opacity="1.000000" stroke-width="1.000000"/>
		<path d="M33.49 52.85C34.47 52.94 35.09 51.68 34.52 50.68" stroke="#E94040" stroke-opacity="1.000000" stroke-width="0.500000" stroke-linecap="round"/>
	</g>
</svg>`

export const stop = `<svg width="45.000000" height="45.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<clipPath id="clip731_22317">
			<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)" fill="white" fill-opacity="0"/>
		</clipPath>
	</defs>
	<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)"  fill-opacity="0.500000"/>
	<g clip-path="url(#clip731_22317)">
		<path d="M64 32C64 49.67 32 64 32 64C32 64 0 49.67 0 32C0 14.32 14.32 0 32 0C49.67 0 64 14.32 64 32Z" fill="#4665D3" fill-opacity="1.000000" fill-rule="nonzero"/>
		<path d="M22.8 43.82Q23.03 44 23.4 44L25.52 44Q25.88 44 26.12 43.82Q26.17 43.79 26.21 43.74Q26.31 43.64 26.37 43.52Q26.48 43.33 26.48 43.08L26.48 33.08L33.56 33.08Q36.51 33.08 38.69 32.12Q40.26 31.43 41.31 30.27Q41.73 29.81 42.07 29.28Q43.27 27.39 43.27 24.55Q43.27 21.72 42.07 19.82Q41.68 19.19 41.19 18.67Q40.17 17.6 38.69 16.95Q36.51 16 33.56 16L23.4 16Q23.05 16 22.82 16.16Q22.77 16.21 22.72 16.26Q22.68 16.29 22.66 16.33Q22.47 16.57 22.47 16.95L22.47 43.08Q22.47 43.36 22.59 43.57Q22.64 43.66 22.72 43.74Q22.76 43.78 22.8 43.82ZM33.35 29.72L26.44 29.72L26.44 19.36L33.35 19.36Q36.4 19.36 37.83 20.71Q38.08 20.94 38.28 21.2Q39.28 22.49 39.28 24.55Q39.28 26.64 38.26 27.91Q38.05 28.17 37.79 28.4Q36.32 29.72 33.35 29.72Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
	</g>
</svg>`

export const offIgnition = `<svg width="45.000000" height="45.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<clipPath id="clip731_22333">
			<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)" fill="white" fill-opacity="0"/>
		</clipPath>
	</defs>
	<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)"  fill-opacity="0.500000"/>
	<g clip-path="url(#clip731_22333)">
		<path d="M64 32C64 49.67 32 64 32 64C32 64 0 49.67 0 32C0 14.32 14.32 0 32 0C49.67 0 64 14.32 64 32Z" fill="#E94040" fill-opacity="1.000000" fill-rule="evenodd"/>
		<path d="M36.29 22.96C36.97 23.69 38.07 23.69 38.74 22.96C39.42 22.23 39.42 21.03 38.74 20.3C38.07 19.56 36.97 19.56 36.29 20.3C35.62 21.03 35.62 22.23 36.29 22.96Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
		<path d="M43.95 16.52C43.5 15.67 43.25 15.4 42.47 14.91L37.27 11.68C35.23 10.4 33.74 9.07 32.07 10.88L25.14 19C23.48 20.81 23.14 23.61 24.32 25.83L25.01 27.13L16.01 36.91C15.36 37.61 15 38.57 15 39.57L15 42.23C15 44.31 16.55 46 18.46 46L20.91 46C21.83 46 22.71 45.6 23.36 44.89L32.36 35.11L33.56 35.87C35.6 37.15 38.18 36.78 39.84 34.97L46.92 27.01C48.59 25.2 48.1 24.38 46.92 22.17L43.95 16.52ZM40.98 17.33C41.24 17.49 41.57 17.85 41.72 18.13L44.69 23.78C45.06 24.59 44.51 24.79 43.95 25.39L37.39 32.31C36.84 32.91 35.98 33.04 35.3 32.61L31.8 30.41L20.91 42.23L18.46 42.23L18.46 39.57L29.35 27.74L27.32 23.94C26.93 23.2 27.04 22.27 27.59 21.67L34.3 14.1C34.85 13.5 35.1 13.67 35.78 14.1L40.98 17.33Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
	</g>
</svg>`

export const onIgnition = `<svg width="45.000000" height="45.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<clipPath id="clip731_22336">
			<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)" fill="white" fill-opacity="0"/>
		</clipPath>
	</defs>
	<rect rx="-0.500000" width="63.000000" height="63.000000" transform="translate(0.500000 0.500000)"  fill-opacity="0.500000"/>
	<g clip-path="url(#clip731_22336)">
		<path d="M64 32C64 49.67 32 64 32 64C32 64 0 49.67 0 32C0 14.32 14.32 0 32 0C49.67 0 64 14.32 64 32Z" fill="#62B15B" fill-opacity="1.000000" fill-rule="evenodd"/>
		<path d="M36.29 22.96C36.97 23.69 38.07 23.69 38.74 22.96C39.42 22.23 39.42 21.03 38.74 20.3C38.07 19.56 36.97 19.56 36.29 20.3C35.62 21.03 35.62 22.23 36.29 22.96Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
		<path d="M43.95 16.52C43.5 15.67 43.25 15.4 42.47 14.91L37.27 11.68C35.23 10.4 33.74 9.07 32.07 10.88L25.14 19C23.48 20.81 23.14 23.61 24.32 25.83L25.01 27.13L16.01 36.91C15.36 37.61 15 38.57 15 39.57L15 42.23C15 44.31 16.55 46 18.46 46L20.91 46C21.83 46 22.71 45.6 23.36 44.89L32.36 35.11L33.56 35.87C35.6 37.15 38.18 36.78 39.84 34.97L46.92 27.01C48.59 25.2 48.1 24.38 46.92 22.17L43.95 16.52ZM40.98 17.33C41.24 17.49 41.57 17.85 41.72 18.13L44.69 23.78C45.06 24.59 44.51 24.79 43.95 25.39L37.39 32.31C36.84 32.91 35.98 33.04 35.3 32.61L31.8 30.41L20.91 42.23L18.46 42.23L18.46 39.57L29.35 27.74L27.32 23.94C26.93 23.2 27.04 22.27 27.59 21.67L34.3 14.1C34.85 13.5 35.1 13.67 35.78 14.1L40.98 17.33Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
	</g>
</svg>`
