import { useAppDispatch, useAppSelector } from "../../../../shared/lib/hooks/store"
import {
  addDriverChecked,
  deleteDriverChecked,
  setActiveDriver,
} from "../../../../store/reducers/drivers/driverSlice"
import { CheckboxItem } from "../../CheckboxItem/CheckboxItem"

export const DriverItemMap = ({ group }) => {
  const dispatch = useAppDispatch()

  const checkedDrivers = useAppSelector((state) => state.driver.checkedDrivers)
  const activeDriver = useAppSelector((state) => state.driver.activeDriver)
  const onAddCheckedDriver = (vehicle, id_group) =>
    dispatch(addDriverChecked({ driver: vehicle, id: id_group }))
  const onDeleteCheckedDriver = (vehicle, id_group) =>
    dispatch(deleteDriverChecked({ driver: vehicle, id: id_group }))
  return (
    <>
      {group.drivers &&
        group.drivers.map((driver, key) => {
          const isChecked = checkedDrivers.some(
            (currentVehicle) => currentVehicle.driver_uid === driver.driver_uid,
          )
          const isActive = activeDriver ? activeDriver.driver_uid === driver.driver_uid : false
          return (
            <div
              className={`checkbox-folder__item ${isActive ? "checkbox-folder__item--active" : ""}`}
              onClick={() => dispatch(setActiveDriver(driver))}
            >
              <CheckboxItem
                onChecked={() =>
                  isChecked
                    ? onDeleteCheckedDriver(driver, group.id)
                    : onAddCheckedDriver(driver, group.id)
                }
                checked={isChecked}
              >
                {driver.driver_name}
              </CheckboxItem>
            </div>
          )
        })}
    </>
  )
}
