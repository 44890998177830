import { AppDispatch, RootState } from "@/store/store"
import { useDispatch, useSelector } from "react-redux"
import { setOpenGlobalSettings } from "src/store/reducers/globalSettings/globalSettings"

import { useAppSelector } from "./../../shared/lib/hooks/store"
import { Content } from "./Content/Content"
import { CreateFolderPopup } from "./Content/Help/Creator/CreateFolderPopup"
import { ForbiddenAlert } from "./Content/Help/ForbiddenAlert"
import { SuccessAlert } from "./Content/SucsessAlert"
import { SettingsIcon } from "./icons/SettingsIcon"
import { Navbar } from "./Navbar/Navbar"
import { DriverSettings } from "./ProfileSettings/Drivers/DriverSettings/DriverSettings"
import { DriverFolderSettings } from "./ProfileSettings/Drivers/FolderSettings/FolderSettings"
import { ClientSettings } from "./ProfileSettings/Users/ClientSettings/ClientSettings"
import { FolderSettings } from "./ProfileSettings/Users/FolderSettings/FolderSettings"
import { UserSettings } from "./ProfileSettings/Users/UserSettings/UserSettings"
import styles from "./styles/settings.module.css"

export const Settings = () => {
  const dispatch = useDispatch<AppDispatch>()
  const objectUID = useSelector((state: RootState) => state.globalSettings.vehicleUID)
  const uid = useSelector((state: RootState) => state.objectSettings.uid)
  const folderStatus = useSelector((state: RootState) => state.globalSettings.folder.status)
  const userCreateUpdateID = useSelector(
    (state: RootState) => state.globalSettings.tree.users.itemSettings.settingsID,
  )
  const driver = useAppSelector(
    (state) => state.globalSettings.tree.drivers.itemSettings.settingsData,
  )

  const folderInfo = useAppSelector((state) => state.globalSettings.userTreeFolder?.data)
  const clientFolderId = useAppSelector((state) => state.globalSettings.tree.users.client_id)
  const driverFolder = useAppSelector(
    (state) => state.globalSettings.tree.drivers.folderSettings.folderSettingsData,
  )
  const accountName = useAppSelector((state) => state.globalSettings.accountName)

  return (
    <div className={`${styles.settings}`}>
      <div className={styles.settings_header}>
        <div className={styles.settings_header_h1}>
          <SettingsIcon fill="rgb(71, 71, 71)" width={24} />
          <h1 className="">Настройки</h1>
          <p className="bg-orange-500 text-white text-[12px] px-2 py-1 rounded-sm">{accountName}</p>
        </div>

        <div
          onClick={() => dispatch(setOpenGlobalSettings(false))}
          className={styles.settings_header_exit}
        >
          Выйти
        </div>
      </div>

      <div className={styles.settings_content_container}>
        <Navbar />

        <Content />
      </div>

      {((folderStatus && folderStatus == "create") || folderStatus == "update") && (
        <CreateFolderPopup />
      )}
      {clientFolderId != null && <ClientSettings id={clientFolderId} />}
      {userCreateUpdateID != null && <UserSettings id={userCreateUpdateID} />}
      {folderInfo && <FolderSettings />}
      {driver && <DriverSettings />}
      {driverFolder && <DriverFolderSettings />}
      <ForbiddenAlert />

      <SuccessAlert />
    </div>
  )
}
